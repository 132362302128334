import React, { useEffect } from 'react';
import { DialogProps } from '@material-ui/core/Dialog';
import StyledDialog from '../../../../components/StyledDialog';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Checkbox,
  FormGroup,
} from '@material-ui/core';
import StyledButton from '../../../../components/StyledButton';
import StyledTextFieldWhite from '../../../../components/StyledTextFieldWhite';
import StyledSelectField from '../../../../components/Forms/FormikSelect';
import StyledMenuItem from '../../../../components/StyledMenuItem';
import StyledFormControl from '../../../../components/StyledFormControl';
import StyledFormControlLabel from '../../../../components/StyledFormControlLabel';
import { CareLineModel } from '@cuidador/database';

import { StyledForm } from './styles';
import { Formik } from 'formik';

import { FormValues, validationSchema } from '../utils';
import useCareCategory from '../../../../hooks/useCareCategory';

interface CareLineDialogProps extends DialogProps {
  careLine?: CareLineModel;
  onConfirm: (values: FormValues) => void;
  onCancel: () => void;
}

const CareLineDialog: React.FC<CareLineDialogProps> = ({
  careLine,
  onCancel,
  onConfirm,
  ...rest
}) => {
  const formInitialValue = {
    name: careLine?.name || '',
    careCategoryId: careLine?.careCategory?.id || undefined,
    isNightShiftDisabled: careLine?.isNightShiftDisabled || undefined,
  };

  const isEdit = careLine ? true : false;

  const { getAll, ids, byId } = useCareCategory();
  const careCategory = ids.map((id) => byId[id]);

  useEffect(() => {
    getAll();
  }, []);

  return (
    <StyledDialog {...rest}>
      <DialogContent>
        <DialogTitle>
          {isEdit ? 'Editar' : 'Adicionar'} linha de cuidado
        </DialogTitle>

        <Formik
          validationSchema={validationSchema}
          initialValues={formInitialValue as FormValues}
          onSubmit={onConfirm}
          validateOnChange={true}
        >
          {(formik) => (
            <StyledForm noValidate={true}>
              <DialogContentText>
                <StyledSelectField
                  name="careCategoryId"
                  label="Nome da categoria de cuidado"
                  value={formik.values?.careCategoryId || ''}
                  SelectDisplayProps={{
                    'data-testid': 'categoryId',
                  }}
                  color="secondary"
                >
                  {careCategory.map((item) => (
                    <StyledMenuItem
                      key={item.id}
                      value={item.id}
                      color="secondary"
                    >
                      {item.name}
                    </StyledMenuItem>
                  ))}
                </StyledSelectField>
                <StyledTextFieldWhite
                  name="name"
                  label="Nome da linha de cuidado"
                  inputProps={{ 'data-testid': 'name-textbox' }}
                  value={formik.values.name}
                  error={Boolean(formik.errors.name)}
                  helperText={formik.errors.name}
                  onChange={formik.handleChange}
                  margin="normal"
                />
                <StyledFormControl>
                  <FormGroup aria-label="position">
                    <StyledFormControlLabel
                      value={formik?.values?.isNightShiftDisabled || undefined}
                      name="isNightShiftDisabled"
                      control={
                        <Checkbox
                          color="primary"
                          checked={
                            formik?.values?.isNightShiftDisabled ? true : false
                          }
                          onChange={(e) =>
                            formik.setFieldValue(
                              'isNightShiftDisabled',
                              e.target.checked
                            )
                          }
                        />
                      }
                      label="Desativar plantão noturno"
                    />
                  </FormGroup>
                </StyledFormControl>
              </DialogContentText>
              <DialogActions>
                <StyledButton
                  disabled={formik.isSubmitting}
                  onClick={onCancel}
                  color="inherit"
                >
                  Cancelar
                </StyledButton>
                <StyledButton
                  disabled={formik.isSubmitting}
                  type="submit"
                  color="inherit"
                  data-testid="save-careLine-button"
                >
                  Salvar
                </StyledButton>
              </DialogActions>
            </StyledForm>
          )}
        </Formik>
      </DialogContent>
    </StyledDialog>
  );
};

export default CareLineDialog;
