import React from 'react';
import { DialogProps } from '@material-ui/core/Dialog';

import StyledDialog from '../../StyledDialog';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from '@material-ui/core';

import StyledButton from '../../StyledButton';

interface ConfirmLogoutProps extends DialogProps {
  onConfirm: (() => void) | undefined;
  onCancel: () => void;
}

const ConfirmLogout: React.FC<ConfirmLogoutProps> = ({
  onCancel,
  onConfirm,
  ...rest
}) => {
  return (
    <StyledDialog {...rest}>
      <DialogContent>
        <DialogTitle>Sair</DialogTitle>
        <DialogContentText align="center">
          Deseja mesmo finalizar sua sessão?
        </DialogContentText>
        <DialogActions>
          <StyledButton onClick={onCancel} color="inherit">
            Cancelar
          </StyledButton>
          <StyledButton
            onClick={onConfirm}
            data-testid="confirm-logout-button"
            variant="text"
            color="inherit"
          >
            Sair
          </StyledButton>
        </DialogActions>
      </DialogContent>
    </StyledDialog>
  );
};

export default ConfirmLogout;
