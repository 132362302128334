import { AnswerConfig as DbAnswerConfig } from '@cuidador/database';
import { AnswerConfigRenderProps } from './AnswerConfigItem/index';
import { OptionProps } from './AnswerOption';

/**
 * Mount answer config db model from render model
 */
export const mountAnswerConfig = (
  answers: AnswerConfigRenderProps[]
): DbAnswerConfig => {
  const sortedAnswers = answers.sort((a, b) => {
    if (a.level > b.level) return 1;
    return -1;
  });
  const dbAnswerConfig: DbAnswerConfig = {
    type: sortedAnswers[0].type as DbAnswerConfig['type'],
    options: sortedAnswers[0].options.map((option) => ({
      id: option.id,
      value: option.value,
      needDescription: option.needDescription,
      nextAnswer:
        sortedAnswers[0].type === 'single' && option.hasNextAnswer
          ? getNextAnswerByLevelAndOptionRef(answers, 1, option.id)
          : undefined,
    })),
  };
  return dbAnswerConfig;
};
const getNextAnswerByLevelAndOptionRef = (
  answers: AnswerConfigRenderProps[],
  level: number,
  ref: string
): DbAnswerConfig => {
  const nextAnswer = answers.find(
    (answer) => answer.optionId === ref && level === answer.level
  );
  return {
    type: (nextAnswer?.type as DbAnswerConfig['type']) || 'single',
    options:
      nextAnswer?.options.map((option) => ({
        id: option.id,
        value: option.value,
        needDescription: option.needDescription,
        nextAnswer:
          nextAnswer.type === 'single' && option.hasNextAnswer
            ? getNextAnswerByLevelAndOptionRef(
                answers,
                nextAnswer.level + 1,
                option.id
              )
            : undefined,
      })) || ([] as DbAnswerConfig['options']),
  };
};

/**
 * Mount answer config render model from db model
 */
export const mountAnswerRender = (
  answerConfig: DbAnswerConfig
): AnswerConfigRenderProps[] => {
  const configs = getNextRenderConfigs({
    answerConfig,
    level: 0,
  });
  return configs;
};
type GetNextRenderConfigParam = {
  optionId?: OptionProps['id'];
  optionValue?: OptionProps['value'];
  level: AnswerConfigRenderProps['level'];
  answerConfig: DbAnswerConfig;
};
const getNextRenderConfigs = ({
  optionId,
  optionValue,
  level,
  answerConfig,
}: GetNextRenderConfigParam): AnswerConfigRenderProps[] => {
  const configs: AnswerConfigRenderProps[] = [];

  const options: OptionProps[] = [];
  answerConfig.options.forEach((option) => {
    const newOption: OptionProps = {
      hasNextAnswer: !!option.nextAnswer,
      needDescription: option.needDescription,
      id: option.id,
      value: option.value,
      type: answerConfig.type,
    };
    options.push(newOption);

    // recursivamente, busca as outras configs
    if (option.nextAnswer) {
      const nextAnswers = getNextRenderConfigs({
        optionId: newOption.id,
        optionValue: newOption.value,
        level: level + 1,
        answerConfig: option.nextAnswer,
      });
      configs.push(...nextAnswers);
    }
  });

  const thisConfig: AnswerConfigRenderProps = {
    level,
    options,
    optionId: optionId ? optionId : undefined,
    optionValue: optionValue ? optionValue : undefined,
    type: answerConfig.type,
  };
  return [thisConfig, ...configs];
};
