import { PatientModel } from '@cuidador/database';
import { useCallback, useReducer } from 'react';
import axios from '../config/axios';
import {
  createReducer,
  Item,
  PaginatedRequestParams,
  ReducerData,
} from '../utils/store/index';
import useMedia from './useMedia';

const endpoint = '/user/patient';

const initialData: ReducerData<PatientModel> = {
  byId: {} as Record<string, Item<PatientModel>>,
  ids: [] as Array<number>,
  total: 0,
  loading: false,
  error: null,
};

const usePatient = () => {
  const { getMedia } = useMedia();
  const [state, dispatch] = useReducer(
    createReducer<PatientModel>(),
    initialData
  );

  const getById = useCallback(async (id: Id) => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.get(`${endpoint}/${id}`);
      dispatch({ type: 'GET_BY_ID', payload: response.data });
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
    }
  }, []);

  const patch = useCallback(async (id: Id, data: Partial<PatientModel>) => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.patch(`${endpoint}/${id}`, data);
      dispatch({ type: 'UPDATE', payload: response.data });
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
      throw err;
    }
  }, []);

  const getPaginated = useCallback(async (params: PaginatedRequestParams) => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.get(`${endpoint}`, {
        params: { ...params },
      });
      dispatch({ type: 'PAGINATION', payload: response.data });
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
    }
  }, []);

  const getProfilePicture = useCallback(async (id: number) => {
    try {
      const response = await getMedia(`/media/profile-picture/patient/${id}`);
      return Promise.resolve(response);
    } catch (err) {
      return Promise.reject(err);
    }
  }, []);

  return {
    ...state,
    getById,
    patch,
    getPaginated,
    getProfilePicture,
  };
};

export default usePatient;
