import React, { useEffect } from 'react';
import {
  FieldsContainer,
  DefinitionContainer,
  QuestionTypeContainer,
  CareCategoryDiv,
  SelectDiv,
  OrderDiv,
  DescriptionDiv,
  FooterContainer,
  Backdrop,
  BackdropCircularProgress,
} from './styles';
import FormikTextField from '../Forms/FormikTextField';
import StyledSelect from '../Forms/FormikSelect';
import StyledMenuItem from '../StyledMenuItem';
import StyledButton from '../StyledButton';
import { Formik, Form, FormikHelpers } from 'formik';
import useCareCategory from '../../hooks/useCareCategory';
import {
  validationSchema,
  FormValues,
  formDataToInterviewQuestionModel,
} from './utils';
import AnswerConfigList from '../AnswerConfigList';
import { AnswerConfig, CareQuestionModel } from '@cuidador/database';

const InterviewQuestionUpsertForm: React.FC<{
  initialValues: FormValues;
  onSubmit: (interviewQuestion: CareQuestionModel) => void | Promise<void>;
  disableDefaultIsSubmittingHandle?: boolean;
}> = ({ initialValues, onSubmit, disableDefaultIsSubmittingHandle }) => {
  const [
    answerConfigBeingEdited,
    setAnswerConfigBeingEdited,
  ] = React.useState<AnswerConfig | null>(null);
  const { getAll, ids, byId, loading } = useCareCategory();

  useEffect(() => {
    getAll();
  }, []);

  const initialAnswerConfig = initialValues.answerConfig
    ? initialValues.answerConfig
    : undefined;

  const handleAnswerConfigChanges = (answerConfig: AnswerConfig) => {
    setAnswerConfigBeingEdited(answerConfig);
  };

  const handleSubmit = (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    const data = formDataToInterviewQuestionModel({
      ...values,
      answerConfig: answerConfigBeingEdited,
    });
    if (disableDefaultIsSubmittingHandle) setSubmitting(false);
    return onSubmit(data as CareQuestionModel);
  };

  if (loading) {
    return (
      <Backdrop open={loading}>
        {loading && (
          <BackdropCircularProgress data-testid="table-backdrop-spinner" />
        )}
      </Backdrop>
    );
  }

  return (
    <>
      <Formik
        initialValues={initialValues as FormValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnChange={false}
      >
        {(formik) => (
          <Form noValidate={true}>
            <FieldsContainer>
              <DefinitionContainer>
                <CareCategoryDiv>
                  <FormikTextField
                    name="name"
                    label="Nome da pergunta"
                    margin="normal"
                    inputProps={{
                      'data-testid': 'name',
                    }}
                  />
                </CareCategoryDiv>
                <SelectDiv>
                  <StyledSelect
                    name="careCategoryId"
                    label="Categoria de cuidado"
                    SelectDisplayProps={{
                      'data-testid': 'careCategoryId',
                    }}
                  >
                    {ids
                      .map((id) => byId[id])
                      .map((item) => (
                        <StyledMenuItem key={item.id} value={item.id}>
                          {item.name}
                        </StyledMenuItem>
                      ))}
                  </StyledSelect>
                </SelectDiv>
                <OrderDiv>
                  <FormikTextField
                    name="orderNumber"
                    label="Ordem"
                    margin="normal"
                    inputProps={{
                      'data-testid': 'orderNumber',
                    }}
                    type="number"
                  />
                </OrderDiv>
              </DefinitionContainer>
              <QuestionTypeContainer>
                <DescriptionDiv>
                  <FormikTextField
                    name="careQuestionText"
                    label="Descrição da pergunta"
                    margin="normal"
                    inputProps={{
                      'data-testid': 'careQuestionText',
                      maxlength: 500,
                    }}
                    multiline
                  />
                </DescriptionDiv>
              </QuestionTypeContainer>
            </FieldsContainer>
            <AnswerConfigList
              onChange={handleAnswerConfigChanges}
              initialData={initialAnswerConfig}
              interview={true}
            />
            <FooterContainer>
              <StyledButton
                color="inherit"
                data-testid="submit"
                type="submit"
                disabled={formik.isSubmitting}
              >
                Salvar
              </StyledButton>
            </FooterContainer>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default InterviewQuestionUpsertForm;
