import React from 'react';
import { StyledForm } from './styles';

import { Formik } from 'formik';
import { initialValues, FormValues, validationSchema } from '../utils';

import { DialogProps } from '@material-ui/core/Dialog';
import { DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import StyledDialog from '../../../../components/StyledDialog';
import StyledButton from '../../../../components/StyledButton';
import FormikTextField from '../../../../components/Forms/FormikTextField';

interface Props extends DialogProps {
  onConfirm: (values: FormValues) => void;
  onCancel: () => void;
}

const CreateReportDialog: React.FC<Props> = ({
  onCancel,
  onConfirm,
  ...rest
}) => {
  return (
    <StyledDialog {...rest}>
      <DialogContent>
        <DialogTitle>Gerar novo relatório</DialogTitle>
        <Formik
          initialValues={initialValues}
          onSubmit={onConfirm}
          validationSchema={validationSchema}
        >
          {({ isSubmitting }) => (
            <StyledForm noValidate={true}>
              <FormikTextField
                color="secondary"
                id="name"
                inputProps={{
                  'data-testid': 'name',
                }}
                label="Nome do relatório"
                name="name"
                margin="normal"
                autoComplete="off"
              />
              <FormikTextField
                color="secondary"
                id="startDate"
                inputProps={{
                  'data-testid': 'startDate',
                }}
                label="Data de início"
                name="startDate"
                margin="normal"
                autoComplete="off"
                type="date"
                InputLabelProps={{ shrink: true }}
              />
              <FormikTextField
                color="secondary"
                id="endDate"
                inputProps={{
                  'data-testid': 'endDate',
                }}
                label="Data final"
                name="endDate"
                margin="normal"
                autoComplete="off"
                type="date"
                InputLabelProps={{ shrink: true }}
              />
              <DialogActions>
                <StyledButton
                  disabled={isSubmitting}
                  onClick={onCancel}
                  color="inherit"
                >
                  Cancelar
                </StyledButton>
                <StyledButton
                  disabled={isSubmitting}
                  type="submit"
                  variant="text"
                  color="inherit"
                  data-testid="submit-report"
                >
                  Continuar
                </StyledButton>
              </DialogActions>
            </StyledForm>
          )}
        </Formik>
      </DialogContent>
    </StyledDialog>
  );
};

export default CreateReportDialog;
