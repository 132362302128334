import React from 'react';
import { Classification } from '@cuidador/shared';
import { Percentage } from './styles';

export const labels: {
  key: Classification;
  label: React.ReactElement | string;
}[] = [
  {
    key: 'good',
    label: (
      <>
        Bom: <Percentage $classification="good">90% a 100%</Percentage>
      </>
    ),
  },
  {
    key: 'moderate',
    label: (
      <>
        Alerta: <Percentage $classification="moderate">70% a 90%</Percentage>
      </>
    ),
  },
  {
    key: 'bad',
    label: (
      <>
        Atenção: <Percentage $classification="bad">0% a 70%</Percentage>
      </>
    ),
  },
];
