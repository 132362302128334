import styled from 'styled-components';
import { Typography } from '@material-ui/core';

export const CareLineName = styled(Typography).attrs({ variant: 'h6' })`
  margin: ${({ theme }) => theme.spacing(2, 0, 1, 1)};
`;

export const CareCategoryColumn = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);
  column-gap: ${({ theme }) => theme.spacing(2)}px;
  row-gap: ${({ theme }) => theme.spacing(2)}px;
`;

export const CareCategory = styled.div`
  align-self: start;
`;
