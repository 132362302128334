import { FormControlLabel } from '@material-ui/core';
import { Form as FormikForm } from 'formik';
import styled from 'styled-components';
import StyledButton from '../../StyledButton';

export const Container = styled.div``;

export const Description = styled.div`
  /* Medium screen  */
  @media only screen and (min-width: 768px) {
    max-width: 30rem;
  }
  line-height: 1.5;
  & p:nth-child(2) {
    text-align: center;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 1rem 0;
`;

export const Button = styled(StyledButton)`
  border: 1px solid ${({ theme }) => theme.palette.extra.text.white};
  color: ${({ theme }) => theme.palette.extra.text.white};
  margin: 0 1rem;
`;

export const Form = styled(FormikForm)`
  display: flex;
  flex: 1;
  flex-direction: column;
  color: ${({ theme }) => theme.palette.extra.text.sectionColor};
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export const ReasonContainer = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.palette.extra.text.sectionColor};
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
  color: ${({ theme }) => theme.palette.extra.text.sectionColor};
  & .MuiRadio-root {
    color: ${({ theme }) => theme.palette.extra.text.sectionColor};
  }
  & .MuiRadio-colorSecondary.Mui-checked {
    color: ${({ theme }) => theme.palette.extra.text.sectionColor};
  }
`;
