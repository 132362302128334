import { Dictionary } from 'lodash';
import {
  AnswerType,
  CareQuestionAnswerModel,
  CareQuestionModel,
  PatientInterviewModel,
} from '@cuidador/database';

export const generateAnswerDictionaryFromQuestionDictionary = (
  questionDictionary: Dictionary<CareQuestionModel[]>
): Dictionary<CareQuestionAnswerModel[]> => {
  const answerDictionary: Dictionary<CareQuestionAnswerModel[]> = {};

  Object.keys(questionDictionary).map((key) => {
    const questions = questionDictionary[key];
    const answers = questions.map(
      (question) =>
        ({
          careQuestion: question,
          questionAnswerData: question.answerConfig,
        } as CareQuestionAnswerModel)
    );
    answerDictionary[key] = answers;
  });
  return answerDictionary;
};

type GetCurrentCareQuestionAnswerParams = {
  careQuestionAnswerDictionary: Dictionary<CareQuestionAnswerModel[]>;
  careQuestionIdToMatch: number;
};
export const getCurrentCareQuestionAnswerList = (
  params: GetCurrentCareQuestionAnswerParams
) => {
  const { careQuestionAnswerDictionary, careQuestionIdToMatch } = params;
  const allCareQuestionAnswerList = Object.entries(
    careQuestionAnswerDictionary
  ).map((careQuestionAnswersMap) => {
    const careQuestionAnswerList = careQuestionAnswersMap[1];
    return careQuestionAnswerList;
  });
  const currentCareQuestionAnswerList = allCareQuestionAnswerList.find(
    (careQuestionAnswerList) =>
      careQuestionAnswerList.find(
        (answer) => answer.careQuestion?.id === careQuestionIdToMatch
      )
  );

  return currentCareQuestionAnswerList;
};

type BuildNewCareQuestionAnswerListParams = {
  careQuestionAnswerToChange: CareQuestionAnswerModel;
  newQuestionAnswerData: AnswerType;
  careQuestionAnswerList: CareQuestionAnswerModel[];
};
export const buildNewCareQuestionAnswerList = (
  params: BuildNewCareQuestionAnswerListParams
) => {
  const {
    careQuestionAnswerToChange,
    newQuestionAnswerData,
    careQuestionAnswerList,
  } = params;
  const newCareQuestionAnswer: CareQuestionAnswerModel = {
    ...careQuestionAnswerToChange,
    questionAnswerData: newQuestionAnswerData,
  };
  const indexToReplace = careQuestionAnswerList.indexOf(
    careQuestionAnswerToChange
  );
  const newCareQuestionAnswerList = careQuestionAnswerList.map(
    (answer, index) => {
      if (index !== indexToReplace) return answer;
      return newCareQuestionAnswer;
    }
  );
  return newCareQuestionAnswerList;
};

type BuildInterviewDataParams = {
  patientId: Id;
  interviewName: string;
  interviewDate: string;
  careQuestionAnswerDictionary: Dictionary<CareQuestionAnswerModel[]>;
};
export const buildInterviewAnswerData = (
  params: BuildInterviewDataParams
): PatientInterviewModel => {
  const {
    patientId,
    interviewName,
    interviewDate,
    careQuestionAnswerDictionary,
  } = params;

  const careQuestionAnswerList: CareQuestionAnswerModel[] = [];

  Object.entries(careQuestionAnswerDictionary)
    .map((careQuestionAnswersMap) => {
      const careQuestionAnswerList = careQuestionAnswersMap[1];
      return careQuestionAnswerList;
    })
    .forEach((list) => careQuestionAnswerList.push(...list));

  /**
   * Get only careQuestionId and questionAnswerData
   */
  const mappedCareQuestionAnswerList = careQuestionAnswerList.map(
    (careQuestionAnswer) =>
      ({
        careQuestionId: careQuestionAnswer.careQuestion?.id,
        questionAnswerData: careQuestionAnswer.questionAnswerData,
      } as CareQuestionAnswerModel)
  );

  const interviewAnswerData: PatientInterviewModel = {
    patientId: patientId as number,
    name: interviewName,
    interviewedAt: interviewDate,
    careQuestionAnswers: mappedCareQuestionAnswerList,
  };
  return interviewAnswerData;
};
