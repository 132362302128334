import {
  TableCell as MaterialTableCell,
  TableContainer as MaterialTableContainer,
  Backdrop as MaterialBackdrop,
  CircularProgress as MaterialCircularProgress,
} from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled.div`
  /* relative to hold the Backdrop absolute layer */
  position: relative;
  overflow-y: hidden;
`;

export const Backdrop = styled(MaterialBackdrop)`
  z-index: ${({ theme }) => theme.zIndex.drawer + 1};
  color: ${({ theme }) => theme.palette.primary.light};
  position: absolute;
`;

export const BackdropCircularProgress = styled(MaterialCircularProgress).attrs({
  color: 'inherit',
})``;

export const TableContainer = styled(MaterialTableContainer)`
  /* Table should have full-height, subtracting 96px for both
     header and pagination containers
  */
  max-height: calc(100vh - 96px - 96px);
  min-height: calc(100vh - 96px - 96px);
`;

export const HeaderCell = styled(MaterialTableCell)`
  background-color: ${({ theme }) => theme.palette.primary.light};
  color: ${({ theme }) => theme.palette.primary.main};
  text-align: left;
  font-size: ${({ theme }) => theme.typography.body1.fontSize};
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${({ theme }) => theme.spacing(3)}px;
`;
