import React, { useContext, useState } from 'react';
import AccountCircle from '@material-ui/icons/AccountCircle';
import SupervisorAccount from '@material-ui/icons/SupervisorAccount';
import Dashboard from '@material-ui/icons/Dashboard';
import Forum from '@material-ui/icons/Forum';
import ContentIcon from '../../assets/Icon-content.png';
import ReportsIcon from '../../assets/Icon-reports.png';
import UsersIcon from '../../assets/Icon-users.png';

import MenuItem from './MenuItem';
import StyledButton from '../StyledButton';
import logo from '../../assets/logo.svg';
import ConfirmLogout from './ConfirmLogout';
import { Container, LogoContainer, LogoutContainer, StyledImg } from './styles';
import { AuthContext } from '../../contexts/auth';

const Sidebar: React.FC = () => {
  const { signOut } = useContext(AuthContext);
  const [isLogoutModalVisible, setIsLogoutModalVisible] = useState(false);
  const closeModal = () => setIsLogoutModalVisible(false);

  return (
    <>
      <Container>
        <LogoContainer>
          <img src={logo} alt="Cuidador de Confiança" />
        </LogoContainer>
        <nav>
          <MenuItem to="/dashboard">
            <Dashboard /> Dashboard
          </MenuItem>
          <MenuItem to="/pacientes">
            <AccountCircle /> Pacientes
          </MenuItem>
          <MenuItem to="/cuidadores">
            <SupervisorAccount /> Rede de Cuidados
          </MenuItem>
          <MenuItem to="/relatorios/linha-de-cuidado">
            <StyledImg src={ReportsIcon} /> Relatórios
          </MenuItem>
          <MenuItem to="/cuidados/categorias-de-cuidado">
            <Forum /> Cuidados
          </MenuItem>
          <MenuItem to="/conteudo">
            <StyledImg src={ContentIcon} /> Conteúdos
          </MenuItem>
          <MenuItem to="/usuarios/todos-usuarios">
            <StyledImg src={UsersIcon} />
            Gerenciar usuários
          </MenuItem>
          <LogoutContainer>
            <StyledButton
              data-testid="logout-button"
              onClick={() => setIsLogoutModalVisible(true)}
              fullWidth
              color="inherit"
            >
              Sair
            </StyledButton>
          </LogoutContainer>
        </nav>
      </Container>
      <ConfirmLogout
        open={isLogoutModalVisible}
        onCancel={closeModal}
        onConfirm={signOut}
      />
    </>
  );
};

export default Sidebar;
