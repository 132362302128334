import React, { memo, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoadingBackdrop from '../../../components/LoadingBackdrop';
import Form from '../../../components/RolePermissions/Form';
import { FormValues } from '../../../components/RolePermissions/utils';
import usePermissions from '../../../hooks/usePermissions';
import useRoles from '../../../hooks/useRoles';
import { resolveErrorMessage } from '../../../utils/error';

type RouteParams = {
  id: string;
};

const UpsertRole: React.FC = () => {
  const { id } = useParams<RouteParams>();
  const { byId, loading: loadingRoles, getById, patch } = useRoles();
  const { loading: loadingPermissions, allPermissions } = usePermissions();
  const history = useHistory();

  useEffect(() => {
    getById(id);
  }, [getById, id]);

  const role = byId[id];

  const handleSubmit = (values: FormValues) => {
    return patch(id, values)
      .then(() => {
        toast.success('Alterações salvas com sucesso');
        history.push('/usuarios/perfis');
      })
      .catch((err) => {
        const displayMessage = resolveErrorMessage(err);
        toast.error(displayMessage);
      });
  };

  if (loadingRoles || loadingPermissions) {
    return <LoadingBackdrop loading={loadingRoles || loadingPermissions} />;
  }

  if (!role || !allPermissions) {
    return null;
  }

  const formInitialValue = {
    title: role.title || '',
    description: role.description || '',
    permissions: role.permissions
      ? role.permissions.map(({ id }) => ({ id } as { id: number }))
      : [],
    isVisible: role.isVisible || false,
    isAutoPatientRelateEnabled: role.isAutoPatientRelateEnabled || false,
    guardianAppAccess: role.guardianAppAccess || false,
    caregiverAppAccess: role.caregiverAppAccess || false,
    adminAppAccess: role.adminAppAccess || false,
  };

  return (
    <Form
      handleSubmit={handleSubmit}
      formInitialValue={formInitialValue}
      allPermissions={allPermissions}
      role={role}
      hasDeleteAction={true}
      saveButtonLabel="Salvar alterações"
    />
  );
};

export default memo(UpsertRole);
