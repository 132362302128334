import { Typography } from '@material-ui/core';
import styled from 'styled-components';

interface RuleTextProps {
  isValid: boolean;
  submitError: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const RuleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacing(1)}px;
`;

export const Title = styled(Typography)`
  color: ${({ theme }) => theme.palette.grey[800]};
  font-weight: bold;
  margin-left: ${({ theme }) => theme.spacing(0.5)}px;
`;

export const RuleText = styled(Typography)<RuleTextProps>`
  color: ${({ isValid, submitError, theme }) =>
    isValid
      ? theme.palette.success.main
      : submitError
      ? theme.palette.error.dark
      : theme.palette.grey[800]};
  text-align: left;
  font-size: clamp(14px, 3.5vw, 0.9rem);
  font-weight: bold;
  margin-left: ${({ theme }) => theme.spacing(1)}px;
`;
