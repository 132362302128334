import React from 'react';
import { StyledPaperCard } from './styles';

import { Typography } from '@material-ui/core';

interface Props {
  title: string;
}

const StyledDashboardCard: React.FC<Props> = ({ title, children }) => {
  return (
    <StyledPaperCard>
      <Typography variant="h5">{title}</Typography>
      {children}
    </StyledPaperCard>
  );
};

export default StyledDashboardCard;
