import React, { useRef, useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { Formik, Form, FormikProps, FormikHelpers } from 'formik';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import { APIError } from '@cuidador/lib';
import { resolveErrorMessage } from '../../utils/error';
import Button from '../../components/StyledButton';
import PasswordRules from '../../components/PasswordRules';
import logo from '../../assets/logo.svg';

import {
  validationSchema,
  formInitialValues,
  FormValues,
  qsStringToObject,
  replaceGlobalLocation,
} from './utils';

import {
  Background,
  Container,
  PasswordRulesContainer,
  StyledTypography,
  HeadingContainer,
  StyledTextField,
} from './styles';
import { resetPassword, AuthContext } from '../../contexts/auth';

const ChangePassword: React.FC = () => {
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showRetypePassword, setShowRetypePassword] = useState(false);
  const [validationError, setValidationError] = useState(false);
  const formikRef = useRef<FormikProps<FormValues> | null>();
  const history = useHistory();
  const { user } = useContext(AuthContext);

  useEffect(() => {
    const { recoverPasswordToken, redirectUrl } = qsStringToObject(
      history.location.search
    );
    if (!recoverPasswordToken || Boolean(user)) {
      if (redirectUrl) {
        replaceGlobalLocation(redirectUrl as string);
      } else {
        history.replace('/');
      }
      return;
    }
  }, [history.location.search]);

  const handleSubmit = (
    values: FormValues,
    { setSubmitting, setFieldError }: FormikHelpers<FormValues>
  ) => {
    if (values.newPassword === values.retypePassword) {
      const { recoverPasswordToken, redirectUrl } = qsStringToObject(
        history.location.search
      );
      setSubmitting(true);
      resetPassword(String(recoverPasswordToken), values.newPassword)
        .then(() => {
          toast.info('Senha redefinida com sucesso.');
        })
        .catch((err: AxiosError<APIError>) => {
          const displayMessage = resolveErrorMessage(err);
          toast.error(displayMessage);
        })
        .finally(() => {
          setSubmitting(false);
          replaceGlobalLocation(redirectUrl as string);
        });
    } else {
      setSubmitting(false);
      setFieldError(
        'retypePassword',
        'A confirmação de senha está diferente da nova senha'
      );
    }
  };

  const handleShowNewPassword = () => setShowNewPassword(!showNewPassword);
  const handleShowRetypePassword = () =>
    setShowRetypePassword(!showRetypePassword);

  return (
    <Background>
      <Container>
        <img src={logo} />
        <HeadingContainer>
          <StyledTypography variant="h6">
            Defina a nova senha para acessar sua conta!
          </StyledTypography>
        </HeadingContainer>
        <Formik
          innerRef={(ref) => (formikRef.current = ref)}
          initialValues={formInitialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(formik) => (
            <Form>
              <StyledTextField
                name="newPassword"
                type={showNewPassword ? 'text' : 'password'}
                label="Nova senha"
                value={formik.values.newPassword}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.newPassword)}
                helperText={formik.errors.newPassword}
                margin="normal"
                fullWidth
                inputProps={{ 'data-testid': 'newPassword' }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleShowNewPassword}>
                        {showNewPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <StyledTextField
                name="retypePassword"
                type={showRetypePassword ? 'text' : 'password'}
                label="Confirmar nova senha"
                value={formik.values.retypePassword}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.retypePassword)}
                helperText={formik.errors.retypePassword}
                margin="normal"
                fullWidth
                inputProps={{ 'data-testid': 'retypePassword' }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleShowRetypePassword}>
                        {showRetypePassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <PasswordRulesContainer>
                <PasswordRules
                  password={formik.values.newPassword}
                  validationError={validationError}
                />
              </PasswordRulesContainer>
              <Button
                data-testid="submit"
                size="large"
                color="inherit"
                type="submit"
                disabled={formik.isSubmitting}
                onClick={() => setValidationError(true)}
              >
                Confirmar
              </Button>
            </Form>
          )}
        </Formik>
      </Container>
    </Background>
  );
};

export default ChangePassword;
