import styled from 'styled-components';
import { DialogTitle } from '@material-ui/core';

export const DialogSubtitle = styled.div`
  display: flex;
  width: 100%;
  align-self: center;
  justify-content: center;
  text-align: center;
`;

export const StyledDialogTitle = styled(DialogTitle)`
  word-wrap: break-word;
`;
