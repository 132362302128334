import React, { useEffect, useState } from 'react';
import {
  Container,
  CardsContainer,
  ContentContainer,
  ContentLine,
  StyledColumn,
} from './styles';

import { DashboardCaregiverCount } from '@cuidador/database';
import useCaregiverDashboard from '../../../hooks/useCaregiverDashboard';

import { toast } from 'react-toastify';
import { Typography } from '@material-ui/core';
import LoadingBackdrop from '../../../components/LoadingBackdrop';
import StyledDashboardCard from '../../../components/StyledDashboardCard';

const CaregiverDashboard: React.FC = () => {
  const [data, setData] = useState<DashboardCaregiverCount[]>([]);
  const [loading, setLoading] = useState(true);

  const { getAll } = useCaregiverDashboard();

  useEffect(() => {
    getAll()
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
        toast.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const levelJunior = data.find((item) => {
    return item.caregiverLevel == 'junior';
  });

  const levelFull = data.find((item) => {
    return item.caregiverLevel == 'full';
  });

  const levelSenior = data.find((item) => {
    return item.caregiverLevel == 'senior';
  });

  const withoutLevel = data.find((item) => {
    return item.caregiverLevel == null;
  });

  const totalActiveCaregivers = data.reduce((sum, current) => {
    return sum + current.count;
  }, 0);

  if (loading) {
    return <LoadingBackdrop loading={loading} />;
  }

  return (
    <Container data-testid="caregiver-dash-container">
      <CardsContainer>
        <StyledDashboardCard title="Cuidadores ativos">
          <ContentContainer>
            <Typography variant="h3">{totalActiveCaregivers}</Typography>
          </ContentContainer>
        </StyledDashboardCard>
        <StyledDashboardCard title="Cuidadores por nível">
          <ContentContainer>
            <ContentLine>
              <StyledColumn>
                <Typography variant="h5">Júnior</Typography>
                <Typography variant="h5">Pleno</Typography>
                <Typography variant="h5">Sênior</Typography>
                <Typography variant="h5">-</Typography>
              </StyledColumn>
              <StyledColumn>
                <Typography variant="h5">{levelJunior?.count}</Typography>
                <Typography variant="h5">{levelFull?.count}</Typography>
                <Typography variant="h5">{levelSenior?.count}</Typography>
                <Typography variant="h5">{withoutLevel?.count}</Typography>
              </StyledColumn>
            </ContentLine>
          </ContentContainer>
        </StyledDashboardCard>
      </CardsContainer>
    </Container>
  );
};

export default CaregiverDashboard;
