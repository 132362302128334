import styled from 'styled-components';
import { Paper } from '@material-ui/core';

export const Container = styled.div`
  margin: ${({ theme }) => theme.spacing(2, 4, 2)};
`;

export const StyledPaperTab = styled(Paper)`
  border-radius: 12px;
`;
