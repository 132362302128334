import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import StyledSelect from '../../../../components/StyledSelectField';
import StyledMenuItem from '../../../../components/StyledMenuItem';
import {
  Background,
  HeadingContainer,
  TypographyContainer,
  StyledTextFieldForm,
} from './styles';
import formatDate from 'date-fns/format';
import useCaregiver from '../../../../hooks/useCaregiver';
import { timezoneUnawareDate } from '../../../../utils/date';
import { cpfMask } from '../../../../utils/inputs';
import LoadingBackdrop from '../../../../components/LoadingBackdrop';

const CaregiverItem: React.FC = () => {
  const params = useParams<{ id: string }>();
  const { getById, byId, loading } = useCaregiver();
  const id = parseInt(params.id);

  useEffect(() => {
    getById(id);
  }, [id]);

  const item = byId[params.id];
  if (item?.user?.cpf) {
    item.user.cpf = cpfMask(item.user.cpf);
  }

  if (loading) {
    return <LoadingBackdrop loading={loading} />;
  }

  return (
    <>
      <Background>
        <HeadingContainer>
          <TypographyContainer>
            <Typography variant="h5">Informações pessoais</Typography>
          </TypographyContainer>
          <StyledTextFieldForm
            name="CPF"
            label="CPF"
            disabled
            value={item?.user?.cpf}
            margin="normal"
            fullWidth
            inputProps={{ 'data-testid': 'email' }}
          />
          <StyledTextFieldForm
            name="phoneNumber"
            label="Telefone"
            disabled
            value={item?.user?.phoneNumber}
            margin="normal"
            fullWidth
            inputProps={{ 'data-testid': 'phoneNumber' }}
          />
          <StyledTextFieldForm
            name="email"
            label="E-mail"
            disabled
            value={item?.user?.email}
            margin="normal"
            fullWidth
            inputProps={{ 'data-testid': 'email' }}
          />
          <StyledSelect
            name="gender"
            id="gender"
            label="Sexo"
            disabled
            value={item?.user?.gender}
          >
            <StyledMenuItem value="female">Feminino</StyledMenuItem>
            <StyledMenuItem value="male">Masculino</StyledMenuItem>
            <StyledMenuItem value="other">Outro</StyledMenuItem>
          </StyledSelect>
          <StyledTextFieldForm
            name="dateOfBirth"
            label="Data de nascimento"
            disabled
            value={
              item?.user?.dateOfBirth &&
              formatDate(timezoneUnawareDate(item.user.dateOfBirth), 'dd/MM/yy')
            }
            margin="normal"
            fullWidth
            inputProps={{ 'data-testid': 'dateOfBirth' }}
          />
          <TypographyContainer>
            <Typography variant="h5">Educação</Typography>
          </TypographyContainer>
          <StyledTextFieldForm
            name="educationLevel"
            label="Escolaridade"
            disabled
            value={item?.educationLevel}
            margin="normal"
            fullWidth
            inputProps={{ 'data-testid': 'educationLevel' }}
          />
          <StyledSelect
            name="worksAsCaregiverForTimer"
            id="worksAsCaregiverForTimer"
            disabled
            label="Há quanto tempo trabalha como cuidador?"
            value={item?.worksAsCaregiverForTime}
          >
            <StyledMenuItem value="lessThan1Year">
              Menos de 1 ano
            </StyledMenuItem>
            <StyledMenuItem value="lessThan3Years">
              Entre 1 e 3 anos
            </StyledMenuItem>
            <StyledMenuItem value="lessThan5Years">
              Entre 3 e 5 anos
            </StyledMenuItem>
            <StyledMenuItem value="moreThan5Years">
              Mais de 5 anos
            </StyledMenuItem>
          </StyledSelect>
          <StyledTextFieldForm
            name="hasTakenProfessionalCourse"
            label="Já realizou algum curso profissionalizante?"
            disabled
            value={item?.hasTakenProfessionalCourse}
            margin="normal"
            fullWidth
            inputProps={{ 'data-testid': 'hasTakenProfessionalCourse' }}
          />
          <TypographyContainer>
            <Typography variant="h5">Agência</Typography>
          </TypographyContainer>
          <StyledSelect
            name="belongsToCaregiverAgency"
            id="belongsToCaregiverAgency"
            disabled
            label="Faz parte de alguma agência de cuidadores?"
            value={`${item?.belongsToCaregiverAgency}`}
          >
            <StyledMenuItem value={0}>Não</StyledMenuItem>
            <StyledMenuItem value={1}>Sim</StyledMenuItem>
          </StyledSelect>
          {!!item?.belongsToCaregiverAgency && (
            <StyledTextFieldForm
              name="caregiverAgencyName"
              label="Qual agência?"
              disabled
              value={item?.caregiverAgencyName}
              margin="normal"
              fullWidth
              inputProps={{ 'data-testid': 'caregiverAgencyName' }}
            />
          )}
        </HeadingContainer>
      </Background>
    </>
  );
};

export default CaregiverItem;
