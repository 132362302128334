import { Tab } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import Header from '../../../components/Header';
import StyledTabs from '../../../components/StyledTabs';
import useGuardian from '../../../hooks/useGuardian';
import usePatient from '../../../hooks/usePatient';
import useUser from '../../../hooks/useUser';
import ActivityList from '../ActivityList';
import ComplicationList from '../ComplicationList';
import InterviewList from '../InterviewList';
import MedicationList from '../MedicationList';
import Personal from '../Personal';
import Reports from '../Reports';
import AdhesionTable from '../AdhesionTable';
import {
  Backdrop,
  BackdropCircularProgress,
  Container,
  StyledPaperTab,
} from './styles';
import { tabOptions } from './utils';

const PatientItem: React.FC<RouteComponentProps<{ tabId: string }>> = (
  props
) => {
  const currentTab = props.match.params.tabId;
  const params = useParams<{ id: string }>();
  const [imageUrl, setImageUrl] = useState('');

  const { getById, byId, loading: loadingPatient } = usePatient();
  const { loading: loadingGuardian } = useGuardian();
  const { getProfilePicture } = useUser();

  const handleGetProfilePicture = async (id: number) => {
    try {
      return await getProfilePicture(id).then(({ data }) => data?.signedUrl);
    } catch (err) {
      console.log('error');
    }
  };

  const id = parseInt(params.id);
  useEffect(() => {
    async function fetchPatientProfilePicture() {
      const result = await handleGetProfilePicture(parseInt(`${id}`));
      if (result) {
        setImageUrl(result);
      }
    }

    getById(id);
    fetchPatientProfilePicture();
  }, [id]);

  const patient = byId[params.id];

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleChange = (_: React.ChangeEvent<{}>, newValue: string) => {
    props.history.replace(`/pacientes/${id}/${newValue}`);
  };

  // Loading should be shown when loading any of both
  // Patient or Guardian resources
  const loading = loadingPatient || loadingGuardian;
  if (loading) {
    return (
      <Backdrop open={loading}>
        {loading && (
          <BackdropCircularProgress data-testid="table-backdrop-spinner" />
        )}
      </Backdrop>
    );
  }

  if (!patient) {
    // Patient not yet loaded
    // [TODO] Handle errors
    return null;
  }

  return (
    <>
      <Header
        img={imageUrl}
        name={patient?.name}
        dateOfBirth={patient?.dateOfBirth}
      />
      <Container>
        <StyledPaperTab>
          <StyledTabs centered value={currentTab} onChange={handleChange}>
            {tabOptions.map((option) => (
              <Tab
                wrapped={true}
                key={option.key}
                label={option.label}
                value={option.key}
              />
            ))}
          </StyledTabs>
        </StyledPaperTab>
        {currentTab === 'pessoais' && <Personal />}
        {currentTab === 'intercorrencia' && <ComplicationList />}
        {currentTab === 'entrevistas' && <InterviewList />}
        {currentTab === 'medicamentos' && <MedicationList />}
        {currentTab === 'rotinas' && <ActivityList />}
        {currentTab === 'relatorios' && <Reports />}
        {currentTab === 'plantoes' && <AdhesionTable />}
      </Container>
    </>
  );
};

export default PatientItem;
