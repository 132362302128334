import React from 'react';
import { ErrorMessage, Field, useFormikContext } from 'formik';
import StyledTextField from '../StyledTextField';
import { TextFieldProps } from '@material-ui/core/TextField';

const FormikField = ({
  name,
  required = false,
  fullWidth = true,
  ...props
}: TextFieldProps & { name: string }) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { errors, touched } = useFormikContext<any>();

  const hasError = touched[name] && errors[name];

  return (
    <div>
      <Field
        required={required}
        autoComplete="off"
        as={StyledTextField}
        name={name}
        error={Boolean(hasError)}
        helperText={<ErrorMessage name={name} />}
        fullWidth={fullWidth}
        {...props}
      />
    </div>
  );
};

export default FormikField;
