import styled from 'styled-components';
import StyledButton from '../StyledButton';

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ExportButton = styled(StyledButton)`
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`;
