import { CareLineIndicatorMessageObject } from '@cuidador/database';

export interface NormalizedCareLineIndicatorMessageObject
  extends CareLineIndicatorMessageObject {
  id: string;
}

// normalizes CareLineIndicatorMessage by adding an id to differentiate between each CareLineIndicatorMessage
export const normalizeCareLineIndicatorMessage = (
  careLineIndicatorMessage: CareLineIndicatorMessageObject
): NormalizedCareLineIndicatorMessageObject => {
  return {
    ...careLineIndicatorMessage,
    id: `rule${careLineIndicatorMessage.careLineIndicatorRuleId}-line${careLineIndicatorMessage.careLineId}`,
  };
};
