import { useCallback, useReducer } from 'react';
import axios from '../config/axios';
import { DashboardCaregiverCount } from '@cuidador/database';
import { createReducer, ReducerData, Item } from '../utils/store/index';

const endpoint = '/report/dashboard/caregiver';

const initialData: ReducerData<DashboardCaregiverCount> = {
  byId: {} as Record<string, Item<DashboardCaregiverCount>>,
  ids: [] as Array<number>,
  total: 0,
  loading: false,
  error: null,
};

const useCaregiverDashboard = () => {
  const [state, dispatch] = useReducer(
    createReducer<DashboardCaregiverCount>(),
    initialData
  );

  const getAll = useCallback(async () => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.get(`${endpoint}/count`);
      return Promise.resolve(response.data);
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
    }
  }, []);

  return {
    ...state,
    getAll,
  };
};

export default useCaregiverDashboard;
