import React, { useCallback } from 'react';

import { StyledHTMLEditor } from './styles';

export interface HTMLEditorProps {
  onChange: (
    content: string,
    delta?: any, // eslint-disable-line
    source?: string,
    editor?: {
      getText: () => string;
    }
  ) => void;
  value?: string;
  id?: string;
  placeholder?: string;
}

// types only on ReactQuill v2 (beta)
const HTMLEditor: React.FC<HTMLEditorProps> = ({
  onChange,
  value,
  id,
  placeholder,
}) => {
  const handleChange = useCallback((content, delta, source, editor) => {
    if (editor.getText() === '\n') onChange('', delta, source, editor);
    else onChange(content, delta, source, editor);
  }, []);

  return (
    <StyledHTMLEditor
      id={id}
      placeholder={placeholder}
      value={value}
      onChange={handleChange}
    />
  );
};

export default HTMLEditor;
