import React, { useEffect, useCallback, useState } from 'react';
import { TableCell, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddCircleOutlineRounded';
import { useHistory, useParams } from 'react-router-dom';
import qs from 'query-string';
import { PatientInterviewModel } from '@cuidador/database';
import { resolveErrorMessage } from '../../../utils/error';
import { AxiosError } from 'axios';
import { APIError } from '@cuidador/lib';
import { toast } from 'react-toastify';
import MyTable from '../../../components/MyTable';
import CreateReportDialog from './CreateInterviewDialog';
import {
  StyledContainer,
  StyledLink,
  AddButton,
  EmptyTableTypography,
} from './styles';

import useInterview from '../../../hooks/useInterview';
import {
  FormValues,
  formDataToInterviewModel,
  formatStringDate,
  formatInterviewStatus,
  formatInterviewAction,
} from './utils';

const tableColumns = ['Nome da entrevista', 'Data da entrevista', 'Status', ''];

const InterviewList: React.FC = () => {
  const [newInterviewDialog, setNewInterviewDialog] = useState(false);
  const { id: patientId } = useParams<{ id: string }>();
  const {
    getByPatientPaginated,
    total,
    loading,
    ids,
    byId,
    post,
  } = useInterview();
  const history = useHistory();
  const limit = parseInt(String(qs.parse(history.location.search).limit)) || 20;

  useEffect(() => {
    onChangeParams();
  }, []);

  const onChangeParams = () => {
    const params = qs.parse(history.location.search);
    getByPatientPaginated(Number(patientId), {
      ...params,
      limit: Number(params.limit) || 20,
    });
  };

  const handleSubmit = (values: FormValues) => {
    return post(formDataToInterviewModel(values, patientId))
      .then(({ data }) => {
        setNewInterviewDialog(false);
        toast.success('Entrevista gerada com sucesso');
        history.push(`${history.location.pathname}/${data.id}`);
      })
      .catch((err: AxiosError<APIError>) => {
        const displayMessage = resolveErrorMessage(err);
        toast.error(displayMessage);
      });
  };

  const renderEmptyTable = useCallback(() => {
    return (
      <EmptyTableTypography variant="h6" color="textPrimary">
        Nenhuma entrevista encontrada para o paciente
      </EmptyTableTypography>
    );
  }, []);

  const renderPatientInterviews = useCallback((item: PatientInterviewModel) => {
    return (
      <>
        <TableCell>{item.name}</TableCell>
        <TableCell>{formatStringDate(item?.interviewedAt)}</TableCell>
        <TableCell>{formatInterviewStatus(item.isDraft)}</TableCell>
        <TableCell>
          <StyledLink to={`/pacientes/${patientId}/entrevistas/${item.id}`}>
            {formatInterviewAction(item.isDraft)}
          </StyledLink>
        </TableCell>
      </>
    );
  }, []);

  const data = ids.map((id) => byId[id]);

  return (
    <StyledContainer>
      <AddButton
        data-testid="create-interview-dialog-open"
        onClick={() => setNewInterviewDialog(true)}
      >
        <AddIcon fontSize="large" color="primary" />
        <Typography variant="h5">Adicionar</Typography>
      </AddButton>

      {data.length === 0 && !loading ? (
        renderEmptyTable()
      ) : (
        <MyTable
          columnNames={tableColumns}
          data={data}
          totalPages={Math.ceil(total / limit)}
          keyExtractor={({ item }) => String(item!.id)}
          renderItem={renderPatientInterviews}
          loading={loading}
          onChangePage={onChangeParams}
          onChangeRowsPerPage={onChangeParams}
        />
      )}
      <CreateReportDialog
        open={newInterviewDialog}
        onCancel={() => setNewInterviewDialog(false)}
        onConfirm={handleSubmit}
      />
    </StyledContainer>
  );
};

export default InterviewList;
