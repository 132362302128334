import { useCallback } from 'react';
import axios from '../config/axios';
import { downloadCSV } from '../utils/csv';
import { normalizeCaregiverMood } from '../utils/mood';
import { CaregiverMood } from '@cuidador/database';
import { parse } from 'json2csv';

const endpoint = '/care/mood';

const useMood = () => {
  const getMoodDataByCaregiver = useCallback(
    async (id: number, name: string | undefined) => {
      try {
        const response = await axios.get(`${endpoint}/by-caregiver/${id}`);
        const normalizedMood = response.data.map(
          (caregiverMood: CaregiverMood) =>
            normalizeCaregiverMood(caregiverMood, name)
        );

        const dataCsv = parse(normalizedMood, {
          fields: Object.keys(normalizedMood[0]),
          delimiter: ';',
        });
        const blobData = new Blob([dataCsv]);
        downloadCSV(blobData, `cuidar-cuidador-${name}-${Date.now()}`);
      } catch (err) {
        return Promise.reject(err);
      }
    },
    []
  );

  return {
    getMoodDataByCaregiver,
  };
};

export default useMood;
