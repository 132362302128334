import { CareQuestionModel } from '@cuidador/database';
import { APIError } from '@cuidador/lib';
import { Typography } from '@material-ui/core';
import { AxiosError } from 'axios';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import CareQuestionUpsertForm from '../../../../components/CareQuestionUpsertForm';
import { formInitialValue } from '../../../../components/CareQuestionUpsertForm/utils';
import HeaderBase from '../../../../components/HeaderBase';
import useCareQuestion from '../../../../hooks/useCareQuestion';
import { resolveErrorMessage } from '../../../../utils/error';
import { Container } from './styles';

const CareQuestionItem: React.FC = () => {
  const { post } = useCareQuestion();
  const history = useHistory();

  const handleSubmit = (data: CareQuestionModel) => {
    return post(data)
      .then(() => {
        history.goBack();
      })
      .catch((err: AxiosError<APIError>) => {
        const displayMessage = resolveErrorMessage(err);
        toast.error(displayMessage);
      });
  };

  return (
    <>
      <HeaderBase title={'PERGUNTA DE CUIDADO'} />
      <Container>
        <Typography variant="h5">Pergunta de cuidado</Typography>
        <CareQuestionUpsertForm
          initialValues={formInitialValue}
          onSubmit={handleSubmit}
        />
      </Container>
    </>
  );
};

export default CareQuestionItem;
