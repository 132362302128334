import Button from '@material-ui/core/Button';
import red from '@material-ui/core/colors/red';
import DeleteIcon from '@material-ui/icons/Delete';
import styled from 'styled-components';
import ComponentButton from '../../../components/StyledButton';

export const Container = styled.div`
  margin-top: ${({ theme }) => theme.spacing(2)}px;
  width: 100%;
`;

export const AddButton = styled(Button).attrs({
  variant: 'contained',
})`
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const Header = styled.div`
  display: flex;
  margin-bottom: ${({ theme }) => theme.spacing(3)}px;
  justify-content: space-between;
`;

export const StyledButton = styled(ComponentButton)`
  text-decoration: underline;
`;

export const DeleteButton = styled(DeleteIcon)`
  background-color: ${red[700]};
  color: ${({ theme }) => theme.palette.common.white};
  border-radius: ${({ theme }) => theme.spacing(0.5)}px;
  padding: 1px;
  cursor: pointer;
`;
