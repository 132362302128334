import { PatientModel } from '@cuidador/database';
import React, { useMemo, useState } from 'react';
import EditIconSvg from '../../assets/EditIconBlack.svg';
import { styledTheme } from '../../styles/theme';
import {
  ButtonContainer,
  ReasonDescription,
  ReasonLabel,
  StatusContainer,
  StatusDescriptionContainer,
  StatusLabelContainer,
  StatusOutterContainer,
} from './styles';
import UpdateStatusModal, {
  FormValues as UpdateStatusFormValues,
} from './UpdateStatusModal';
import {
  getPatientStatus,
  getPatientStatusLabelAfterReset,
  getStatusDescription,
  PatientStatusType,
  resolveDisablingReasonLabel,
  resolveStatusLabel,
} from './utils';

const EditIcon = () => <img src={EditIconSvg} />;

const StatusLabel: React.FC<{ status: PatientStatusType }> = ({ status }) => {
  let textColor;
  if (status === 'inactive') {
    textColor = styledTheme.palette.extra.patientStatusCard.inactive;
  }
  if (status === 'pending') {
    textColor = styledTheme.palette.extra.patientStatusCard.pending;
  }
  if (status === 'active') {
    textColor = styledTheme.palette.extra.patientStatusCard.active;
  }
  return (
    <span style={{ color: textColor, fontWeight: 'bold' }}>
      {resolveStatusLabel(status)}
    </span>
  );
};

const PatientStatus: React.FC<{
  patient: PatientModel;
  handlePatientDisableSubmit: (values: UpdateStatusFormValues) => Promise<void>;
  handlePatientEnableSubmit: () => Promise<void>;
}> = ({ patient, handlePatientDisableSubmit, handlePatientEnableSubmit }) => {
  const [statusDialogOpened, setStatusDialogOpened] = useState(false);

  const patientStatus = useMemo(() => {
    return getPatientStatus(patient.status!, Boolean(patient.isNew));
  }, [patient.status, patient.isNew]);

  const onPatientDisableSubmit = async (values: UpdateStatusFormValues) => {
    return handlePatientDisableSubmit(values).then(() => {
      setStatusDialogOpened(false);
    });
  };

  const onPatientEnableSubmit = async () => {
    return handlePatientEnableSubmit().then(() => {
      setStatusDialogOpened(false);
    });
  };

  return (
    <>
      <StatusOutterContainer>
        <StatusContainer>
          <StatusLabelContainer>
            <b>Status atual: </b>
            <StatusLabel status={patientStatus} />
          </StatusLabelContainer>
          <ButtonContainer>
            <button
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.preventDefault();
                setStatusDialogOpened(true);
              }}
            >
              <EditIcon data-testid="edit-patient-status" />
              {patientStatus === 'inactive' ? 'Restaurar Status' : 'Inativar'}
            </button>
          </ButtonContainer>
        </StatusContainer>

        {patientStatus === 'inactive' && (
          <>
            <StatusLabelContainer>
              <b>Motivo: </b>
              <ReasonLabel>
                {resolveDisablingReasonLabel(patient.disablingReason)}
              </ReasonLabel>
            </StatusLabelContainer>
            {patient.disablingReason === 'other' && (
              <ReasonDescription>
                {patient.disablingReasonDescription}
              </ReasonDescription>
            )}
          </>
        )}

        <StatusDescriptionContainer>
          <p>{getStatusDescription(patientStatus)}</p>
        </StatusDescriptionContainer>
      </StatusOutterContainer>

      {statusDialogOpened && (
        <UpdateStatusModal
          open={statusDialogOpened}
          onClose={() => setStatusDialogOpened(false)}
          onPatientDisableSubmit={onPatientDisableSubmit}
          onPatientEnableSubmit={onPatientEnableSubmit}
          patientStatus={patient.status}
          patientStatusLabelAfterReset={getPatientStatusLabelAfterReset(
            Boolean(patient.isNew)
          )}
          patientStatusDescription={getStatusDescription(
            getPatientStatus('enabled', Boolean(patient.isNew))
          )}
        />
      )}
    </>
  );
};

export default PatientStatus;
