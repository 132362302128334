import React, { useCallback, useEffect, useState } from 'react';
import _, { Dictionary } from 'lodash';
import { AxiosError } from 'axios';
import { APIError } from '@cuidador/lib';
import { resolveErrorMessage } from '../../../../utils/error';
import { useParams, useHistory } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import {
  CareQuestionAnswerModel,
  AnswerType,
  CareCategoryModel,
} from '@cuidador/database';
import {
  NavigationButtonsContainer,
  ButtonContainer,
  StyledContainer,
  InterviewConfigContainer,
  InterviewConfigForm,
  StyledInterviewNameTextField,
} from './styles';
import HeaderBase from '../../../../components/HeaderBase';
import LoadingBackdrop from '../../../../components/LoadingBackdrop';
import StyledButton from '../../../../components/StyledButton';
import StyledTextField from '../../../../components/StyledTextField';
import { toast } from 'react-toastify';
import {
  buildNewCareQuestionAnswerList,
  getCurrentCareQuestionAnswerList,
} from './utils';
import InterviewAnswerList from '../../../../components/InterviewAnswerList';
import useInterview from '../../../../hooks/useInterview';
import { format, startOfDay } from 'date-fns';
import * as Sentry from '@sentry/react';

const InterviewAnswerInsert: React.FC = () => {
  const [readOnly, setReadOnly] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [pageIndexes, setPageIndexes] = useState<string[]>([]);
  const [
    answersGroupedByCareCategoryDictionary,
    setAnswersGroupedByCareCategoryDictionary,
  ] = useState<Dictionary<CareQuestionAnswerModel[]>>();
  const [careCategoryList, setCareCategoryList] = useState<
    CareCategoryModel[]
  >();

  const [interviewName, setInterviewName] = useState('');
  const [interviewDate, setInterviewDate] = useState(
    format(startOfDay(new Date()), 'yyyy-MM-dd')
  );

  const history = useHistory();
  const { interviewId } = useParams<{
    interviewId: string;
  }>();

  const {
    getById: getInterviewById,
    postFinishInterview,
    patchInterviewCareQuestionAnswer,
  } = useInterview();

  useEffect(() => {
    async function loadInterview() {
      try {
        setLoading(true);
        const patientInterviewData = await getInterviewById(
          Number(interviewId)
        );
        if (!patientInterviewData) {
          return toast.error(
            'Erro ao carregar entrevista. Tente novamente daqui a pouco'
          );
        }
        // Create and Edit: isDraft true - not read only
        // Show: isDraft false - read only
        setReadOnly(!patientInterviewData.isDraft);

        setInterviewDate(
          format(
            new Date(String(patientInterviewData.interviewedAt)),
            'yyyy-MM-dd'
          )
        );
        setInterviewName(patientInterviewData.name!);

        const groupedAnswers = _.groupBy(
          patientInterviewData.careQuestionAnswers,
          'careQuestion.careCategoryId'
        );
        setAnswersGroupedByCareCategoryDictionary(groupedAnswers);

        const availableCareCategories = patientInterviewData?.careQuestionAnswers?.map(
          (answer) => {
            return answer.careQuestion?.careCategory as CareCategoryModel;
          }
        );
        const uniqueCareCategories = _.uniqBy(availableCareCategories, 'id');
        const orderedCareCategories = _.orderBy(uniqueCareCategories, 'order');
        setCareCategoryList(orderedCareCategories);

        const availableCareCategoryIds = orderedCareCategories.map(
          (careCategory) => String(careCategory.id)
        );
        setPageIndexes(availableCareCategoryIds);
      } catch (error) {
        console.log(error);
        toast.error(
          'Erro ao carregar entrevista, tente novamente daqui a pouco'
        );
      } finally {
        setLoading(false);
      }
    }

    loadInterview();
  }, []);

  const handleSubmitInterview = () => {
    setLoading(true);
    postFinishInterview(Number(interviewId))
      .then(() => {
        history.goBack();
        toast.info('Entrevista salva com sucesso');
      })
      .catch((err: AxiosError<APIError>) => {
        const displayMessage = resolveErrorMessage(err);
        toast.error(displayMessage);
      })
      .finally(() => setLoading(false));
  };

  const getCurrentPageAnswers = (): CareQuestionAnswerModel[] => {
    const careCategoryId = pageIndexes[currentIndex];
    if (!answersGroupedByCareCategoryDictionary) return [];
    return answersGroupedByCareCategoryDictionary[careCategoryId];
  };

  /**
   * Resets the interview answers dictionary given an careQuestionId
   * and the new CareQuestionAnswer.questionAnswerData.
   */
  type BuildNewAnswerListParams = {
    careQuestionId: Id;
    newQuestionAnswerData: AnswerType;
  };
  const buildNewAnswerList = useCallback(
    (params: BuildNewAnswerListParams) => {
      const { careQuestionId, newQuestionAnswerData } = params;
      setAnswersGroupedByCareCategoryDictionary(
        (answersGroupedByCareCategoryDictionary) => {
          if (!answersGroupedByCareCategoryDictionary) return;

          const currentCareQuestionAnswerList = getCurrentCareQuestionAnswerList(
            {
              careQuestionAnswerDictionary: answersGroupedByCareCategoryDictionary,
              careQuestionIdToMatch: careQuestionId as number,
            }
          );
          if (!currentCareQuestionAnswerList) return;

          const careQuestionAnswerToChange = currentCareQuestionAnswerList.find(
            (answer) => answer.careQuestion?.id === careQuestionId
          );
          if (!careQuestionAnswerToChange) return;

          const newCareQuestionAnswerList = buildNewCareQuestionAnswerList({
            careQuestionAnswerToChange,
            newQuestionAnswerData,
            careQuestionAnswerList: currentCareQuestionAnswerList,
          });

          const careCategoryId = Number(
            careQuestionAnswerToChange?.careQuestion?.careCategoryId
          );
          const newCareQuestionAnswerDictionary = {
            ...answersGroupedByCareCategoryDictionary,
            [`${careCategoryId}`]: newCareQuestionAnswerList,
          };
          return newCareQuestionAnswerDictionary;
        }
      );
    },
    [setAnswersGroupedByCareCategoryDictionary]
  );

  const handleAnswerOnChange = useCallback(
    (careQuestionId: Id, newQuestionAnswerData: AnswerType) => {
      buildNewAnswerList({ careQuestionId, newQuestionAnswerData });
    },
    [buildNewAnswerList]
  );

  const handleAnswerOnChangeDebounce = useCallback(
    (careQuestionId: number, newQuestionAnswerData: AnswerType) => {
      patchInterviewCareQuestionAnswer(
        Number(interviewId),
        careQuestionId,
        newQuestionAnswerData
      ).catch((err) => {
        Sentry.captureException(err);
        return toast.error(
          'Erro ao salvar resposta. Tente novamente daqui a pouco'
        );
      });
    },
    [handleAnswerOnChange]
  );

  const RenderAnswersPageHeader = () => {
    const careCategoryId = pageIndexes[currentIndex];
    const careCategory = careCategoryList?.find(
      (category) => category.id === Number(careCategoryId)
    );
    return (
      <>
        <Typography variant="h5" color="primary">
          {careCategory?.name} - {currentIndex + 1}/{pageIndexes.length}
        </Typography>
      </>
    );
  };

  const RenderNavigationButtons = () => {
    const isFirstPage = currentIndex === 0;
    const isLastPage = currentIndex === pageIndexes.length - 1;
    return (
      <NavigationButtonsContainer>
        <ButtonContainer>
          <StyledButton
            color="primary"
            disabled={isFirstPage}
            onClick={() => {
              window.scrollTo(0, 0);
              setCurrentIndex(currentIndex - 1);
            }}
          >
            Anterior
          </StyledButton>
        </ButtonContainer>
        <ButtonContainer>
          {!isLastPage ? (
            <StyledButton
              color="primary"
              onClick={() => {
                window.scrollTo(0, 0);
                setCurrentIndex(currentIndex + 1);
              }}
            >
              Próximo
            </StyledButton>
          ) : (
            <StyledButton
              color="primary"
              onClick={handleSubmitInterview}
              disabled={readOnly}
            >
              Salvar
            </StyledButton>
          )}
        </ButtonContainer>
      </NavigationButtonsContainer>
    );
  };

  if (loading) return <LoadingBackdrop loading={loading} />;

  return (
    <>
      <HeaderBase title={'ENTREVISTA'} />
      <StyledContainer fixed>
        <InterviewConfigContainer>
          <Typography variant="h5" color="primary">
            Entrevista
          </Typography>
          <InterviewConfigForm>
            {/* HARD CODED: disable name and date interview fields */}
            <StyledInterviewNameTextField
              disabled={true}
              label="Nome da entrevista"
              inputProps={{
                maxLength: 100,
                'data-testid': 'interviewName',
              }}
              value={interviewName}
              onChange={(e) => setInterviewName(e.target.value)}
            />
            <StyledTextField
              disabled={true}
              label="Data"
              type="date"
              inputProps={{
                'data-testid': 'interviewedAt',
              }}
              value={interviewDate}
              onChange={(e) => setInterviewDate(e.target.value)}
            />
          </InterviewConfigForm>
        </InterviewConfigContainer>
        <RenderAnswersPageHeader />
        <InterviewAnswerList
          readOnly={readOnly}
          careQuestionAnswerList={getCurrentPageAnswers()}
          handleAnswerOnChange={handleAnswerOnChange}
          debounceAnswerOnChange={handleAnswerOnChangeDebounce}
        />
        <RenderNavigationButtons />
      </StyledContainer>
    </>
  );
};

export default InterviewAnswerInsert;
