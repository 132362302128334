interface PasswordRule {
  text: string;
  isValid: (value: string) => boolean;
}

export const passwordRules: PasswordRule[] = [
  {
    text: 'Pelo menos 8 caracteres',
    isValid: (password) => password.length >= 8,
  },
  {
    text: 'Uma letra maiúscula',
    isValid: (password) => /[A-Z]/.test(password),
  },
  {
    text: 'Uma letra minúscula',
    isValid: (password) => /[a-z]/.test(password),
  },
  {
    text: 'Um número',
    isValid: (password) => /[0-9]/.test(password),
  },
  {
    text: 'Um caractere especial (!, *, -, ?, @, /)',
    isValid: (password) => /[!@#$%&?.,;/\* -]/.test(password),
  },
];
