import { FormControlLabel } from '@material-ui/core';
import styled from 'styled-components';

export const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const DefinitionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const QuestionTypeContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const CareLineDiv = styled.div`
  width: 35%;
`;

export const SelectDiv = styled.div`
  width: 35%;
`;

export const FrequencyDiv = styled.div`
  width: 20%;
`;

export const DescriptionDiv = styled.div`
  width: 80%;
`;

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
  margin-left: ${({ theme }) => theme.spacing(2)}px;
`;
