import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email('Insira um e-mail válido')
    .required('E-mail é obrigatório'),
});

export const formInitialValues = {
  email: '',
};

export interface FormValues {
  email: string;
}
