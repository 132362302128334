import React from 'react';
import { ReportIndicatorModel } from '@cuidador/database';
import { Checkbox, Typography } from '@material-ui/core';
import { NormalizedReportIndicator } from '@cuidador/shared';

import StyledFormControlLabel from '../../../components/StyledFormControlLabel';

import {
  LabelContainer,
  Label,
  ClassificationLabel,
  IndicatorTitleContainer,
  IndicatorTitle,
  IndicatorMessage,
  OptionContainer,
} from '../styles';
import { IndicatorSectionProps } from '../utils';
import { labels } from './utils';

const RoutineIndicator: React.FC<
  IndicatorSectionProps<ReportIndicatorModel[]>
> = ({ data = [], onChange, selectedIndicators }) => {
  const renderOptionLabel = ({
    message,
    reportEventSubCategory,
  }: NormalizedReportIndicator) => {
    const { classification, eventSubCategory } = reportEventSubCategory!;
    return (
      <IndicatorTitle>
        <ClassificationLabel $classification={classification!} />
        {`${eventSubCategory!.name}: `}
        <IndicatorMessage>{message}</IndicatorMessage>
      </IndicatorTitle>
    );
  };

  return (
    <div data-testid="routineIndicatorSection">
      <IndicatorTitleContainer>
        <Typography variant="h6">Rotinas</Typography>
        <LabelContainer>
          <Typography variant="caption">Legenda:</Typography>
          {labels.map(({ key, label }) => (
            <Label key={key}>
              <Typography color="textPrimary" variant="caption">
                {label}
              </Typography>
              <ClassificationLabel $classification={key} />
            </Label>
          ))}
        </LabelContainer>
      </IndicatorTitleContainer>

      {data.map((item) => (
        <OptionContainer key={item.id}>
          <StyledFormControlLabel
            value={item.id}
            name={String(item.id)}
            data-testid={item.id}
            control={
              <Checkbox
                color="primary"
                checked={selectedIndicators.includes(item.id!)}
                onChange={() => onChange(item.id!)}
              />
            }
            label={renderOptionLabel(item)}
          />
        </OptionContainer>
      ))}
    </div>
  );
};

export default React.memo(RoutineIndicator);
