import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { DialogActions } from '@material-ui/core';
import StyledDialog from '../../../StyledDialog';
import useRoles from '../../../../hooks/useRoles';
import {
  StyledDialogContent,
  DialogTitle,
  DialogSubtitle,
  StyledTypography,
  StyledButton,
  StyledTextField,
} from '../styles';
import theme from '../../../../styles/theme';

export type RevisionDialogProps = {
  open: boolean;
  roleTitle?: string;
  roleIdToBeAssigned?: number;
  handleBack: () => void;
  handleConfirm: () => void;
};

const RevisionDialog: React.FC<RevisionDialogProps> = ({
  open,
  roleTitle,
  roleIdToBeAssigned,
  handleBack,
  handleConfirm,
}) => {
  const { getById, byId } = useRoles();

  useEffect(() => {
    getById(String(roleIdToBeAssigned));
  }, [getById, roleIdToBeAssigned]);

  const roleTitleToBeAssigned = byId[Number(roleIdToBeAssigned)]?.title || '';

  const [roleTitleToBeDeleted, setRoleTitleToBeDeleted] = useState('');

  const handleRevisionConfirmed = (roleTitle?: string) => {
    if (roleTitle?.trim() !== roleTitleToBeDeleted.trim()) {
      toast.error(
        'O nome do perfil está incorreto. Insira o nome corretamente para prosseguir'
      );
    } else {
      handleConfirm();
    }
  };

  const infoColor = theme.palette.info.main;
  const contrastColor = theme.palette.getContrastText('#000');

  return (
    <StyledDialog open={open}>
      <StyledDialogContent>
        <DialogSubtitle width={70}>
          <DialogTitle>REVISÃO</DialogTitle>
          <StyledTypography>
            Você está prestes a excluir definitivamente o Tipo de Perfil
          </StyledTypography>
          <DialogTitle>{roleTitle}</DialogTitle>
          <StyledTypography>
            Os usuários que têm este Tipo de Perfil passarão a ser do tipo
          </StyledTypography>
          <DialogTitle>{roleTitleToBeAssigned}</DialogTitle>
          <StyledTypography>
            Para confirmar, digite o nome do perfil que será excluído no campo
            abaixo e clique em “Confirmar”
          </StyledTypography>
        </DialogSubtitle>
        <StyledTextField
          inputProps={{
            'data-testid': 'role-to-be-deleted',
          }}
          name="roleTitle"
          margin="normal"
          onChange={({ target }) => setRoleTitleToBeDeleted(target.value)}
          value={roleTitleToBeDeleted}
        />
        <DialogActions>
          <StyledButton
            textColor={infoColor}
            backgroundColor={contrastColor}
            borderColor={contrastColor}
            onClick={handleBack}
          >
            VOLTAR
          </StyledButton>
          <StyledButton
            data-testid="revision-button"
            textColor={contrastColor}
            borderColor={contrastColor}
            onClick={() => handleRevisionConfirmed(roleTitle)}
          >
            CONFIRMAR
          </StyledButton>
        </DialogActions>
      </StyledDialogContent>
    </StyledDialog>
  );
};

export default RevisionDialog;
