import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import DeleteIcon from '@material-ui/icons/Delete';
import useRoles from '../../../hooks/useRoles';
import AlertDialog from './AlertDialog';
import ChooseRoleDialog from './ChooseRoleDialog';
import RevisionDialog from './RevisionDialog';
import { resolveErrorMessage } from '../../../utils/error';
import { RoleModel } from '@cuidador/database';
import { Button } from '../Form/styles';
import theme from '../../../styles/theme';

interface DeleteDialogsProps {
  role: RoleModel;
}

type DialogsStatus =
  | 'closedDialogs'
  | 'alertDialogOpen'
  | 'chooseRoleDialogOpen'
  | 'revisionDialogOpen';

const DeleteButtonAndDialogs: React.FC<DeleteDialogsProps> = ({ role }) => {
  const { remove } = useRoles();
  const history = useHistory();

  const [dialogsStatus, setDialogsStatus] = useState<DialogsStatus>(
    'closedDialogs'
  );
  const [roleIdToBeAssigned, setRoleIdToBeAssigned] = useState<number>();

  const handleGetRoleIdToBeAssigned = (roleId: number) =>
    setRoleIdToBeAssigned(roleId);

  const handleDeleteRole = () => {
    const params = { substituteRoleId: Number(roleIdToBeAssigned) };

    remove(Number(role?.id), { data: params })
      .then(() => {
        toast.success('Perfil deletado com sucesso');
        history.push('/usuarios/perfis');
      })
      .catch((err) => {
        const displayMessage = resolveErrorMessage(err);
        toast.error(displayMessage);
      });
  };

  return (
    <>
      <Button
        data-testid="delete-button"
        textColor="white"
        backgroundColor={theme.palette.warning.light}
        borderColor={theme.palette.warning.light}
        onClick={() => setDialogsStatus('alertDialogOpen')}
      >
        <DeleteIcon />
        Exluir
      </Button>
      <AlertDialog
        open={dialogsStatus === 'alertDialogOpen' ? true : false}
        roleTitle={role.title}
        roleIsDeletable={role.isDeletable}
        handleBack={() => setDialogsStatus('closedDialogs')}
        handleConfirm={() => setDialogsStatus('chooseRoleDialogOpen')}
      />
      <ChooseRoleDialog
        open={dialogsStatus === 'chooseRoleDialogOpen' ? true : false}
        roleTitle={role.title}
        handleGetRoleIdToBeAssigned={handleGetRoleIdToBeAssigned}
        handleBack={() => setDialogsStatus('alertDialogOpen')}
        handleConfirm={() => setDialogsStatus('revisionDialogOpen')}
      />
      <RevisionDialog
        open={dialogsStatus === 'revisionDialogOpen' ? true : false}
        roleTitle={role.title}
        roleIdToBeAssigned={roleIdToBeAssigned}
        handleBack={() => setDialogsStatus('chooseRoleDialogOpen')}
        handleConfirm={() => {
          setDialogsStatus('closedDialogs');
          handleDeleteRole();
        }}
      />
    </>
  );
};

export default DeleteButtonAndDialogs;
