import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  padding: ${({ theme }) => theme.spacing(2, 4)};
`;

export const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
`;

export const EditIconButton = styled(IconButton)`
  padding: 0;
  margin-left: ${({ theme }) => theme.spacing(1)}px;
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${({ theme }) => theme.palette.text.primary};
`;

export const IconContainer = styled.div`
  margin-left: ${({ theme }) => theme.spacing(2)}px;
`;
