import { AnswerConfig as DbAnswerConfig } from '@cuidador/database';
import { Input, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { v4 as generateUUID } from 'uuid';
import StyledSelect from '../../Forms/FormikSelect';
import StyledMenuItem from '../../StyledMenuItem';
import StyledTextField from '../../StyledTextField';
import AnswerOption, { OptionProps } from '../AnswerOption';
import {
  AnswerConfigContainer,
  HorizontalPlacement,
  InputContainer,
  TypeQuestionDiv,
  UnfoldDiv,
} from './styles';

export interface AnswerConfigRenderProps {
  type: DbAnswerConfig['type'] | '';
  level: number;
  options: OptionProps[];
  optionId?: OptionProps['id'];
  optionValue?: string;
  interview?: boolean;
  questionText?: string;
}

const AnswerConfigItem: React.FC<
  AnswerConfigRenderProps & {
    onChange: (answer: AnswerConfigRenderProps) => void;
  }
> = ({
  type,
  level,
  optionId,
  optionValue,
  options,
  onChange,
  interview,
  questionText = '',
}) => {
  const [newQuestion, setNewQuestion] = useState('');

  const handleInputKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && newQuestion.trim() !== '') {
      e.preventDefault();
      addOption();
    }
  };

  const addOption = () => {
    const newOptionsList = [
      ...options,
      {
        value: newQuestion.trim(),
        needDescription: false,
        hasNextAnswer: false,
        type,
        /**
         * Generates a new UUID for render and referencing helper
         */
        id: generateUUID(),
      },
    ];
    setNewQuestion('');
    onChange(
      buildAnswerConfigRender({
        options: newOptionsList,
      })
    );
  };

  const handleOptionChange = (option: OptionProps) => {
    const newOptionList = options.map((op) => {
      if (op.id !== option.id) return op;
      return {
        ...op,
        ...option,
      };
    });
    onChange(
      buildAnswerConfigRender({
        options: newOptionList,
      })
    );
  };

  const handleOptionDelete = (option: OptionProps) => {
    const matchingOption = options.find((op) => op.id === option.id);
    const indexToRemove = options.indexOf(matchingOption as OptionProps);
    const newOptionList = [
      ...options.slice(0, indexToRemove),
      ...options.slice(indexToRemove + 1),
    ];
    onChange(
      buildAnswerConfigRender({
        options: newOptionList,
      })
    );
  };

  const buildAnswerConfigRender = (
    modify?: Partial<AnswerConfigRenderProps>
  ): AnswerConfigRenderProps => ({
    options,
    type,
    level,
    optionId,
    ...modify,
  });

  const UnfoldHeader = () => {
    return (
      <UnfoldDiv>
        <Typography variant="subtitle1">
          Desdobramento de {`"${optionValue}"`}
        </Typography>
      </UnfoldDiv>
    );
  };

  return (
    <AnswerConfigContainer>
      {level > 0 && (
        <>
          <UnfoldHeader />
          <StyledTextField
            margin="normal"
            fullWidth
            label="Descrição da pergunta"
            defaultValue={questionText}
            disabled
            multiline
          />
        </>
      )}
      <HorizontalPlacement>
        <TypeQuestionDiv>
          <StyledSelect
            value={type}
            onChange={(e) => {
              onChange(
                buildAnswerConfigRender({
                  type: e.target.value as AnswerConfigRenderProps['type'],
                })
              );
            }}
            name="questionType"
            label="Tipo de pergunta"
            SelectDisplayProps={{
              'data-testid': 'answerTypeSelect',
            }}
          >
            <StyledMenuItem value="single" key="single">
              Seleção única
            </StyledMenuItem>
            <StyledMenuItem value="multi" key="multi">
              Seleção múltipla
            </StyledMenuItem>
          </StyledSelect>
        </TypeQuestionDiv>
      </HorizontalPlacement>
      {options?.map((option) => (
        <AnswerOption
          key={option.id}
          {...option}
          type={type}
          onChange={handleOptionChange}
          onDelete={handleOptionDelete}
          interview={interview}
        />
      ))}
      <InputContainer>
        <Input
          color="primary"
          placeholder="Adicionar opção"
          fullWidth
          onKeyPress={handleInputKeyPress}
          inputProps={{
            maxLength: 100,
          }}
          value={newQuestion}
          onChange={(e) => setNewQuestion(e.target.value)}
        />
      </InputContainer>
    </AnswerConfigContainer>
  );
};

export default AnswerConfigItem;
