import styled from 'styled-components';

export const QuestionTypeContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const CareLineDiv = styled.div`
  width: 70%;
  margin-right: ${({ theme }) => theme.spacing(6)}px;
`;

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.spacing(4)}px;
`;

export const ActionOutcomeTypeContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.spacing(4)}px;
`;

export const StaticCareLineContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const StaticCareLineTextBox = styled.div`
  width: 50%;
`;

export const StaticClassificationTextBox = styled.div`
  width: 50%;
  margin-left: ${({ theme }) => theme.spacing(6)}px;
`;
