import * as yup from 'yup';
import qs from 'qs';
import { validatePasswordStrength } from '../../utils/inputs';

export const validationSchema = yup.object().shape({
  newPassword: yup
    .string()
    .required('Por favor, insira a senha')
    .test(
      'isValidPassword',
      'Por favor, insira uma senha que atenda aos critérios listados abaixo',
      (value) => validatePasswordStrength(value || '')
    ),
  retypePassword: yup
    .string()
    .oneOf(
      [yup.ref('newPassword'), ''],
      'A confirmação de senha está diferente da nova senha'
    )
    .required('Confirmação de senha é obrigatória'),
});

export const formInitialValues = {
  newPassword: '',
  retypePassword: '',
};

export interface FormValues {
  newPassword: string;
  retypePassword: string;
}

export const qsStringToObject = (data: string) =>
  qs.parse(data, { ignoreQueryPrefix: true });

export function replaceGlobalLocation(url: string) {
  global.location.replace(url);
}
