import React, { useCallback } from 'react';
import { Checkbox, TableCell } from '@material-ui/core';
import { PermissionAction, PermissionModel } from '@cuidador/database';
import MyTable from '../../MyTable';
import { AllPermissions } from '../../../hooks/usePermissions';
import { FormValues } from '../utils';
import { useMemo } from 'react';

const columnNames = [
  'Permissões',
  'Invocar',
  'Criar',
  'Ver',
  'Atualizar',
  'Excluir',
];

type IsPermissionCheckedByAction = {
  [action: string]: {
    id: number;
    isChecked: boolean;
  };
};

const ActionCheckbox: React.FC<{
  action: PermissionAction;
  isPermissionCheckedByAction: IsPermissionCheckedByAction;
  onClick: (permissionId: number) => void;
}> = ({ action, isPermissionCheckedByAction, onClick }) => {
  const permission = isPermissionCheckedByAction[action];
  if (!permission) {
    return null;
  }
  return (
    <Checkbox
      checked={permission.isChecked}
      onClick={() => onClick(permission.id)}
      data-testid={`id-permission-${permission.id}`}
    />
  );
};

const PermissionsTable: React.FC<{
  allPermissions: AllPermissions;
  currentPermissions: FormValues['permissions'];
  handlePermissionChange: (
    field: string,
    value: FormValues['permissions']
  ) => void;
}> = ({ allPermissions, currentPermissions, handlePermissionChange }) => {
  const permissions = useMemo(() => {
    return Object.values(allPermissions);
  }, [allPermissions]);

  const handleChangeRolePermissions = (idPermission: number) => {
    const idAlreadyExists = currentPermissions.some(
      ({ id }) => id === idPermission
    );

    if (idAlreadyExists) {
      const newRolePermissionsIdFiltered = currentPermissions.filter(
        ({ id }) => id !== idPermission
      );

      handlePermissionChange('permissions', [...newRolePermissionsIdFiltered]);
    } else {
      handlePermissionChange('permissions', [
        ...currentPermissions,
        { id: idPermission },
      ]);
    }
  };

  const renderItem = useCallback(
    (resourcePermissions: PermissionModel[]) => {
      const resource = resourcePermissions[0].resource;

      const systemResource = allPermissions[resource!];

      const isPermissionCheckedByAction: IsPermissionCheckedByAction = {};
      resourcePermissions.forEach(({ action }) => {
        const systemPermission = systemResource.find(
          ({ action: permissionAction }) => permissionAction === action
        );

        if (!systemPermission) {
          return;
        }

        const isChecked = currentPermissions.some(({ id: permissionId }) => {
          return permissionId === systemPermission?.id;
        });

        isPermissionCheckedByAction[action!] = {
          id: systemPermission.id!,
          isChecked,
        };
      });

      return (
        <>
          <TableCell>{resource}</TableCell>
          <TableCell>
            <ActionCheckbox
              action="invoke"
              isPermissionCheckedByAction={isPermissionCheckedByAction}
              onClick={handleChangeRolePermissions}
            />
          </TableCell>
          <TableCell>
            <ActionCheckbox
              action="create"
              isPermissionCheckedByAction={isPermissionCheckedByAction}
              onClick={handleChangeRolePermissions}
            />
          </TableCell>
          <TableCell>
            <ActionCheckbox
              action="read"
              isPermissionCheckedByAction={isPermissionCheckedByAction}
              onClick={handleChangeRolePermissions}
            />
          </TableCell>
          <TableCell>
            <ActionCheckbox
              action="update"
              isPermissionCheckedByAction={isPermissionCheckedByAction}
              onClick={handleChangeRolePermissions}
            />
          </TableCell>
          <TableCell>
            <ActionCheckbox
              action="delete"
              isPermissionCheckedByAction={isPermissionCheckedByAction}
              onClick={handleChangeRolePermissions}
            />
          </TableCell>
        </>
      );
    },
    [allPermissions, currentPermissions, handleChangeRolePermissions]
  );

  return (
    <MyTable
      columnNames={columnNames}
      data={permissions}
      renderItem={renderItem}
      keyExtractor={({ item }) => String(item[0].id)}
      hasPagination={false}
      loading={false}
    />
  );
};

export default React.memo(PermissionsTable);
