import { CaregiverModel } from '@cuidador/database';
import { TableCell } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import formatDate from 'date-fns/format';
import qs from 'query-string';
import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import MyTable from '../../components/MyTable';
import SearchTextField from '../../components/SearchTextField';
import useCaregiver from '../../hooks/useCaregiver';

import IconSelfCare from '../../assets/Icon-selfcare.svg';
import { isSelfCare } from '../../contexts/auth';
import ChangeLevelDialog from './ChangeLevelDialog';
import {
  Container,
  EditIconButton,
  Header,
  IconContainer,
  StyledLink,
} from './styles';
import { FormValues, LEVEL_ENUM, STATUS_ENUM } from './utils';

const tableColumns = [
  'Data',
  'Nome',
  'Tipo',
  'Nível',
  'Contato',
  'Pacientes',
  'Status',
];

const Caregiver: React.FC = () => {
  const { getPaginated, ids, byId, total, loading, patch } = useCaregiver();
  const [
    caregiverBeingEdited,
    setCaregiverBeingEdited,
  ] = React.useState<CaregiverModel>();
  const history = useHistory();
  const limit = parseInt(String(qs.parse(history.location.search).limit)) || 20;

  useEffect(() => {
    onChangeParams();
  }, []);

  const resolveCaregiverRoleTitle = (caregiver: CaregiverModel) => {
    return caregiver.user?.role?.title;
  };

  const renderCaregiverItem = useCallback((item: CaregiverModel) => {
    return (
      <>
        <TableCell>
          {item?.createdAt && formatDate(new Date(item.createdAt), 'dd/MM/yy')}
        </TableCell>
        <TableCell>
          <StyledLink to={`/cuidadores/${item.id}/pessoais`}
            style={{ color: '#0088D3', textDecoration: 'none' }}>
            {item?.user?.name}{' '}
            {isSelfCare(item.user) && (
              <IconContainer>
                <img src={IconSelfCare} />
              </IconContainer>
            )}
          </StyledLink>
        </TableCell>
        <TableCell>{resolveCaregiverRoleTitle(item)}</TableCell>
        <TableCell>
          {item?.level ? LEVEL_ENUM[item.level] : null}
          <EditIconButton
            onClick={() => setCaregiverBeingEdited(item)}
            data-testid={`editLevel-${item.id}`}
          >
            <EditIcon />
          </EditIconButton>
        </TableCell>
        <TableCell>{item?.user?.phoneNumber}</TableCell>
        <TableCell>
          {item?.user?.patients?.map((patient) => (
            <li key={patient.id}>{patient.name}</li>
          ))}
        </TableCell>
        <TableCell>
          {item?.user?.status && STATUS_ENUM[item.user.status]}
        </TableCell>
        {/* [TODO] Add Shifts column */}
        {/* [TODO] Add Relationship column */}
      </>
    );
  }, []);

  const onChangeParams = () => {
    const params = qs.parse(history.location.search);
    getPaginated({ ...params, limit: Number(params.limit) || 20 });
  };

  const updateCaregiver = (values: FormValues) =>
    patch(caregiverBeingEdited!.id!, values as CaregiverModel).then(() => {
      setCaregiverBeingEdited(undefined);
    });

  const data = ids.map((id) => byId[id]);

  return (
    <Container>
      <Header>
        <SearchTextField
          fieldName="user.name"
          onChangeDebounced={onChangeParams}
        />
      </Header>
      <MyTable
        columnNames={tableColumns}
        data={data}
        totalPages={Math.ceil(total / limit)}
        keyExtractor={({ item }) => String(item!.id)}
        renderItem={renderCaregiverItem}
        loading={loading}
        onChangePage={onChangeParams}
        onChangeRowsPerPage={onChangeParams}
      />
      <ChangeLevelDialog
        open={Boolean(caregiverBeingEdited)}
        caregiver={caregiverBeingEdited}
        onCancel={() => setCaregiverBeingEdited(undefined)}
        onConfirm={updateCaregiver}
      />
    </Container>
  );
};

export default Caregiver;
