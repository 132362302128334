import styled from 'styled-components';
import StyledTextField from '../../../../components/StyledTextField';
import { Typography } from '@material-ui/core';
import { CloudDownload } from '@material-ui/icons';

export const Background = styled.div`
  padding: 0px ${({ theme }) => theme.spacing(2)};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const HeadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${({ theme }) => theme.spacing(50)}px;
  margin-bottom: ${({ theme }) => theme.spacing(4)}px;
`;

export const TitleContainer = styled.div`
  max-width: 100%;
  text-align: center;
  color: ${({ theme }) => theme.palette.primary.main};
  margin-top: ${({ theme }) => theme.spacing(4)}px;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`;

export const SubTitleContainer = styled.div`
  max-width: 100%;
  text-align: left;
  color: ${({ theme }) => theme.palette.primary.main};
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`;

export const StyledTextFieldForm = styled(StyledTextField)`
  & .MuiFormLabel-root.Mui-disabled {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export const StyledClickContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const StyledTypography = styled(Typography)`
  text-align: center;
`;

export const StyledIcon = styled(CloudDownload)`
  font-size: ${({ theme }) => theme.spacing(10)}px;
  color: ${({ theme }) => theme.palette.secondary.main};
  transition-delay: 0.2s;
`;
