import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  name: Yup.string().required('Por favor, informe um nome'),
  cpf: Yup.string().required('Por favor, informe um CPF'),
  phoneNumber: Yup.string().required('Por favor, informe um telefone'),
  email: Yup.string().email().required('Por favor, informe um e-mail'),
  roleId: Yup.mixed().nullable(),
});

export interface FormValues {
  name?: string;
  cpf?: string;
  phoneNumber?: string;
  email?: string;
  roleId?: number;
}
