export function formatClassification(classification: string | undefined) {
  switch (classification) {
    case 'functional':
      return 'Funcional';
    case 'dysfunctional':
      return 'Disfuncional';
    case 'severe_dysfunctional':
      return 'Disfuncional grave';
    default:
      return '';
  }
}

export function formatFrequency(frequency: string | undefined) {
  const formattedFrequency = frequency?.slice(0, -1);
  if (formattedFrequency === '1') {
    return formattedFrequency + ' dia';
  } else {
    return formattedFrequency + ' dias';
  }
}

export interface FormValues {
  id: Id | undefined;
}
