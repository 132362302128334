import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { Formik, Form, FormikProps, FormikHelpers } from 'formik';

import Button from '../../components/StyledButton';
import StyledTextFieldWhite from '../../components/StyledTextFieldWhite';
import logo from '../../assets/logo.svg';

import { validationSchema, formInitialValues, FormValues } from './utils';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import { APIError } from '@cuidador/lib';
import { resolveErrorMessage } from '../../utils/error';

import { Background, Container, HeadingContainer } from './styles';
import { sendPasswordResetEmail } from '../../contexts/auth';

const ForgotPassword: React.FC = () => {
  const formikRef = useRef<FormikProps<FormValues> | null>();
  const history = useHistory();

  const handleSubmit = (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    setSubmitting(true);
    sendPasswordResetEmail(values.email)
      .then(() => {
        toast.info('E-mail de redefinição de senha enviado.');
        history.goBack();
      })
      .catch((err: AxiosError<APIError>) => {
        if (err.response?.data.message === 'User not found.') {
          formikRef.current?.setFieldError('email', 'Email inválido');
        } else {
          const displayMessage = resolveErrorMessage(err);
          toast.error(displayMessage);
        }
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <Background>
      <Container>
        <img src={logo} />
        <HeadingContainer>
          <Typography variant="h6">
            Insira seu e-mail que iremos enviar as instruções para recuperar a
            senha.
          </Typography>
        </HeadingContainer>
        <Formik
          innerRef={(ref) => (formikRef.current = ref)}
          initialValues={formInitialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          validateOnChange={false}
        >
          {(formik) => (
            <Form>
              <StyledTextFieldWhite
                name="email"
                label="E-mail"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.email)}
                helperText={formik.errors.email}
                margin="normal"
                fullWidth
                inputProps={{ 'data-testid': 'email' }}
              />
              <Button
                data-testid="submit"
                size="large"
                color="inherit"
                type="submit"
                disabled={formik.isSubmitting}
              >
                Enviar
              </Button>
            </Form>
          )}
        </Formik>
      </Container>
    </Background>
  );
};

export default ForgotPassword;
