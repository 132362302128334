import { User, UserModel } from '@cuidador/database';
import { useCallback, useReducer } from 'react';
import axios from '../config/axios';
import {
  createReducer,
  Item,
  PaginatedRequestParams,
  ReducerData,
} from '../utils/store/index';
import useMedia from './useMedia';

const endpoint = '/user';

type patchUserDTO = Partial<UserModel> & { roleId?: number };

const initialData: ReducerData<User> = {
  byId: {} as Record<string, Item<User>>,
  ids: [] as Array<number>,
  total: 0,
  loading: false,
  error: null,
};

const useUser = () => {
  const { getMedia } = useMedia();
  const [state, dispatch] = useReducer(createReducer<User>(), initialData);

  const getPaginated = useCallback(async (params: PaginatedRequestParams) => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.get(
        `${endpoint}?status=enabled&status=disabled&status=awaitingSignupCompletion`,
        {
          params: { ...params },
        }
      );
      dispatch({ type: 'PAGINATION', payload: response.data });
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
    }
  }, []);

  const getById = useCallback(async (id: string) => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.get(`${endpoint}/${id}`);
      dispatch({ type: 'GET_BY_ID', payload: response.data });
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
    }
  }, []);

  const patch = useCallback(async (id: string, data: patchUserDTO) => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.patch(`${endpoint}/${id}`, data);
      dispatch({ type: 'UPDATE', payload: response.data });
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
      return Promise.reject(err);
    }
  }, []);

  const getProfilePicture = useCallback(async (id: number) => {
    try {
      const response = await getMedia(`/media/profile-picture/user/${id}`);
      return Promise.resolve(response);
    } catch (err) {
      return Promise.reject(err);
    }
  }, []);

  return {
    ...state,
    getPaginated,
    getById,
    patch,
    getProfilePicture,
  };
};

export default useUser;
