import styled from 'styled-components';
import { Form } from 'formik';
import StyledMenuItem from '../../../components/StyledMenuItem';
import FormikSelect from '../../../components/Forms/FormikSelect';
import { ReactComponent as LogoBlue } from '../../../assets/logo-blue.svg';
import { ReactComponent as LogoWhite } from '../../../assets/logo-white.svg';
import { ReactComponent as LogoPurple } from '../../../assets/logo-purple.svg';

export const Container = styled.div`
  padding-inline: ${({ theme }) => theme.spacing(4)}px;
  display: flex;
  flex-direction: column;
  height: 120vh;
`;

export const FormContainer = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

export const Section = styled.div`
  margin-top: ${({ theme }) => theme.spacing(2)}px;
`;

export const Label = styled.label`
  display: flex;
  font-weight: 600;
`;

export const RoleLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 700;
  font-size: 18px;
  margin-inline: ${({ theme }) => theme.spacing(1)}px;
  width: 100%;

  svg {
    margin-left: ${({ theme }) => theme.spacing(1)}px;
  }
`;

export const RoleDescription = styled.span`
  font-weight: 400;
  font-size: 14px;
  margin-left: ${({ theme }) => theme.spacing(1)}px;
  margin-top: ${({ theme }) => theme.spacing(1)}px;
  text-align: justify;
  white-space: normal;
`;

export const RoleMenuItem = styled(StyledMenuItem)`
  color: ${({ theme }) => theme.palette.getContrastText('#FFF')};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-block: ${({ theme }) => theme.spacing(0.5)}px;
  max-width: 500px;
  width: 100%;
`;

export const RoleSelect = styled(FormikSelect)`
  & [role='button'] {
    div {
      color: inherit;
      font-weight: inherit;
      width: fit-content;
    }
    span {
      display: none;
    }
  }
`;

export const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.spacing(4)}px;
  margin-bottom: ${({ theme }) => theme.spacing(4)}px;
  margin-inline: auto;
  max-width: 500px;
  width: 100%;
`;

export const AppContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const AppLabel = styled.span`
  margin-left: ${({ theme }) => theme.spacing(1)}px;
`;

export const StyledLogoBlue = styled(LogoBlue)`
  height: 25px;
  width: 25px;
`;

export const StyledLogoWhite = styled(LogoWhite)`
  height: 25px;
  width: 25px;
`;

export const StyledLogoPurple = styled(LogoPurple)`
  height: 25px;
  width: 25px;
`;
