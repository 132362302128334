import styled from 'styled-components';
import { Link } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import ErrorIcon from '@material-ui/icons/Error';
import { Typography } from '@material-ui/core';

interface StyledTypographyProps {
  statusColor: string;
}

export const Container = styled.div`
  margin: ${({ theme }) => theme.spacing(2, 4, 2)};
`;

export const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
`;

export const EditIconButton = styled(IconButton)`
  padding: 0;
  margin-left: ${({ theme }) => theme.spacing(1)}px;
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${({ theme }) => theme.palette.info.main};
`;

export const StyledErrorIcon = styled(ErrorIcon)`
  color: #e05e3c;
  font-size: 35px;
`;

export const StatusDiv = styled.div`
  display: flex;
  justify-content: center;
`;

export const IconContainer = styled.div`
  margin-left: ${({ theme }) => theme.spacing(2)}px;
`;

export const IconSelfCare = styled.img`
  width: 25px;
`;

export const CaregiverContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const StyledTypography = styled(Typography)<StyledTypographyProps>`
  color: ${(props) => props.statusColor};
  font-weight: 500;
`;
