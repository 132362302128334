import { useCallback, useReducer } from 'react';
import axios from '../config/axios';
import { ContentModel } from '@cuidador/database';
import {
  createReducer,
  ReducerData,
  Item,
  PaginatedRequestParams,
} from '../utils/store/index';

const endpoint = '/care/content';

const initialData: ReducerData<ContentModel> = {
  byId: {} as Record<string, Item<ContentModel>>,
  ids: [] as Array<number>,
  total: 0,
  loading: false,
  error: null,
};

const useContent = () => {
  const [state, dispatch] = useReducer(
    createReducer<ContentModel>(),
    initialData
  );

  const getPaginated = useCallback(async (params: PaginatedRequestParams) => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.get(`${endpoint}`, { params });
      dispatch({ type: 'PAGINATION', payload: response.data });
      return response.data.results as ContentModel[];
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
    }
  }, []);

  const getById = useCallback(async (id: Id) => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.get(`${endpoint}/${id}`);
      dispatch({ type: 'GET_BY_ID', payload: response.data });
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
    }
  }, []);

  const post = useCallback(async (data: ContentModel) => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.post(`${endpoint}`, data);
      dispatch({ type: 'CREATE', payload: response.data });
      return Promise.resolve(response);
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
      return Promise.reject(err);
    }
  }, []);

  const patch = useCallback(async (id: Id, data: Partial<ContentModel>) => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.patch(`${endpoint}/${id}`, data);
      dispatch({ type: 'UPDATE', payload: response.data });
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
      return Promise.reject(err);
    }
  }, []);

  const remove = useCallback(async (id: Id) => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.delete(`${endpoint}/${id}`);
      dispatch({ type: 'REMOVE', payload: response.data });
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
      return Promise.reject(err);
    }
  }, []);

  return {
    ...state,
    getPaginated,
    post,
    patch,
    getById,
    remove,
  };
};

export default useContent;
