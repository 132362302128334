import React, { useMemo, useContext } from 'react';

import Sidebar from '../Sidebar';
import Routes from '../../routes/Routes';
import { Container, Content } from './styles';
import { AuthContext } from '../../contexts/auth';
import { useRouteMatch } from 'react-router-dom';

export interface ContentProps {
  fullWidth: boolean;
}

const routesWithoutMenu = [
  '/login',
  '/cuidadores/:id',
  '/pacientes/:id',
  '/cuidados/perguntas-de-cuidado/adicionar',
  '/cuidados/perguntas-de-cuidado/:id',
  '/cuidados/perguntas-de-entrevista/adicionar',
  '/cuidados/perguntas-de-entrevista/:id',
  '/cuidados/acoes/adicionar',
  '/cuidados/acoes/:id',
  '/conteudo/novo',
  '/conteudo/:id',
];

const Layout: React.FC = () => {
  const { user } = useContext(AuthContext);
  const match = useRouteMatch(routesWithoutMenu);

  const shouldRenderSidebar = useMemo(() => {
    return Boolean(user) && !Boolean(match);
  }, [user, match]);

  return (
    <Container>
      {shouldRenderSidebar && <Sidebar />}
      <Content fullWidth={!shouldRenderSidebar}>
        <Routes />
      </Content>
    </Container>
  );
};

export default Layout;
