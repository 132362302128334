import format from 'date-fns/format';
import parse from 'date-fns/parse';
import ptBrLocale from 'date-fns/locale/pt-BR';
import { WeekdayEnum, EventScheduleModel } from '@cuidador/database';

/**
 * Formats weekday for ptBr locale.
 * Example: Input "mon", output "Seg"
 */
const formatWeekday = (weekday: string) => {
  return format(parse(weekday, 'EEE', new Date()), 'EEE', {
    locale: ptBrLocale,
  });
};

// Resolve weekdays label
export const weekdaysLabel = (
  eventSchedule: EventScheduleModel | undefined
) => {
  // Builds active weekdays array
  const weekDays: WeekdayEnum[] = [];
  if (eventSchedule?.mon) weekDays.push('mon');
  if (eventSchedule?.tue) weekDays.push('tue');
  if (eventSchedule?.wed) weekDays.push('wed');
  if (eventSchedule?.thu) weekDays.push('thu');
  if (eventSchedule?.fri) weekDays.push('fri');
  if (eventSchedule?.sat) weekDays.push('sat');
  if (eventSchedule?.sun) weekDays.push('sun');

  return weekDays.map(formatWeekday).join(' - ');
};

export const formatAppointmentDate = (eventHappensAt: string) => {
  return format(new Date(String(eventHappensAt)), 'dd/MM - EEE', {
    locale: ptBrLocale,
  });
};
