import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import { ClassificationLabelProps } from './utils';

export const Container = styled.div`
  color: ${({ theme }) => theme.palette.primary.main};
  padding: ${({ theme }) => theme.spacing(2)}px;
`;

export const PageContainer = styled.div`
  width: 1024px;
  max-width: 100%;
  margin: auto;
`;

export const TitleContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
`;

export const PageTitle = styled(Typography).attrs({
  variant: 'h5',
})`
  font-weight: 500;
`;

export const Divider = styled.hr`
  border: unset;
  height: 1px;
  margin: ${({ theme }) => theme.spacing(2, 0)};
  background-color: ${({ theme }) => theme.palette.grey[300]};
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${({ theme }) => theme.spacing(1)};
`;

export const LabelGroupContainer = styled.div`
  display: flex;
`;

export const LabelContainer = styled.div<{ $direction?: 'column' | 'row' }>`
  display: flex;
  align-items: ${({ $direction = 'row' }) =>
    $direction === 'row' ? 'baseline' : 'flex-end'};
  justify-content: flex-end;
  flex-direction: ${({ $direction = 'row' }) => $direction};
`;

export const Label = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-left: ${({ theme }) => theme.spacing(2)}px;
  align-items: center;
`;

export const ClassificationLabel = styled.div<ClassificationLabelProps>`
  display: inline-block;
  margin: ${({ theme }) => theme.spacing(0, 1)};
  width: ${({ theme }) => theme.spacing(2)}px;
  height: ${({ theme }) => theme.spacing(2)}px;
  border-radius: 50%;
  background-color: ${({
    theme,
    $classification = 'good',
    $backgroundColor,
  }) => {
    if ($backgroundColor) return `#${$backgroundColor}`;
    return theme.palette.extra.indicators[$classification];
  }};

  :first-child {
    margin-left: 0;
  }
  :last-child {
    margin-right: 0;
  }
`;

export const IndicatorTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
`;

export const OptionContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
  & .MuiFormControlLabel-root {
    align-items: flex-start;
    margin: 0;
    & .MuiFormControlLabel-label {
      text-align: justify;
    }
    & .MuiCheckbox-root {
      /* checkbox is aligned to the top, keeping the shape during click effect */
      margin-top: ${({ theme }) => -theme.spacing(1)}px;
    }
  }
`;

export const IndicatorTitle = styled(Typography).attrs({
  component: 'span',
})``;

export const IndicatorMessage = styled(Typography).attrs({
  component: 'span',
  color: 'textPrimary',
})``;
