import red from '@material-ui/core/colors/red';
import styled from 'styled-components';

export const Centralizer = styled.div`
  align-items: center;
  flex-direction: column;
  display: flex;
  flex: 1;
  justify-content: flex-end;
  padding: ${({ theme }) => theme.spacing(1)}px;
  margin-top: ${({ theme }) => theme.spacing(1.5)}px;
  margin-left: ${({ theme }) => theme.spacing(1.5)}px;
  margin-right: ${({ theme }) => theme.spacing(1.5)}px;

  svg {
    font-size: ${({ theme }) => theme.spacing(7)}px;
  }

  p {
    font-size: ${({ theme }) => theme.spacing(2.25)}px;
    margin-top: ${({ theme }) => theme.spacing(1)}px;
    margin-bottom: ${({ theme }) => theme.spacing(1)}px;
  }

  div {
    button:first-child {
      color: ${red[700]};
      background-color: ${({ theme }) => theme.palette.common.white};
      border: none;
    }
  }
`;
