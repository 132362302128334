import styled from 'styled-components';

export const Container = styled.aside`
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 256px;
  height: 135vh;
  color: white;
  z-index: 1;
  background-image: linear-gradient(
    ${({ theme }) => theme.palette.info.main},
    ${({ theme }) => theme.palette.secondary.main}
  );
  box-shadow: 5px 3px 10px #0f0f0f6d;

  nav {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
`;

export const LogoContainer = styled.div`
  padding: ${({ theme }) => theme.spacing(8)}px
    ${({ theme }) => theme.spacing(4)}px;
  img {
    width: 100%;
  }
`;

export const LogoutContainer = styled.div`
  margin: ${({ theme }) => theme.spacing(4)}px;
`;

export const StyledImg = styled.img`
  margin-right: 14px;
`;
