import styled from 'styled-components';
import { Paper } from '@material-ui/core';
import {
  Backdrop as MaterialBackdrop,
  CircularProgress as MaterialCircularProgress,
} from '@material-ui/core';

export const Container = styled.div`
  margin: ${({ theme }) => theme.spacing(2, 4, 2)};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledPaperTab = styled(Paper)`
  border-radius: 13px;
  padding: ${({ theme }) => theme.spacing(0, 2, 0, 0)};
`;

export const Backdrop = styled(MaterialBackdrop)`
  z-index: ${({ theme }) => theme.zIndex.drawer + 1};
  color: ${({ theme }) => theme.palette.primary.light};
  position: absolute;
`;

export const BackdropCircularProgress = styled(MaterialCircularProgress).attrs({
  color: 'inherit',
})``;
