import styled from 'styled-components';
import {
  Backdrop as MaterialBackdrop,
  Button,
  CircularProgress as MaterialCircularProgress,
} from '@material-ui/core';
import { Form as FormikForm } from 'formik';

export const Background = styled.div`
  padding: 0px ${({ theme }) => theme.spacing(2)}px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 425px;
`;

export const TypographyContainer = styled.div`
  width: 400px;
  max-width: 100%;
  text-align: left;
  color: ${({ theme }) => theme.palette.primary.main};
  margin-top: ${({ theme }) => theme.spacing(4)}px;
  margin-bottom: ${({ theme }) => theme.spacing(4)}px;
`;

export const Backdrop = styled(MaterialBackdrop)`
  z-index: ${({ theme }) => theme.zIndex.drawer + 1};
  color: ${({ theme }) => theme.palette.primary.light};
  position: absolute;
`;

export const BackdropCircularProgress = styled(MaterialCircularProgress).attrs({
  color: 'inherit',
})``;

export const Form = styled(FormikForm)`
  margin-top: ${({ theme }) => theme.spacing(2)}px;
  width: 100%;
`;

export const Centralizer = styled.div`
  align-items: center;
  flex-direction: column;
  display: flex;
`;

export const SubmitButton = styled(Button).attrs({ variant: 'outlined' })`
  border-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.main};
  height: ${({ theme }) => theme.spacing(6)}px;
  width: ${({ theme }) => theme.spacing(24)}px;
  margin: ${({ theme }) => theme.spacing(2)}px 0
    ${({ theme }) => theme.spacing(4)}px;
  font-size: 20px;
`;
