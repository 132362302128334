import React from 'react';
import { Tab } from '@material-ui/core';
import { RouteComponentProps } from 'react-router-dom';
import StyledTabs from '../../components/StyledTabs';
import { Container, StyledPaperTab } from './styles';
import { tabOptions } from './utils';
import CareLineIndicators from './CareLineIndicators';

const Reports: React.FC<RouteComponentProps<{ tabId: string }>> = (props) => {
  const currentTab = props.match.params.tabId;

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleChange = (_: React.ChangeEvent<{}>, newValue: string) => {
    props.history.push(`/relatorios/${newValue}`);
  };

  return (
    <Container>
      <StyledPaperTab>
        <StyledTabs centered value={currentTab} onChange={handleChange}>
          {tabOptions.map((option) => (
            <Tab
              wrapped={true}
              key={option.key}
              label={option.label}
              value={option.key}
            />
          ))}
        </StyledTabs>
      </StyledPaperTab>

      {currentTab === 'linha-de-cuidado' && <CareLineIndicators />}
    </Container>
  );
};

export default Reports;
