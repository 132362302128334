import React from 'react';
import { DialogProps } from '@material-ui/core/Dialog';

import StyledDialog from '../../../components/StyledDialog';
import { PatientModel } from '@cuidador/database';
import { DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { ChangeExpirationDateFormValues } from '../utils';
import { TextContent } from './styles';
import StyledButton from '../../../components/StyledButton';
import { Formik, Form } from 'formik';
import { validationSchema } from '../utils';
import StyledTextFieldWhite from '../../../components/StyledTextFieldWhite';
import formatDate from 'date-fns/format';

interface ChangeLevelDialogProps extends DialogProps {
  patient?: PatientModel;
  onConfirm: (values: ChangeExpirationDateFormValues) => Promise<void>;
  onCancel: () => void;
}

const ChangeExpirationDateDialog: React.FC<ChangeLevelDialogProps> = ({
  patient,
  onCancel,
  onConfirm,
  ...rest
}) => {
  const formInitialValue = {
    expirationDate: patient?.planExpirationDate
      ? formatDate(new Date(patient.planExpirationDate), 'yyyy-MM-dd')
      : '',
  };
  return (
    <StyledDialog {...rest}>
      <DialogContent>
        <DialogTitle>{`Informe a validade do plano de ${patient?.name}`}</DialogTitle>
        <Formik
          validationSchema={validationSchema}
          initialValues={formInitialValue}
          onSubmit={onConfirm}
          validateOnChange={false}
        >
          {(formik) => (
            <Form>
              <TextContent>
                <StyledTextFieldWhite
                  name="expirationDate"
                  label="Data de validade"
                  value={formik.values.expirationDate}
                  error={Boolean(formik.errors.expirationDate)}
                  onChange={formik.handleChange}
                  helperText={formik.errors.expirationDate}
                  margin="normal"
                  type="date"
                  inputProps={{
                    'data-testid': 'expirationDate',
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              </TextContent>
              <DialogActions>
                <StyledButton
                  data-testid="cancel"
                  size="large"
                  color="inherit"
                  onClick={onCancel}
                >
                  Cancelar
                </StyledButton>
                <StyledButton
                  data-testid="submit"
                  size="large"
                  color="inherit"
                  type="submit"
                  variant="text"
                  disabled={formik.isSubmitting}
                >
                  Salvar
                </StyledButton>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </StyledDialog>
  );
};

export default ChangeExpirationDateDialog;
