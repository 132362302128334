import styled from 'styled-components';
import { Paper } from '@material-ui/core';

export const StyledPaperCard = styled(Paper)`
  border-radius: 12px;
  width: 270px;
  min-height: 220px;
  color: ${({ theme }) => theme.palette.primary.main};
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing(3)}px;
  margin: ${({ theme }) => theme.spacing(2.5, 0)};
`;
