import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { Typography, Container } from '@material-ui/core';

export const StyledContainer = styled(Container)`
  margin-top: ${({ theme }) => theme.spacing(3)}px;
`;

export const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${({ theme }) => theme.palette.info.main};
  text-decoration: underline;
`;

export const AddButton = styled(Button).attrs({
  variant: 'contained',
})`
  color: ${({ theme }) => theme.palette.primary.main};
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`;

export const EmptyTableTypography = styled(Typography)`
  text-align: center;
`;
