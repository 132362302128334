import { Tab } from '@material-ui/core';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import StyledTabs from '../../../components/StyledTabs';
import { StyledPaperTab } from './styles';

const tabOptions = [
  { key: 'todos-usuarios', label: 'Usuários' },
  { key: 'perfis', label: 'Tipos de Perfil' },
];

type RouteParams = {
  tabId?: 'todos-usuarios' | 'perfis';
  id?: string;
};

const TabMenu: React.FC = () => {
  const history = useHistory();

  const { tabId } = useParams<RouteParams>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (_: any, value: string) => {
    history.replace(`/usuarios/${value}`);
  };

  return (
    <StyledPaperTab>
      <StyledTabs centered value={tabId} onChange={handleChange}>
        {tabOptions.map((option) => (
          <Tab
            wrapped={true}
            key={option.key}
            label={option.label}
            value={option.key}
          />
        ))}
      </StyledTabs>
    </StyledPaperTab>
  );
};

export default TabMenu;
