import TextField from '@material-ui/core/TextField';
import styled from 'styled-components';

const StyledTextField = styled(TextField).attrs({ variant: 'outlined' })`
  & label {
    color: ${({ theme }) => theme.palette.primary.main};
  }
  & label.Mui-disabled {
    color: ${({ theme }) => theme.palette.primary.main};
    opacity: 65%;
  }
  & input {
    color: ${({ theme }) => theme.palette.primary.main};
  }
  & textarea {
    color: ${({ theme }) => theme.palette.primary.main};
  }
  & .MuiOutlinedInput-root:not(.Mui-error) {
    & fieldset {
      border-color: ${({ theme }) => theme.palette.primary.main};
      color: ${({ theme }) => theme.palette.primary.main};
    }
    &:hover fieldset {
      border-color: ${({ theme }) => theme.palette.primary.main};
      color: ${({ theme }) => theme.palette.primary.main};
    }
    &.Mui-focused fieldset {
      border-color: ${({ theme }) => theme.palette.primary.main};
      color: ${({ theme }) => theme.palette.primary.main};
    }
  }
  & .MuiOutlinedInput-root.Mui-disabled {
    & input:disabled {
      color: ${({ theme }) => theme.palette.primary.main};
      opacity: 65%;
    }

    & fieldset {
      border-color: ${({ theme }) => theme.palette.primary.main};
      opacity: 65%;
    }
  }
`;

export default StyledTextField;
