import { useCallback, useReducer } from 'react';
import axios from '../config/axios';
import { EventSubCategoryModel } from '@cuidador/database';
import {
  createReducer,
  ReducerData,
  Item,
  PaginatedRequestParams,
} from '../utils/store/index';

const endpoint = '/care/event/category/sub';

const initialData: ReducerData<EventSubCategoryModel> = {
  byId: {} as Record<string, Item<EventSubCategoryModel>>,
  ids: [] as Array<number>,
  total: 0,
  loading: false,
  error: null,
};

const useEventSubCategory = () => {
  const [state, dispatch] = useReducer(
    createReducer<EventSubCategoryModel>(),
    initialData
  );

  const getAll = useCallback(async () => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.get(`${endpoint}`);
      dispatch({ type: 'GET_ALL', payload: response.data });
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
    }
  }, []);

  const getPaginated = useCallback(async (params: PaginatedRequestParams) => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.get(`${endpoint}`, {
        params,
      });
      dispatch({ type: 'PAGINATION', payload: response.data });
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
    }
  }, []);

  const getByParams = useCallback(async (categoryId: Id) => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.get(`${endpoint}?categoryId=${categoryId}`);
      dispatch({ type: 'GET_ALL', payload: response.data });
      return response.data as EventSubCategoryModel[];
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
    }
  }, []);

  const getOnlyForMedication = useCallback(
    async (params: PaginatedRequestParams) => {
      try {
        dispatch({ type: 'LOADING' });
        const response = await axios.get(`${endpoint}`, {
          params: {
            ...params,
            categoryId: 2, // CategoryId 2 = Medication Id
          },
        });
        dispatch({ type: 'PAGINATION', payload: response.data });
        return response.data.results;
      } catch (err) {
        dispatch({ type: 'ERROR', payload: err });
      }
    },
    []
  );

  const post = useCallback(async (data: EventSubCategoryModel) => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.post(`${endpoint}`, data);
      dispatch({ type: 'CREATE', payload: response.data });
      return Promise.resolve(response);
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
      return Promise.reject(err);
    }
  }, []);

  const patch = useCallback(
    async (id: Id, data: Partial<EventSubCategoryModel>) => {
      try {
        dispatch({ type: 'LOADING' });
        const response = await axios.patch(`${endpoint}/${id}`, data);
        dispatch({ type: 'UPDATE', payload: response.data });
      } catch (err) {
        dispatch({ type: 'ERROR', payload: err });
        return Promise.reject(err);
      }
    },
    []
  );
  const remove = useCallback(async (id: Id) => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.delete(`${endpoint}/${id}`);
      dispatch({ type: 'REMOVE', payload: response.data });
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
      return Promise.reject(err);
    }
  }, []);

  return {
    ...state,
    getAll,
    getPaginated,
    getByParams,
    getOnlyForMedication,
    post,
    patch,
    remove,
  };
};

export default useEventSubCategory;
