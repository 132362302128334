import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Form as FormikForm } from 'formik';
import Button from '../StyledButton';

export const Form = styled(FormikForm)`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
`;

export const TypographyContainer = styled.div`
  max-width: 100%;
  text-align: center;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
`;

export const ButtonBox = styled.div`
  margin-top: ${({ theme }) => theme.spacing(2)}px;
  justify-content: flex-end;
  display: flex;
`;

export const StyledButton = styled(Button)`
  margin-left: ${({ theme }) => theme.spacing(4)}px;
  border-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.main};
`;
