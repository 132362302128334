import { useCallback, useReducer } from 'react';
import axios from '../config/axios';
import {
  ActionModel,
  AnswerType,
  PatientInterviewModel,
} from '@cuidador/database';
import {
  createReducer,
  ReducerData,
  Item,
  PaginatedRequestParams,
} from '../utils/store/index';

const endpoint = '/care/interview';

const finishInterviewEndPoint = (interviewId: number) =>
  `care/interview/${interviewId}/finish`;
const careQuestionAnswerEndPoint = (interviewId: number, questionId: number) =>
  `care/interview/${interviewId}/answer-question/${questionId}`;

const initialData: ReducerData<ActionModel> = {
  byId: {} as Record<string, Item<ActionModel>>,
  ids: [] as Array<number>,
  total: 0,
  loading: false,
  error: null,
};

const useInterview = () => {
  const [state, dispatch] = useReducer(
    createReducer<ActionModel>(),
    initialData
  );

  const getByPatientPaginated = useCallback(
    async (patientId: Id, params: PaginatedRequestParams) => {
      try {
        dispatch({ type: 'LOADING' });
        const response = await axios.get(
          `${endpoint}/by-patient/${patientId}`,
          {
            params: { ...params, orderBy: 'interviewedAt', order: 'desc' },
          }
        );
        dispatch({ type: 'PAGINATION', payload: response.data });
      } catch (err) {
        dispatch({ type: 'ERROR', payload: err });
      }
    },
    []
  );

  const getById = useCallback(async (id: Id) => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.get(`${endpoint}/${id}`);
      dispatch({ type: 'GET_BY_ID', payload: response.data });
      return Promise.resolve(response.data as PatientInterviewModel);
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
      throw err;
    }
  }, []);

  const post = useCallback(async (data: PatientInterviewModel) => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.post(`${endpoint}`, data);
      dispatch({ type: 'CREATE', payload: response.data });
      return Promise.resolve(response);
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
      return Promise.reject(err);
    }
  }, []);

  const postFinishInterview = useCallback(async (interviewId: number) => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.post(finishInterviewEndPoint(interviewId));
      dispatch({ type: 'UPDATE', payload: response.data });
      return Promise.resolve(response);
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
      return Promise.reject(err);
    }
  }, []);

  const patchInterviewCareQuestionAnswer = useCallback(
    async (interviewId: number, questionId: number, data: AnswerType) => {
      try {
        const response = await axios.patch(
          careQuestionAnswerEndPoint(interviewId, questionId),
          { questionAnswerData: { ...data } }
        );
        return Promise.resolve(response);
      } catch (err) {
        dispatch({ type: 'ERROR', payload: err });
        return Promise.reject(err);
      }
    },
    []
  );

  return {
    ...state,
    getByPatientPaginated,
    getById,
    post,
    postFinishInterview,
    patchInterviewCareQuestionAnswer,
  };
};

export default useInterview;
