import styled from 'styled-components';
import { Button, IconButton } from '@material-ui/core';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.spacing(4)}px;
`;

export const TableContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(3)}px;
`;

export const AddButton = styled(Button).attrs({
  variant: 'contained',
})`
  background-color: ${({ theme }) =>
    theme.palette.getContrastText(theme.palette.primary.main)};
  color: ${({ theme }) => theme.palette.primary.main};
  border: 2px solid ${({ theme }) => theme.palette.primary.main};
  align-self: flex-end;
  padding: ${({ theme }) => theme.spacing(1.5, 2)}px;
  width: fit-content;
`;

export const EditIconButton = styled(IconButton)`
  padding: 0;
  margin-left: ${({ theme }) => theme.spacing(1)}px;
  color: ${({ theme }) => theme.palette.primary.main};
`;
