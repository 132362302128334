import React from 'react';
import { DialogProps } from '@material-ui/core/Dialog';

import StyledDialog from '../../../components/StyledDialog';
import { CaregiverModel } from '@cuidador/database';
import { DialogActions, DialogContent, DialogTitle } from '@material-ui/core';

import { FormValues, levelsOptions } from '../utils';
import StyledSelect from '../../../components/Forms/FormikSelect';
import StyledMenuItem from '../../../components/StyledMenuItem';
import StyledButton from '../../../components/StyledButton';
import { Formik } from 'formik';

import { StyledForm } from './styles';
import { validationSchema } from '../utils';

interface ChangeLevelDialogProps extends DialogProps {
  caregiver?: CaregiverModel;
  onConfirm: (values: FormValues) => Promise<void>;
  onCancel: () => void;
}

const ChangeLevelDialog: React.FC<ChangeLevelDialogProps> = ({
  caregiver,
  onCancel,
  onConfirm,
  ...rest
}) => {
  const formInitialValue = { level: caregiver?.level || '' };
  return (
    <StyledDialog {...rest}>
      <DialogContent>
        <DialogTitle>{`Informe o nível de ${caregiver?.user?.name}`}</DialogTitle>
        <Formik
          validationSchema={validationSchema}
          initialValues={formInitialValue}
          onSubmit={onConfirm}
        >
          {({ isSubmitting }) => (
            <StyledForm noValidate={true}>
              <StyledSelect
                name="level"
                label="Nível"
                SelectDisplayProps={{
                  'data-testid': 'level',
                }}
              >
                {levelsOptions.map((item) => (
                  <StyledMenuItem key={item.value} value={item.value}>
                    {item.label}
                  </StyledMenuItem>
                ))}
              </StyledSelect>
              <DialogActions>
                <StyledButton
                  disabled={isSubmitting}
                  onClick={onCancel}
                  color="inherit"
                >
                  Cancelar
                </StyledButton>
                <StyledButton
                  disabled={isSubmitting}
                  type="submit"
                  variant="text"
                  color="inherit"
                >
                  Salvar
                </StyledButton>
              </DialogActions>
            </StyledForm>
          )}
        </Formik>
      </DialogContent>
    </StyledDialog>
  );
};

export default ChangeLevelDialog;
