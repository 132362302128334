import React from 'react';
import { InputAdornment } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { useDebouncedCallback } from 'use-debounce';
import qs from 'query-string';

import { StyledInput } from './styles';
import { useHistory } from 'react-router-dom';

type SearchTextFieldProps = {
  fieldName: string;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onChangeDebounced?: (value: string) => void;
};

const getSearchValueFromUrl = (url: string) => url.replace(/^\%|\%$/g, '');

const SearchTextField: React.FC<SearchTextFieldProps> = ({
  fieldName,
  onChange,
  onChangeDebounced,
}) => {
  const history = useHistory();
  const defaultValue = getSearchValueFromUrl(
    (qs.parse(history.location.search)[fieldName] as string) || ''
  );

  const debounced = useDebouncedCallback((value) => {
    const newSearch = {
      ...qs.parse(history.location.search),
      [fieldName]: value ? `%${value}%` : undefined,
      page: 0,
    };
    const newUrl = qs.stringifyUrl({
      url: history.location.pathname,
      query: newSearch,
    });
    history.push(newUrl);
    if (onChangeDebounced) onChangeDebounced(value);
  }, 500);

  return (
    <StyledInput
      defaultValue={defaultValue}
      onChange={(e) => {
        debounced.callback(e.target.value);
        if (onChange) onChange(e);
      }}
      inputProps={{
        'data-testid': 'searchField',
      }}
      endAdornment={
        <InputAdornment position="end">
          <Search />
        </InputAdornment>
      }
    />
  );
};

export default SearchTextField;
