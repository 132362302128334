import React from 'react';
import { Field, useFormikContext } from 'formik';
import StyledSelectField from '../StyledSelectField';
import { SelectProps } from '@material-ui/core';

interface MySelectDisplayProps extends React.HTMLAttributes<HTMLDivElement> {
  'data-testid'?: string;
}

const FormikSelect = ({
  name,
  required = false,
  ...props
}: SelectProps & {
  name: string;
  required?: boolean;
  SelectDisplayProps?: MySelectDisplayProps;
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { errors, touched } = useFormikContext<any>();

  const hasError = touched[name] && errors[name];

  return (
    <div>
      <Field
        type="select"
        id={name}
        required={required}
        as={StyledSelectField}
        name={name}
        error={hasError}
        {...props}
      />
    </div>
  );
};

export default FormikSelect;
