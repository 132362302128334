import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import BackgroundImage from '../../assets/loginBackground.svg';
import TextField from '../../components/StyledTextField';

export const Background = styled.div`
  height: 110%;
  background-image: ${`url(${BackgroundImage})`};
  background-size: cover;
  background-position: center;
  padding: 0px ${({ theme }) => theme.spacing(2)}px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 80%;
  width: 100%;
  color: white;
  img {
    max-width: 100%;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 400px;
    max-width: 100%;
    text-align: center;
    flex: 1;
    justify-content: center;
    align-items: center;

    .MuiButton-root {
      margin-top: ${({ theme }) => theme.spacing(4)}px;
    }
  }
`;

export const StyledTypography = styled(Typography)`
  margin-block: ${({ theme }) => theme.spacing(6, 4)};
`;

export const PasswordRulesContainer = styled.div`
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.palette.common.white};
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  border-radius: 4px;
  padding: ${({ theme }) => theme.spacing(1)}px;
  margin-top: ${({ theme }) => theme.spacing(2)}px;
  width: 100%;
`;

export const HeadingContainer = styled.div`
  width: 400px;
  max-width: 100%;
  text-align: center;
`;

export const StyledTextField = styled(TextField).attrs({ variant: 'outlined' })`
  & label:not(.Mui-error) {
    color: ${({ theme }) => theme.palette.common.white};
  }
  & input {
    color: ${({ theme }) => theme.palette.common.white};
  }
  & textarea {
    color: ${({ theme }) => theme.palette.common.white};
  }
  & .MuiInputAdornment-root .MuiIconButton-label {
    color: ${({ theme }) => theme.palette.common.white};
  }
  & .MuiOutlinedInput-root:not(.Mui-error) {
    & fieldset {
      border-color: ${({ theme }) => theme.palette.common.white};
      color: ${({ theme }) => theme.palette.common.white};
    }
    &:hover fieldset {
      border-color: ${({ theme }) => theme.palette.common.white};
      color: ${({ theme }) => theme.palette.common.white};
    }
    &.Mui-focused fieldset {
      border-color: ${({ theme }) => theme.palette.common.white};
      color: ${({ theme }) => theme.palette.common.white};
    }
  }
`;
