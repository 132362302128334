/**
 * https://medium.com/trainingcenter/mascara-de-cpf-com-react-javascript-a07719345c93
 * Will be of type ddd.ddd.ddd-dd
 */
export const cpfMask = (value: string) => {
  return value
    .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1'); // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
};

export const numberMask = (value: string) => {
  return value.replace(/\D/g, ''); // substitui qualquer caracter que nao seja numero por nada
};

// Will be of type ddddd-ddd
export const cepMask = (value: string) => {
  return value
    .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{5})(\d{1,3})/, '$1-$2')
    .replace(/(-\d{3})\d+?$/, '$1'); // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
};

// Will be of type (dd) ddddd-dddd* or (dd) ddddd-dddd*
// Warning: If first 2 digits are "55", they will be removed (brazil international code)
export const phoneMask = (value: string) => {
  let numberOnly = value.replace(/\D/g, ''); // substitui qualquer caracter que nao seja numero por nada

  // Removes "55" if present
  if (value.startsWith('55')) {
    numberOnly = numberOnly.slice(2);
  }

  return numberOnly
    .replace(/(\d{2})(\d{1,5})/, '($1) $2')
    .replace(/(\d{4,5})(\d{4})(\d*)/, '$1-$2');
};

/**
 * https://www.geradorcpf.com/javascript-validar-cpf.htm
 */
export function validateCpf(rawCpf = '') {
  const cpf = rawCpf.replace(/[^\d]+/g, '');
  if (cpf === '') return false;
  // Elimina CPFs invalidos conhecidos
  if (
    cpf.length !== 11 ||
    cpf === '00000000000' ||
    cpf === '11111111111' ||
    cpf === '22222222222' ||
    cpf === '33333333333' ||
    cpf === '44444444444' ||
    cpf === '55555555555' ||
    cpf === '66666666666' ||
    cpf === '77777777777' ||
    cpf === '88888888888' ||
    cpf === '99999999999'
  )
    return false;
  // Valida 1o digito
  let add = 0;
  for (let i = 0; i < 9; i++) add += parseInt(cpf.charAt(i)) * (10 - i);
  let rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) rev = 0;
  if (rev !== parseInt(cpf.charAt(9))) return false;
  // Valida 2o digito
  add = 0;
  for (let i = 0; i < 10; i++) add += parseInt(cpf.charAt(i)) * (11 - i);
  rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) rev = 0;
  if (rev !== parseInt(cpf.charAt(10))) return false;
  return true;
}

export const validatePasswordStrength = (value: string) => {
  // at least 8 characters
  // at least 1 number
  // at least 1 special character
  const regex = /(?=.*\d)(?=.*[a-zA-Z])(?=.*[!@#$%&.,;?/\* -])[\da-zA-Z!@#$%&.,;?/\* -]{8,}/;
  return regex.test(value);
};
