import styled from 'styled-components';
import StyledTextField from '../../../../components/StyledTextField';

export const Background = styled.div`
  padding: 0px ${({ theme }) => theme.spacing(2)};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const HeadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  margin-bottom: ${({ theme }) => theme.spacing(4)}px;
`;

export const TypographyContainer = styled.div`
  width: 400px;
  max-width: 100%;
  text-align: left;
  color: ${({ theme }) => theme.palette.primary.main};
  margin-top: ${({ theme }) => theme.spacing(4)}px;
  margin-bottom: ${({ theme }) => theme.spacing(4)}px;
`;

export const StyledTextFieldForm = styled(StyledTextField)`
  & .MuiFormLabel-root.Mui-disabled {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;
