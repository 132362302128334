import React, { useCallback, useEffect } from 'react';
import { Container, EditIconButton, ColorCell, NoMessageDiv } from './styles';
import useIndicators from '../../../hooks/useIndicators';
import { CareLineIndicatorMessageObject } from '@cuidador/database';
import { TableCell } from '@material-ui/core';
import { ReactComponent as EditIcon } from '../../../assets/Icon-edit.svg';
import { useHistory } from 'react-router-dom';
import qs from 'query-string';
import MyTable from '../../../components/MyTable';
import CareLineIndicatorsDialog from './CareLineIndicatorsDialog';
import { FormValues } from './utils';
import { resolveErrorMessage } from '../../../utils/error';
import { AxiosError } from 'axios';
import { APIError } from '@cuidador/lib';
import { toast } from 'react-toastify';

const tableColumns = [
  'Categoria de cuidado',
  'Linha de cuidado',
  'Regra',
  'Cor',
  'Mensagem',
  '',
];

const CareLineIndicators: React.FC = () => {
  const {
    getPaginated,
    patchMessage,
    loading,
    ids,
    total,
    byId,
  } = useIndicators();
  const history = useHistory();
  const [
    careLineIndicatorsBeingEdited,
    setCareLineIndicatorsBeingEdited,
  ] = React.useState<CareLineIndicatorMessageObject>();

  const limit = parseInt(String(qs.parse(history.location.search).limit)) || 12;

  useEffect(() => {
    onChangeParams();
  }, []);

  const renderIndicatorItem = useCallback(
    (item: CareLineIndicatorMessageObject) => {
      return (
        <>
          <TableCell>{item?.categoryName}</TableCell>
          <TableCell>{item?.careLineName}</TableCell>
          <TableCell>{item?.ruleName}</TableCell>
          <TableCell>
            <ColorCell
              style={{
                backgroundColor: `#${item?.ruleColor}`,
              }}
            >
              {' '}
            </ColorCell>
          </TableCell>
          <TableCell>
            {item?.ruleMessage ? (
              item?.ruleMessage
            ) : (
              <NoMessageDiv>Nenhuma mensagem configurada</NoMessageDiv>
            )}
          </TableCell>
          <TableCell align="right">
            <EditIconButton>
              <EditIcon
                onClick={() => setCareLineIndicatorsBeingEdited(item)}
                data-testid="edit-dialog-open"
              />
            </EditIconButton>
          </TableCell>
        </>
      );
    },
    []
  );

  const onChangeParams = () => {
    const params = qs.parse(history.location.search);
    getPaginated({ ...params, limit: Number(params.limit) || 12 });
  };

  const data = ids.map((id) => byId[id]);

  const patchCareLineIndicator = (values: FormValues) => {
    patchMessage(values)
      .then(() => onChangeParams())
      .catch((err: AxiosError<APIError>) => {
        const displayMessage = resolveErrorMessage(err);
        toast.error(displayMessage);
      })
      .finally(() => setCareLineIndicatorsBeingEdited(undefined));
  };

  return (
    <Container>
      <MyTable
        columnNames={tableColumns}
        data={data}
        totalPages={Math.ceil(total / limit)}
        rowsPerPageOptions={[12, 24, 36]}
        keyExtractor={({ item }) => String(item!.id)}
        renderItem={renderIndicatorItem}
        loading={loading}
        onChangePage={onChangeParams}
        onChangeRowsPerPage={onChangeParams}
      />
      <CareLineIndicatorsDialog
        open={Boolean(careLineIndicatorsBeingEdited)}
        careLineIndicator={careLineIndicatorsBeingEdited}
        onCancel={() => setCareLineIndicatorsBeingEdited(undefined)}
        onConfirm={patchCareLineIndicator}
      />
    </Container>
  );
};

export default CareLineIndicators;
