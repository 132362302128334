import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Container } from './styles';

import { resolveErrorMessage } from '../../../../utils/error';
import { AxiosError } from 'axios';
import { APIError } from '@cuidador/lib';
import { toast } from 'react-toastify';
import useAction from '../../../../hooks/useAction';
import { ActionModel } from '@cuidador/database';

import { Typography } from '@material-ui/core';
import HeaderBase from '../../../../components/HeaderBase';
import ActionUpsertForm from '../../../../components/ActionUpsertForm';
import LoadingBackdrop from '../../../../components/LoadingBackdrop';
import StyledSimpleDialog from '../../../../components/StyledSimpleDialog';

const ActionUpdateItem: React.FC = () => {
  const [actionData, setActionData] = useState<ActionModel | null>(null);
  const [actionToBeEdited, setActionToBeEdited] = useState<ActionModel | null>(
    null
  );
  const [cancelEditActionDialogOpen, setCancelEditActionDialogOpen] = useState(
    false
  );
  const params = useParams<{ id: string }>();
  const { loading, getById, patch } = useAction();
  const history = useHistory();

  const id = Number(params.id);
  useEffect(
    function fetchInitialValues() {
      if (id) {
        getActionData();
      }
    },
    [id]
  );

  const getActionData = async () => {
    try {
      const action = (await getById(id)) as ActionModel;
      setActionData(action);
    } catch (err) {
      const displayMessage = resolveErrorMessage(err);
      toast.error(displayMessage);
    }
  };

  const handleOpenEditConfirmation = (data: ActionModel) => {
    setActionToBeEdited(data);
  };

  const handleCancel = () => {
    setCancelEditActionDialogOpen(true);
  };

  const handleSubmit = () => {
    return patch(id, actionToBeEdited!)
      .then(() => {
        history.goBack();
      })
      .catch((err: AxiosError<APIError>) => {
        const displayMessage = resolveErrorMessage(err);
        toast.error(displayMessage);
      })
      .finally(() => setActionToBeEdited(null));
  };

  if (loading) return <LoadingBackdrop loading={loading} />;

  return (
    <>
      <HeaderBase title={'CONFIGURAR AÇÕES'} onBackButtonClick={handleCancel} />
      <Container>
        <Typography variant="h5">Tipo</Typography>
        {actionData && (
          <ActionUpsertForm
            initialValues={actionData}
            onSubmit={handleOpenEditConfirmation}
            disableDefaultIsSubmittingHandle={true}
          />
        )}
        <StyledSimpleDialog
          open={Boolean(actionToBeEdited)}
          handleNo={() => setActionToBeEdited(null)}
          handleYes={handleSubmit}
          title={'Tem certeza que deseja editar essa ação?'}
          subTitle="Todas as alterações serão salvas."
        />
        <StyledSimpleDialog
          open={cancelEditActionDialogOpen}
          handleNo={() => setCancelEditActionDialogOpen(false)}
          handleYes={() => history.goBack()}
          title={'Tem certeza que deseja cancelar a edição dessa ação?'}
          subTitle="Todas as alterações serão perdidas."
        />
      </Container>
    </>
  );
};

export default ActionUpdateItem;
