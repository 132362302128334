import { Checkbox, FormControlLabel } from '@material-ui/core';
import { useFormikContext } from 'formik';
import React from 'react';
import { FormValues } from '../utils';
import {
  CheckboxGroup,
  Container,
  ControlsContainer,
  FormikFieldStyled,
  InputGroup,
  Label,
  LabelMessage,
  OptionsGroup,
  StyledHelperText,
} from './styles';

const RoleProfile: React.FC = () => {
  const { values, setFieldValue, errors } = useFormikContext<FormValues>();

  const { guardianAppAccess, caregiverAppAccess, adminAppAccess } = errors;

  const thereIsNotAtLeastOneAppSelected =
    !!guardianAppAccess && !!caregiverAppAccess && !!adminAppAccess;

  return (
    <Container>
      <InputGroup>
        <Label>
          Nome do tipo de perfil
          <LabelMessage>(Máx. 50 caracteres)</LabelMessage>
        </Label>
        <FormikFieldStyled
          name="title"
          width={60}
          inputProps={{ 'data-testid': 'title' }}
        />
      </InputGroup>
      <InputGroup>
        <Label>
          Descrição do tipo de perfil
          <LabelMessage>(Máx. 500 caracteres)</LabelMessage>
        </Label>
        <FormikFieldStyled
          name="description"
          width={95}
          inputProps={{ 'data-testid': 'description' }}
        />
      </InputGroup>
      <Label>Quais apps o perfil acessa?</Label>
      <CheckboxGroup>
        <ControlsContainer>
          <FormControlLabel
            control={
              <Checkbox
                name="guardianAppAccess"
                checked={values.guardianAppAccess}
                onChange={(e) =>
                  setFieldValue('guardianAppAccess', e.target.checked)
                }
                value={values.guardianAppAccess}
              />
            }
            label="Família"
            data-testid="guardian-app-access"
          />
          <FormControlLabel
            control={
              <Checkbox
                name="caregiverAppAccess"
                checked={values.caregiverAppAccess}
                onChange={(e) =>
                  setFieldValue('caregiverAppAccess', e.target.checked)
                }
                value={values.caregiverAppAccess}
              />
            }
            label="Cuidador"
            data-testid="caregiver-app-access"
          />
          <FormControlLabel
            control={
              <Checkbox
                name="adminAppAccess"
                checked={values.adminAppAccess}
                onChange={(e) =>
                  setFieldValue('adminAppAccess', e.target.checked)
                }
                value={values.adminAppAccess}
              />
            }
            label="Admin"
            data-testid="admin-app-access"
          />
        </ControlsContainer>
        {thereIsNotAtLeastOneAppSelected && (
          <StyledHelperText>
            Por favor, informe ao menos um app
          </StyledHelperText>
        )}
      </CheckboxGroup>
      <OptionsGroup>
        <div>
          <Label>Perfil público</Label>
          <CheckboxGroup>
            <FormControlLabel
              control={
                <Checkbox
                  name="isVisible"
                  checked={values.isVisible}
                  onChange={(e) => setFieldValue('isVisible', e.target.checked)}
                />
              }
              label="Tornar perfil público"
              data-testid="public-profile"
            />
          </CheckboxGroup>
        </div>
        <div>
          <Label>Vínculo automático</Label>
          <CheckboxGroup>
            <FormControlLabel
              control={
                <Checkbox
                  name="isAutoPatientRelateEnabled"
                  checked={values.isAutoPatientRelateEnabled}
                  onChange={(e) =>
                    setFieldValue(
                      'isAutoPatientRelateEnabled',
                      e.target.checked
                    )
                  }
                />
              }
              label="Ligar vínculo automático para este perfil"
              data-testid="isAutoPatientRelateEnabled"
            />
          </CheckboxGroup>
        </div>
      </OptionsGroup>
    </Container>
  );
};

export default RoleProfile;
