import { useCallback, useReducer } from 'react';
import axios from '../config/axios';
import { EventModel } from '@cuidador/database';
import {
  createReducer,
  ReducerData,
  Item,
  PaginatedRequestParams,
} from '../utils/store/index';

const endpoint = '/care/event/activity';

const initialData: ReducerData<EventModel> = {
  byId: {} as Record<string, Item<EventModel>>,
  ids: [] as Array<number>,
  total: 0,
  loading: false,
  error: null,
};

const useActivity = () => {
  const [state, dispatch] = useReducer(
    createReducer<EventModel>(),
    initialData
  );

  const getByPatientPaginated = useCallback(
    async (patientId: Id, params: PaginatedRequestParams) => {
      try {
        dispatch({ type: 'LOADING' });
        const response = await axios.get(
          `${endpoint}/by-patient/${patientId}`,
          {
            params: { ...params, orderBy: 'eventHappensAt', order: 'desc' },
          }
        );
        dispatch({ type: 'PAGINATION', payload: response.data });
      } catch (err) {
        dispatch({ type: 'ERROR', payload: err });
      }
    },
    []
  );

  return {
    ...state,
    getByPatientPaginated,
  };
};

export default useActivity;
