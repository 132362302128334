import { NormalizedReport } from '@cuidador/shared';
import formatDate from 'date-fns/format';

export type ClassificationLabelProps = {
  $classification?: 'good' | 'moderate' | 'bad';
  $backgroundColor?: string;
};

export type IndicatorSectionProps<T> = {
  data?: T;
  onChange: (id: number) => void;
  selectedIndicators: number[];
};

export type FormValues = {
  message: string;
  reportIndicatorIdList: number[];
};

export const getInitialValues = (report?: NormalizedReport): FormValues => {
  if (!report) {
    return {
      message: '',
      reportIndicatorIdList: [],
    };
  }

  const indicators: number[] = [];
  const {
    complicationIndicators,
    medicationIndicator,
    routineIndicators,
    careLineIndicators,
    staticCareLineIndicators,
  } = report;

  complicationIndicators?.forEach(({ id, isOnReport }) =>
    isOnReport ? indicators.push(id!) : null
  );

  if (medicationIndicator?.isOnReport) indicators.push(medicationIndicator.id!);

  routineIndicators?.forEach(({ id, isOnReport }) =>
    isOnReport ? indicators.push(id!) : null
  );

  careLineIndicators?.forEach(({ id, isOnReport }) =>
    isOnReport ? indicators.push(id!) : null
  );

  staticCareLineIndicators?.forEach(({ id, isOnReport }) =>
    isOnReport ? indicators.push(id!) : null
  );

  return {
    message: report.message || '',
    reportIndicatorIdList: indicators,
  };
};

export const formatReportPeriod = (startDate: string, endDate: string) =>
  `Período de acompanhamento: ${formatDate(
    new Date(startDate),
    'dd/MM/yyyy'
  )} até ${formatDate(new Date(endDate), 'dd/MM/yyyy')}`;
