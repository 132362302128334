import React from 'react';
import { ThemeProvider, StylesProvider } from '@material-ui/core/styles';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { BrowserRouter } from 'react-router-dom';

import theme, { styledTheme } from './styles/theme';
import { AuthProvider } from './contexts/auth';
import { GlobalLoadingProvider } from './contexts/RequestInterceptor';
import GlobalStyle from './styles/globalStyle';
import Toast from './components/Toast';
import Layout from './components/Layout';
import ErrorBoundary from './components/ErrorBoundary';
import './config/sentry';

const App: React.FC = () => (
  <ErrorBoundary>
    <StylesProvider injectFirst>
      <ThemeProvider theme={theme}>
        <StyledThemeProvider theme={styledTheme}>
          <GlobalStyle />
          <GlobalLoadingProvider>
            <BrowserRouter>
              <AuthProvider>
                <Toast />
                <Layout />
              </AuthProvider>
            </BrowserRouter>
          </GlobalLoadingProvider>
        </StyledThemeProvider>
      </ThemeProvider>
    </StylesProvider>
  </ErrorBoundary>
);
export default App;
