import { AnswerConfig, CareQuestionModel } from '@cuidador/database';
import { Checkbox, InputAdornment } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import AnswerConfigList from '../../components/AnswerConfigList';
import AutocompleteTextField, {
  OnChangeValue as AutoCompleteOnChangeValue,
} from '../../components/AutocompleteTextField';
import StyledSelect from '../../components/Forms/FormikSelect';
import FormikTextField from '../../components/Forms/FormikTextField';
import StyledButton from '../../components/StyledButton';
import StyledMenuItem from '../../components/StyledMenuItem';
import useCareLine from '../../hooks/useCareLine';
import { numberMask } from '../../utils/inputs';
import {
  CareLineDiv,
  DefinitionContainer,
  DescriptionDiv,
  FieldsContainer,
  FooterContainer,
  FrequencyDiv,
  QuestionTypeContainer,
  SelectDiv,
  StyledFormControlLabel,
} from './styles';
import {
  formDataToCareQuestionModel,
  FormValues,
  validationSchema,
} from './utils';

const CareQuestionUpsertForm: React.FC<{
  initialValues: FormValues;
  onSubmit: (careQuestion: CareQuestionModel) => void | Promise<void>;
  disableDefaultIsSubmittingHandle?: boolean;
}> = ({ initialValues, onSubmit, disableDefaultIsSubmittingHandle }) => {
  const [
    answerConfigBeingEdited,
    setAnswerConfigBeingEdited,
  ] = React.useState<AnswerConfig | null>(null);
  const { getPaginated } = useCareLine();

  const initialAnswerConfig = initialValues.answerConfig
    ? initialValues.answerConfig
    : undefined;
  const autoCompleteInitialValue = initialValues.careLineId
    ? {
        text: String(initialValues.careLineValue),
        value: Number(initialValues.careLineId),
      }
    : undefined;

  const handleAutoCompleteOnChange = async (
    textValue: string
  ): Promise<AutoCompleteOnChangeValue[]> => {
    const params = { name: `%${textValue}%` };
    const data = await getPaginated({ ...params, limit: 20 });
    return data?.map((careLine) => ({
      text: careLine.name,
      value: careLine.id,
    })) as AutoCompleteOnChangeValue[];
  };

  const handleAutoCompleteListItemClick = (
    item: AutoCompleteOnChangeValue,
    setFieldValue: (field: string, value: string) => void
  ) => {
    // set Formik field value outside form, because it is our autocomplete component
    setFieldValue('careLineId', String(item.value));
  };

  const handleAnswerConfigChanges = (answerConfig: AnswerConfig) => {
    setAnswerConfigBeingEdited(answerConfig);
  };

  const handleSubmit = (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    const data = formDataToCareQuestionModel({
      ...values,
      answerConfig: answerConfigBeingEdited,
    });
    if (disableDefaultIsSubmittingHandle) setSubmitting(false);
    return onSubmit(data as CareQuestionModel);
  };

  return (
    <>
      <Formik
        initialValues={initialValues as FormValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnChange={false}
      >
        {(formik) => (
          <Form noValidate={true}>
            <FieldsContainer>
              <DefinitionContainer>
                <CareLineDiv>
                  <AutocompleteTextField
                    onListValueClick={(item: AutoCompleteOnChangeValue) => {
                      handleAutoCompleteListItemClick(
                        item,
                        formik.setFieldValue
                      );
                    }}
                    onChangeDebounced={handleAutoCompleteOnChange}
                    name="careLineId"
                    label="Nome da linha de cuidado"
                    initialValue={autoCompleteInitialValue}
                  />
                </CareLineDiv>
                <SelectDiv>
                  <StyledSelect
                    name="classification"
                    label="Classificação"
                    SelectDisplayProps={{
                      'data-testid': 'classification',
                    }}
                    value={formik.values.classification || ''}
                  >
                    <StyledMenuItem key="functional" value="functional">
                      Funcional
                    </StyledMenuItem>
                    <StyledMenuItem key="dysfunctional" value="dysfunctional">
                      Disfuncional
                    </StyledMenuItem>
                    <StyledMenuItem
                      key="severe_dysfunctional"
                      value="severe_dysfunctional"
                    >
                      Disfuncional grave
                    </StyledMenuItem>
                  </StyledSelect>
                </SelectDiv>
                <FrequencyDiv>
                  <FormikTextField
                    name="frequencyRule"
                    label="Frequência"
                    margin="normal"
                    value={numberMask(formik.values.frequencyRule)}
                    inputProps={{
                      'data-testid': 'frequencyRule',
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">dias</InputAdornment>
                      ),
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                </FrequencyDiv>
              </DefinitionContainer>
              <QuestionTypeContainer>
                <DescriptionDiv>
                  <FormikTextField
                    name="careQuestionText"
                    label="Descrição da pergunta"
                    margin="normal"
                    inputProps={{
                      'data-testid': 'careQuestion',
                      maxLength: 500,
                    }}
                    multiline
                  />
                </DescriptionDiv>
                <StyledFormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      name="shouldRunOnOnboarding"
                      onChange={formik.handleChange}
                      checked={formik.values.shouldRunOnOnboarding}
                    />
                  }
                  label="Ativar classificação automaticamente no Onboarding do paciente"
                />
              </QuestionTypeContainer>
            </FieldsContainer>
            <AnswerConfigList
              questionText={formik.values.careQuestionText}
              onChange={handleAnswerConfigChanges}
              initialData={initialAnswerConfig}
            />
            <FooterContainer>
              <StyledButton
                color="inherit"
                data-testid="submit"
                type="submit"
                disabled={formik.isSubmitting}
              >
                Salvar
              </StyledButton>
            </FooterContainer>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default CareQuestionUpsertForm;
