import { ReportModel } from '@cuidador/database';
import { useCallback, useReducer } from 'react';
import axios from '../config/axios';
import {
  createReducer,
  Item,
  PaginatedRequestParams,
  ReducerData,
} from '../utils/store/index';
import useMedia from './useMedia';

const endpoint = '/report';

const initialData: ReducerData<ReportModel> = {
  byId: {} as Record<string, Item<ReportModel>>,
  ids: [] as Array<Id>,
  total: 0,
  loading: false,
  error: null,
};

const useReport = () => {
  const [state, dispatch] = useReducer(
    createReducer<ReportModel>(),
    initialData
  );
  const { getMedia } = useMedia();

  const getByPatientPaginated = useCallback(
    async (patientId: Id, params: PaginatedRequestParams) => {
      try {
        dispatch({ type: 'LOADING' });
        const response = await axios.get(
          `${endpoint}/by-patient/${patientId}`,
          {
            params: { ...params },
          }
        );
        dispatch({ type: 'PAGINATION', payload: response.data });
      } catch (err) {
        dispatch({ type: 'ERROR', payload: err });
      }
    },
    []
  );

  const getReportUrl = useCallback(
    (patientId: Id, reportId: Id) =>
      getMedia(`/media/patient/${patientId}/report/${reportId}`),
    []
  );

  const post = useCallback(async (data: ReportModel) => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.post(`${endpoint}`, data, {
        timeout: 0, // For report generation, no timeout will be used
      });
      dispatch({ type: 'CREATE', payload: response.data });
      return Promise.resolve(response);
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
      return Promise.reject(err);
    }
  }, []);

  const remove = useCallback(async (id: Id, patientId: Id) => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.delete(
        `${endpoint}/${id}/by-patient/${patientId}`
      );
      dispatch({ type: 'REMOVE', payload: { id } });
      return Promise.resolve(response);
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
      return Promise.reject(err);
    }
  }, []);

  const getById = useCallback(async (reportId: Id) => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.get(`${endpoint}/${reportId}`);
      dispatch({
        type: 'GET_BY_ID',
        payload: response.data,
      });
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
      return Promise.reject(err);
    }
  }, []);

  const saveReportSetup = useCallback(
    async (
      reportId: Id,
      reportConfig: { reportIndicatorIdList: Id[]; message: string }
    ) => {
      try {
        await axios.put(`${endpoint}/${reportId}`, reportConfig);
      } catch (err) {
        return Promise.reject(err);
      }
    },
    []
  );

  const regenerateReport = useCallback(async (reportId: Id) => {
    try {
      await axios.post(`${endpoint}/${reportId}/refresh`);
    } catch (err) {
      return Promise.reject(err);
    }
  }, []);

  return {
    ...state,
    getByPatientPaginated,
    getReportUrl,
    post,
    remove,
    getById,
    saveReportSetup,
    regenerateReport,
  };
};

export default useReport;
