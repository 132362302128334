export const dosesNames: Record<
  string,
  { nameSingular: string; namePlural: string }
> = {
  pill: {
    nameSingular: 'comprimido',
    namePlural: 'comprimidos',
  },
  capsule: {
    nameSingular: 'cápsula',
    namePlural: 'cápsulas',
  },
  sachet: {
    nameSingular: 'sachê',
    namePlural: 'sachês',
  },
  drops: {
    nameSingular: 'gota',
    namePlural: 'gotas',
  },
  ampoule: {
    nameSingular: 'ampola',
    namePlural: 'ampolas',
  },
  suppository: {
    nameSingular: 'supositório',
    namePlural: 'supositórios',
  },
  inhaled: {
    nameSingular: 'jato/puff',
    namePlural: 'jatos/puffs',
  },
  unit: {
    nameSingular: 'unidade',
    namePlural: 'unidades',
  },
  ml: {
    nameSingular: 'ml',
    namePlural: 'ml',
  },
  spoon: {
    nameSingular: 'colher',
    namePlural: 'colheres',
  },
  grams: {
    nameSingular: 'grama',
    namePlural: 'gramas',
  },
  strip: {
    nameSingular: 'placa/tira/fita',
    namePlural: 'placas/tiras/fitas',
  },
  sticker: {
    nameSingular: 'adesivo',
    namePlural: 'adesivos',
  },
};

export const frequencyOptions: Record<string, { name: string }> = {
  '2h': { name: '2h/2h' },
  '4h': { name: '4h/4h' },
  '6h': { name: '6h/6h' },
  '8h': { name: '8h/8h' },
  '12h': { name: '12h/12h' },
  '24h': { name: '1 vez ao dia' },
  '2d': { name: 'A cada dois dias' },
  '3d': { name: 'A cada três dias' },
  '4d': { name: 'A cada quatro dias' },
  '5d': { name: 'A cada cinco dias' },
  '6d': { name: 'A cada seis dias' },
  '7d': { name: 'Semanalmente' },
  '15d': { name: 'Quinzenal' },
  '1M': { name: 'Mensal' },
  '3M': { name: 'Trimestral' },
  '6M': { name: 'Semestral' },
};
