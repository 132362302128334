import React, { useEffect, useState } from 'react';

import AddIcon from '@material-ui/icons/AddCircleOutlineRounded';
import DeleteIcon from '@material-ui/icons/Delete';
import { ReactComponent as EditIcon } from '../../../assets/Icon-edit.svg';
import { TableCell, Typography } from '@material-ui/core';
import MyTable from '../../../components/MyTable';
import useCareCategory from '../../../hooks/useCareCategory';
import { CareCategoryModel } from '@cuidador/database';
import { resolveErrorMessage } from '../../../utils/error';
import { AxiosError } from 'axios';
import { APIError } from '@cuidador/lib';
import { toast } from 'react-toastify';

import StyledSimpleDialog from '../../../components/StyledSimpleDialog';
import CareCategoryDialog from './CareCategoryDialog';
import { FormValues, formDataToCareCategoryModel } from './utils';
import {
  Container,
  AddButton,
  Header,
  EditIconButton,
  DeleteIconButton,
  ColorCell,
} from './styles';

const tableColumns = ['Ordem', 'Categoria de cuidado', 'Cor', ''];

const Category: React.FC = () => {
  const { getAll, post, patch, loading, ids, byId, remove } = useCareCategory();

  const [
    careCategoryDialogOpen,
    setCareCategoryDialogOpen,
  ] = React.useState<boolean>(false);

  const [
    careCategoryBeingEdited,
    setCareCategoryBeingEdited,
  ] = React.useState<CareCategoryModel>();

  const [deleteDialogStatus, setDeleteDialogStatus] = useState(false);
  const [
    careCategoryBeingDeleted,
    setCareCategoryBeingDeleted,
  ] = React.useState<CareCategoryModel>();

  useEffect(() => {
    getAll();
  }, []);

  const renderCareCategoryItem = (item: CareCategoryModel) => {
    return (
      <>
        <TableCell>{item.order}</TableCell>
        <TableCell>{item?.name}</TableCell>
        <TableCell>
          <ColorCell style={{ backgroundColor: `#${item.colorHexa}` }}>
            {' '}
          </ColorCell>
        </TableCell>
        <TableCell align="right">
          <EditIconButton>
            <EditIcon
              onClick={() => setCareCategoryBeingEdited(item)}
              data-testid="edit-dialog-open"
            />
          </EditIconButton>
          <DeleteIconButton>
            <DeleteIcon
              onClick={() => {
                setCareCategoryBeingDeleted(item);
                setDeleteDialogStatus(true);
              }}
              data-testid="delete-dialog-open"
            />
          </DeleteIconButton>
        </TableCell>
      </>
    );
  };

  const cancelDialog = () => {
    if (careCategoryDialogOpen) {
      return setCareCategoryDialogOpen(false);
    } else {
      return setCareCategoryBeingEdited(undefined);
    }
  };

  const postCareCategory = (values: FormValues) => {
    careCategoryBeingEdited
      ? patch(careCategoryBeingEdited!.id!, formDataToCareCategoryModel(values))
          .then(() => {
            setCareCategoryBeingEdited(undefined);
          })
          .catch((err: AxiosError<APIError>) => {
            const displayMessage = resolveErrorMessage(err);
            toast.error(displayMessage);
          })
      : post(formDataToCareCategoryModel(values))
          .then(() => {
            setCareCategoryDialogOpen(false);
          })
          .catch((err: AxiosError<APIError>) => {
            const displayMessage = resolveErrorMessage(err);
            toast.error(displayMessage);
          });
  };

  const deleteCareCategory = (id: Id) => {
    remove(id)
      .then(() => {
        // Forces table refresh
        setDeleteDialogStatus(false);
        return getAll();
      })
      .catch((err: AxiosError<APIError>) => {
        const displayMessage = resolveErrorMessage(err);
        toast.error(displayMessage);
      });
  };

  const data = ids.map((id) => byId[id]);
  return (
    <Container>
      <Header>
        <AddButton
          data-testid="create-carecategory-dialog-open"
          onClick={() => setCareCategoryDialogOpen(true)}
        >
          <AddIcon fontSize="large" color="primary" />
          <Typography variant="h5">Adicionar</Typography>
        </AddButton>
      </Header>
      <MyTable
        columnNames={tableColumns}
        data={data}
        hasPagination={false}
        keyExtractor={({ item }) => String(item!.id)}
        renderItem={renderCareCategoryItem}
        loading={loading}
      />
      <CareCategoryDialog
        open={careCategoryDialogOpen || Boolean(careCategoryBeingEdited)}
        careCategory={careCategoryBeingEdited}
        onCancel={() => cancelDialog()}
        onConfirm={postCareCategory}
      />
      <StyledSimpleDialog
        open={deleteDialogStatus}
        handleNo={() => setDeleteDialogStatus(false)}
        handleYes={() =>
          deleteCareCategory(
            careCategoryBeingDeleted?.id ? careCategoryBeingDeleted.id : 0
          )
        }
        title={`Tem certeza que deseja excluir a categoria de cuidado ${careCategoryBeingDeleted?.name}?`}
        subTitle="Todas as linhas de cuidado, perguntas de cuidado e ações relacionadas a ela serão excluídas também."
      />
    </Container>
  );
};

export default Category;
