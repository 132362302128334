import { SubscriptionDetailModel } from '@cuidador/database';
import { DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import useSubscription from '../../hooks/useSubscription';
import LoadingBackdrop from '../LoadingBackdrop';
import StyledButton from '../StyledButton';
import StyledDialog from '../StyledDialog';
import {
  StyledIcon,
  StyledPlan,
  StyledPlanDescription,
  StyledTypography,
} from './styles';

interface Props extends DialogProps {
  onClose: () => void;
  subscriptionId: string;
}

const PatientPlanDialog: React.FC<Props> = ({
  onClose,
  subscriptionId,
  ...rest
}) => {
  const { getById, loading } = useSubscription();
  const [subscription, setSubscription] = useState<SubscriptionDetailModel>();

  const fetchData = (id: string) => {
    getById(id)
      .then((subscription) => {
        setSubscription(subscription);
      })
      .catch(() => {
        toast.error('Erro ao buscar detalhes do plano');
      });
  };

  useEffect(() => {
    if (!subscriptionId) return;
    fetchData(subscriptionId);
  }, [subscriptionId]);

  if (loading) return <LoadingBackdrop loading={loading} />;

  return (
    <StyledDialog {...rest}>
      <DialogContent>
        <DialogTitle>Detalhes do plano</DialogTitle>
        <StyledPlan>
          <StyledTypography variant="h6">
            <StyledIcon>
              <LoyaltyIcon />
            </StyledIcon>
            {subscription && subscription.plan?.name}
          </StyledTypography>
          <StyledTypography variant="subtitle2">
            <StyledPlanDescription>
              {subscription && subscription.plan?.description}
            </StyledPlanDescription>
          </StyledTypography>
        </StyledPlan>
        <DialogActions>
          <StyledButton onClick={onClose} color="inherit">
            Fechar
          </StyledButton>
        </DialogActions>
      </DialogContent>
    </StyledDialog>
  );
};

export default PatientPlanDialog;
