import React from 'react';
import { Checkbox, Typography } from '@material-ui/core';
import { NormalizedReportIndicator } from '@cuidador/shared';

import StyledFormControlLabel from '../../../components/StyledFormControlLabel';
import { IndicatorSectionProps } from '../utils';
import {
  LabelContainer,
  Label,
  ClassificationLabel,
  IndicatorTitleContainer,
  IndicatorTitle,
  IndicatorMessage,
  OptionContainer,
} from '../styles';
import { Percentage } from './styles';
import { labels } from './utils';

const MedicationIndicator: React.FC<
  IndicatorSectionProps<NormalizedReportIndicator>
> = ({ data, onChange, selectedIndicators }) => {
  const renderOptionLabel = ({
    message,
    reportEventSubCategory,
  }: NormalizedReportIndicator) => {
    const { classification, percentage } = reportEventSubCategory!;
    return (
      <IndicatorTitle>
        {percentage && (
          <Percentage $classification={classification!}>
            {percentage}%
          </Percentage>
        )}
        {'Adesão Medicamentosa: '}
        <IndicatorMessage>{message}</IndicatorMessage>
      </IndicatorTitle>
    );
  };

  return (
    <div data-testid="medicationIndicatorSection">
      <IndicatorTitleContainer>
        <Typography variant="h6">Medicamentos</Typography>
        <LabelContainer>
          <Typography variant="caption">Legenda:</Typography>
          {labels.map(({ key, label }) => (
            <Label key={key}>
              <Typography color="textPrimary" variant="caption">
                {label}
              </Typography>
              <ClassificationLabel $classification={key} />
            </Label>
          ))}
        </LabelContainer>
      </IndicatorTitleContainer>

      {data && (
        <OptionContainer key={data.id}>
          <StyledFormControlLabel
            value={data.id}
            name={String(data.id)}
            data-testid={data.id}
            control={
              <Checkbox
                color="primary"
                checked={selectedIndicators.includes(data.id!)}
                onChange={() => onChange(data.id!)}
              />
            }
            label={renderOptionLabel(data)}
          />
        </OptionContainer>
      )}
    </div>
  );
};

export default React.memo(MedicationIndicator);
