import styled from 'styled-components';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export const StyledHTMLEditor = styled(ReactQuill)`
  display: flex;
  flex: 1;
  flex-direction: column;
  .ql-toolbar,
  .ql-container {
    font-family: inherit;
    border-color: ${({ theme }) => theme.palette.primary.main};
  }

  .ql-toolbar {
    border-top-left-radius: ${({ theme }) => theme.shape.borderRadius}px;
    border-top-right-radius: ${({ theme }) => theme.shape.borderRadius}px;
  }

  .ql-container {
    flex: 1;
    height: 0;
    color: ${({ theme }) => theme.palette.primary.main};
    border-bottom-left-radius: ${({ theme }) => theme.shape.borderRadius}px;
    border-bottom-right-radius: ${({ theme }) => theme.shape.borderRadius}px;
  }
`;
