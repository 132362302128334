import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

export const Container = styled.div`
  margin-top: ${({ theme }) => theme.spacing(3)}px;
`;

export const Header = styled.div`
  display: flex;
  margin-bottom: ${({ theme }) => theme.spacing(3)}px;
  justify-content: space-between;
`;

export const AddButton = styled(Button).attrs({
  variant: 'contained',
})`
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const EditIconButton = styled(IconButton)`
  padding: 0;
  margin-left: ${({ theme }) => theme.spacing(1)}px;
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const DeleteIconButton = styled(IconButton)`
  padding: 0;
  margin-left: ${({ theme }) => theme.spacing(1)}px;
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const TypographyContainer = styled.div`
  width: 100%;
  text-align: center;
`;

export const TextWrap = styled.div`
  max-width: 30vw;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
`;
