import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email('Insira um e-mail válido')
    .required('E-mail é obrigatório'),
  password: yup
    .string()
    .min(6, 'A senha deve conter pelo menos 6 caracteres')
    .required('Senha é obrigatória'),
});

export const formInitialValues = {
  email: '',
  password: '',
};

export interface FormValues {
  email: string;
  password: string;
}
