import { DialogActions } from '@material-ui/core';
import red from '@material-ui/core/colors/red';
import { DialogProps } from '@material-ui/core/Dialog';
import WarningIcon from '@material-ui/icons/Warning';
import React from 'react';
import StyledButton from '../StyledButton';
import { StyledMuiDialog } from '../StyledDialog/styles';
import { Centralizer } from './styles';

interface Props extends DialogProps {
  reportName?: string;
  onConfirm: () => void;
  onCancel: () => void;
}

const RemoveReportDialog: React.FC<Props> = ({
  onCancel,
  onConfirm,
  reportName,
  ...rest
}) => {
  return (
    <StyledMuiDialog {...rest} backgroundColor={red[700]}>
      <Centralizer>
        <WarningIcon fontSize="large" />
        <p>Tem certeza que deseja EXCLUIR o relatório {reportName}?</p>
        <p>Esta ação não pode ser desfeita.</p>
        <DialogActions>
          <StyledButton color="inherit" onClick={onCancel}>
            VOLTAR
          </StyledButton>
          <StyledButton color="inherit" onClick={onConfirm}>
            CONFIRMAR
          </StyledButton>
        </DialogActions>
      </Centralizer>
    </StyledMuiDialog>
  );
};

export default RemoveReportDialog;
