import styled from 'styled-components';
import { Form } from 'formik';

export const ButtonContent = styled.div`
  margin-top: ${({ theme }) => theme.spacing(8)}px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledForm = styled(Form)`
  & label {
    color: ${({ theme }) => theme.palette.common.white};
  }
  & input {
    color: ${({ theme }) => theme.palette.common.white};
  }
  & textarea {
    color: ${({ theme }) => theme.palette.common.white};
  }
  & .MuiOutlinedInput-root:not(.Mui-error) {
    color: ${({ theme }) => theme.palette.common.white};
    & fieldset {
      border-color: ${({ theme }) => theme.palette.common.white};
      color: ${({ theme }) => theme.palette.common.white};
    }
    &:hover fieldset {
      border-color: ${({ theme }) => theme.palette.common.white};
      color: ${({ theme }) => theme.palette.common.white};
    }
    &.Mui-focused fieldset {
      border-color: ${({ theme }) => theme.palette.common.white};
      color: ${({ theme }) => theme.palette.common.white};
    }
  }
  color: ${({ theme }) => theme.palette.common.white};
`;
