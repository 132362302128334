import { ReportModel } from '@cuidador/database';
import { APIError } from '@cuidador/lib';
import { Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddCircleOutlineRounded';
import { AxiosError } from 'axios';
import qs from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import MyTable from '../../../components/MyTable';
import RemoveReportDialog from '../../../components/RemoveReportDialog';
import useReport from '../../../hooks/useReport';
import { resolveErrorMessage } from '../../../utils/error';
import CreateReportDialog from './CreateReportDialog';
import ReportListItem from './ReportListItem';
import { AddButton, Container, Header } from './styles';
import { formDataToReportModel, FormValues } from './utils';

const tableColumns = [
  'Nome do relatório',
  'Período analisado',
  'Status',
  'Regerar PDF',
  '',
  '',
];

const Reports: React.FC = () => {
  const [handleDialog, setHandleDialog] = useState(false);
  const [
    reportToBeRemoved,
    setReportToBeRemoved,
  ] = useState<Partial<ReportModel> | null>(null);
  const history = useHistory();
  const {
    loading,
    getByPatientPaginated,
    total,
    ids,
    byId,
    post,
    remove,
  } = useReport();
  const { id } = useParams<{ id: string }>();

  const limit = parseInt(String(qs.parse(history.location.search).limit)) || 20;

  useEffect(() => {
    onChangeParams();
  }, []);

  const onChangeParams = () => {
    const params = qs.parse(history.location.search);
    getByPatientPaginated(Number(id), {
      ...params,
      limit: Number(params.limit) || 20,
    });
  };

  const handleSubmit = async (values: FormValues) => {
    return post(formDataToReportModel(values, id))
      .then(({ data }) => {
        setHandleDialog(false);
        toast.success('Relatório gerado com sucesso');
        history.push(`${history.location.pathname}/${data.id}/configurar`);
      })
      .catch((err: AxiosError<APIError>) => {
        const displayMessage = resolveErrorMessage(err);
        toast.error(displayMessage);
      });
  };

  const removeReport = async () => {
    return remove(reportToBeRemoved?.id, reportToBeRemoved?.patientId)
      .then(() => {
        setReportToBeRemoved(null);
        toast.success('Relatório removido com sucesso');
      })
      .catch((err: AxiosError<APIError>) => {
        console.error(err);
        toast.error(resolveErrorMessage(err));
      });
  };

  const renderItem = useCallback((report: ReportModel) => {
    return (
      <ReportListItem
        {...report}
        patientId={Number(id)}
        setReportToBeRemoved={setReportToBeRemoved}
      />
    );
  }, []);

  const data = ids.map((id) => byId[id]);

  return (
    <Container>
      <Header>
        <AddButton
          data-testid="create-report-dialog-open"
          onClick={() => setHandleDialog(true)}
        >
          <AddIcon fontSize="large" color="primary" />
          <Typography variant="h5">Adicionar</Typography>
        </AddButton>
      </Header>
      <MyTable
        columnNames={tableColumns}
        data={data}
        totalPages={Math.ceil(total / limit)}
        keyExtractor={({ item }) => String(item!.id)}
        renderItem={renderItem}
        loading={loading}
        onChangePage={onChangeParams}
        onChangeRowsPerPage={onChangeParams}
      />
      <CreateReportDialog
        open={handleDialog}
        onCancel={() => setHandleDialog(false)}
        onConfirm={handleSubmit}
      />
      <RemoveReportDialog
        open={!!reportToBeRemoved}
        onCancel={() => setReportToBeRemoved(null)}
        onConfirm={removeReport}
        reportName={reportToBeRemoved?.name}
      />
    </Container>
  );
};

export default Reports;
