import styled from 'styled-components';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';

export const StyledHeader = styled.div`
  background-image: linear-gradient(
    to right,
    ${({ theme }) => theme.palette.info.main},
    ${({ theme }) => theme.palette.secondary.main}
  );
  color: ${({ theme }) => theme.palette.common.white};
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(2, 4)};
`;

export const StyledHeaderBackButton = styled.a`
  transition: all 0.3s;
  cursor: pointer;
  border-radius: 5px;
  :active {
    transform: scale(0.9);
  }
  margin-right: ${({ theme }) => theme.spacing(8)}px;
`;

export const StyledHeaderLabel = styled.label`
  color: ${({ theme }) => theme.palette.common.white};
  font-size: ${({ theme }) => theme.spacing(4)}px;
  font-family: Work Sans;
  font-weight: 500;
  margin-left: auto;
  margin-right: auto;
`;

export const StyledHeaderIcon = styled(ArrowBackRoundedIcon)`
  font-size: ${({ theme }) => theme.spacing(5)}px;
  color: ${({ theme }) => theme.palette.common.white};
`;
