import styled from 'styled-components';

export const Container = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
  flex: 1;
`;

export const CardsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
`;

export const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  flex: 1;
  width: 100%;
`;

export const ContentLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
