export interface PaginatedRequestParams {
  limit?: number;
  page?: number;
}

export interface ReducerProps<T> extends ReducerData<T> {
  getById?: (id: Id) => Promise<void>;
  getPaginated?: (params: PaginatedRequestParams) => Promise<void>;
  post?: (body: Record<string, T>) => Promise<void>;
  patch?: (id: Id, body: Record<string, Item<T>>) => Promise<void>;
  remove?: (id: Id) => Promise<void>;
}

export interface ReducerData<T> {
  byId: Record<string, Item<T>>;
  ids: Array<Id>;
  total: number;
  loading: boolean;
  error: Error | null;
}

export type Item<T> = {
  id: Id;
} & T;

type GET_BY_ID_TYPE<T> = {
  type: 'GET_BY_ID';
  payload: Item<T>;
};

type GET_ALL_TYPE<T> = {
  type: 'GET_ALL';
  payload: Item<T>[];
};

type CREATE_TYPE<T> = {
  type: 'CREATE';
  payload: Item<T>;
};

type UPDATE_TYPE<T> = {
  type: 'UPDATE';
  payload: Item<T>;
};

type REMOVE_TYPE = {
  type: 'REMOVE';
  payload: {
    id: Id;
  };
};

type PAGINATION_TYPE<T> = {
  type: 'PAGINATION';
  payload: {
    results: Item<T>[];
    total: number;
  };
};

type ERROR_TYPE = {
  type: 'ERROR';
  payload: Error;
};

type LOADING_TYPE = {
  type: 'LOADING';
};

type ActionType<T> =
  | GET_ALL_TYPE<T>
  | GET_BY_ID_TYPE<T>
  | CREATE_TYPE<T>
  | UPDATE_TYPE<T>
  | REMOVE_TYPE
  | PAGINATION_TYPE<T>
  | ERROR_TYPE
  | LOADING_TYPE;

function getIdsAndContent<T>(data: Item<T>[]) {
  const ids: Array<Id> = [];
  const byId = data.reduce<Record<string, Item<T>>>((prev, cur) => {
    if (cur.id) {
      ids.push(cur.id);
      prev[cur.id] = cur;
    }
    return prev;
  }, {});
  return { byId, ids, total: ids.length };
}

export const createReducer = <T>() => (
  state: ReducerData<T>,
  action: ActionType<T>
): ReducerProps<T> => {
  switch (action.type) {
    case 'GET_ALL':
      return {
        ...state,
        ...getIdsAndContent<T>(action.payload),
        loading: false,
      };
    case 'GET_BY_ID':
      return {
        ...state,
        ids: Array.from(new Set([...state.ids, action.payload.id])),
        byId: {
          ...state.byId,
          [action.payload.id]: action.payload,
        },
        loading: false,
      };
    case 'CREATE':
      return {
        ...state,
        ids: Array.from(new Set([...state.ids, action.payload.id])),
        byId: { ...state.byId, [action.payload.id]: action.payload },
        total: state.total + 1,
        loading: false,
      };
    case 'UPDATE':
      return {
        ...state,
        ids: Array.from(new Set([...state.ids, action.payload.id])),
        byId: { ...state.byId, [action.payload.id]: action.payload },
        loading: false,
      };
    case 'REMOVE':
      delete state.byId[action.payload.id];
      return {
        ...state,
        ids: state.ids.filter((id: Id) => id !== action.payload.id),
        total: state.total - 1,
        loading: false,
      };
    case 'PAGINATION':
      return {
        ...state,
        ...getIdsAndContent<T>(action.payload.results),
        total: action.payload.total,
        loading: false,
      };
    case 'ERROR':
      return { ...state, error: action.payload, loading: false };
    case 'LOADING':
      return { ...state, loading: true };
    default:
      return state;
  }
};
