import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import qs from 'query-string';
import { TableCell } from '@material-ui/core';
import SearchTextField from '../../../components/SearchTextField';
import MyTable from '../../../components/MyTable';
import useUser from '../../../hooks/useUser';
import { User } from '@cuidador/database';
import { ReactComponent as EditIcon } from '../../../assets/Icon-edit.svg';
import { TextWrap } from '../../Content/styles';
import { Container, Header, EditIconButton } from './styles';

const columnNames = ['Nome', 'Tipo de Perfil', ''];

const renderItem = (item: User) => {
  const history = useHistory();

  const handleViewUserInfo = (id: number) =>
    history.push(`/usuarios/todos-usuarios/${id}`);

  return (
    <>
      <TableCell>
        <TextWrap>
        <a
          href={`/usuarios/todos-usuarios/${item.id}`}          
          style={{ textDecoration: 'none', color: '#0088D3' }}
        >
          {item.name}
        </a>
      </TextWrap>
      </TableCell>
      <TableCell>
        <TextWrap>{item.role?.['title']}</TextWrap>
      </TableCell>
      <TableCell align="right">
        <EditIconButton>
          <EditIcon onClick={() => handleViewUserInfo(Number(item.id))} />
        </EditIconButton>
      </TableCell>
    </>
  );
};

const UserList: React.FC = () => {
  const { ids, byId, total, loading, getPaginated } = useUser();

  const history = useHistory();

  const limit = parseInt(String(qs.parse(history.location.search).limit)) || 20;

  useEffect(() => {
    onChangeParams();
  }, []);

  const onChangeParams = () => {
    const params = qs.parse(history.location.search);
    getPaginated({ ...params, limit: Number(params.limit) || 20 });
  };

  const users = ids.map((id) => byId[id]);

  return (
    <Container>
      <Header>
        <SearchTextField fieldName="name" onChangeDebounced={onChangeParams} />
      </Header>
      <MyTable
        columnNames={columnNames}
        data={users}
        totalPages={Math.ceil(total / limit)}
        renderItem={renderItem}
        keyExtractor={({ item }) => String(item.id)}
        loading={loading}
        onChangePage={onChangeParams}
        onChangeRowsPerPage={onChangeParams}
      />
    </Container>
  );
};

export default UserList;
