import { PatientModel } from '@cuidador/database';
import { Radio, RadioGroup } from '@material-ui/core';
import { Formik } from 'formik';
import React from 'react';
import * as yup from 'yup';
import FormikFieldWhite from '../../Forms/FormikTextFieldWhite';
import StyledSimpleModal from '../../StyledSimpleModal';
import { resolveDisablingReasonLabel } from '../utils';
import {
  Button,
  ButtonsContainer,
  Container,
  Description,
  Form,
  ReasonContainer,
  StyledFormControlLabel,
} from './styles';

interface UpdateStatusModalProps {
  open: boolean;
  onClose: () => void;
  onPatientDisableSubmit: (values: FormValues) => Promise<void>;
  onPatientEnableSubmit: () => Promise<void>;
  patientStatus?: PatientModel['status'];
  patientStatusDescription?: string;
  patientStatusLabelAfterReset?: string;
}

type FormDisablingReason = {
  value: PatientModel['disablingReason'];
  label: string;
};

export type FormValues = {
  disablingReason?: PatientModel['disablingReason'];
  disablingReasonDescription?: string;
};

const disablingReasons: FormDisablingReason[] = [
  { value: 'death', label: resolveDisablingReasonLabel('death') },
  { value: 'cancellation', label: resolveDisablingReasonLabel('cancellation') },
  {
    value: 'temporary',
    label: resolveDisablingReasonLabel('temporary'),
  },
  { value: 'other', label: resolveDisablingReasonLabel('other') },
];

const validationSchema = yup.object().shape({
  disablingReason: yup
    .string()
    .required()
    .oneOf(['death', 'cancellation', 'temporary', 'other']),
  disablingReasonDescription: yup.string().when('disablingReason', {
    is: 'other',
    then: yup.string().max(255).required('Por favor, descreva o motivo'),
    otherwise: yup.string().optional(),
  }),
});

const defaultValues: FormValues = {
  disablingReason: 'death',
  disablingReasonDescription: '',
};

const UpdateStatusModal: React.FC<UpdateStatusModalProps> = ({
  open,
  onClose,
  onPatientDisableSubmit,
  onPatientEnableSubmit,
  patientStatus,
  patientStatusDescription,
  patientStatusLabelAfterReset,
}) => {
  return (
    <StyledSimpleModal open={open}>
      <Container>
        {patientStatus === 'disabled' ? (
          <>
            <Description>
              <p>
                Você está prestes a restaurar o status. Ao restaurar o status
                desta pessoa sob cuidado será:
              </p>
              <p>
                <b>{patientStatusLabelAfterReset}</b>
              </p>
              <p>{patientStatusDescription}</p>
            </Description>
            <ButtonsContainer>
              <Button onClick={onClose}>Cancelar</Button>
              <Button onClick={onPatientEnableSubmit}>Confirmar</Button>
            </ButtonsContainer>
          </>
        ) : (
          <Formik
            validateOnChange={false}
            initialValues={defaultValues}
            validationSchema={validationSchema}
            onSubmit={onPatientDisableSubmit}
          >
            {({ isSubmitting, values, setFieldValue }) => {
              return (
                <Form noValidate={true}>
                  <Description>
                    <p>
                      Ao confirmar a <b>inativação</b>, todos os plantões
                      futuros para essa pessoa sob cuidado serão{' '}
                      <b>cancelados</b>. Entretanto, as{' '}
                      <b>informações históricas permanecerão disponíveis</b>{' '}
                      para consulta e será possível também restaurar o status no
                      futuro. Caso tenha <b>certeza</b> de que quer desativar,
                      escolha a opção que melhor descreve o motivo:
                    </p>
                  </Description>
                  <RadioGroup
                    name="disablingReason"
                    value={values.disablingReason}
                  >
                    {disablingReasons.map((reason) => (
                      <StyledFormControlLabel
                        key={reason.value}
                        value={reason.value}
                        control={<Radio />}
                        onChange={() =>
                          setFieldValue('disablingReason', reason.value)
                        }
                        label={reason.label}
                      />
                    ))}
                  </RadioGroup>
                  {values.disablingReason === 'other' && (
                    <ReasonContainer>
                      <FormikFieldWhite
                        id="disablingReasonDescription"
                        multiline
                        rows={4}
                        inputProps={{
                          'data-testid': 'disablingReasonDescription',
                          maxlength: 255,
                        }}
                        label="Descrição"
                        name="disablingReasonDescription"
                        margin="normal"
                        autoComplete="off"
                        fullWidth={true}
                      />
                    </ReasonContainer>
                  )}
                  <ButtonsContainer>
                    <Button onClick={onClose}>Cancelar</Button>
                    <Button disabled={isSubmitting} type="submit">
                      Confirmar
                    </Button>
                  </ButtonsContainer>
                </Form>
              );
            }}
          </Formik>
        )}
      </Container>
    </StyledSimpleModal>
  );
};

export default UpdateStatusModal;
