import { useCallback, useReducer } from 'react';
import axios from '../config/axios';
import { createReducer, ReducerData, Item } from '../utils/store/index';
import { RoleModel } from '@cuidador/database';

const endpoint = '/auth/role';

type RoleData = {
  title: string;
  description: string;
  permissions: Array<{ id: number }>;
  guardianAppAccess: boolean;
  caregiverAppAccess: boolean;
  adminAppAccess: boolean;
};

type RoleRemove = {
  data: { substituteRoleId: number };
};

const initialData: ReducerData<RoleModel> = {
  byId: {} as Record<string, Item<RoleModel>>,
  ids: [] as Array<number>,
  total: 0,
  loading: false,
  error: null,
};

const useRoles = () => {
  const [state, dispatch] = useReducer(createReducer<RoleModel>(), initialData);

  const getAll = useCallback(async () => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.get(`${endpoint}?orderBy=title`);
      dispatch({ type: 'GET_ALL', payload: response.data.results });
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
    }
  }, []);

  const getById = useCallback(async (id: string) => {
    try {
      const response = await axios.get(`${endpoint}/${id}`);
      dispatch({ type: 'GET_BY_ID', payload: response.data });
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
    }
  }, []);

  const patch = useCallback(async (id: string, data: Partial<RoleData>) => {
    try {
      const response = await axios.patch(`${endpoint}/${id}`, data);
      dispatch({ type: 'UPDATE', payload: response.data });
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
      return Promise.reject(err);
    }
  }, []);

  const post = useCallback(async (data: Partial<RoleData>) => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.post(`${endpoint}`, data);
      dispatch({ type: 'CREATE', payload: response.data });
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
      return Promise.reject(err);
    }
  }, []);

  const remove = useCallback(async (id: number, data: RoleRemove) => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.delete(`${endpoint}/${id}`, data);
      dispatch({ type: 'REMOVE', payload: response.data });
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
    }
  }, []);

  return {
    ...state,
    getById,
    getAll,
    patch,
    post,
    remove,
  };
};

export default useRoles;
