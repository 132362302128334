import * as Yup from 'yup';
import { CareCategoryModel } from '@cuidador/database';

export interface FormValues {
  name: string;
  colorHexa: string;
  order: string;
}

export const validationSchema = Yup.object().shape({
  name: Yup.string().required(
    'Por favor, insira o nome da categoria de cuidado'
  ),
  colorHexa: Yup.string().required('Por favor, selecione uma cor'),
  order: Yup.string().required('Por favor, insira a ordem da categoria'),
});

export const formDataToCareCategoryModel = (values: FormValues) =>
  (({
    order: parseInt(values.order),
    name: values.name,
    colorHexa: values.colorHexa.substring(1),
  } as unknown) as CareCategoryModel);
