import React, { useContext, useEffect, useRef, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { Formik, Form, FormikProps, FormikHelpers } from 'formik';
import { Link } from 'react-router-dom';

import Button from '../../components/StyledButton';
import { AuthContext } from '../../contexts/auth';
import logo from '../../assets/logo.svg';

import { validationSchema, formInitialValues, FormValues } from './utils';

import {
  Background,
  Container,
  TypographyContainer,
  StyledTextField,
} from './styles';

const Login: React.FC = () => {
  const [showPassword, setShowPassword] = useState(false);
  const formikRef = useRef<FormikProps<FormValues> | null>();

  const { signIn, error } = useContext(AuthContext);

  useEffect(() => {
    if (error) {
      formikRef.current?.setFieldError(
        'password',
        'Senha incorreta ou usuário não encontrado'
      );
    }
  }, [error]);

  const handleSubmit = (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    setSubmitting(true);
    return signIn(values.email, values.password);
  };

  const handleShowPassword = () => setShowPassword(!showPassword);

  return (
    <Background>
      <Container>
        <img src={logo} />
        <TypographyContainer>
          <Typography variant="h5">
            Insira as informações para acessar sua conta!
          </Typography>
        </TypographyContainer>
        <Formik
          innerRef={(ref) => (formikRef.current = ref)}
          initialValues={formInitialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(formik) => (
            <Form>
              <StyledTextField
                name="email"
                label="E-mail"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.email)}
                helperText={formik.errors.email}
                margin="normal"
                fullWidth
                inputProps={{ 'data-testid': 'email' }}
              />
              <StyledTextField
                name="password"
                type={showPassword ? 'text' : 'password'}
                label="Senha"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.password)}
                helperText={formik.errors.password}
                margin="normal"
                fullWidth
                inputProps={{ 'data-testid': 'password' }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleShowPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                data-testid="submit"
                size="large"
                color="inherit"
                type="submit"
                disabled={formik.isSubmitting}
              >
                Entrar
              </Button>
            </Form>
          )}
        </Formik>
        <Link to="/esqueci-minha-senha">Esqueci minha senha</Link>
      </Container>
    </Background>
  );
};

export default Login;
