import * as Yup from 'yup';
import formatDate from 'date-fns/format';
import { parse, startOfDay, endOfDay } from 'date-fns';
import { ReportModel } from '@cuidador/database';

export const formatReportPeriod = (startDate: string, endDate: string) =>
  `${formatDate(new Date(startDate!), 'dd/MM/yyyy')} - ${formatDate(
    new Date(endDate!),
    'dd/MM/yyyy'
  )}`;

export const formatStatus = (isDraft: boolean) => {
  return isDraft ? 'Não finalizado' : 'Finalizado';
};

export const formatStartDate = (date: string) => {
  return startOfDay(parse(`${date}`, 'yyyy-MM-dd', new Date())).toISOString();
};

export const formatEndDate = (date: string) => {
  const endDate = endOfDay(parse(`${date}`, 'yyyy-MM-dd', new Date()));
  endDate.setMilliseconds(0);
  return endDate.toISOString();
};

export const formDataToReportModel = (
  values: FormValues,
  patientId: string
) => {
  const isoStartDate = formatStartDate(`${values.startDate}`);
  const isoEndDate = formatEndDate(`${values.endDate}`);

  return {
    name: values.name,
    startDate: isoStartDate,
    endDate: isoEndDate,
    patientId: Number(patientId),
  } as ReportModel;
};

export const initialValues = {
  name: '',
  startDate: '',
  endDate: '',
};

export interface FormValues {
  name?: string;
  startDate?: string;
  endDate: string;
}

export const validationSchema = Yup.object().shape({
  name: Yup.mixed().required('Por favor, selecione um nome'),
  startDate: Yup.string().required('Por favor, insira uma data inicial'),
  endDate: Yup.string().required('Por favor, insira uma data final'),
});
