/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/no-children-prop */
import { PatientModel } from '@cuidador/database';
import { APIError } from '@cuidador/lib';
import { TableCell } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { AxiosError } from 'axios';
import formatDate from 'date-fns/format';
import qs from 'query-string';
import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import SelfCareIcon from '../../assets/Icon-selfcare.svg';
import MyTable from '../../components/MyTable';
import PatientPlanDialog from '../../components/PatientPlanDialog';
import SearchTextField from '../../components/SearchTextField';
import StyledButton from '../../components/StyledButton';
import { isSelfCare } from '../../contexts/auth';
import usePatient from '../../hooks/usePatient';
import theme from '../../styles/theme';
import { timezoneUnawareDate } from '../../utils/date';
import { resolveErrorMessage } from '../../utils/error';
import ChangeClassificationDialog from './ChangeClassificationDialog';
import ChangeExpirationDateDialog from './ChangeExpirationDateDialog';
import {
  CaregiverContainer,
  Container,
  EditIconButton,
  Header,
  IconContainer,
  IconSelfCare,
  StatusDiv,
  StyledErrorIcon,
  StyledLink,
  StyledTypography,
} from './styles';
import {
  ChangeClassificationFormValues,
  ChangeExpirationDateFormValues,
  formatClassification,
} from './utils';

const tableColumns = [
  'Data',
  'PSC',
  'Responsável',
  'Contato',
  'Cuidadores',
  'Atenção',
  'Plano',
  'Validade',
  'Classificação',
  'Status',
];

const Patients: React.FC = () => {
  const { getPaginated, ids, byId, total, loading, patch } = usePatient();
  const [
    patientBeingEdited,
    setPatientBeingEdited,
  ] = React.useState<PatientModel>();
  const [
    patientClassificationBeingEdited,
    setPatientClassificationBeingEdited,
  ] = React.useState<PatientModel>();
  const [order, setOrder] = React.useState('');
  const [orderBy, setOrderBy] = React.useState('');
  const [
    patientPlanDialogVisible,
    setPatientPlanDialogVisible,
  ] = React.useState(false);
  const [
    patientModalSubscriptionId,
    setPatientModalSubscriptionId,
  ] = React.useState('');

  const history = useHistory();

  const limit = parseInt(String(qs.parse(history.location.search).limit)) || 20;

  useEffect(() => {
    onChangeParams();
  }, []);

  const onChangeParams = () => {
    const params = qs.parse(history.location.search);
    // set the order according to the params
    setOrder(String(params.order));
    if (params.orderBy === 'complicationCount') {
      setOrderBy('Atenção');
    }
    getPaginated({ ...params, limit: Number(params.limit) || 20 });
  };

  const onRequestSort = (column: string) => {
    if (column === 'Atenção') {
      const newSearch = {
        ...qs.parse(history.location.search),
        orderBy: 'complicationCount',
        order:
          qs.parse(history.location.search).order === 'desc' ? 'asc' : 'desc',
      };
      const newUrl = qs.stringifyUrl({
        url: history.location.pathname,
        query: newSearch,
      });
      history.push(newUrl);

      setOrder(
        qs.parse(history.location.search).order === 'desc' ? 'asc' : 'desc'
      );
      setOrderBy(column);

      onChangeParams();
    }
  };

  const renderPatientItem = useCallback((item: PatientModel) => {
    const contrastTextThemeColor = theme.palette.info.dark;
    const successThemeColor = theme.palette.success.light;
    const warningThemeColor = theme.palette.warning.dark;

    const resolvePatientStatusLabel = (status: string, isNew: number) => {
      if (status === 'disabled') return 'INATIVO';
      else if (status === 'enabled' && isNew === 1) return 'PENDENTE';
      else return 'ATIVO';
    };

    const resolveStatusColor = (status: string, isNew: number) => {
      switch (resolvePatientStatusLabel(status, isNew)) {
        case 'ATIVO':
          return successThemeColor;
        case 'PENDENTE':
          return warningThemeColor;
        default:
          return contrastTextThemeColor;
      }
    };

    return (
      <>
        <TableCell>
          {item.createdAt && formatDate(new Date(item.createdAt), 'dd/MM/yy')}
        </TableCell>
        <TableCell>
          <StyledLink to={`/pacientes/${item.id}/pessoais`}>
            {item.name}
          </StyledLink>
        </TableCell>
        <TableCell>{item.organization?.createdBy?.name}</TableCell>
        <TableCell>{item.phoneNumber}</TableCell>
        <TableCell>
          {(item.users || []).map((user) => (
            <CaregiverContainer key={user?.id}>
              <li>
                {user?.name}{' '}
                {isSelfCare(user) && (
                  <IconContainer>
                    <IconSelfCare src={SelfCareIcon} />
                  </IconContainer>
                )}
              </li>
            </CaregiverContainer>
          ))}
        </TableCell>
        <TableCell>
          {Number(item.complicationCount) > 0 && <StyledErrorIcon />}
        </TableCell>
        <TableCell>
          {item.organization?.subscriptionId && (
            <StyledButton
              color="inherit"
              variant="text"
              onClick={() => {
                const subscriptionId = item.organization?.subscriptionId || '';
                setPatientModalSubscriptionId(subscriptionId);
                setPatientPlanDialogVisible(true);
              }}
            >
              Ver detalhes
            </StyledButton>
          )}
        </TableCell>
        <TableCell>
          {item.planExpirationDate &&
            formatDate(
              timezoneUnawareDate(item.planExpirationDate),
              'dd/MM/yy'
            )}
          <EditIconButton
            data-testid="plan-expire-date-dialog-open"
            onClick={() => setPatientBeingEdited(item)}
          >
            <EditIcon />
          </EditIconButton>
        </TableCell>
        <TableCell>
          <span>{formatClassification(item.classification)}</span>
          <EditIconButton
            data-testid="plan-classification-dialog-open"
            onClick={() => setPatientClassificationBeingEdited(item)}
          >
            <EditIcon />
          </EditIconButton>
        </TableCell>
        <TableCell>
          <StatusDiv>
            <StyledTypography
              statusColor={resolveStatusColor(
                String(item.status),
                Number(item.isNew)
              )}
            >
              {resolvePatientStatusLabel(
                String(item.status),
                Number(item.isNew)
              )}
            </StyledTypography>
          </StatusDiv>
        </TableCell>
      </>
    );
  }, []);

  const updatePatientExpirationDate = async (
    values: ChangeExpirationDateFormValues
  ) => {
    const data = {
      planExpirationDate: values.expirationDate,
    };
    await patch(patientBeingEdited!.id!, { ...data })
      .then(() => {
        setPatientBeingEdited(undefined);
      })
      .catch(parseErrorAndFireToast);
  };

  const updatePatientClassification = async (
    values: ChangeClassificationFormValues
  ) => {
    const data = {
      classification: values.classification,
    };
    await patch(patientClassificationBeingEdited!.id!, { ...data })
      .then(() => {
        setPatientClassificationBeingEdited(undefined);
      })
      .catch(parseErrorAndFireToast);
  };

  const parseErrorAndFireToast = (err: AxiosError<APIError>) => {
    const displayMessage = resolveErrorMessage(err);
    toast.error(displayMessage);
  };

  const data = ids.map((id) => byId[id]);

  return (
    <Container>
      <Header>
        <SearchTextField fieldName="name" onChangeDebounced={onChangeParams} />
      </Header>
      <PatientPlanDialog
        open={patientPlanDialogVisible}
        onClose={() => setPatientPlanDialogVisible(false)}
        subscriptionId={patientModalSubscriptionId}
      />
      <MyTable
        columnNames={tableColumns}
        data={data}
        totalPages={Math.ceil(total / limit)}
        keyExtractor={({ item }) => String(item!.id)}
        renderItem={renderPatientItem}
        loading={loading}
        onChangePage={onChangeParams}
        onChangeRowsPerPage={onChangeParams}
        onRequestSort={onRequestSort}
        order={order === 'asc' ? 'asc' : 'desc'}
        orderBy={orderBy}
      />
      <ChangeExpirationDateDialog
        open={Boolean(patientBeingEdited)}
        patient={patientBeingEdited}
        onCancel={() => setPatientBeingEdited(undefined)}
        onConfirm={updatePatientExpirationDate}
      />
      <ChangeClassificationDialog
        open={Boolean(patientClassificationBeingEdited)}
        patient={patientClassificationBeingEdited}
        onCancel={() => setPatientClassificationBeingEdited(undefined)}
        onConfirm={updatePatientClassification}
      />
    </Container>
  );
};

export default Patients;
