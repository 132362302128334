import { Switch } from '@material-ui/core';
import styled from 'styled-components';

const StyledSwitch = styled(Switch)`
  height: 50px;
  width: 76px;
  & .MuiSwitch-thumb {
    border: 2px solid ${({ theme }) => theme.palette.primary.main};
    width: 32px;
    height: 32px;
  }
  & .MuiSwitch-track {
    background-color: ${({ theme }) => theme.palette.common.white};
    height: 27px;
    border-radius: 16px;
  }
  & .MuiSwitch-colorSecondary.Mui-checked {
    color: ${({ theme }) => theme.palette.primary.main};
  }
  & .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: ${({ theme }) => theme.palette.primary.main};
    border-radius: 16px;
    height: 27px;
  }
`;

export default StyledSwitch;
