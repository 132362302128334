import React from 'react';
import { useParams } from 'react-router-dom';
import TabMenu from './TabMenu';
import RolesList from './RolesList';
import UserList from './UserList';
import { Container } from './styles';

type RouteParams = {
  tabId?: 'todos-usuarios' | 'perfis';
  id?: string;
};

const Users: React.FC = () => {
  const { tabId } = useParams<RouteParams>();

  return (
    <Container>
      <TabMenu />
      <div data-testid={tabId} />
      {tabId === 'todos-usuarios' && <UserList />}
      {tabId === 'perfis' && <RolesList />}
    </Container>
  );
};

export default Users;
