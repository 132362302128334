import React from 'react';
import { clientSideScheme } from '@cuidador/whitelabel';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlined from '@material-ui/icons/CheckBoxOutlined';
import CloseIcon from '@material-ui/icons/Close';
import { passwordRules } from './utils';
import { Container, RuleContainer, RuleText, Title } from './styles';

interface PasswordRulesProps {
  password: string;
  validationError?: boolean;
}

const PasswordRules: React.FC<PasswordRulesProps> = ({
  password,
  validationError = false,
}) => {
  const { appFPalette } = clientSideScheme();
  return (
    <Container>
      <Title variant="caption">Critérios para criar sua senha</Title>
      {passwordRules.map((rule, index) => {
        const isValid = rule.isValid(password);
        return (
          <RuleContainer key={index}>
            {isValid ? (
              <CheckBoxIcon htmlColor={appFPalette.extra.color.green} />
            ) : validationError ? (
              <CloseIcon htmlColor={appFPalette.extra.color.red.dark} />
            ) : (
              <CheckBoxOutlined htmlColor={appFPalette.extra.color.grey.main} />
            )}
            <RuleText
              variant="subtitle2"
              isValid={isValid}
              submitError={validationError}
            >
              {rule.text}
            </RuleText>
          </RuleContainer>
        );
      })}
    </Container>
  );
};

export default PasswordRules;
