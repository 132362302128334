import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const Toast = styled(ToastContainer)`
  .Toastify__toast--info {
    background: ${({ theme }) => theme.palette.info.main};
    .Toastify__progress-bar {
      background-color: ${({ theme }) => theme.palette.primary.light};
    }
  }
  .Toastify__toast--success {
    background: ${({ theme }) => theme.palette.success.main};
  }
  .Toastify__toast--warning {
    background: ${({ theme }) => theme.palette.warning.main};
  }
  .Toastify__toast--error {
    background: ${({ theme }) => theme.palette.error.main};
    .Toastify__progress-bar {
      background-color: ${({ theme }) => theme.palette.error.light};
    }
  }
`;
