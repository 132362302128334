import { useCallback, useReducer } from 'react';
import axios from '../config/axios';
import { MedicationModel } from '@cuidador/database';
import { createReducer, ReducerData, Item } from '../utils/store/index';

const endpoint = '/care/medication';

const initialData: ReducerData<MedicationModel> = {
  byId: {} as Record<string, Item<MedicationModel>>,
  ids: [] as Array<number>,
  total: 0,
  loading: false,
  error: null,
};

const useMedication = () => {
  const [state, dispatch] = useReducer(
    createReducer<MedicationModel>(),
    initialData
  );

  const getAllByPatientId = useCallback(async (patientId: Id, params) => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.get(`${endpoint}/by-patient/${patientId}`, {
        params: {
          ...params,
          orderBy: 'eventSchedule.createdAt',
          order: 'desc',
        },
      });
      dispatch({ type: 'GET_ALL', payload: response.data });
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
    }
  }, []);

  return {
    ...state,
    getAllByPatientId,
  };
};

export default useMedication;
