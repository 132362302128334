import React from 'react';
import {
  StyledHeader,
  StyledHeaderLabel,
  StyledHeaderBackButton,
  StyledHeaderIcon,
} from './styles';
import { useHistory } from 'react-router-dom';

interface HeaderBase {
  title?: string;
  onBackButtonClick?: () => void;
}

const HeaderBase: React.FC<HeaderBase> = ({ title, onBackButtonClick }) => {
  const history = useHistory();

  const goBackNavigation = () => {
    history.length <= 1 ? history.push('/') : history.goBack();
  };

  return (
    <StyledHeader>
      <StyledHeaderBackButton onClick={onBackButtonClick || goBackNavigation}>
        <StyledHeaderIcon />
      </StyledHeaderBackButton>
      <StyledHeaderLabel>{title}</StyledHeaderLabel>
    </StyledHeader>
  );
};

export default HeaderBase;
