import React, { useEffect } from 'react';
import {
  Container,
  TypographyContainer,
  InputBox,
  Backdrop,
  BackdropCircularProgress,
} from './styles';

import { useHistory, useParams } from 'react-router-dom';
import { resolveErrorMessage } from '../../../utils/error';
import { AxiosError } from 'axios';
import { APIError } from '@cuidador/lib';
import { toast } from 'react-toastify';
import useContent from '../../../hooks/useContent';
import Header from '../../../components/HeaderBase';
import ContentUpsertForm from '../../../components/ContentUpsertForm';
import { FormValues } from '../../../components/ContentUpsertForm';
import { Typography } from '@material-ui/core';

const ContentUpdate: React.FC = () => {
  const params = useParams<{ id: string }>();
  const { getById, patch, loading, byId } = useContent();
  const history = useHistory();

  const id = parseInt(params.id);

  useEffect(
    function fetchInitialValues() {
      if (id) {
        getById(id);
      }
    },
    [id]
  );

  if (loading) {
    return (
      <Backdrop open={loading}>
        {loading && (
          <BackdropCircularProgress data-testid="table-backdrop-spinner" />
        )}
      </Backdrop>
    );
  }

  const content = byId[id];
  const formInitialValue = {
    title: content?.title,
    text: content?.text,
  };

  const handleSubmit = (values: FormValues) => {
    patch(id, values)
      .then(() => {
        history.goBack();
      })
      .catch((err: AxiosError<APIError>) => {
        const displayMessage = resolveErrorMessage(err);
        toast.error(displayMessage);
      });
  };

  if (loading) {
    return (
      <Backdrop open={loading}>
        {loading && (
          <BackdropCircularProgress data-testid="table-backdrop-spinner" />
        )}
      </Backdrop>
    );
  }

  return (
    <Container>
      <Header title="Edição de Conteúdo" />
      <InputBox>
        <TypographyContainer>
          <Typography variant="h4">Conteúdos</Typography>
        </TypographyContainer>
        <ContentUpsertForm
          initialValues={formInitialValue}
          onSubmit={handleSubmit}
        />
      </InputBox>
    </Container>
  );
};

export default ContentUpdate;
