import styled from 'styled-components';

export const Container = styled.div`
  margin: ${({ theme }) => theme.spacing(2, 0)};
`;

export const UnfoldContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing(8)}px;
  margin-bottom: -${({ theme }) => theme.spacing(1)}px;
`;
