import React from 'react';
import { DialogProps } from '@material-ui/core/Dialog';

import StyledDialog from '../StyledDialog';
import { DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import StyledButton from '../StyledButton';

interface ChangeStatusDialogProps extends DialogProps {
  name?: string;
  onConfirm: () => void;
  onCancel: () => void;
  checked?: boolean;
}
const ChangeStatusDialog: React.FC<ChangeStatusDialogProps> = ({
  name,
  onCancel,
  onConfirm,
  checked,
  ...rest
}) => {
  return (
    <StyledDialog {...rest}>
      <DialogContent>
        {checked ? (
          <DialogTitle>{`Realmente deseja DESATIVAR ${name}?`}</DialogTitle>
        ) : (
          <DialogTitle>{`Realmente deseja ATIVAR ${name}?`}</DialogTitle>
        )}
        <DialogActions>
          <StyledButton
            data-testid="cancel-change-status"
            size="large"
            color="inherit"
            onClick={onCancel}
          >
            Cancelar
          </StyledButton>
          <StyledButton
            data-testid="confirm-change-status"
            size="large"
            color="inherit"
            type="submit"
            variant="text"
            onClick={onConfirm}
          >
            Confirmar
          </StyledButton>
        </DialogActions>
      </DialogContent>
    </StyledDialog>
  );
};

export default ChangeStatusDialog;
