import React, { memo } from 'react';
import { Formik, Form as FormikForm } from 'formik';
import TabMenu from '../../../pages/Users/TabMenu';
import Profile from '../Profile';
import Table from '../Table';
import DeleteButtonAndDialogs from '../DeleteButtonAndDialogs';
import { AllPermissions } from '../../../hooks/usePermissions';
import { FormValues, validationSchema } from '../utils';
import { RoleModel } from '@cuidador/database';
import { Container, FormContainer, OptionsContainer, Button } from './styles';
import theme from '../../../styles/theme';

const Form: React.FC<{
  handleSubmit: (values: FormValues) => void;
  formInitialValue: FormValues;
  role?: RoleModel;
  hasDeleteAction: boolean;
  saveButtonLabel: string;
  allPermissions: AllPermissions;
}> = ({
  handleSubmit,
  formInitialValue,
  role,
  hasDeleteAction,
  saveButtonLabel,
  allPermissions,
}) => {
  return (
    <Container>
      <TabMenu />
      <Formik
        initialValues={formInitialValue}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, isSubmitting, setFieldValue }) => {
          const handlePermissionChange = setFieldValue;
          return (
            <FormikForm noValidate={true}>
              <FormContainer>
                <div>
                  <Profile />
                </div>
                <OptionsContainer>
                  {hasDeleteAction && role && (
                    <DeleteButtonAndDialogs role={role} />
                  )}
                  <Button
                    data-testid="save-button"
                    textColor={String(theme.palette.primary.main)}
                    backgroundColor="white"
                    borderColor={String(theme.palette.primary.main)}
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {saveButtonLabel}
                  </Button>
                </OptionsContainer>
              </FormContainer>
              {allPermissions && (
                <Table
                  allPermissions={allPermissions}
                  currentPermissions={values.permissions}
                  handlePermissionChange={handlePermissionChange}
                />
              )}
            </FormikForm>
          );
        }}
      </Formik>
    </Container>
  );
};

export default memo(Form);
