import * as Yup from 'yup';
import { CareQuestionModel, AnswerConfig } from '@cuidador/database';

export const validationSchema = Yup.object().shape({
  classification: Yup.mixed().required(
    'Por favor, selecione uma classificação'
  ),
  careLineId: Yup.string().required('Por favor, insira uma linha de cuidado'),
  frequencyRule: Yup.string().required('Por favor, insira um valor'),
  careQuestionText: Yup.string().required('Por favor, insira a pergunta'),
});

export interface FormValues {
  careLineId: string;
  /**
   * Helps to render autocomplete field
   */
  careLineValue?: string;
  classification: CareQuestionModel['classification'];
  frequencyRule: string;
  careQuestionText: string;
  answerConfig: AnswerConfig | null;
  shouldRunOnOnboarding?: boolean;
}

export const formInitialValue = {
  classification: undefined,
  careLineId: '',
  frequencyRule: '',
  careQuestionText: '',
  answerConfig: null,
};

export const formDataToCareQuestionModel = (values: FormValues) =>
  ({
    careQuestionText: values.careQuestionText,
    frequencyRule: `${values.frequencyRule}d`,
    careLineId: parseInt(values.careLineId),
    classification: values.classification,
    answerConfig: values.answerConfig,
    type: 'shift_question',
    shouldRunOnOnboarding: values.shouldRunOnOnboarding ? true : false,
  } as CareQuestionModel);

export const careQuestionModelToFormData = (
  careQuestion: CareQuestionModel
): FormValues => ({
  answerConfig: careQuestion.answerConfig as AnswerConfig,
  careLineId: String(careQuestion.careLineId),
  careQuestionText: String(careQuestion.careQuestionText),
  classification: careQuestion.classification,
  // get only number of days out of frequencyRule
  frequencyRule: String(careQuestion.frequencyRule).replace('d', ''),
  careLineValue: careQuestion.careLine?.name,
  shouldRunOnOnboarding: careQuestion.shouldRunOnOnboarding,
});
