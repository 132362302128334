import React, { useEffect, useState } from 'react';
import { RadioGroup, Radio } from '@material-ui/core';

import { ActionModel } from '@cuidador/database';
import StyledFormControlLabel from '../../components/StyledFormControlLabel';
import ActionCareQuestionForm from './ActionCareQuestionForm';
import ActionInterviewQuestion from './ActionInterviewQuestionForm';
import ActionEvent from './ActionEventForm';

const ActionUpsertForm: React.FC<{
  initialValues?: ActionModel;
  onSubmit: (action: ActionModel) => void | Promise<void>;
  disableDefaultIsSubmittingHandle?: boolean;
}> = ({ initialValues, onSubmit, disableDefaultIsSubmittingHandle }) => {
  const [currentType, setCurrentType] = useState<ActionModel['type']>(
    undefined
  );
  const [isEdit, setIsEdit] = useState(false);

  useEffect(function fetchInitialType() {
    if (initialValues) {
      setIsEdit(true);
      setCurrentType(initialValues.type);
    } else {
      setCurrentType('shift_question');
    }
  }, []);

  return (
    <>
      <RadioGroup
        aria-label="type"
        data-test-id="type-radio"
        name="type"
        value={currentType || ''}
      >
        <StyledFormControlLabel
          value="shift_question"
          control={<Radio color="primary" />}
          onChange={() => setCurrentType('shift_question')}
          label="Pergunta de cuidado"
          disabled={isEdit}
        />
        <StyledFormControlLabel
          value="interview_question"
          control={<Radio color="primary" />}
          onChange={() => setCurrentType('interview_question')}
          label="Entrevista"
          disabled={isEdit}
        />
        <StyledFormControlLabel
          value="event"
          control={<Radio color="primary" />}
          onChange={() => setCurrentType('event')}
          label="Evento"
          disabled={isEdit}
        />
      </RadioGroup>
      {currentType === 'shift_question' && (
        <ActionCareQuestionForm
          onSubmit={onSubmit}
          initialValues={initialValues}
          disableDefaultIsSubmittingHandle={disableDefaultIsSubmittingHandle}
        />
      )}
      {currentType === 'interview_question' && (
        <ActionInterviewQuestion
          onSubmit={onSubmit}
          initialValues={initialValues}
          disableDefaultIsSubmittingHandle={disableDefaultIsSubmittingHandle}
        />
      )}
      {currentType === 'event' && (
        <ActionEvent
          onSubmit={onSubmit}
          initialValues={initialValues}
          disableDefaultIsSubmittingHandle={disableDefaultIsSubmittingHandle}
        />
      )}
    </>
  );
};

export default ActionUpsertForm;
