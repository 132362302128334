import FormControl from '@material-ui/core/FormControl';
import styled from 'styled-components';

const StyledFormControl = styled(FormControl).attrs(({ variant }) => ({
  variant: variant || 'outlined',
}))`
  & label {
    color: ${({ theme }) => theme.palette.primary.main};
  }
  & label.Mui-disabled {
    color: ${({ theme }) => theme.palette.primary.main};
    opacity: 65%;
  }
  & .MuiOutlinedInput-root:not(.Mui-error, .Mui-disabled) {
    & fieldset {
      border-color: ${({ theme }) => theme.palette.primary.main};
      color: ${({ theme }) => theme.palette.primary.main};
    }
    &:hover fieldset {
      border-color: ${({ theme }) => theme.palette.primary.main};
      color: ${({ theme }) => theme.palette.primary.main};
    }
    &.Mui-focused fieldset {
      border-color: ${({ theme }) => theme.palette.primary.main};
      color: ${({ theme }) => theme.palette.primary.main};
    }
  }
  & .MuiOutlinedInput-root.Mui-disabled {
    & fieldset {
      border-color: ${({ theme }) => theme.palette.primary.main};
      opacity: 65%;
    }
  }
  & .MuiInputBase-root.Mui-disabled {
    color: ${({ theme }) => theme.palette.primary.main};
    cursor: default;
  }
  margin-top: ${({ theme }) => theme.spacing(2)}px;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
`;

export default StyledFormControl;
