import styled from 'styled-components';
import {
  Backdrop as MaterialBackdrop,
  CircularProgress as MaterialCircularProgress,
} from '@material-ui/core';

export const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const DefinitionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const QuestionTypeContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const CareCategoryDiv = styled.div`
  width: 35%;
`;

export const SelectDiv = styled.div`
  width: 35%;
`;

export const OrderDiv = styled.div`
  width: 20%;
`;

export const DescriptionDiv = styled.div`
  width: 100%;
`;

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const Backdrop = styled(MaterialBackdrop)`
  z-index: ${({ theme }) => theme.zIndex.drawer + 1};
  color: ${({ theme }) => theme.palette.primary.light};
  position: absolute;
`;

export const BackdropCircularProgress = styled(MaterialCircularProgress).attrs({
  color: 'inherit',
})``;
