import { useCallback, useReducer } from 'react';
import axios from '../config/axios';
import {
  createReducer,
  ReducerData,
  Item,
  PaginatedRequestParams,
} from '../utils/store/index';

export interface TableRow {
  shiftId: number;
  shiftPatientId: number;
  shiftName?: string;
  executionsAmount?: number;
  totalEventsAmount?: number;
  totalAccomplishedEventsAmount?: number;
  totalNotAccomplishedEventsAmount?: number;
  totalMedicationEventsAmount?: number;
  totalMedicationAccomplishedEventsAmount?: number;
  totalMedicationNotAccomplishedEventsAmount?: number;
  totalCareQuestionsAmount?: number;
  totalCareQuestionsAnswerAmount?: number;
  totalComplicationEventsAmount?: number;
  status:
    | 'Não iniciado'
    | 'Iniciado'
    | 'Incompleto'
    | 'Finalizado'
    | 'Finalizado automaticamente'
    | 'Finalizada'
    | 'Em andamento'
    | 'Removida do plantão'
    | '-';
  shiftPlannedToStartAt: string;
  shiftPlannedToEndAt: string;
  executionRegisteredStartedAt?: string;
  executionRegisteredEndedAt?: string;
  executionStartedAt?: string;
  executionEndedAt?: string;
  executionNumber?: number;
  caregiverId?: number;
  caregiverName?: string;
  rowType:
    | 'shift_without_executions'
    | 'shift_with_one_execution'
    | 'shift'
    | 'execution';
}

const endpoint = '/care/shift';

const initialData: ReducerData<TableRow> = {
  byId: {} as Record<string, Item<TableRow>>,
  ids: [] as Array<number>,
  total: 0,
  loading: false,
  error: null,
};

const useShift = () => {
  const [state, dispatch] = useReducer(createReducer<TableRow>(), initialData);

  const getAdhesionTableByPatientId = useCallback(
    async (patientId: Id, params: PaginatedRequestParams) => {
      try {
        dispatch({ type: 'LOADING' });
        const { data } = await axios.get(
          `${endpoint}/by-patient/${patientId}/adhesion`,
          {
            params,
          }
        );

        const idStartOffset = (params.limit ?? 0) * (params.page ?? 0);

        const { results, total } = data;

        /**
         * Here we set the id by row for each row.
         * It's done here because doesn't make sense an id of adhesion table on backend,
         *  but the frontend needs it, so we give a virtual one.
         */
        const resultsWithIds: Item<TableRow>[] = results.map(
          (row: TableRow, index: number) => ({
            ...row,
            id: idStartOffset + index + 1,
          })
        );

        dispatch({
          type: 'PAGINATION',
          payload: { results: resultsWithIds, total },
        });
      } catch (err) {
        dispatch({ type: 'ERROR', payload: err });
      }
    },
    []
  );

  return {
    ...state,
    getAdhesionTableByPatientId,
  };
};

export default useShift;
