import { useCallback } from 'react';
import axios from '../config/axios';
import { downloadZip } from '../utils/zip';

const endpoint = '/dump';
const TIMEOUT_MS = 5 * 60 * 1000; // 5 min timeout

const useCaregiverDashboard = () => {
  const getDatabaseDump = useCallback(async () => {
    try {
      const response = await axios.get(`${endpoint}/database`, {
        timeout: TIMEOUT_MS, // force timeout
        responseType: 'arraybuffer',
      });
      const blobData = new Blob([response.data], {
        type: 'application/octet-stream',
      });
      downloadZip(blobData, `cuidador-dump-${Date.now()}`);
    } catch (err) {
      return Promise.reject(err);
    }
  }, []);

  return {
    getDatabaseDump,
  };
};

export default useCaregiverDashboard;
