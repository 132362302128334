import * as Yup from 'yup';
import { ActionModel } from '@cuidador/database';

export const optionClassifications = [
  { id: 'functional', type: 'Funcional' },
  { id: 'dysfunctional', type: 'Disfuncional' },
  { id: 'severe_dysfunctional', type: 'Disfuncional grave' },
];

export const validationSchema = Yup.object().shape({
  careQuestionId: Yup.string().required(
    'Por favor, insira uma pergunta de entrevista'
  ),
  careQuestionOptionId: Yup.string().required(
    'Por favor, selecione a resposta associada'
  ),
  newCareLineId: Yup.string().when('actionOutcomeType', {
    is: 'activate_static_care_line',
    then: Yup.string().required('Por favor, insira uma linha de cuidado'),
  }),
  classification: Yup.string()
    .when('actionOutcomeType', {
      is: 'activate_static_care_line',
      then: Yup.string().required('Por favor, selecione uma classificação'),
    })
    .nullable(),
});

export interface FormValues {
  careQuestionId: Id | undefined;
  newCareQuestionId: Id | undefined;
  deactivatedCareLineId: Id | undefined;
  careQuestionOptionId: string;
  actionOutcomeType: ActionModel['actionOutcomeType'] | undefined;
  newCareLineId: string;
  classification: ActionModel['classification'] | undefined;
}

export const formInitialValue = {
  careQuestionId: undefined,
  newCareQuestionId: undefined,
  deactivatedCareLineId: undefined,
  careQuestionOptionId: '',
  actionOutcomeType: '',
  newCareLineId: '',
  classification: '',
};

export const actionModelToFormData = (values: ActionModel) =>
  ({
    careQuestionId: values.careQuestionId || undefined,
    newCareQuestionId: values.newCareQuestionId || undefined,
    deactivatedCareLineId: values.deactivatedCareLineId || undefined,
    careQuestionOptionId: values.careQuestionOptionId,
    actionOutcomeType: values.actionOutcomeType,
    careQuestion: {
      careQuestionText: values.careQuestion?.careQuestionText,
    },
    newCareLineId: values.newCareLineId || undefined,
    classification: values.classification,
  } as ActionModel);

export const formDataToActionModel = (values: FormValues) => {
  if (mustHaveNewCareQuestion(values.actionOutcomeType)) {
    values.deactivatedCareLineId = '';
    values.newCareLineId = '';
    values.classification = undefined;
  } else if (values.actionOutcomeType === 'activate_static_care_line') {
    values.newCareQuestionId = '';
    values.deactivatedCareLineId = '';
  } else {
    values.newCareQuestionId = '';
    values.newCareLineId = '';
    values.classification = undefined;
  }

  return {
    type: 'interview_question',
    careQuestionOptionId: values.careQuestionOptionId || undefined,
    careQuestionId: parseInt(`${values.careQuestionId}`) || undefined,
    actionOutcomeType: values.actionOutcomeType || '',
    newCareQuestionId: parseInt(`${values.newCareQuestionId}`) || undefined,
    deactivatedCareLineId:
      parseInt(`${values.deactivatedCareLineId}`) || undefined,
    newCareLineId: values.newCareLineId
      ? parseInt(values.newCareLineId)
      : undefined,
    classification: values.classification,
  } as ActionModel;
};

export function formatClassification(classification: string | undefined) {
  switch (classification) {
    case 'functional':
      return 'Funcional';
    case 'dysfunctional':
      return 'Disfuncional';
    case 'severe_dysfunctional':
      return 'Disfuncional grave';
    default:
      return '';
  }
}

export const mustHaveNewCareQuestion = (
  actionOutcomeType: ActionModel['actionOutcomeType'] | undefined
) => {
  return (
    actionOutcomeType === 'activate_care_question' ||
    actionOutcomeType === 'activate_additional_care_question'
  );
};
