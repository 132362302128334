import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import AuthenticatedRoute from './AuthenticatedRoute';
import UnauthenticatedRoute from './UnauthenticatedRoute';

import Login from '../pages/Login';
import Patients from '../pages/Patients';
import PatientItem from '../pages/Patients/PatientItem';
import Caregiver from '../pages/Caregiver';
import CaregiverItem from '../pages/Caregiver/CaregiverItem';
import ForgotPassword from '../pages/ForgotPassword';
import Care from '../pages/Care';
import CareQuestionInsert from '../pages/Care/CareQuestion/CareQuestionInsert';
import CareQuestionUpdate from '../pages/Care/CareQuestion/CareQuestionUpdate';
import InterviewInsert from '../pages/Care/InterviewQuestion/InterviewInsert';
import InterviewUpdate from '../pages/Care/InterviewQuestion/InterviewUpdate';
import ActionInsert from '../pages/Care/Action/ActionInsert';
import ActionUpdate from '../pages/Care/Action/ActionUpdate';
import Content from '../pages/Content';
import ContentInsert from '../pages/Content/ContentInsert';
import ContentUpdate from '../pages/Content/ContentUpdate';
import PatientInterview from '../pages/Patients/InterviewList/PatientInterview';
import ChangePassword from '../pages/ChangePassword';
import Dashboard from '../pages/Dashboard';
import Reports from '../pages/Reports';
import ReportSetup from '../pages/ReportSetup';
import Users from '../pages/Users';
import NewRole from '../pages/Users/NewRole';
import UpsertRole from '../pages/Users/UpsertRole';
import UpsertUser from '../pages/Users/UpsertUser';

const Routes: React.FC = () => {
  return (
    <Switch>
      <UnauthenticatedRoute path="/login" component={Login} />
      <UnauthenticatedRoute
        path="/esqueci-minha-senha"
        component={ForgotPassword}
      />
      <UnauthenticatedRoute
        path="/criar-nova-senha"
        component={ChangePassword}
      />

      <AuthenticatedRoute exact path="/dashboard/:tab?" component={Dashboard} />
      <AuthenticatedRoute exact path="/cuidadores" component={Caregiver} />
      <AuthenticatedRoute
        exact
        path="/cuidadores/:id/:tabId"
        component={CaregiverItem}
      />
      <AuthenticatedRoute exact path="/pacientes" component={Patients} />
      <AuthenticatedRoute
        exact
        path="/pacientes/:id/:tabId"
        component={PatientItem}
      />
      <AuthenticatedRoute exact path="/relatorios/:tabId" component={Reports} />
      <AuthenticatedRoute exact path="/cuidados/:tabId" component={Care} />
      <AuthenticatedRoute
        exact
        path="/cuidados/perguntas-de-cuidado/adicionar"
        component={CareQuestionInsert}
      />
      <AuthenticatedRoute
        exact
        path="/cuidados/perguntas-de-cuidado/:id"
        component={CareQuestionUpdate}
      />
      <AuthenticatedRoute
        exact
        path="/cuidados/perguntas-de-entrevista/adicionar"
        component={InterviewInsert}
      />
      <AuthenticatedRoute
        exact
        path="/cuidados/perguntas-de-entrevista/:id"
        component={InterviewUpdate}
      />
      <AuthenticatedRoute
        exact
        path="/cuidados/acoes/adicionar"
        component={ActionInsert}
      />
      <AuthenticatedRoute
        exact
        path="/cuidados/acoes/:id"
        component={ActionUpdate}
      />
      <AuthenticatedRoute exact path="/conteudo" component={Content} />
      <AuthenticatedRoute
        exact
        path="/conteudo/novo"
        component={ContentInsert}
      />
      <AuthenticatedRoute
        exact
        path="/conteudo/:id"
        component={ContentUpdate}
      />
      <AuthenticatedRoute exact path="/usuarios/:tabId" component={Users} />

      <AuthenticatedRoute
        exact
        path="/usuarios/todos-usuarios/:id"
        component={UpsertUser}
      />

      <AuthenticatedRoute
        exact
        path="/usuarios/:tabId/novo"
        component={NewRole}
      />
      <AuthenticatedRoute
        exact
        path="/usuarios/:tabId/:id"
        component={UpsertRole}
      />

      <AuthenticatedRoute
        exact
        path="/pacientes/:patientId/entrevistas/:interviewId"
        component={PatientInterview}
      />
      <AuthenticatedRoute
        exact
        path="/pacientes/:patientId/relatorios/:reportId/configurar"
        component={ReportSetup}
      />

      <Redirect to="/dashboard" />
    </Switch>
  );
};

export default Routes;
