import React from 'react';
import { StyledLogoBlue, StyledLogoPurple, StyledLogoWhite } from '../styles';

interface RoleAppsProps {
  guardianAppAccess?: boolean;
  caregiverAppAccess?: boolean;
  adminAppAccess?: boolean;
}

const RoleApps: React.FC<RoleAppsProps> = ({
  guardianAppAccess,
  caregiverAppAccess,
  adminAppAccess,
}) => {
  const accessibleApps = [];

  if (guardianAppAccess) {
    accessibleApps.push(<StyledLogoWhite />);
  }
  if (caregiverAppAccess) {
    accessibleApps.push(<StyledLogoBlue />);
  }
  if (adminAppAccess) {
    accessibleApps.push(<StyledLogoPurple />);
  }

  return (
    <div>
      {accessibleApps.map((apps) => (
        <>{apps}</>
      ))}
    </div>
  );
};

export default RoleApps;
