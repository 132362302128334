import { CaregiverModel } from '@cuidador/database';
import { APIError } from '@cuidador/lib';
import { Tab } from '@material-ui/core';
import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ChangeStatusDialog from '../../../components/ChangeStatusDialog';
import Header from '../../../components/Header';
import LoadingBackdrop from '../../../components/LoadingBackdrop';
import StyledTabs from '../../../components/StyledTabs';
import useCaregiver from '../../../hooks/useCaregiver';
import useUser from '../../../hooks/useUser';
import { resolveErrorMessage } from '../../../utils/error';
import CaregiverMoodDownload from './CaregiverMoodDownload';
import Personal from './Personal';
import { Container, StyledPaperTab } from './styles';
import { tabOptions } from './utils';

const CaregiverItem: React.FC<RouteComponentProps<{ tabId: string }>> = (
  props
) => {
  const currentTab = props.match.params.tabId;
  const params = useParams<{ id: string }>();
  const [imageUrl, setImageUrl] = useState('');

  const { getById, byId, loading, patchStatus } = useCaregiver();
  const { getProfilePicture } = useUser();

  const handleGetProfilePicture = async (id: number) => {
    try {
      return await getProfilePicture(id).then(({ data }) => data?.signedUrl);
    } catch (err) {
      console.log('error');
    }
  };

  const id = parseInt(params.id);
  useEffect(() => {
    async function fetchPatientProfilePicture() {
      const result = await handleGetProfilePicture(parseInt(`${id}`));
      if (result) {
        setImageUrl(result);
      }
    }

    getById(id);
    fetchPatientProfilePicture();
  }, [id]);

  const item = byId[params.id];
  const isDisabled = item?.user?.status === 'disabled';
  const [
    isStatusChangeDialogOpened,
    setIsStatusChangeDialogOpened,
  ] = React.useState<boolean>(false);

  const onChangeStatusDialogOpen = () => {
    setIsStatusChangeDialogOpened(true);
  };

  // Swaps user status
  const changeStatus = () => {
    const newStatus = isDisabled ? 'enabled' : 'disabled';
    patchStatus(item!.id!, {
      status: newStatus,
    } as Partial<CaregiverModel>)
      .then(() => {
        setIsStatusChangeDialogOpened(false);
      })
      .catch((err: AxiosError<APIError>) => {
        const displayMessage = resolveErrorMessage(err);
        toast.error(displayMessage);
      });
  };

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleChange = (_: React.ChangeEvent<{}>, newValue: string) => {
    props.history.replace(`/cuidadores/${id}/${newValue}`);
  };

  return (
    <>
      {loading && <LoadingBackdrop loading={loading} />}
      <Header
        img={imageUrl}
        checked={!isDisabled}
        onCheckedChange={onChangeStatusDialogOpen}
        name={item?.user?.name}
        dateOfBirth={item?.user?.dateOfBirth}
      />
      <ChangeStatusDialog
        open={isStatusChangeDialogOpened}
        checked={!isDisabled}
        name={item?.user?.name}
        onCancel={() => setIsStatusChangeDialogOpened(false)}
        onConfirm={changeStatus}
      />
      <Container>
        <StyledPaperTab>
          <StyledTabs centered value={currentTab} onChange={handleChange}>
            {tabOptions.map((option) => (
              <Tab
                wrapped={true}
                key={option.key}
                label={option.label}
                value={option.key}
              />
            ))}
          </StyledTabs>
        </StyledPaperTab>
        {currentTab === 'pessoais' && <Personal />}
        {currentTab === 'cuidar' && <CaregiverMoodDownload />}
      </Container>
    </>
  );
};

export default CaregiverItem;
