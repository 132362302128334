import { useCallback, useReducer } from 'react';
import axios from '../config/axios';
import { CareQuestionModel } from '@cuidador/database';
import {
  createReducer,
  ReducerData,
  Item,
  PaginatedRequestParams,
} from '../utils/store/index';

const endpoint = '/care/question';

const initialData: ReducerData<CareQuestionModel> = {
  byId: {} as Record<string, Item<CareQuestionModel>>,
  ids: [] as Array<number>,
  total: 0,
  loading: false,
  error: null,
};

type CareQuestionPaginatedParams = PaginatedRequestParams & {
  type: CareQuestionModel['type'];
  orderBy?: string;
};

const useCareQuestion = () => {
  const [state, dispatch] = useReducer(
    createReducer<CareQuestionModel>(),
    initialData
  );

  const getPaginated = useCallback(
    async (params: CareQuestionPaginatedParams) => {
      try {
        dispatch({ type: 'LOADING' });
        const response = await axios.get(`${endpoint}`, { params });
        dispatch({ type: 'PAGINATION', payload: response.data });
        return response.data.results as CareQuestionModel[];
      } catch (err) {
        dispatch({ type: 'ERROR', payload: err });
        throw err;
      }
    },
    []
  );

  const getById = useCallback(async (id: Id) => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.get(`${endpoint}/${id}`);
      dispatch({ type: 'GET_BY_ID', payload: response.data });
      return Promise.resolve(response.data as CareQuestionModel);
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
    }
  }, []);

  const post = useCallback(async (data: CareQuestionModel) => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.post(`${endpoint}`, data);
      dispatch({ type: 'CREATE', payload: response.data });
      return Promise.resolve(response);
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
      return Promise.reject(err);
    }
  }, []);

  const patch = useCallback(
    async (id: Id, data: Partial<CareQuestionModel>) => {
      try {
        dispatch({ type: 'LOADING' });
        const response = await axios.patch(`${endpoint}/${id}`, data);
        dispatch({ type: 'UPDATE', payload: response.data });
      } catch (err) {
        dispatch({ type: 'ERROR', payload: err });
        return Promise.reject(err);
      }
    },
    []
  );

  const remove = useCallback(async (id: Id) => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.delete(`${endpoint}/${id}`);
      dispatch({ type: 'REMOVE', payload: response.data });
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
      return Promise.reject(err);
    }
  }, []);

  return {
    ...state,
    getPaginated,
    post,
    patch,
    remove,
    getById,
  };
};

export default useCareQuestion;
