import styled from 'styled-components';
import StyledButton from '../../StyledButton';

interface ButtonProps {
  textColor: string;
  backgroundColor: string;
  borderColor: string;
}

export const Container = styled.div`
  padding: ${({ theme }) => theme.spacing(0, 4)};
  display: flex;
  flex-direction: column;
  padding-inline: ${({ theme }) => theme.spacing(4)}px;
`;

export const FormContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr;
  margin-top: ${({ theme }) => theme.spacing(4)}px;
`;

export const OptionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Button = styled(StyledButton)<ButtonProps>`
  color: ${(props) => props.textColor};
  background-color: ${(props) => props.backgroundColor};
  border: 2px solid ${(props) => props.borderColor};
  border-radius: ${({ theme }) => theme.spacing(1)}px;
  margin-left: ${({ theme }) => theme.spacing(1)}px;
  height: fit-content;

  &:hover {
    color: ${(props) => props.textColor};
    background-color: ${(props) => props.backgroundColor};
    border-color: ${(props) => props.borderColor};
  }
`;
