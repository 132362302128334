import styled from 'styled-components';

export const HorizontalPlacement = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
`;

export const TypeQuestionDiv = styled.div`
  width: 40%;
`;

export const AnswerConfigContainer = styled.div`
  margin: ${({ theme }) => theme.spacing(2)}px 0;
`;

export const InputContainer = styled.div`
  width: 60%;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  margin-top: ${({ theme }) => theme.spacing(2)}px;
`;

export const UnfoldDiv = styled.div`
  margin: ${({ theme }) => theme.spacing(4, 0, 1)};
`;
