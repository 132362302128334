import React, {
  useEffect,
  useReducer,
  createContext,
  useCallback,
} from 'react';
import LoadingProgress from '../components/LoadingLinearProgress';
import { auth, authRequestInterceptor } from '../config/firebase';
import * as axios from '../config/axios';
import { toast } from 'react-toastify';

const reducer = (state: number, { type }: { type: string }) => {
  switch (type) {
    case 'INCREMENT_LOADING':
      return state + 1;
    case 'DECREMENT_LOADING':
      return state - 1;
    default:
      return state;
  }
};

type GlobalLoadingContextType = {
  incrementLoading: () => void;
  decrementLoading: () => void;
};

export const GlobalLoadingContext = createContext<GlobalLoadingContextType>(
  {} as GlobalLoadingContextType
);

export const GlobalLoadingProvider: React.FC = ({ children }) => {
  const [loadingCount, dispatch] = useReducer(reducer, 0);

  useEffect(() => {
    const requestInterceptor = axios.addGlobalRequestInterceptor((config) => {
      incrementLoading();
      return config;
    });
    const responseInterceptor = axios.addGlobalResponseInterceptor(
      (config) => {
        decrementLoading();
        return config;
      },
      (error) => {
        decrementLoading();
        if (error.response?.status === 401 && location.pathname !== '/login') {
          toast.error('Erro com as credenciais! Faça login novamente.');
          auth().signOut();
        }
        return Promise.reject(error);
      }
    );

    const firebaseAuthRequestInterceptor = axios.addGlobalRequestInterceptor(
      authRequestInterceptor
    );

    return () => {
      axios.removeGlobalRequestInterceptor(requestInterceptor);
      axios.removeGlobalResponseInterceptor(responseInterceptor);
      axios.removeGlobalRequestInterceptor(firebaseAuthRequestInterceptor);
    };
  }, []);

  const incrementLoading = useCallback(() => {
    dispatch({ type: 'INCREMENT_LOADING' });
  }, []);

  const decrementLoading = useCallback(() => {
    dispatch({ type: 'DECREMENT_LOADING' });
  }, []);

  return (
    <GlobalLoadingContext.Provider
      value={{ incrementLoading, decrementLoading }}
    >
      {loadingCount ? <LoadingProgress /> : null}
      {children}
    </GlobalLoadingContext.Provider>
  );
};

export default GlobalLoadingProvider;
