import React, { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import FormikTextField from '../Forms/FormikTextField';
import { StyledAutocompleteItem, StyledAutocompleteList } from './styles';

export type OnChangeValue = {
  value: number;
  text: string;
};

type AutocompleteFieldProps = {
  initialValue?: OnChangeValue;
  onChangeDebounced: (inputText: string) => Promise<OnChangeValue[]>;
  onListValueClick: (listValue: OnChangeValue) => void;
  name: string;
  label: string;
  testId?: string;
};

const SearchTextField: React.FC<AutocompleteFieldProps> = ({
  initialValue,
  onChangeDebounced,
  onListValueClick,
  name,
  label,
  testId,
}) => {
  const [textValue, setTextValue] = useState('');
  const [listValues, setListValues] = useState<OnChangeValue[]>([]);
  const [showList, setShowList] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (initialValue) {
      setTextValue(initialValue.text);
    }
  }, []);

  const debounced = useDebouncedCallback(async (inputText) => {
    setLoading(true);
    const newListValues = await onChangeDebounced(inputText);
    setListValues(newListValues);
    setShowList(true);
    setLoading(false);
  }, 1000);

  const handleListValueClicked = (item: OnChangeValue) => {
    onListValueClick(item);
    setShowList(false);
    setTextValue(item.text);
  };
  return (
    <>
      <FormikTextField
        value={textValue}
        disabled={loading}
        name={name!}
        margin="normal"
        label={label}
        onChange={(e) => {
          setTextValue(e.target.value);
          debounced.callback(e.target.value);
        }}
        inputProps={{
          'data-testid': testId || 'autocompleteField',
        }}
      />

      {showList && (
        <StyledAutocompleteList>
          {listValues.map((item) => {
            return (
              <StyledAutocompleteItem
                key={item.value}
                onClick={() => handleListValueClicked(item)}
              >
                {item.text}
              </StyledAutocompleteItem>
            );
          })}
        </StyledAutocompleteList>
      )}
    </>
  );
};

export default SearchTextField;
