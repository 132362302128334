import React from 'react';
import StyledTabs from '../../components/StyledTabs';
import { Tab } from '@material-ui/core';
import { StyledPaperTab, Container } from './styles';
import { tabOptions } from './utils';
import { RouteComponentProps } from 'react-router-dom';
import Events from './Events';
import Category from './Category';
import CareLine from './CareLine';
import CareQuestion from './CareQuestion';
import InterviewQuestion from './InterviewQuestion';
import Action from './Action';

const Care: React.FC<RouteComponentProps<{ tabId: string }>> = (props) => {
  const currentTab = props.match.params.tabId;

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleChange = (_: React.ChangeEvent<{}>, newValue: string) => {
    props.history.push(`/cuidados/${newValue}`);
  };

  return (
    <Container>
      <StyledPaperTab>
        <StyledTabs centered value={currentTab} onChange={handleChange}>
          {tabOptions.map((option) => (
            <Tab
              wrapped={true}
              key={option.key}
              label={option.label}
              value={option.key}
            />
          ))}
        </StyledTabs>
      </StyledPaperTab>
      {currentTab === 'linhas-de-cuidado' && <CareLine />}
      {currentTab === 'eventos' && <Events />}
      {currentTab === 'categorias-de-cuidado' && <Category />}
      {currentTab === 'perguntas-de-cuidado' && <CareQuestion />}
      {currentTab === 'perguntas-de-entrevista' && <InterviewQuestion />}
      {currentTab === 'acoes' && <Action />}
    </Container>
  );
};

export default Care;
