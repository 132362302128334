/**
 * @jest-environment jest-environment-jsdom-sixteen
 **/

import Typography from '@material-ui/core/Typography';
import { Formik } from 'formik';
import * as Sentry from '@sentry/react';
import isEqual from 'lodash/isEqual';
import { toast } from 'react-toastify';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import FormikSelect from '../../../components/Forms/FormikSelect';
import FormikTextField from '../../../components/Forms/FormikTextField';
import PatientStatus from '../../../components/PatientStatus';
import { FormValues as UpdateStatusFormValues } from '../../../components/PatientStatus/UpdateStatusModal';
import StyledMenuItem from '../../../components/StyledMenuItem';
import usePatient from '../../../hooks/usePatient';
import { cepMask, cpfMask, phoneMask } from '../../../utils/inputs';
import {
  Backdrop,
  BackdropCircularProgress,
  Background,
  Centralizer,
  Container,
  Form,
  SubmitButton,
  TypographyContainer,
} from './styles';
import {
  formDataToPatientModel,
  FormValues,
  patientModelToFormData,
  PtBrProvinces,
  validationSchema,
} from './utils';

const Personal: React.FC = () => {
  const params = useParams<{ id: string }>();

  const { getById, byId, loading: loadingPatient, patch } = usePatient();

  const id = parseInt(params.id);
  useEffect(() => {
    getById(id);
  }, [id]);

  const patient = byId[params.id];
  // Loading should be shown when loading any of both
  // Patient resources
  const loading = loadingPatient;
  if (loading) {
    return (
      <Backdrop open={loading}>
        {loading && (
          <BackdropCircularProgress data-testid="table-backdrop-spinner" />
        )}
      </Backdrop>
    );
  }

  if (!patient) {
    // Patient not yet loaded
    // [TODO] Handle errors
    return null;
  }

  const handleSubmit = (values: FormValues) => {
    patch(id, formDataToPatientModel(values));
  };

  const handlePatientDisableSubmit = async (values: UpdateStatusFormValues) => {
    try {
      await patch(params.id, {
        ...values,
        status: 'disabled',
        statusUpdatedAt: new Date().toISOString(),
      });
      toast.success('Status do paciente modificado');
    } catch (err) {
      Sentry.captureException(err);
      toast.error('Erro ao modificar status do paciente');
    }
  };

  const handlePatientEnableSubmit = async () => {
    try {
      await patch(params.id, {
        status: 'enabled',
        statusUpdatedAt: new Date().toISOString(),
      });
      toast.success('Status do paciente restaurado');
    } catch (err) {
      Sentry.captureException(err);
      toast.error('Erro ao restaurar status do paciente');
    }
  };

  const formInitialValue = patientModelToFormData(patient);
  return (
    <Background>
      <Container>
        <TypographyContainer>
          <Typography variant="h5">
            Informações da pessoa sob cuidado
          </Typography>
        </TypographyContainer>
        <PatientStatus
          patient={patient}
          handlePatientDisableSubmit={handlePatientDisableSubmit}
          handlePatientEnableSubmit={handlePatientEnableSubmit}
        />
        <Formik
          initialValues={formInitialValue as FormValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue, isSubmitting }) => {
            return (
              <Form
                // noValidate disables browser default validation
                noValidate={true}
              >
                <FormikTextField
                  name="cpf"
                  label="CPF"
                  margin="normal"
                  inputProps={{ 'data-testid': 'cpf' }}
                  // CPF cannot be changed. So it's disabled
                  disabled={true}
                  value={cpfMask(values.cpf || '')}
                />
                <FormikSelect name="gender" label="Sexo">
                  <StyledMenuItem value="female">Feminino</StyledMenuItem>
                  <StyledMenuItem value="male">Masculino</StyledMenuItem>
                  <StyledMenuItem value="other">Outro</StyledMenuItem>
                </FormikSelect>
                <FormikTextField
                  name="nickname"
                  label="Como gosta de ser chamado?"
                  margin="normal"
                  inputProps={{ 'data-testid': 'nickname' }}
                />
                <FormikTextField
                  name="livesWith"
                  label="Com quem mora?"
                  margin="normal"
                  inputProps={{ 'data-testid': 'livesWith' }}
                />
                <FormikSelect name="educationLevel" label="Escolaridade">
                  <StyledMenuItem value="none">
                    Nenhuma (analfabeto)
                  </StyledMenuItem>
                  <StyledMenuItem value="reads-and-writes">
                    Lê e escreve
                  </StyledMenuItem>
                  <StyledMenuItem value="incomplete-elementary">
                    Ensino fundamental incompleto
                  </StyledMenuItem>
                  <StyledMenuItem value="complete-elementary">
                    Ensino fundamental completo
                  </StyledMenuItem>
                  <StyledMenuItem value="incomplete-highschool">
                    Ensino médio incompleto
                  </StyledMenuItem>
                  <StyledMenuItem value="complete-highschool">
                    Ensino médio completo
                  </StyledMenuItem>
                  <StyledMenuItem value="incomplete-college">
                    Ensino superior incompleto
                  </StyledMenuItem>
                  <StyledMenuItem value="complete-college">
                    Ensino superior completo
                  </StyledMenuItem>
                </FormikSelect>
                <FormikSelect name="martialStatus" label="Estado Civil">
                  <StyledMenuItem value={'single'}>Solteiro(a)</StyledMenuItem>
                  <StyledMenuItem value={'maried'}>Casado(a)</StyledMenuItem>
                  <StyledMenuItem value={'separated'}>
                    Separado(a)
                  </StyledMenuItem>
                  <StyledMenuItem value={'divorced'}>
                    Divorciado(a)
                  </StyledMenuItem>
                  <StyledMenuItem value={'widow'}>Viúvo(a)</StyledMenuItem>
                  <StyledMenuItem value={'other'}>Outro</StyledMenuItem>
                </FormikSelect>
                <FormikSelect name="religion" label="Religião">
                  <StyledMenuItem value={'christian'}>
                    Cristão(ã) (católico, espírita,
                    protestante/evangélico/pentecostal, testemunha de Jeová)
                  </StyledMenuItem>
                  <StyledMenuItem value={'without-religion'}>
                    Sem religião (ateu(ia), agnóstico(a), deísta)
                  </StyledMenuItem>
                  <StyledMenuItem value={'afro-brazilian'}>
                    Afro-brasileira (Candomblé, Umbanda, Tambor-de-mina)
                  </StyledMenuItem>
                  <StyledMenuItem value={'buddhist'}>Budista</StyledMenuItem>
                  <StyledMenuItem value={'islamic'}>Islâmico(a)</StyledMenuItem>
                  <StyledMenuItem value={'jewish'}>Judeu(ia)</StyledMenuItem>
                  <StyledMenuItem value={'other'}>Outro</StyledMenuItem>
                </FormikSelect>
                <FormikSelect
                  name="hasHealthPlan"
                  label="Possui Plano de Saúde?"
                >
                  <StyledMenuItem value={1}>Sim</StyledMenuItem>
                  <StyledMenuItem value={0}>Não</StyledMenuItem>
                </FormikSelect>
                <FormikTextField
                  name="healthPlanName"
                  label="Qual Plano de Saúde?"
                  margin="normal"
                  inputProps={{ 'data-testid': 'healthPlanName' }}
                  disabled={!values.hasHealthPlan}
                />
                <FormikSelect
                  name="isMonitoredByCaregiver"
                  label="Possui acompanhamento de um cuidador?"
                  SelectDisplayProps={{
                    'data-testid': 'isMonitoredByCaregiver',
                  }}
                >
                  <StyledMenuItem value={1}>Sim</StyledMenuItem>
                  <StyledMenuItem value={0}>Não</StyledMenuItem>
                </FormikSelect>
                <FormikTextField
                  name="isMonitoredSince"
                  label="Desde quando é acompanhado?"
                  margin="normal"
                  fullWidth
                  inputProps={{ 'data-testid': 'isMonitoredSince' }}
                  placeholder="DD/MM/AAAA"
                  disabled={!values.isMonitoredByCaregiver}
                />
                <TypographyContainer>
                  <Typography variant="h5">Endereço</Typography>
                </TypographyContainer>
                <FormikTextField
                  name="streetName"
                  label="Rua"
                  margin="normal"
                  inputProps={{ 'data-testid': 'streetName' }}
                />
                <FormikTextField
                  name="streetNumber"
                  label="Número"
                  margin="normal"
                  inputProps={{ 'data-testid': 'streetNumber' }}
                />
                <FormikTextField
                  name="streetComplement"
                  label="Complemento"
                  margin="normal"
                  inputProps={{ 'data-testid': 'streetComplement' }}
                />
                <FormikTextField
                  name="city"
                  label="Cidade"
                  margin="normal"
                  inputProps={{ 'data-testid': 'city' }}
                />
                <FormikSelect
                  name="province"
                  label="Estado"
                  SelectDisplayProps={{
                    'data-testid': 'province',
                  }}
                  color="secondary"
                >
                  {PtBrProvinces.map((item) => (
                    <StyledMenuItem
                      key={item.id}
                      value={item.name}
                      color="secondary"
                    >
                      {item.name}
                    </StyledMenuItem>
                  ))}
                </FormikSelect>
                <FormikTextField
                  name="postalCode"
                  label="CEP"
                  margin="normal"
                  inputProps={{ 'data-testid': 'postalCode' }}
                  onChange={(e) =>
                    setFieldValue('postalCode', cepMask(e.target.value))
                  }
                />

                <TypographyContainer>
                  <Typography variant="h5">Médico</Typography>
                </TypographyContainer>
                <FormikTextField
                  name="doctorSpecialty"
                  label="Especialidade"
                  margin="normal"
                  inputProps={{ 'data-testid': 'doctorSpecialty' }}
                />
                <FormikTextField
                  name="doctorName"
                  label="Nome"
                  margin="normal"
                  inputProps={{ 'data-testid': 'doctorName' }}
                />
                <FormikTextField
                  name="doctorPhoneNumber"
                  label="Telefone"
                  margin="normal"
                  inputProps={{ 'data-testid': 'doctorPhoneNumber' }}
                  onChange={(e) =>
                    setFieldValue(
                      'doctorPhoneNumber',
                      phoneMask(e.target.value)
                    )
                  }
                />
                <FormikTextField
                  name="doctorEmail"
                  label="E-mail"
                  margin="normal"
                  inputProps={{ 'data-testid': 'doctorEmail' }}
                />

                <TypographyContainer>
                  <Typography variant="h5">
                    Informações importantes para o cuidador
                  </Typography>
                </TypographyContainer>
                <FormikTextField
                  name="infoToCaregiver"
                  label="Informações importantes para o cuidador"
                  margin="normal"
                  inputProps={{ 'data-testid': 'infoToCaregiver' }}
                />

                <TypographyContainer>
                  <Typography variant="h5">
                    Informações do responsável
                  </Typography>
                </TypographyContainer>
                <FormikTextField
                  name="guardianName"
                  label="Nome Completo"
                  margin="normal"
                  inputProps={{ 'data-testid': 'guardianName' }}
                  disabled // Can't be updated by Admin
                />
                <FormikTextField
                  name="guardianCpf"
                  label="CPF"
                  margin="normal"
                  inputProps={{ 'data-testid': 'guardianCpf' }}
                  value={cpfMask(values.guardianCpf || '')}
                  disabled // Can't be updated by Admin
                />
                <FormikTextField
                  name="guardianPhoneNumber"
                  label="Telefone"
                  margin="normal"
                  inputProps={{ 'data-testid': 'guardianPhoneNumber' }}
                  onChange={(e) =>
                    setFieldValue(
                      'guardianPhoneNumber',
                      phoneMask(e.target.value)
                    )
                  }
                  disabled // Can't be updated by Admin
                />
                <FormikTextField
                  name="guardianEmail"
                  label="e-mail"
                  margin="normal"
                  inputProps={{ 'data-testid': 'guardianEmail' }}
                  disabled // Can't be updated by Admin
                />
                <FormikSelect
                  name="guardianHowMetUs"
                  label="Como nos conheceu?"
                  disabled // Can't be updated by Admin
                >
                  <StyledMenuItem value={'friends'}>
                    Indicação de amigos
                  </StyledMenuItem>
                  <StyledMenuItem value={'whatsapp'}>Whatsapp</StyledMenuItem>
                  <StyledMenuItem value={'instagram'}>Instagram</StyledMenuItem>
                  <StyledMenuItem value={'facebook'}>Facebook</StyledMenuItem>
                  <StyledMenuItem value={'professionalIndication'}>
                    Indicação Profissional
                  </StyledMenuItem>
                  <StyledMenuItem value={'google'}>Google</StyledMenuItem>
                  <StyledMenuItem value={'other'}>Outros</StyledMenuItem>
                </FormikSelect>
                <FormikSelect
                  name="guardianRelationToPatient"
                  label="Relacionamento com o paciente"
                  disabled // Can't be updated by Admin
                >
                  <StyledMenuItem value={'father'}>Pai</StyledMenuItem>
                  <StyledMenuItem value={'mother'}>Mãe</StyledMenuItem>
                  <StyledMenuItem value={'son'}>Filho</StyledMenuItem>
                  <StyledMenuItem value={'daugther'}>Filha</StyledMenuItem>
                  <StyledMenuItem value={'cousin'}>Primo(a)</StyledMenuItem>
                  <StyledMenuItem value={'uncle'}>Tio(a)</StyledMenuItem>
                  <StyledMenuItem value={'brother'}>Irmão(ã)</StyledMenuItem>
                  <StyledMenuItem value={'other'}>Outro</StyledMenuItem>
                </FormikSelect>
                <Centralizer>
                  <SubmitButton
                    data-testid="submit"
                    type="submit"
                    disabled={isSubmitting || isEqual(formInitialValue, values)}
                    onClick={() => handleSubmit(values)}
                  >
                    Salvar
                  </SubmitButton>
                </Centralizer>
              </Form>
            );
          }}
        </Formik>
      </Container>
    </Background>
  );
};

export default Personal;
