import * as Yup from 'yup';
import { CareLineModel } from '@cuidador/database';

export interface FormValues {
  name: string;
  careCategoryId: Id | undefined;
  isNightShiftDisabled: boolean | undefined;
}

export interface IdFormValues {
  id: Id | undefined;
}

export const validationSchema = Yup.object().shape({
  name: Yup.string().required('Por favor, insira o nome da linha de cuidado'),
  careCategoryId: Yup.number().required(
    'Por favor, insira uma categoria de cuidado.'
  ),
});

export const formDataToCareLineModel = (values: FormValues) =>
  (({
    name: values.name,
    categoryId: values.careCategoryId,
    isNightShiftDisabled: values.isNightShiftDisabled ? true : false,
  } as unknown) as CareLineModel);
