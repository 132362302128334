import React, { useCallback, useEffect, useMemo } from 'react';
import qs from 'query-string';
import { useHistory, useParams } from 'react-router-dom';
import { TableCell } from '@material-ui/core';
import MyTable from '../../../components/MyTable';
import useShift, { TableRow } from '../../../hooks/useShift';
import usePatient from '../../../hooks/usePatient';
import {
  Background,
  Container,
  EmptyTableTypography,
  TableRowWithBackground,
} from './styles';
import { Item } from '../../../utils/store';
import { resolveValueKeyOfFieldFromColumnMap, tableColumnMap } from './utils';

const AdhesionTable: React.FC = () => {
  const history = useHistory();
  const { id: patientId } = useParams<{ id: string }>();
  const {
    getAdhesionTableByPatientId,
    total: totalPatientPaginated,
    loading: loadingPatientPaginated,
    ids: patientPaginatedIds,
    byId: patientPaginatedById,
  } = useShift();
  const {
    getById: getPatientById,
    byId: patientById,
    loading: loadingPatient,
  } = usePatient();

  const loading = loadingPatientPaginated || loadingPatient;

  const limit =
    parseInt(String(qs.parse(history.location.search).limit)) || 150000;

  useEffect(() => {
    getPatientById(patientId);
  }, [patientId]);

  const patient = patientById[patientId];

  useEffect(() => {
    onChangeParams();
  }, []);

  const onChangeParams = useCallback(() => {
    const params = qs.parse(history.location.search);
    getAdhesionTableByPatientId(Number(patientId), {
      ...params,
      limit,
    });
  }, [getAdhesionTableByPatientId, limit]);

  const renderEmptyTable = useCallback(() => {
    return (
      <EmptyTableTypography variant="h6" color="textPrimary">
        Nenhum plantão encontrado para o paciente
      </EmptyTableTypography>
    );
  }, []);

  const renderRow = useCallback((row: TableRow, index?: number) => {
    const rowTestId = `adhesion-table-row-${index}`;

    return (
      <TableRowWithBackground
        variant={row.rowType === 'execution' ? 'clear' : 'opaque'}
        key={rowTestId}
        data-testid={rowTestId}
      >
        {tableColumnMap.map((columnMap) => {
          const keyOf = resolveValueKeyOfFieldFromColumnMap(columnMap, row);

          const value = !!keyOf ? row[keyOf] : undefined;

          const fieldTestId = `adhesion-field-${keyOf ?? undefined}`;

          return (
            <TableCell key={fieldTestId} data-testid={fieldTestId}>
              {value}
            </TableCell>
          );
        })}
      </TableRowWithBackground>
    );
  }, []);

  const data = useMemo(
    () => patientPaginatedIds.map((id) => patientPaginatedById[id]),
    [patientPaginatedIds, patientPaginatedById]
  );

  const tablePayload = {
    header: tableColumnMap.map(([header]) => header),
    body: data.map((row) => {
      const tableEntries = tableColumnMap.map((columnMap) => {
        const field = resolveValueKeyOfFieldFromColumnMap(columnMap, row);
        const value = !!field ? row[field] : '';
        return [columnMap[0], value];
      });

      return Object.fromEntries(tableEntries) as Record<
        string,
        number | string
      >;
    }),
  };

  return (
    <Background>
      <Container>
        {data.length === 0 && !loading ? (
          renderEmptyTable()
        ) : (
          <MyTable<Item<TableRow>>
            columnNames={tableColumnMap.map(([header]) => header)}
            data={data}
            totalPages={Math.ceil(totalPatientPaginated / limit)}
            renderRow={renderRow}
            loading={loading}
            onChangePage={onChangeParams}
            onChangeRowsPerPage={onChangeParams}
            rowsPerPageOptions={[150000, 20, 35, 50]}
            isExportableTable
            sheetName="adesão"
            fileName={`${patientId}_${patient?.name}`}
            tablePayload={tablePayload}
          />
        )}
      </Container>
    </Background>
  );
};

export default AdhesionTable;
