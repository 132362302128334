import React, { useState, useEffect } from 'react';
import {
  Container,
  CardsContainer,
  ContentContainer,
  ContentLine,
  StyledColumn,
} from './styles';

import { DashboardPatientCount } from '@cuidador/database';
import usePatientDashboard from '../../../hooks/usePatientDashboard';

import { toast } from 'react-toastify';
import { Typography } from '@material-ui/core';
import LoadingBackdrop from '../../../components/LoadingBackdrop';
import StyledDashboardCard from '../../../components/StyledDashboardCard';

const PatientDashboard: React.FC = () => {
  const [patientData, setPatientData] = useState<DashboardPatientCount>();
  const [loading, setLoading] = useState(true);

  const { getAll } = usePatientDashboard();

  useEffect(() => {
    getAll()
      .then((patientData) => {
        setPatientData(patientData);
      })
      .catch((error) => {
        toast.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const oneToThree = patientData?.complicationsByPatient.find((item) => {
    return item.range == '1 - 3';
  });

  const fourToSix = patientData?.complicationsByPatient.find((item) => {
    return item.range == '4 - 6';
  });

  const plusSix = patientData?.complicationsByPatient.find((item) => {
    return item.range == '6+';
  });

  if (loading) {
    return <LoadingBackdrop loading={loading} />;
  }

  return (
    <Container data-testid="patient-dash-container">
      <CardsContainer>
        <StyledDashboardCard title="Pacientes ativos">
          <ContentContainer>
            <Typography variant="h3">
              {patientData?.activePatientsCount}
            </Typography>
          </ContentContainer>
        </StyledDashboardCard>
        <StyledDashboardCard title="Pacientes novos">
          <Typography variant="subtitle2">Nos ultimos 30 dias</Typography>
          <ContentContainer>
            <Typography variant="h3">
              {patientData?.newPatientsCount}
            </Typography>
          </ContentContainer>
        </StyledDashboardCard>
        <StyledDashboardCard title="Pacientes inativos">
          <ContentContainer>
            <Typography variant="h3">
              {patientData?.lostPatientsCount}
            </Typography>
          </ContentContainer>
        </StyledDashboardCard>
      </CardsContainer>
      <CardsContainer>
        <StyledDashboardCard title="Pacientes c/ intercorrência">
          <ContentContainer>
            <Typography variant="h3">
              {patientData?.patientsWithComplicationsCount}
            </Typography>
          </ContentContainer>
        </StyledDashboardCard>
        <StyledDashboardCard title="Total de intercorrências">
          <ContentContainer>
            <Typography variant="h3">
              {patientData?.totalComplicationsCount}
            </Typography>
          </ContentContainer>
        </StyledDashboardCard>
        <StyledDashboardCard title="Intercorrências por paciente">
          <ContentContainer>
            <ContentLine>
              <StyledColumn>
                <Typography variant="h5">1 - 3</Typography>
                <Typography variant="h5">4 - 6</Typography>
                <Typography variant="h5">6 +</Typography>
              </StyledColumn>
              <StyledColumn>
                <Typography variant="h5">{oneToThree?.count}</Typography>
                <Typography variant="h5">{fourToSix?.count}</Typography>
                <Typography variant="h5">{plusSix?.count}</Typography>
              </StyledColumn>
            </ContentLine>
          </ContentContainer>
        </StyledDashboardCard>
      </CardsContainer>
    </Container>
  );
};

export default PatientDashboard;
