import { UserModel } from '@cuidador/database';
import * as Sentry from '@sentry/react';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import axios from '../config/axios';
import firebase, { auth } from '../config/firebase';
import { resolveErrorMessage } from '../utils/error';
import { GlobalLoadingContext } from './RequestInterceptor';

interface Error {
  message?: string;
  code?: string;
  name?: string;
}

export const DEFAULT_ROLES = {
  guardian: 1,
  caregiver: 2,
  admin: 3,
  selfCare: 4,
  visualizerGuardian: 5,
  supporter: 6,
};

export interface ContextProps {
  user?: firebase.User | null;
  loading?: boolean;
  error?: Error | null;
  signIn(email: string, password: string): void;
  signOut?(): void;
}

export interface Props {
  children?: React.ReactNode;
}

export interface FirebaseErrorType {
  code: string;
  message: string;
}

export const sendPasswordResetEmail = async (email: string) => {
  const response = await axios.post(`/auth/password/recover-request`, {
    email,
    userProfile: 'admin', // to guarantee the redirection after changing the password
  });
  return response;
};

export const resetPassword = async (
  recoverPasswordToken: string,
  newPassword: string
) => {
  const response = await axios.post(`/auth/password/recover-set`, {
    recoverPasswordToken,
    newPassword,
  });
  return response;
};

export const requestSignIn = async (email: string, password: string) => {
  const response = await axios.post(`/auth/login`, {
    email,
    password,
    userProfile: 'admin',
  });
  return auth().signInWithCustomToken(response.data.token);
};

const getUserInfo = async () => axios.get<UserModel>('/user/me');

export const AuthContext = createContext<ContextProps>({} as ContextProps);

export const AuthProvider: React.FC = ({ children }: Props) => {
  const [user, setUser] = useState<firebase.User | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState(true);
  const { incrementLoading, decrementLoading } = useContext(
    GlobalLoadingContext
  );

  const signIn = async (email: string, password: string) => {
    try {
      incrementLoading();
      await requestSignIn(email, password);
    } catch (err) {
      setError(err);
    } finally {
      decrementLoading();
    }
  };

  const signOut = () => {
    auth().signOut();
  };

  useEffect(() => {
    const unsubscribe = auth().onAuthStateChanged(async (firebaseUser) => {
      const token = await firebaseUser?.getIdToken();
      axios.defaults.headers.Authorization = firebaseUser
        ? `Bearer ${token}`
        : undefined;

      if (firebaseUser) {
        try {
          const { claims } = await firebaseUser.getIdTokenResult();
          if (claims.auth !== 'admin') {
            // On this case, user has logged in with a token registered with wrong role
            toast.error(
              'As credenciais utilizadas não são válidas para este aplicativo. Entre em contato com o suporte'
            );
            Sentry.captureException(
              new Error(
                `User with firebaseUser.uid=${firebaseUser.uid} has logged in on appA using a token signed with role=${claims.auth}. This should never happen.`
              )
            );
            signOut();
            return;
          }

          const response = await getUserInfo();
          const userRole = response.data?.role;
          if (!userRole) {
            // On this case, user has no role and should not be allowed to use the app
            toast.error(
              `Você não possui um perfil de acesso configurado! Por favor, entre em contato com o suporte`
            );
            Sentry.captureException(
              new Error(
                `User with firebaseUser.uid=${firebaseUser.uid} has no assigned role. This should never happen.`
              )
            );
            signOut();
            return;
          }
          if (!userRole.adminAppAccess) {
            // On this case, user role doesnt have access to admin app and should not be allowed to use the app
            const roleTitle = userRole.title;
            toast.error(
              `Você possui o perfil de acesso ${roleTitle}, que não te dá acesso ao aplicativo do Administrador`
            );
            signOut();
            return;
          }
        } catch (err) {
          toast.error(resolveErrorMessage(err));
        }
      }

      setUser(firebaseUser);
      setLoading(false);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (user) {
      Sentry.setUser({
        id: String(user?.uid),
        email: user?.email || undefined,
        username: user.displayName || undefined,
      });
    } else {
      Sentry.setUser(null);
    }
  }, [user]);

  if (loading) return null;

  return (
    <AuthContext.Provider value={{ user, loading, error, signIn, signOut }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const isSupporter = (user?: UserModel) => {
  return user?.role?.id === DEFAULT_ROLES.supporter;
};

export const isSelfCare = (user?: UserModel) => {
  return user?.role?.id === DEFAULT_ROLES.selfCare;
};
