import React, { Fragment } from 'react';
import { ReportCareLineModel } from '@cuidador/database';
import { ReportCareCategory } from '@cuidador/shared';
import { Checkbox, Typography } from '@material-ui/core';

import StyledFormControlLabel from '../../../components/StyledFormControlLabel';

import {
  LabelContainer,
  Label,
  ClassificationLabel,
  IndicatorTitleContainer,
  OptionContainer,
  IndicatorTitle,
  IndicatorMessage,
} from '../styles';
import { IndicatorSectionProps } from '../utils';
import { CareLineName } from './styles';
import { labels } from './utils';

const CareLineIndicator: React.FC<
  IndicatorSectionProps<ReportCareCategory[]>
> = ({ data = [], onChange, selectedIndicators }) => {
  const renderOptionLabel = (reportCareLine: ReportCareLineModel) => (
    <IndicatorTitle>
      <ClassificationLabel
        $backgroundColor={reportCareLine!.indicatorRule?.colorHexa}
      />
      {`${reportCareLine!.careLine?.name}: `}
      <IndicatorMessage>
        {reportCareLine!.indicatorMessage?.message || ''}
      </IndicatorMessage>
    </IndicatorTitle>
  );

  const renderEmpty = () => (
    <IndicatorTitle>
      <IndicatorMessage>
        {'Nenhuma pergunta de cuidado feita durante o período.'}
      </IndicatorMessage>
    </IndicatorTitle>
  );

  return (
    <div data-testid="careLineIndicatorSection">
      <IndicatorTitleContainer>
        <Typography variant="h6">Cuidados</Typography>
        <LabelContainer>
          <Typography variant="caption">Legenda:</Typography>
          {labels.map(({ key, label }) => (
            <Label key={key}>
              <Typography color="textPrimary" variant="caption">
                {label}
              </Typography>
              <ClassificationLabel $classification={key} />
            </Label>
          ))}
        </LabelContainer>
      </IndicatorTitleContainer>
      {data.length
        ? data.map((careCategory) => (
            <Fragment key={careCategory.id}>
              <CareLineName>{careCategory.name}</CareLineName>
              {careCategory?.reportCareLines?.map((reportCareLine) => (
                <OptionContainer key={reportCareLine.id}>
                  <StyledFormControlLabel
                    value={reportCareLine.id}
                    name={String(reportCareLine.id)}
                    data-testid={reportCareLine.id}
                    control={
                      <Checkbox
                        color="primary"
                        checked={selectedIndicators.includes(
                          reportCareLine.id!
                        )}
                        onChange={() => onChange(reportCareLine.id!)}
                      />
                    }
                    label={renderOptionLabel(reportCareLine!)}
                  />
                </OptionContainer>
              ))}
            </Fragment>
          ))
        : renderEmpty()}
    </div>
  );
};

export default React.memo(CareLineIndicator);
