import React from 'react';
import { toast } from 'react-toastify';
import WarningIcon from '@material-ui/icons/Warning';
import StyledDialog from '../../../StyledDialog';
import {
  DialogSubtitle,
  StyledTypography,
  StyledDialogContent,
  StyledDialogActions,
  StyledButton,
} from '../styles';
import theme from '../../../../styles/theme';

export type DialogProps = {
  open: boolean;
  roleTitle?: string;
  roleIsDeletable?: boolean;
  handleBack: () => void;
  handleConfirm: () => void;
};

const AlertDialog: React.FC<DialogProps> = ({
  open,
  roleTitle,
  roleIsDeletable,
  handleBack,
  handleConfirm,
}) => {
  const warningColor = theme.palette.warning.light;
  const contrastColor = theme.palette.getContrastText('#000');

  return (
    <StyledDialog open={open}>
      <StyledDialogContent backgroundColor={warningColor}>
        <WarningIcon color="inherit" fontSize="large" />
        <DialogSubtitle width={70}>
          <StyledTypography>
            Tem certeza que deseja EXCLUIR o tipo de perfil{' '}
            <strong>“{roleTitle}”</strong>?
          </StyledTypography>
          <StyledTypography>
            Esta ação não pode ser desfeita e pode ocasionar problemas
          </StyledTypography>
        </DialogSubtitle>
        <StyledDialogActions>
          <StyledButton
            textColor={warningColor}
            backgroundColor={contrastColor}
            borderColor={contrastColor}
            onClick={handleBack}
          >
            VOLTAR
          </StyledButton>
          <StyledButton
            data-testid="confirm-delete-button"
            textColor={contrastColor}
            borderColor={contrastColor}
            onClick={() => {
              if (!roleIsDeletable) {
                toast.error('Este perfil não pode ser excluído');
              } else {
                handleConfirm();
              }
            }}
          >
            CONFIRMAR
          </StyledButton>
        </StyledDialogActions>
      </StyledDialogContent>
    </StyledDialog>
  );
};

export default AlertDialog;
