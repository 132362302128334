import { EventSubCategoryModel } from '@cuidador/database';
import * as Yup from 'yup';

export function resolveCategoryNameById(categoryId: Id | undefined) {
  switch (categoryId) {
    case 1:
      return 'Geral';
    case 2:
      return 'Medicação';
    case 3:
      return 'Intercorrência';
    case 4:
      return 'Controle';
    default:
      return '';
  }
}

export function resolveCategoryListLabelById(
  isRoutine: boolean | undefined,
  isAppointment: boolean | undefined
) {
  if (isRoutine && isAppointment) {
    return 'Rotina, Compromisso';
  } else if (isRoutine) {
    return 'Rotina';
  } else {
    return 'Compromisso';
  }
}

export interface FormValues {
  category: string;
  routine: boolean;
  appointment: boolean;
  name: string;
}

export interface IdFormValues {
  id: Id | undefined;
}

export const validationSchema = Yup.object()
  .shape({
    name: Yup.string().required('Por favor, insira o nome da subcategoria'),
    category: Yup.string().required('Por favor, insira uma categoria'),
    routine: Yup.boolean(),
    appointment: Yup.boolean(),
  })
  .test(
    'checkboxValidation',
    'Por favor, selecione pelo menos uma opção',
    (obj) => {
      if (obj?.category !== '1') {
        return true;
      } else if (obj.routine || obj.appointment) {
        return true;
      } else {
        return new Yup.ValidationError(
          'Por favor, selecione pelo menos uma opção',
          null,
          'routine'
        );
      }
    }
  );

export const formDataToEventSubCategoryModel = (values: FormValues) =>
  (({
    categoryId: values.category,
    name: values.name,
    isRoutine: values.routine ? true : false,
    isAppointment: values.appointment ? true : false,
  } as unknown) as EventSubCategoryModel);
