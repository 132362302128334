import styled from 'styled-components';

export const TextContent = styled.div`
  margin-top: ${({ theme }) => theme.spacing(1)}px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonContent = styled.div`
  margin-top: ${({ theme }) => theme.spacing(8)}px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
