import React, { useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Background, Container } from './styles';
import { TableCell } from '@material-ui/core';
import qs from 'query-string';
import { useHistory } from 'react-router-dom';
import { EventModel } from '@cuidador/database';
import MyTable from '../../../components/MyTable';
import formatDate from 'date-fns/format';
import useActivity from '../../../hooks/useActivity';
import { weekdaysLabel, formatAppointmentDate } from './utils';

const tableColumns = [
  'Tipo',
  'Rotina',
  'Horário',
  'Dias da semana',
  'Descrição',
];

const ActivityList: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  const { getByPatientPaginated, ids, byId, total, loading } = useActivity();
  const history = useHistory();
  const limit = parseInt(String(qs.parse(history.location.search).limit)) || 20;

  useEffect(() => {
    onChangeParams();
  }, []);

  const onChangeParams = () => {
    const params = qs.parse(history.location.search);
    getByPatientPaginated(Number(id), {
      ...params,
      limit: Number(params.limit) || 20,
    });
  };

  const renderActivityItem = useCallback((item: EventModel) => {
    return (
      <>
        <TableCell>{item.eventScheduleId ? 'Rotina' : 'Compromisso'}</TableCell>
        <TableCell>{item?.subCategory?.name}</TableCell>
        <TableCell>
          {formatDate(new Date(String(item.eventHappensAt)), 'HH:mm')}
        </TableCell>
        <TableCell>
          {item.eventScheduleId
            ? weekdaysLabel(item.eventSchedule)
            : formatAppointmentDate(String(item.eventHappensAt))}
        </TableCell>
        <TableCell>{item.description}</TableCell>
      </>
    );
  }, []);

  const data = ids.map((id) => byId[id]);

  return (
    data && (
      <Background>
        <Container>
          <MyTable
            columnNames={tableColumns}
            data={data}
            totalPages={Math.ceil(total / limit)}
            keyExtractor={({ item }) => String(item!.id)}
            renderItem={renderActivityItem}
            loading={loading}
            onChangePage={onChangeParams}
            onChangeRowsPerPage={onChangeParams}
          />
        </Container>
      </Background>
    )
  );
};

export default ActivityList;
