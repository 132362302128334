import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import {
  Background,
  HeadingContainer,
  TitleContainer,
  SubTitleContainer,
  StyledTypography,
  StyledIcon,
  StyledClickContainer,
} from './styles';
import useCaregiver from '../../../../hooks/useCaregiver';
import { toast } from 'react-toastify';
import useMood from '../../../../hooks/useMood';
import LoadingBackdrop from '../../../../components/LoadingBackdrop';

const CaregiverMoodDownload: React.FC = () => {
  const params = useParams<{ id: string }>();
  const [loading, setLoading] = useState(false);
  const { getById, byId, loading: loadingCaregiver } = useCaregiver();
  const { getMoodDataByCaregiver } = useMood();
  const id = parseInt(params.id);

  useEffect(() => {
    getById(id);
  }, [id]);

  const handleDownloadClick = async (id: number, name: string | undefined) => {
    if (loading) return;
    try {
      setLoading(true);
      await getMoodDataByCaregiver(id, name);
    } catch (error) {
      console.error(error);
      toast.error(
        'Erro ao baixar os dados do cuidador. Entre em contato com o suporte'
      );
    } finally {
      setLoading(false);
    }
  };

  const item = byId[params.id];

  return (
    <>
      {(loadingCaregiver || loading) && (
        <LoadingBackdrop loading={loadingCaregiver || loading} />
      )}
      <Background>
        <HeadingContainer>
          <TitleContainer>
            <Typography variant="h5">Perguntas cuidar do cuidador.</Typography>
          </TitleContainer>
          <SubTitleContainer>
            <Typography variant="subtitle1">
              1 - Como está se sentindo agora?
            </Typography>
            <Typography variant="subtitle1">
              2 - Como se sentiu hoje?
            </Typography>
          </SubTitleContainer>
          <StyledClickContainer
            onClick={() => handleDownloadClick(item.id, item.user?.name)}
          >
            <StyledIcon />
            <StyledTypography variant="subtitle1" color="primary">
              Download respostas
            </StyledTypography>
          </StyledClickContainer>
        </HeadingContainer>
      </Background>
    </>
  );
};

export default CaregiverMoodDownload;
