import { ActionModel } from '@cuidador/database';
import * as Yup from 'yup';

export const formInitialValue = {
  eventSubcategoryId: '',
  eventCategoryId: '',
  actionTriggerType: undefined,
  actionOutcomeType: undefined,
  triggerEventStatus: undefined,
  triggerTimeLapseInHours: '',
  triggerAdherenceInPercentage: '',
  triggerAdherenceType: undefined,
  triggerThreshold: '',
  newCareQuestionId: '',
  deactivatedCareLineId: '',
  sendEmailGuardian: false,
  hasRecommendedContent: false,
  contentId: '',
  emailMessageAddition: '',
  newCareLineId: '',
  classification: '',
};

export interface FormValues {
  eventSubcategoryId: string;
  eventCategoryId: string;
  actionTriggerType: ActionModel['actionTriggerType'] | undefined;
  actionOutcomeType: ActionModel['actionOutcomeType'] | undefined;
  triggerEventStatus: ActionModel['triggerEventStatus'] | undefined;
  triggerTimeLapseInHours: string;
  triggerAdherenceInPercentage: string;
  triggerAdherenceType: ActionModel['triggerAdherenceType'] | undefined;
  triggerThreshold: string;
  newCareQuestionId: string;
  deactivatedCareLineId: string;
  sendEmailGuardian: boolean;
  hasRecommendedContent: boolean;
  contentId?: string;
  emailMessageAddition?: string;
  newCareLineId: string;
  classification: ActionModel['classification'] | undefined;
}

export const validationSchema = Yup.object().shape({
  eventCategoryId: Yup.mixed().required('Por favor, selecione uma categoria'),
  eventSubcategoryId: Yup.mixed().required(
    'Por favor, selecione uma subcategoria'
  ),
  triggerEventStatus: Yup.mixed().required(
    'Por favor, selecione um tipo de resposta'
  ),
  actionTriggerType: Yup.mixed().required(
    'Por favor, selecione um tipo de gatilho'
  ),
  contentId: Yup.string().when('hasRecommendedContent', {
    is: true,
    then: Yup.string().required('Por favor, insira um conteúdo recomendado'),
  }),
  emailMessageAddition: Yup.string().max(
    2500,
    'Por favor, a mensagem adicional do email deve conter no máximo 2500 caracteres'
  ),
  newCareLineId: Yup.string().when('actionOutcomeType', {
    is: 'activate_static_care_line',
    then: Yup.string().required('Por favor, insira uma linha de cuidado'),
  }),
  classification: Yup.string()
    .when('actionOutcomeType', {
      is: 'activate_static_care_line',
      then: Yup.string().required('Por favor, selecione uma classificação'),
    })
    .nullable(),
});

export const optionClassifications = [
  { id: 'functional', type: 'Funcional' },
  { id: 'dysfunctional', type: 'Disfuncional' },
  { id: 'severe_dysfunctional', type: 'Disfuncional grave' },
];

export const eventCategory = [
  { id: 1, category: 'Geral' },
  { id: 2, category: 'Medicação' },
  { id: 3, category: 'Intercorrência' },
  { id: 4, category: 'Controle' },
];

export const triggerStatusTypes = [
  { id: 'not_accomplished', status: 'Não feito' },
  { id: 'accomplished', status: 'Feito' },
];

export const triggerAdherenceTypes = [
  { id: 'below', type: 'Abaixo de' },
  { id: 'above', type: 'Acima de' },
];

export function formatClassification(classification: string | undefined) {
  switch (classification) {
    case 'functional':
      return 'Funcional';
    case 'dysfunctional':
      return 'Disfuncional';
    case 'severe_dysfunctional':
      return 'Disfuncional grave';
    default:
      return '';
  }
}

export const formDataToActionModel = (values: FormValues) => {
  if (mustHaveNewCareQuestion(values.actionOutcomeType)) {
    values.deactivatedCareLineId = '';
    values.newCareLineId = '';
    values.classification = undefined;
  } else if (values.actionOutcomeType === 'activate_static_care_line') {
    values.newCareQuestionId = '';
    values.deactivatedCareLineId = '';
  } else {
    values.newCareQuestionId = '';
    values.newCareLineId = '';
    values.classification = undefined;
  }
  if (values.actionTriggerType == 'consecutive') {
    values.triggerTimeLapseInHours = '';
    values.triggerAdherenceType = undefined;
    values.triggerAdherenceInPercentage = '';
  } else if (values.actionTriggerType == 'answers_quantity_timelapse') {
    values.triggerAdherenceType = undefined;
    values.triggerAdherenceInPercentage = '';
  } else {
    values.triggerThreshold = '';
  }
  return {
    eventSubcategoryId: parseInt(values.eventSubcategoryId),
    actionTriggerType: values.actionTriggerType,
    actionOutcomeType: values.actionOutcomeType,
    triggerEventStatus: values.triggerEventStatus,
    triggerTimelapseInHours: values.triggerTimeLapseInHours
      ? parseInt(values.triggerTimeLapseInHours)
      : undefined,
    triggerAdherenceInPercentage: values.triggerAdherenceInPercentage
      ? parseInt(values.triggerAdherenceInPercentage)
      : undefined,
    triggerAdherenceType: values.triggerAdherenceType,
    triggerThreshold: values.triggerThreshold
      ? parseInt(values.triggerThreshold)
      : undefined,
    newCareQuestionId: values.newCareQuestionId
      ? parseInt(values.newCareQuestionId)
      : undefined,
    deactivatedCareLineId: values.deactivatedCareLineId
      ? parseInt(values.deactivatedCareLineId)
      : undefined,
    sendEmailGuardian: values.sendEmailGuardian,
    type: 'event',
    contentId: values.hasRecommendedContent ? Number(values.contentId) : null,
    emailMessageAddition: values.sendEmailGuardian
      ? values.emailMessageAddition
      : '',
    newCareLineId: values.newCareLineId
      ? parseInt(values.newCareLineId)
      : undefined,
    classification: values.classification,
  } as ActionModel;
};

export const actionEventModelToFormData = (
  action: ActionModel
): FormValues => ({
  triggerThreshold: action.triggerThreshold
    ? String(action.triggerThreshold)
    : '',
  actionOutcomeType: action.actionOutcomeType,
  actionTriggerType: action.actionTriggerType,
  triggerEventStatus: action.triggerEventStatus,
  triggerTimeLapseInHours: action.triggerTimelapseInHours
    ? String(action.triggerTimelapseInHours)
    : '',
  triggerAdherenceInPercentage: action.triggerAdherenceInPercentage
    ? String(action.triggerAdherenceInPercentage)
    : '',
  triggerAdherenceType: action.triggerAdherenceType
    ? action.triggerAdherenceType
    : undefined,
  newCareQuestionId: action.newCareQuestionId
    ? String(action.newCareQuestionId)
    : '',
  deactivatedCareLineId: action.deactivatedCareLineId
    ? String(action.deactivatedCareLineId)
    : '',
  sendEmailGuardian: Boolean(action.sendEmailGuardian),
  eventSubcategoryId: action.eventSubcategoryId
    ? String(action.eventSubcategoryId)
    : '',
  eventCategoryId: action.eventSubcategory?.eventCategory?.id
    ? String(action.eventSubcategory?.eventCategory?.id)
    : '',
  hasRecommendedContent: Boolean(action.contentId),
  contentId: action.contentId ? String(action.contentId) : '',
  emailMessageAddition: action.emailMessageAddition
    ? String(action.emailMessageAddition)
    : '',
  newCareLineId: action.newCareLineId ? String(action.newCareLineId) : '',
  classification: action.classification,
});

export const mustHaveNewCareQuestion = (
  actionOutcomeType: ActionModel['actionOutcomeType'] | undefined
) => {
  return (
    actionOutcomeType === 'activate_care_question' ||
    actionOutcomeType === 'activate_additional_care_question'
  );
};
