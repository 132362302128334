import * as Yup from 'yup';
import { PatientInterviewModel } from '@cuidador/database';
import { parseISO, format, parse, startOfDay } from 'date-fns';

export const formatInterviewStatus = (status: boolean | undefined) =>
  status ? 'Não finalizada' : 'Finalizada';

export const formatInterviewAction = (status: boolean | undefined) =>
  status ? 'EDITAR' : 'VISUALIZAR';

export const formatStringDate = (stringDate: string | undefined): string => {
  const date = parseISO(
    String(stringDate).substring(0, String(stringDate).indexOf('T'))
  );
  return format(date, 'dd/MM/yyyy');
};

export const formatDate = (date: string) => {
  return startOfDay(parse(`${date}`, 'yyyy-MM-dd', new Date())).toISOString();
};

export const formDataToInterviewModel = (
  values: FormValues,
  patientId: string
) => {
  const isoInterviewedAt = formatDate(`${values.interviewedAt}`);

  return {
    name: values.name,
    interviewedAt: isoInterviewedAt,
    patientId: Number(patientId),
  } as PatientInterviewModel;
};

export interface FormValues {
  name?: string;
  interviewedAt?: string;
}

export const initialValues = {
  name: '',
  interviewedAt: '',
};

export const validationSchema = Yup.object().shape({
  name: Yup.mixed().required('Por favor, insira um nome'),
  interviewedAt: Yup.string().required('Por favor, insira uma data válida'),
});
