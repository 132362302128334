import styled from 'styled-components';

import TextField from '../../components/StyledTextField';
import BackgroundImage from '../../assets/loginBackground.svg';

export const Background = styled.div`
  height: 100vh;
  background-image: ${`url(${BackgroundImage})`};
  background-size: cover;
  background-position: center;
  padding: 0px ${({ theme }) => theme.spacing(2)}px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 80%;
  width: 100%;
  color: white;
  img {
    max-width: 100%;
    margin-bottom: ${({ theme }) => theme.spacing(8)}px;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 400px;
    max-width: 100%;
    text-align: center;
    flex: 1;
    justify-content: center;
    align-items: center;

    .MuiButton-root {
      margin-top: ${({ theme }) => theme.spacing(4)}px;
    }
  }

  a {
    font-size: 18px;
    color: white;
  }
`;

export const StyledTextField = styled(TextField).attrs({ variant: 'outlined' })`
  & label:not(.Mui-error) {
    color: ${({ theme }) => theme.palette.common.white};
  }
  & input {
    color: ${({ theme }) => theme.palette.common.white};
  }
  & textarea {
    color: ${({ theme }) => theme.palette.common.white};
  }
  & .MuiInputAdornment-root .MuiIconButton-label {
    color: ${({ theme }) => theme.palette.common.white};
  }
  & .MuiOutlinedInput-root:not(.Mui-error) {
    & fieldset {
      border-color: ${({ theme }) => theme.palette.common.white};
      color: ${({ theme }) => theme.palette.common.white};
    }
    &:hover fieldset {
      border-color: ${({ theme }) => theme.palette.common.white};
      color: ${({ theme }) => theme.palette.common.white};
    }
    &.Mui-focused fieldset {
      border-color: ${({ theme }) => theme.palette.common.white};
      color: ${({ theme }) => theme.palette.common.white};
    }
  }
`;

export const TypographyContainer = styled.div`
  width: 400px;
  max-width: 100%;
  text-align: center;
`;
