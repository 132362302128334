import { Typography } from '@material-ui/core';
import styled from 'styled-components';

export const Percentage = styled(Typography).attrs({
  component: 'span',
})<{
  $classification: 'good' | 'moderate' | 'bad';
}>`
  color: ${({ $classification, theme }) =>
    theme.palette.extra.indicators[$classification]};
  margin-right: ${({ theme }) => theme.spacing(1)}px;
  font-weight: 500;
`;
