import React from 'react';
import FormikTextField from '../Forms/FormikTextField';
import * as Yup from 'yup';
import isEqual from 'lodash/isEqual';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { Form, ButtonBox, StyledButton } from './styles';
import { useHistory } from 'react-router-dom';
import HTMLEditor from '../HTMLEditor';
import { FormHelperText } from '@material-ui/core';

export interface FormValues {
  title?: string;
  text?: string;
}

const validationSchema = Yup.object().shape({
  title: Yup.string().required('Por favor, insira um título.'),
  text: Yup.string()
    .required('Por favor, insira o texto.')
    .max(
      10000,
      'Por favor, o texto do conteúdo deve conter no máximo 10000 caracteres'
    ),
});

const ContentUpsertForm: React.FC<{
  initialValues: FormValues;
  innerRef?: React.Ref<FormikProps<FormValues>>;
  onSubmit: (
    values: FormValues,
    formikHelpers: FormikHelpers<FormValues>
  ) => void | Promise<void>;
}> = ({ initialValues, innerRef, onSubmit }) => {
  const history = useHistory();

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        validateOnChange={false}
        innerRef={innerRef}
      >
        {({ values, isSubmitting, setFieldValue, errors }) => {
          return (
            <Form
              // noValidate disables browser default validation
              noValidate={true}
            >
              <FormikTextField
                id="title"
                inputProps={{
                  'data-testid': 'title-textbox',
                  maxlength: 150,
                }}
                label="Título do conteúdo"
                name="title"
                margin="normal"
                autoComplete="off"
                multiline
              />
              <HTMLEditor
                data-testid="text-textbox"
                id="html-editor"
                value={values.text}
                placeholder="Texto do conteúdo"
                onChange={(content) => setFieldValue('text', content)}
              />
              <FormHelperText error={true}>{errors.text}</FormHelperText>
              <ButtonBox>
                <StyledButton
                  size="large"
                  color="inherit"
                  onClick={() => history.goBack()}
                >
                  Cancelar
                </StyledButton>
                <StyledButton
                  data-testid="submit-content"
                  type="submit"
                  size="large"
                  color="inherit"
                  disabled={isSubmitting || isEqual(initialValues, values)}
                >
                  Salvar
                </StyledButton>
              </ButtonBox>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default ContentUpsertForm;
