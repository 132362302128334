import React from 'react';
import { DialogProps } from '@material-ui/core/Dialog';
import StyledDialog from '../../../components/StyledDialog';
import { PatientModel } from '@cuidador/database';
import { DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { ChangeClassificationFormValues } from '../utils';
import StyledButton from '../../../components/StyledButton';
import StyledSelect from '../../../components/Forms/FormikSelect';
import StyledMenuItem from '../../../components/StyledMenuItem';
import { Formik } from 'formik';
import { StyledForm } from './styles';

interface ChangeLevelDialogProps extends DialogProps {
  patient?: PatientModel;
  onConfirm: (values: ChangeClassificationFormValues) => Promise<void>;
  onCancel: () => void;
}

const ChangeExpirationDateDialog: React.FC<ChangeLevelDialogProps> = ({
  patient,
  onCancel,
  onConfirm,
  ...rest
}) => {
  const formInitialValue = {
    classification: patient?.classification
      ? patient?.classification
      : 'functional',
  };
  return (
    <StyledDialog {...rest}>
      <DialogContent>
        <DialogTitle>{`Informe a classificação do ${patient?.name}`}</DialogTitle>
        <Formik
          initialValues={formInitialValue}
          onSubmit={onConfirm}
          validateOnChange={false}
        >
          {(formik) => (
            <StyledForm>
              <StyledSelect
                name="classification"
                label="Classificação"
                SelectDisplayProps={{
                  'data-testid': 'classification',
                }}
              >
                <StyledMenuItem value="functional" color="primary">
                  Funcional
                </StyledMenuItem>
                <StyledMenuItem value="dysfunctional" color="primary">
                  Disfuncional
                </StyledMenuItem>
                <StyledMenuItem value="severe_dysfunctional" color="primary">
                  Disfuncional grave
                </StyledMenuItem>
              </StyledSelect>
              <DialogActions>
                <StyledButton
                  data-testid="cancel"
                  size="large"
                  color="inherit"
                  onClick={onCancel}
                >
                  Cancelar
                </StyledButton>
                <StyledButton
                  data-testid="submit"
                  size="large"
                  color="inherit"
                  type="submit"
                  variant="text"
                  disabled={formik.isSubmitting}
                >
                  Salvar
                </StyledButton>
              </DialogActions>
            </StyledForm>
          )}
        </Formik>
      </DialogContent>
    </StyledDialog>
  );
};

export default ChangeExpirationDateDialog;
