import { FormHelperText } from '@material-ui/core';
import styled from 'styled-components';
import FormikField from '../../Forms/FormikTextField';

interface FormikFieldStyledProps {
  width: number;
}

export const Container = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(3)}px;
`;

export const Label = styled.label`
  display: flex;
  font-weight: 600;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
`;

export const LabelMessage = styled.span`
  font-size: 12px;
  font-weight: 400;
  margin-left: ${({ theme }) => theme.spacing(1)}px;
`;

export const FormikFieldStyled = styled(FormikField)<FormikFieldStyledProps>`
  width: ${(props) => props.width}%;
`;

export const InputGroup = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`;

export const StyledHelperText = styled(FormHelperText)`
  color: ${({ theme }) => theme.palette.error.main};
  margin-left: ${({ theme }) => theme.spacing(2)}px;
`;

export const ControlsContainer = styled.div`
  display: flex;
`;

export const CheckboxGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`;

export const OptionsGroup = styled.div`
  display: flex;

  div + div {
    margin-left: ${({ theme }) => theme.spacing(5)}px;
  }
`;
