import Dialog from '@material-ui/core/Dialog';
import styled from 'styled-components';

export const StyledMuiDialog = styled<{ backgroundColor?: string }>(Dialog)`
  & .MuiPaper-root {
    background-color: ${({ backgroundColor, theme }) =>
      backgroundColor ?? theme.palette.info.main};
    border-radius: 21px;
    min-width: 500px;
    color: ${({ theme }) => theme.palette.common.white};
    max-width: 100%;
    max-height: 98%;
  }
  & .MuiDialogContentText-root {
    display: flex;
    flex-direction: column;
  }
  & .MuiDialogActions-root {
    justify-content: center;
    padding: 16px 24px;
  }
  & .MuiDialogTitle-root {
    text-align: center;
  }
  & .MuiTypography-colorTextSecondary {
    color: ${({ theme }) => theme.palette.common.white};
  }
  & .MuiDialogTitle-root {
    z-index: 5;
  }
  & .MuiDialogContent-root {
    z-index: 5;
  }
`;

export const StyledElipseBlueTop = styled.img`
  width: 250px;
  height: 250px;
  top: -190px;
  right: 30px;
  position: absolute;
  z-index: 2;
`;

export const StyledElipseGreenTop = styled.img`
  width: 250px;
  height: 250px;
  top: -150px;
  right: -100px;
  position: absolute;
  z-index: 1;
`;

export const StyledElipseBlueBot = styled.img`
  width: 250px;
  height: 250px;
  bottom: -150px;
  left: -120px;
  position: absolute;
  z-index: 2;
`;

export const StyledElipseGreenBot = styled.img`
  width: 250px;
  height: 250px;
  bottom: -180px;
  left: 20px;
  position: absolute;
  z-index: 1;
`;

export const StyledDiv = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;
