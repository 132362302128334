import React, { useEffect, useState } from 'react';
import {
  Container,
  AddButton,
  Header,
  EditIconButton,
  DeleteIconButton,
} from './styles';

import { formDataToCareLineModel, FormValues } from './utils';

import qs from 'query-string';
import { useHistory } from 'react-router-dom';
import { resolveErrorMessage } from '../../../utils/error';
import { AxiosError } from 'axios';
import { APIError } from '@cuidador/lib';
import { toast } from 'react-toastify';
import { ReactComponent as EditIcon } from '../../../assets/Icon-edit.svg';
import AddIcon from '@material-ui/icons/AddCircleOutlineRounded';
import DeleteIcon from '@material-ui/icons/Delete';
import { TableCell, Typography } from '@material-ui/core';
import MyTable from '../../../components/MyTable';
import SearchTextField from '../../../components/SearchTextField';
import useCareLine from '../../../hooks/useCareLine';
import { CareLineModel } from '@cuidador/database';
import CareLineDialog from './CareLineDialog';
import StyledSimpleDialog from '../../../components/StyledSimpleDialog';

const tableColumns = [
  'Categoria de cuidado',
  'Linha de cuidado',
  'Plantão Noturno',
  '',
];

const CareLine: React.FC = () => {
  const {
    getPaginated,
    post,
    patch,
    remove,
    ids,
    byId,
    total,
    loading,
  } = useCareLine();
  const history = useHistory();

  const limit = parseInt(String(qs.parse(history.location.search).limit)) || 20;

  useEffect(() => {
    onChangeParams();
  }, []);

  const onChangeParams = () => {
    const params = qs.parse(history.location.search);
    getPaginated({ ...params, limit: Number(params.limit) || 20 });
  };

  const data = ids.map((id) => byId[id]);

  const [careLineDialogOpen, setCareLineDialogOpen] = React.useState<boolean>(
    false
  );

  const [
    careLineBeingEdited,
    setCareLineBeingEdited,
  ] = React.useState<CareLineModel>();

  const [deleteDialogStatus, setDeleteDialogStatus] = useState(false);
  const [
    careLineBeingDeleted,
    setCareLineBeingDeleted,
  ] = React.useState<CareLineModel>();

  const cancelDialog = () => {
    if (careLineDialogOpen) {
      return setCareLineDialogOpen(false);
    } else {
      return setCareLineBeingEdited(undefined);
    }
  };

  const postCareLine = (values: FormValues) => {
    careLineBeingEdited
      ? patch(careLineBeingEdited!.id!, formDataToCareLineModel(values))
          .then(() => {
            setCareLineBeingEdited(undefined);
          })
          .catch((err: AxiosError<APIError>) => {
            const displayMessage = resolveErrorMessage(err);
            toast.error(displayMessage);
          })
      : post(formDataToCareLineModel(values))
          .then(() => {
            setCareLineDialogOpen(false);
          })
          .catch((err: AxiosError<APIError>) => {
            const displayMessage = resolveErrorMessage(err);
            toast.error(displayMessage);
          });
  };

  const deleteCareLine = (id: Id) => {
    remove(id)
      .then(() => {
        // Forces table refresh
        setDeleteDialogStatus(false);
        return onChangeParams();
      })
      .then(() => {
        setDeleteDialogStatus(false);
      })
      .catch((err: AxiosError<APIError>) => {
        const displayMessage = resolveErrorMessage(err);
        toast.error(displayMessage);
      });
  };

  const renderCareLineItem = (item: CareLineModel) => {
    return (
      <>
        <TableCell>{item?.careCategory?.name}</TableCell>
        <TableCell>{item?.name}</TableCell>
        <TableCell>
          {item?.isNightShiftDisabled ? 'Desativado' : 'Ativado'}
        </TableCell>
        <TableCell align="right">
          <EditIconButton>
            <EditIcon
              data-testid="edit-dialog-open"
              onClick={() => setCareLineBeingEdited(item)}
            />
          </EditIconButton>
          <DeleteIconButton>
            <DeleteIcon
              data-testid="delete-dialog-open"
              onClick={() => {
                setDeleteDialogStatus(true);
                setCareLineBeingDeleted(item);
              }}
            />
          </DeleteIconButton>
        </TableCell>
      </>
    );
  };

  return (
    <Container>
      <Header>
        <AddButton
          data-testid="create-careLine-dialog-open"
          onClick={() => setCareLineDialogOpen(true)}
        >
          <AddIcon fontSize="large" color="primary" />
          <Typography variant="h5">Adicionar</Typography>
        </AddButton>
        <SearchTextField fieldName="name" onChangeDebounced={onChangeParams} />
      </Header>
      <MyTable
        columnNames={tableColumns}
        data={data}
        totalPages={Math.ceil(total / limit)}
        hasPagination={true}
        keyExtractor={({ item }) => String(item!.id)}
        renderItem={renderCareLineItem}
        onChangePage={onChangeParams}
        onChangeRowsPerPage={onChangeParams}
        loading={loading}
      />
      <CareLineDialog
        open={careLineDialogOpen || Boolean(careLineBeingEdited)}
        careLine={careLineBeingEdited}
        onCancel={() => cancelDialog()}
        onConfirm={postCareLine}
      />
      <StyledSimpleDialog
        open={deleteDialogStatus}
        handleNo={() => setDeleteDialogStatus(false)}
        handleYes={() =>
          deleteCareLine(careLineBeingDeleted?.id ? careLineBeingDeleted.id : 0)
        }
        title={`Tem certeza que deseja excluir a linha de cuidado ${careLineBeingDeleted?.name}?`}
        subTitle="Todos as perguntas de cuidado e ações relacionados com ela serão excluídas também."
      />
    </Container>
  );
};

export default CareLine;
