import { CaregiverModel } from '@cuidador/database';
import { useCallback, useReducer } from 'react';
import axios from '../config/axios';
import {
  createReducer,
  Item,
  PaginatedRequestParams,
  ReducerData,
} from '../utils/store/index';

const endpoint = '/user/caregiver';

const initialData: ReducerData<CaregiverModel> = {
  byId: {} as Record<string, Item<CaregiverModel>>,
  ids: [] as Array<number>,
  total: 0,
  loading: false,
  error: null,
};

const useCaregiver = () => {
  const [state, dispatch] = useReducer(
    createReducer<CaregiverModel>(),
    initialData
  );

  const getById = useCallback(async (id: Id) => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.get(`${endpoint}/${id}`);
      dispatch({ type: 'GET_BY_ID', payload: response.data });
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
    }
  }, []);

  const post = useCallback(async (data: CaregiverModel) => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.post(`${endpoint}`, data);
      dispatch({ type: 'CREATE', payload: response.data });
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
    }
  }, []);

  const patch = useCallback(async (id: Id, data: CaregiverModel) => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.patch(`${endpoint}/${id}`, data);
      dispatch({ type: 'UPDATE', payload: response.data });
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
      throw Error(err);
    }
  }, []);

  const patchStatus = useCallback(
    async (id: Id, data: Partial<CaregiverModel>) => {
      try {
        dispatch({ type: 'LOADING' });
        const response = await axios.patch(`${endpoint}/${id}/status`, data);
        dispatch({ type: 'UPDATE', payload: response.data });
      } catch (err) {
        dispatch({ type: 'ERROR', payload: err });
        return Promise.reject(err);
      }
    },
    []
  );

  const remove = useCallback(async (id: Id) => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.delete(`${endpoint}/${id}`);
      dispatch({ type: 'REMOVE', payload: response.data });
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
    }
  }, []);

  const getPaginated = useCallback(async (params: PaginatedRequestParams) => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.get(`${endpoint}`, { params });
      dispatch({ type: 'PAGINATION', payload: response.data });
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
    }
  }, []);

  return {
    ...state,
    getById,
    post,
    patch,
    patchStatus,
    remove,
    getPaginated,
  };
};

export default useCaregiver;
