import * as Yup from 'yup';
import { ActionModel } from '@cuidador/database';

export const optionClassifications = [
  { id: 'functional', type: 'Funcional' },
  { id: 'dysfunctional', type: 'Disfuncional' },
  { id: 'severe_dysfunctional', type: 'Disfuncional grave' },
];

export const formInitialValue = {
  actionOutcomeType: undefined,
  careQuestionId: '',
  triggerThreshold: '',
  careQuestionOptionId: '',
  newCareQuestionId: '',
  deactivatedCareLineId: '',
  sendEmailGuardian: false,
  hasRecommendedContent: false,
  contentId: '',
  emailMessageAddition: '',
  newCareLineId: '',
  classification: '',
};

export interface FormValues {
  actionOutcomeType: ActionModel['actionOutcomeType'] | undefined;
  careQuestionId: string;
  triggerThreshold: string;
  careQuestionOptionId: string;
  newCareQuestionId: string;
  deactivatedCareLineId: string;
  sendEmailGuardian: boolean;
  hasRecommendedContent: boolean;
  contentId?: string;
  emailMessageAddition?: string;
  newCareLineId: string;
  classification: ActionModel['classification'] | undefined;
}

export const validationSchema = Yup.object().shape({
  actionOutcomeType: Yup.mixed().required('Por favor, selecione uma ação'),
  careQuestionId: Yup.string().required(
    'Por favor, insira uma linha de cuidado'
  ),
  triggerThreshold: Yup.string().required('Por favor, insira um valor'),
  careQuestionOptionId: Yup.string().required(
    'Por favor, selecione as respostas associadas'
  ),
  contentId: Yup.string().when('hasRecommendedContent', {
    is: true,
    then: Yup.string().required('Por favor, insira um conteúdo recomendado'),
  }),
  emailMessageAddition: Yup.string().max(
    2500,
    'Por favor, a mensagem adicional do email deve conter no máximo 2500 caracteres'
  ),
  newCareLineId: Yup.string().when('actionOutcomeType', {
    is: 'activate_static_care_line',
    then: Yup.string().required('Por favor, insira uma linha de cuidado'),
  }),
  classification: Yup.string()
    .when('actionOutcomeType', {
      is: 'activate_static_care_line',
      then: Yup.string().required('Por favor, selecione uma classificação'),
    })
    .nullable(),
});

export function formatClassification(classification: string | undefined) {
  switch (classification) {
    case 'functional':
      return 'Funcional';
    case 'dysfunctional':
      return 'Disfuncional';
    case 'severe_dysfunctional':
      return 'Disfuncional grave';
    default:
      return '';
  }
}

export const formDataToActionModel = (values: FormValues) => {
  if (mustHaveNewCareQuestion(values.actionOutcomeType)) {
    values.deactivatedCareLineId = '';
    values.newCareLineId = '';
    values.classification = undefined;
  } else if (values.actionOutcomeType === 'activate_static_care_line') {
    values.newCareQuestionId = '';
    values.deactivatedCareLineId = '';
  } else {
    values.newCareQuestionId = '';
    values.newCareLineId = '';
    values.classification = undefined;
  }
  return {
    careQuestionId: parseInt(values.careQuestionId),
    actionOutcomeType: values.actionOutcomeType,
    triggerThreshold: parseInt(values.triggerThreshold),
    careQuestionOptionId: values.careQuestionOptionId,
    newCareQuestionId: values.newCareQuestionId
      ? parseInt(values.newCareQuestionId)
      : undefined,
    deactivatedCareLineId: values.deactivatedCareLineId
      ? parseInt(values.deactivatedCareLineId)
      : undefined,
    sendEmailGuardian: values.sendEmailGuardian,
    type: 'shift_question',
    contentId: values.hasRecommendedContent ? Number(values.contentId) : null,
    emailMessageAddition: values.sendEmailGuardian
      ? values.emailMessageAddition
      : '',
    newCareLineId: values.newCareLineId
      ? parseInt(values.newCareLineId)
      : undefined,
    classification: values.classification,
  } as ActionModel;
};

export const actionCareQuestionModelToFormData = (
  action: ActionModel
): FormValues => ({
  careQuestionId: action.careQuestionId ? String(action.careQuestionId) : '',
  triggerThreshold: String(action.triggerThreshold),
  careQuestionOptionId: action.careQuestionOptionId!,
  actionOutcomeType: action.actionOutcomeType,
  newCareQuestionId: action.newCareQuestionId
    ? String(action.newCareQuestionId)
    : '',
  deactivatedCareLineId: action.deactivatedCareLineId
    ? String(action.deactivatedCareLineId)
    : '',
  sendEmailGuardian: Boolean(action.sendEmailGuardian),
  hasRecommendedContent: Boolean(action.contentId),
  contentId: action.contentId ? String(action.contentId) : '',
  emailMessageAddition: action.emailMessageAddition
    ? String(action.emailMessageAddition)
    : '',
  newCareLineId: action.newCareLineId ? String(action.newCareLineId) : '',
  classification: action.classification,
});

export const mustHaveNewCareQuestion = (
  actionOutcomeType: ActionModel['actionOutcomeType'] | undefined
) => {
  return (
    actionOutcomeType === 'replace_care_question' ||
    actionOutcomeType === 'activate_additional_care_question'
  );
};
