import React from 'react';
import { downloadExcel } from 'react-export-table-to-excel';
import { ButtonContainer, ExportButton } from './styles';

export interface DownloadExcelTable {
  isExportableTable?: boolean;
  fileName?: string;
  sheetName?: string;
  tablePayload?: {
    header: string[];
    body:
      | Array<{
          [key: string]: string | number | boolean;
        }>
      | Array<(string | number | boolean)[]>;
  };
}

interface ExportTableProps extends DownloadExcelTable {
  children: React.ReactNode;
}

const ExportTable: React.FC<ExportTableProps> = ({
  isExportableTable,
  fileName,
  sheetName,
  tablePayload,
  children,
}) => {
  const handleDownloadExcel = () => {
    downloadExcel({
      fileName: fileName || '',
      sheet: sheetName || '',
      tablePayload: {
        header: tablePayload?.header || [],
        body: tablePayload?.body || [],
      },
    });
  };

  return (
    <>
      {!!isExportableTable && (
        <ButtonContainer>
          <ExportButton
            color="primary"
            variant="outlined"
            onClick={handleDownloadExcel}
          >
            Exportar
          </ExportButton>
        </ButtonContainer>
      )}
      {children}
    </>
  );
};

export default ExportTable;
