import React, { useState } from 'react';
import { toast } from 'react-toastify';
import {
  StyledContainer,
  StyledTypography,
  StyledIcon,
  StyledClickContainer,
} from './styles';

import useDump from '../../../hooks/useDump';
import LoadingBackdrop from '../../../components/LoadingBackdrop';

const Downloads: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const { getDatabaseDump } = useDump();

  const handleDownloadClick = async () => {
    if (loading) return;
    try {
      setLoading(true);
      await getDatabaseDump();
    } catch (error) {
      console.error(error);
      toast.error(
        'Erro ao baixar dados do sistema. Entre em contato com o suporte'
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <LoadingBackdrop loading={loading} />}
      <StyledContainer>
        <StyledClickContainer onClick={handleDownloadClick}>
          <StyledIcon />
          <StyledTypography variant="h4" color="primary">
            Download dados do sistema
          </StyledTypography>
        </StyledClickContainer>
      </StyledContainer>
    </>
  );
};

export default Downloads;
