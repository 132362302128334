import { useCallback } from 'react';

import axios from '../config/axios';

const useMedia = () => {
  const getMedia = useCallback(
    (endpoint: string) =>
      axios.request({
        method: 'get',
        url: endpoint,
        data: null,
      }),
    []
  );

  return { getMedia };
};

export default useMedia;
