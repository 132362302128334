import { DialogContent, DialogTitle } from '@material-ui/core';
import React from 'react';
import StyledDialog from './StyledDialog';

export type DialogProps = {
  open: boolean;
  title?: string;
};

const StyledSimpleModal: React.FC<DialogProps> = ({
  open,
  title,
  children,
}) => {
  return (
    <StyledDialog open={open}>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>{children}</DialogContent>
    </StyledDialog>
  );
};

export default StyledSimpleModal;
