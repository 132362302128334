import { EventModel, ReportIndicatorModel } from '@cuidador/database';
import { Checkbox, Typography } from '@material-ui/core';
import formatDate from 'date-fns/format';
import React from 'react';

import StyledFormControlLabel from '../../../components/StyledFormControlLabel';
import { timezoneUnawareDate } from '../../../utils/date';

import {
  ClassificationLabel,
  IndicatorMessage,
  IndicatorTitle,
  IndicatorTitleContainer,
  OptionContainer,
} from '../styles';
import { IndicatorSectionProps } from '../utils';

const ComplicationIndicator: React.FC<
  IndicatorSectionProps<ReportIndicatorModel[]>
> = ({ selectedIndicators, onChange, data = [] }) => {
  const renderOptionLabel = (event: EventModel) => (
    <IndicatorTitle>
      <ClassificationLabel $classification={'bad'} />
      {event!.subCategory!.name}
      {' | em '}
      {formatDate(timezoneUnawareDate(event.eventHappensAt!), 'dd/MM/yyyy')}
      {' | Reportado por: '}
      <IndicatorMessage>{event?.caregiver?.user?.name}</IndicatorMessage>
    </IndicatorTitle>
  );

  const renderEmpty = () => (
    <IndicatorTitle>
      <ClassificationLabel $classification={'good'} />
      <IndicatorMessage>
        {'Não houve intercorrência no período avaliado.'}
      </IndicatorMessage>
    </IndicatorTitle>
  );

  return (
    <div data-testid="complicationIndicatorSection">
      <IndicatorTitleContainer>
        <Typography variant="h6">Intercorrências</Typography>
      </IndicatorTitleContainer>

      {data.length
        ? data.map(({ id, reportComplication }) => (
            <OptionContainer key={id}>
              <StyledFormControlLabel
                value={id}
                name={String(id)}
                data-testid={id}
                control={
                  <Checkbox
                    color="primary"
                    checked={selectedIndicators.includes(id!)}
                    onChange={() => onChange(id!)}
                  />
                }
                label={renderOptionLabel(reportComplication!.event!)}
              />
            </OptionContainer>
          ))
        : renderEmpty()}
    </div>
  );
};

export default React.memo(ComplicationIndicator);
