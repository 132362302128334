import React, { useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Background, Container } from './styles';
import { TableCell } from '@material-ui/core';
import qs from 'query-string';
import { useHistory } from 'react-router-dom';
import { MedicationModel } from '@cuidador/database';
import useMedication from '../../../hooks/useMedication';
import MyTable from '../../../components/MyTable';
import formatDate from 'date-fns/format';
import { dosesNames, frequencyOptions } from './utils';

const tableColumns = [
  'Medicação',
  'Início',
  'Fim',
  'Quantidade',
  'Frequência',
  'Recomendações médicas',
  'Uso se necessário',
];

const MedicationList: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  const { getAllByPatientId, ids, byId, loading } = useMedication();
  const history = useHistory();

  useEffect(() => {
    onChangeParams();
  }, []);

  const onChangeParams = () => {
    const params = qs.parse(history.location.search);
    getAllByPatientId(Number(id), {
      ...params,
    });
  };

  const renderMedicationItem = useCallback((item: MedicationModel) => {
    const doseFormat = item?.dosageFormat;
    const doseFormatFormated =
      item?.dosageQuantity == 1
        ? dosesNames[String(doseFormat)]?.nameSingular
        : dosesNames[String(doseFormat)]?.namePlural;
    const frequencyRule = item?.eventSchedule?.frequencyRule;
    const frequencyRuleFormated =
      frequencyOptions[String(frequencyRule)]?.name || '';
    const ifNecessaryFormatted = item.ifNecessary ? 'Sim' : 'Não';

    return (
      <>
        <TableCell>{item.subCategory?.name}</TableCell>
        <TableCell>
          {item.eventSchedule?.frequencyStartsAt &&
            formatDate(
              new Date(item.eventSchedule?.frequencyStartsAt),
              'dd/MM/yyyy'
            )}
        </TableCell>
        <TableCell>
          {item.eventSchedule?.frequencyEndsAt
            ? formatDate(
                new Date(item.eventSchedule?.frequencyEndsAt),
                'dd/MM/yyyy'
              )
            : 'Contínuo'}
        </TableCell>
        <TableCell>
          {item.dosageQuantity} {doseFormatFormated}
        </TableCell>
        <TableCell>{frequencyRuleFormated}</TableCell>
        <TableCell>{item.medicalNotes}</TableCell>
        <TableCell>{ifNecessaryFormatted}</TableCell>
      </>
    );
  }, []);

  const data = ids.map((id) => byId[id]);

  return (
    data && (
      <Background>
        <Container>
          <MyTable
            columnNames={tableColumns}
            data={data}
            keyExtractor={({ item }) => String(item!.id)}
            renderItem={renderMedicationItem}
            loading={loading}
            hasPagination={false}
          />
        </Container>
      </Background>
    )
  );
};

export default MedicationList;
