import React, { useState, useEffect } from 'react';
import {
  QuestionTypeContainer,
  CareLineDiv,
  FooterContainer,
  ActionOutcomeTypeContainer,
  EmailContainer,
} from './styles';
import {
  StaticCareLineContainer,
  StaticCareLineTextBox,
  StaticClassificationTextBox,
} from '../styles';
import {
  InputAdornment,
  RadioGroup,
  Radio,
  Typography,
  FormHelperText,
  FormGroup,
  Checkbox,
} from '@material-ui/core';
import AutocompleteTextField, {
  OnChangeValue as AutoCompleteOnChangeValue,
} from '../../../components/AutocompleteTextField';
import { Formik, Form, FormikHelpers } from 'formik';
import { resolveErrorMessage } from '../../../utils/error';
import { toast } from 'react-toastify';
import FormikTextField from '../../../components/Forms/FormikTextField';
import StyledButton from '../../../components/StyledButton';
import StyledSelectField from '../../../components/Forms/FormikSelect';
import StyledMenuItem from '../../../components/StyledMenuItem';
import StyledFormControl from '../../../components/StyledFormControl';
import { ActionModel } from '@cuidador/database';
import StyledFormControlLabel from '../../../components/StyledFormControlLabel';
import {
  validationSchema,
  FormValues,
  formInitialValue,
  formDataToActionModel,
  actionCareQuestionModelToFormData,
  formatClassification,
  mustHaveNewCareQuestion,
  optionClassifications,
} from './utils';
import useCareQuestion from '../../../hooks/useCareQuestion';
import useCareLine from '../../../hooks/useCareLine';
import { CareQuestionModel } from '@cuidador/database';
import { numberMask } from '../../../utils/inputs';
import useContent from '../../../hooks/useContent';

const ActionCareQuestionForm: React.FC<{
  initialValues?: ActionModel;
  onSubmit: (action: ActionModel) => void | Promise<void>;
  disableDefaultIsSubmittingHandle?: boolean;
}> = ({ initialValues, onSubmit, disableDefaultIsSubmittingHandle }) => {
  const [optionPaths, setOptionPaths] = useState<
    CareQuestionModel['optionPaths'] | undefined
  >();
  const { getPaginated: getCareQuestionPaginated, getById } = useCareQuestion();
  const { getPaginated: getCareLinePaginated } = useCareLine();
  const { getPaginated: getContentPaginated } = useContent();

  const selectedMainCareQuestionOptionPath = async (careQuestionId: Id) => {
    try {
      const careQuestion = (await getById(careQuestionId)) as CareQuestionModel;
      setOptionPaths(careQuestion.optionPaths);
    } catch (err) {
      const displayMessage = resolveErrorMessage(err);
      toast.error(displayMessage);
    }
  };

  const initialValuesForm = initialValues
    ? actionCareQuestionModelToFormData(initialValues)
    : formInitialValue;

  useEffect(() => {
    if (initialValues)
      selectedMainCareQuestionOptionPath(initialValues.careQuestionId!);
  }, []);

  const mainCareQuestionAutoCompleteInitialValue = initialValuesForm.careQuestionId
    ? {
        text: `${
          initialValues?.careQuestion?.careLine?.name
        } - ${formatClassification(
          initialValues?.careQuestion?.classification
        )}`,
        value: Number(initialValuesForm.careQuestionId),
      }
    : undefined;

  const newCareQuestionAutoCompleteInitialValue = initialValuesForm.newCareQuestionId
    ? {
        text: `${
          initialValues?.newCareQuestion?.careLine?.name
        } - ${formatClassification(
          initialValues?.newCareQuestion?.classification
        )}`,
        value: Number(initialValuesForm.newCareQuestionId),
      }
    : undefined;

  const deactivatedCareLineAutoCompleteInitialValue = initialValuesForm.deactivatedCareLineId
    ? {
        text: `${initialValues?.deactivatedCareLine?.name}`,
        value: Number(initialValuesForm.deactivatedCareLineId),
      }
    : undefined;

  const newCareLineAutoCompleteInitialValue = initialValuesForm.newCareLineId
    ? {
        text: `${initialValues?.newCareLine?.name}`,
        value: Number(initialValuesForm.newCareLineId),
      }
    : undefined;

  const contentAutoCompleteInitialValue = initialValuesForm.contentId
    ? {
        text: `${initialValues?.content?.title}`,
        value: Number(initialValuesForm.contentId),
      }
    : undefined;

  const handleCareQuestionAutoCompleteOnChange = async (
    textValue: string
  ): Promise<AutoCompleteOnChangeValue[]> => {
    const params = {
      type: 'shift_question' as CareQuestionModel['type'],
      'careLine.name': `%${textValue}%`,
    };
    const data = await getCareQuestionPaginated({ ...params, limit: 20 });
    return data?.map((careQuestion) => ({
      text: `${careQuestion?.careLine?.name} - ${formatClassification(
        careQuestion?.classification
      )}`,
      value: careQuestion?.id,
    })) as AutoCompleteOnChangeValue[];
  };

  const handleCareLineAutoCompleteOnChange = async (
    textValue: string
  ): Promise<AutoCompleteOnChangeValue[]> => {
    const params = { name: `%${textValue}%` };
    const data = await getCareLinePaginated({ ...params, limit: 20 });
    return data?.map((careLine) => ({
      text: careLine.name,
      value: careLine.id,
    })) as AutoCompleteOnChangeValue[];
  };

  const handleContentAutoCompleteOnChange = async (
    textValue: string
  ): Promise<AutoCompleteOnChangeValue[]> => {
    const params = { title: `%${textValue}%` };
    const data = await getContentPaginated({ ...params, limit: 20 });
    return data?.map((content) => ({
      text: content.title,
      value: content.id,
    })) as AutoCompleteOnChangeValue[];
  };

  const handleMainCareQuestionListItemClick = (
    item: AutoCompleteOnChangeValue,
    setFieldValue: (field: string, value: string) => void
  ) => {
    // set Formik field value outside form, because it is our autocomplete component
    selectedMainCareQuestionOptionPath(item.value);
    setFieldValue('careQuestionId', String(item.value));
  };

  const handleNewCareQuestionListItemClick = (
    item: AutoCompleteOnChangeValue,
    setFieldValue: (field: string, value: string) => void
  ) => {
    // set Formik field value outside form, because it is our autocomplete component
    setFieldValue('newCareQuestionId', String(item.value));
  };

  const handleDeactivatedCareLineListItemClick = (
    item: AutoCompleteOnChangeValue,
    setFieldValue: (field: string, value: string) => void
  ) => {
    // set Formik field value outside form, because it is our autocomplete component
    setFieldValue('deactivatedCareLineId', String(item.value));
  };

  const handleNewCareLineListItemClick = (
    item: AutoCompleteOnChangeValue,
    setFieldValue: (field: string, value: string) => void
  ) => {
    // set Formik field value outside form, because it is our autocomplete component
    setFieldValue('newCareLineId', String(item.value));
  };

  const handleContentListItemClick = (
    item: AutoCompleteOnChangeValue,
    setFieldValue: (field: string, value: string) => void
  ) => {
    // set Formik field value outside form, because it is our autocomplete component
    setFieldValue('contentId', String(item.value));
  };

  const handleSubmit = (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    const data = formDataToActionModel({
      ...values,
    });
    if (disableDefaultIsSubmittingHandle) setSubmitting(false);
    return onSubmit(data as ActionModel);
  };
  return (
    <>
      <Formik
        initialValues={initialValuesForm as FormValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnChange={false}
      >
        {(formik) => (
          <Form noValidate={true}>
            <QuestionTypeContainer>
              <CareLineDiv>
                <AutocompleteTextField
                  onListValueClick={(item: AutoCompleteOnChangeValue) => {
                    handleMainCareQuestionListItemClick(
                      item,
                      formik.setFieldValue
                    );
                  }}
                  onChangeDebounced={handleCareQuestionAutoCompleteOnChange}
                  name="careQuestionId"
                  label="Linha de cuidado - Classificação"
                  initialValue={mainCareQuestionAutoCompleteInitialValue}
                />
              </CareLineDiv>
              <FormikTextField
                name="triggerThreshold"
                label="Gatilho"
                margin="normal"
                value={numberMask(formik.values.triggerThreshold)}
                inputProps={{
                  'data-testid': 'triggerThreshold',
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      respostas consecutivas
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{ shrink: true }}
              />
            </QuestionTypeContainer>
            <StyledSelectField
              name="careQuestionOptionId"
              label="Resposta associada"
              value={formik.values.careQuestionOptionId || ''}
              SelectDisplayProps={{
                'data-testid': 'optionPath',
              }}
              color="secondary"
            >
              {optionPaths?.map((item) => (
                <StyledMenuItem
                  key={item.optionId}
                  value={item.optionId}
                  color="secondary"
                >
                  {item.pathValue}
                </StyledMenuItem>
              ))}
            </StyledSelectField>
            <ActionOutcomeTypeContainer>
              <Typography variant="h5">Ação gerada</Typography>
              <RadioGroup
                aria-label="actionOutcomeType"
                data-test-id="actionOutcomeType-radio"
                name="actionOutcomeType"
                value={formik.values.actionOutcomeType}
              >
                <StyledFormControlLabel
                  value="replace_care_question"
                  control={<Radio color="primary" />}
                  onChange={() =>
                    formik.setFieldValue(
                      'actionOutcomeType',
                      'replace_care_question'
                    )
                  }
                  label="Substituição de classificação"
                />
                <StyledFormControlLabel
                  value="deactivate_care_line"
                  control={<Radio color="primary" />}
                  onChange={() =>
                    formik.setFieldValue(
                      'actionOutcomeType',
                      'deactivate_care_line'
                    )
                  }
                  label="Desativação de linha de cuidado"
                />
                <StyledFormControlLabel
                  value="activate_additional_care_question"
                  control={<Radio color="primary" />}
                  onChange={() =>
                    formik.setFieldValue(
                      'actionOutcomeType',
                      'activate_additional_care_question'
                    )
                  }
                  label="Ativação de classificação complementar"
                />
                <StyledFormControlLabel
                  value="activate_static_care_line"
                  control={<Radio color="primary" />}
                  onChange={() =>
                    formik.setFieldValue(
                      'actionOutcomeType',
                      'activate_static_care_line'
                    )
                  }
                  label="Ativação de classificação estática"
                />
                {formik.errors.actionOutcomeType && (
                  <FormHelperText error={true}>
                    {formik.errors.actionOutcomeType}
                  </FormHelperText>
                )}
              </RadioGroup>
            </ActionOutcomeTypeContainer>
            {mustHaveNewCareQuestion(formik.values.actionOutcomeType) && (
              <AutocompleteTextField
                onListValueClick={(item: AutoCompleteOnChangeValue) => {
                  handleNewCareQuestionListItemClick(
                    item,
                    formik.setFieldValue
                  );
                }}
                onChangeDebounced={handleCareQuestionAutoCompleteOnChange}
                name="newCareQuestionId"
                label="Linha de cuidado - Classificação"
                initialValue={newCareQuestionAutoCompleteInitialValue}
                testId="newCareQuestionAutocomplete"
              />
            )}
            {formik.values.actionOutcomeType == 'deactivate_care_line' && (
              <AutocompleteTextField
                onListValueClick={(item: AutoCompleteOnChangeValue) => {
                  handleDeactivatedCareLineListItemClick(
                    item,
                    formik.setFieldValue
                  );
                }}
                onChangeDebounced={handleCareLineAutoCompleteOnChange}
                name="deactivatedCareLineId"
                label="Linha de cuidado"
                testId="deactivatedCareLineAutocomplete"
                initialValue={deactivatedCareLineAutoCompleteInitialValue}
              />
            )}
            {formik.values.actionOutcomeType == 'activate_static_care_line' && (
              <StaticCareLineContainer>
                <StaticCareLineTextBox>
                  <AutocompleteTextField
                    onListValueClick={(item: AutoCompleteOnChangeValue) => {
                      handleNewCareLineListItemClick(
                        item,
                        formik.setFieldValue
                      );
                    }}
                    onChangeDebounced={handleCareLineAutoCompleteOnChange}
                    name="newCareLineId"
                    label="Linha de cuidado"
                    testId="newCareLineAutocomplete"
                    initialValue={newCareLineAutoCompleteInitialValue}
                  />
                </StaticCareLineTextBox>
                <StaticClassificationTextBox>
                  <StyledSelectField
                    name="classification"
                    label="Classificação"
                    value={formik.values.classification || ''}
                    SelectDisplayProps={{
                      'data-testid': 'classification',
                    }}
                    color="secondary"
                  >
                    {optionClassifications?.map((item) => (
                      <StyledMenuItem
                        key={item.id}
                        value={item.id}
                        color="secondary"
                      >
                        {item.type}
                      </StyledMenuItem>
                    ))}
                  </StyledSelectField>
                </StaticClassificationTextBox>
              </StaticCareLineContainer>
            )}
            <EmailContainer>
              <StyledFormControl fullWidth>
                <FormGroup aria-label="position">
                  <StyledFormControlLabel
                    value="sendEmailGuardian"
                    name="sendEmailGuardian"
                    control={
                      <Checkbox
                        color="primary"
                        checked={formik.values.sendEmailGuardian}
                        onChange={(e) =>
                          formik.setFieldValue(
                            'sendEmailGuardian',
                            e.target.checked
                          )
                        }
                      />
                    }
                    label="Enviar e-mail para a família"
                  />
                  {formik.values.sendEmailGuardian && (
                    <FormikTextField
                      name="emailMessageAddition"
                      label="Texto personalizado do e-mail"
                      margin="normal"
                      value={formik.values.emailMessageAddition}
                      inputProps={{
                        'data-testid': 'emailMessageAddition',
                      }}
                    />
                  )}
                  <StyledFormControlLabel
                    value="hasRecommendedContent"
                    name="hasRecommendedContent"
                    control={
                      <Checkbox
                        color="primary"
                        checked={formik.values.hasRecommendedContent}
                        onChange={(e) => {
                          formik.setFieldValue(
                            'hasRecommendedContent',
                            e.target.checked
                          );
                        }}
                      />
                    }
                    label="Configurar conteúdo recomendo para cuidador"
                  />
                </FormGroup>
              </StyledFormControl>
              {formik.values.hasRecommendedContent && (
                <AutocompleteTextField
                  onListValueClick={(item: AutoCompleteOnChangeValue) => {
                    handleContentListItemClick(item, formik.setFieldValue);
                  }}
                  onChangeDebounced={handleContentAutoCompleteOnChange}
                  name="contentId"
                  label="Conteúdo recomendado"
                  initialValue={contentAutoCompleteInitialValue}
                  testId="contentAutocomplete"
                />
              )}
            </EmailContainer>
            <FooterContainer>
              <StyledButton
                color="inherit"
                data-testid="submit"
                type="submit"
                disabled={formik.isSubmitting}
              >
                Salvar
              </StyledButton>
            </FooterContainer>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ActionCareQuestionForm;
