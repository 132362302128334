import { EventSubCategoryModel } from '@cuidador/database';
import {
  Checkbox,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormGroup,
  FormHelperText,
  Typography,
} from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { Formik } from 'formik';
import React from 'react';
import StyledButton from '../../../../components/StyledButton';
import StyledDialog from '../../../../components/StyledDialog';
import StyledFormControl from '../../../../components/StyledFormControl';
import StyledFormControlLabel from '../../../../components/StyledFormControlLabel';
import StyledTextFieldWhite from '../../../../components/StyledTextFieldWhite';
import { FormValues, validationSchema } from '../utils';
import { OptionsContainer, StyledForm } from './styles';

interface SubCategoryDialogProps extends DialogProps {
  subCategory?: EventSubCategoryModel;
  onConfirm: (values: FormValues) => void;
  onCancel: () => void;
}

const SubCategoryDialog: React.FC<SubCategoryDialogProps> = ({
  subCategory,
  onCancel,
  onConfirm,
  ...rest
}) => {
  const formInitialValue = {
    name: subCategory?.name || '',
    category: subCategory?.categoryId ? String(subCategory?.categoryId) : '',
    routine: subCategory?.isRoutine || false,
    appointment: subCategory?.isAppointment || false,
  };

  const isEdit = subCategory ? true : false;

  return (
    <StyledDialog {...rest}>
      <DialogContent>
        <DialogTitle>{isEdit ? 'Editar' : 'Adicionar'} tipo</DialogTitle>

        <Formik
          validationSchema={validationSchema}
          initialValues={formInitialValue as FormValues}
          onSubmit={onConfirm}
          validateOnChange={false}
        >
          {(formik) => (
            <StyledForm noValidate={true}>
              <DialogContentText>
                <OptionsContainer>
                  <StyledFormControl>
                    <Typography variant="h6">Categoria</Typography>
                    <RadioGroup
                      aria-label="category"
                      data-test-id="category-radio"
                      name="category"
                      value={formik.values.category}
                    >
                      <StyledFormControlLabel
                        value="1"
                        disabled={isEdit}
                        control={<Radio color="primary" />}
                        onChange={() => formik.setFieldValue('category', '1')}
                        label="Geral"
                      />
                      <StyledFormControlLabel
                        value="2"
                        disabled={isEdit}
                        control={<Radio color="primary" />}
                        onChange={() => formik.setFieldValue('category', '2')}
                        label="Medicação"
                      />
                      <StyledFormControlLabel
                        value="3"
                        disabled={isEdit}
                        control={<Radio color="primary" />}
                        onChange={() => formik.setFieldValue('category', '3')}
                        label="Intercorrência"
                      />
                      <StyledFormControlLabel
                        value="4"
                        disabled={isEdit}
                        control={<Radio color="primary" />}
                        onChange={() => formik.setFieldValue('category', '4')}
                        label="Controle"
                      />
                      {formik.errors.category && (
                        <FormHelperText error={true}>
                          {formik.errors.category}
                        </FormHelperText>
                      )}
                    </RadioGroup>
                  </StyledFormControl>
                  {formik.values.category === '1' && (
                    <StyledFormControl>
                      <Typography variant="h6">Lista</Typography>
                      <FormGroup aria-label="position">
                        <StyledFormControlLabel
                          value="appointment"
                          name="appointment"
                          control={
                            <Checkbox
                              color="primary"
                              checked={formik.values.appointment}
                              onChange={(e) =>
                                formik.setFieldValue(
                                  'appointment',
                                  e.target.checked
                                )
                              }
                            />
                          }
                          label="Compromisso"
                        />
                        <StyledFormControlLabel
                          value="routine"
                          name="routine"
                          control={
                            <Checkbox
                              color="primary"
                              checked={formik.values.routine}
                              onChange={(e) =>
                                formik.setFieldValue(
                                  'routine',
                                  e.target.checked
                                )
                              }
                            />
                          }
                          label="Rotina"
                        />
                      </FormGroup>
                      {formik.values.appointment === false &&
                        formik.values.routine === false && (
                          <FormHelperText error={true}>
                            {formik.errors.routine}
                          </FormHelperText>
                        )}
                    </StyledFormControl>
                  )}
                </OptionsContainer>
                <StyledTextFieldWhite
                  name="name"
                  label="Nome do tipo"
                  inputProps={{ 'data-testid': 'name-textbox' }}
                  value={formik.values.name}
                  error={Boolean(formik.errors.name)}
                  onChange={formik.handleChange}
                  helperText={formik.errors.name}
                  margin="normal"
                />
              </DialogContentText>
              <DialogActions>
                <StyledButton
                  disabled={formik.isSubmitting}
                  onClick={onCancel}
                  color="inherit"
                >
                  Cancelar
                </StyledButton>
                <StyledButton
                  disabled={formik.isSubmitting}
                  type="submit"
                  data-testid="save-event-button"
                  variant="text"
                  color="inherit"
                >
                  Salvar
                </StyledButton>
              </DialogActions>
            </StyledForm>
          )}
        </Formik>
      </DialogContent>
    </StyledDialog>
  );
};

export default SubCategoryDialog;
