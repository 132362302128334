import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import StyledTextField from '../../components/StyledTextField';

export const QuestionsWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing(3)}px;
  margin-bottom: ${({ theme }) => theme.spacing(3)}px;
`;

export const QuestionContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing(3)}px;
`;

export const QuestionTitle = styled(Typography)``;

export const OptionsWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing(1)}px;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
`;

export const OptionDescriptionContainer = styled.div``;

export const StyledOptionDescriptionTextField = styled(StyledTextField).attrs({
  label: 'Observações',
  fullWidth: true,
  multiline: true,
  inputProps: {
    maxLength: 200,
    'data-testid': 'interviewOptionText',
  },
})``;
