import React from 'react';
import { useHistory } from 'react-router-dom';
import { Container } from './styles';
import { Typography } from '@material-ui/core';
import { resolveErrorMessage } from '../../../../utils/error';
import { AxiosError } from 'axios';
import { APIError } from '@cuidador/lib';
import { toast } from 'react-toastify';
import { formInitialValue } from '../../../../components/InterviewQuestionUpsertForm/utils';
import { CareQuestionModel } from '@cuidador/database';
import useCareQuestion from '../../../../hooks/useCareQuestion';
import HeaderBase from '../../../../components/HeaderBase';
import InterviewUpsertForm from '../../../../components/InterviewQuestionUpsertForm';

const InterviewInsert: React.FC = () => {
  const { post } = useCareQuestion();
  const history = useHistory();

  const handleSubmit = (data: CareQuestionModel) => {
    return post(data)
      .then(() => {
        history.goBack();
      })
      .catch((err: AxiosError<APIError>) => {
        const displayMessage = resolveErrorMessage(err);
        toast.error(displayMessage);
      });
  };

  return (
    <>
      <HeaderBase title={'ENTREVISTA'} />
      <Container>
        <Typography variant="h5">Entrevista</Typography>
        <InterviewUpsertForm
          initialValues={formInitialValue}
          onSubmit={handleSubmit}
        />
      </Container>
    </>
  );
};

export default InterviewInsert;
