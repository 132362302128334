import React, { useEffect, useState } from 'react';
import {
  Container,
  AddButton,
  Header,
  EditIconButton,
  DeleteIconButton,
  TextWrap,
} from './styles';

import useContent from '../../hooks/useContent';
import qs from 'query-string';
import { useHistory } from 'react-router-dom';
import { ContentModel } from '@cuidador/database';
import { resolveErrorMessage } from '../../utils/error';
import { AxiosError } from 'axios';
import { APIError } from '@cuidador/lib';
import { toast } from 'react-toastify';

import AddIcon from '@material-ui/icons/AddCircleOutlineRounded';
import DeleteIcon from '@material-ui/icons/Delete';
import { ReactComponent as EditIcon } from '../../assets/Icon-edit.svg';
import { TableCell, Typography } from '@material-ui/core';
import MyTable from '../../components/MyTable';
import SearchTextField from '../../components/SearchTextField';
import StyledSimpleDialog from '../../components/StyledSimpleDialog';

const tableColumns = ['Título', 'Texto', ''];

const Content: React.FC = () => {
  const [dialogStatus, setDialogStatus] = useState(false);
  const [contentItem, setcontentItem] = React.useState<ContentModel>();

  const { getPaginated, loading, ids, byId, total, remove } = useContent();
  const history = useHistory();
  const limit = parseInt(String(qs.parse(history.location.search).limit)) || 20;

  useEffect(() => {
    onChangeParams();
  }, []);

  const onChangeParams = () => {
    const params = qs.parse(history.location.search);
    getPaginated({ ...params, limit: Number(params.limit) || 20 });
  };

  const data = ids.map((id) => byId[id]);

  const handleDelete = (id: Id) => {
    setDialogStatus(false);
    remove(id)
      .then(() => {
        return onChangeParams();
      })
      .catch((err: AxiosError<APIError>) => {
        const displayMessage = resolveErrorMessage(err);
        toast.error(displayMessage);
      });
  };

  const renderContentItem = (item: ContentModel) => {
    return (
      <>
        <TableCell>
          <TextWrap>{item.title}</TextWrap>
        </TableCell>
        <TableCell>
          <TextWrap>{item.text}</TextWrap>
        </TableCell>
        <TableCell align="right">
          <EditIconButton onClick={() => history.push(`/conteudo/${item.id}`)}>
            <EditIcon data-testid="edit-dialog-open" />
          </EditIconButton>
          <DeleteIconButton
            onClick={() => {
              setDialogStatus(true);
              setcontentItem(item);
            }}
          >
            <DeleteIcon data-testid="delete-dialog-open" />
          </DeleteIconButton>
        </TableCell>
      </>
    );
  };

  return (
    <Container>
      <Header>
        <AddButton
          data-testid="create-content-dialog-open"
          onClick={() => history.push('/conteudo/novo')}
        >
          <AddIcon fontSize="large" color="primary" />
          <Typography variant="h5">Adicionar</Typography>
        </AddButton>
        <SearchTextField fieldName="title" onChangeDebounced={onChangeParams} />
      </Header>
      <MyTable
        columnNames={tableColumns}
        data={data}
        totalPages={Math.ceil(total / limit)}
        hasPagination={true}
        keyExtractor={({ item }) => String(item!.id)}
        renderItem={renderContentItem}
        onChangePage={onChangeParams}
        onChangeRowsPerPage={onChangeParams}
        loading={loading}
      />
      <StyledSimpleDialog
        open={dialogStatus}
        handleNo={() => setDialogStatus(false)}
        handleYes={() => handleDelete(contentItem?.id ? contentItem.id : 0)}
        title={`Tem certeza que deseja excluir o conteúdo ${contentItem?.title}?`}
        subTitle="Você não poderá recuperá-lo depois."
      />
    </Container>
  );
};

export default Content;
