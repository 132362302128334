import styled from 'styled-components';
import { Container } from '@material-ui/core';
import StyledTextField from '../../../../components/StyledTextField';

export const StyledContainer = styled(Container)`
  margin-top: ${({ theme }) => theme.spacing(3)}px;
  margin-bottom: ${({ theme }) => theme.spacing(3)}px;
`;

export const NavigationButtonsContainer = styled.div`
  text-align: right;
`;

export const ButtonContainer = styled.span`
  margin: ${({ theme }) => theme.spacing(1)}px;
`;

export const InterviewConfigContainer = styled.div`
  margin: ${({ theme }) => theme.spacing(3, 0)};
`;

export const InterviewConfigForm = styled.div`
  margin: ${({ theme }) => theme.spacing(3, 0)};
  display: flex;
`;

export const StyledInterviewNameTextField = styled(StyledTextField)`
  margin-right: ${({ theme }) => theme.spacing(3)}px;
  flex: 0.5;
`;
