import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { resolveErrorMessage } from '../../../utils/error';
import { AxiosError } from 'axios';
import { APIError } from '@cuidador/lib';
import { toast } from 'react-toastify';
import AddIcon from '@material-ui/icons/AddCircleOutlineRounded';
import DeleteIcon from '@material-ui/icons/Delete';
import { ReactComponent as EditIcon } from '../../../assets/Icon-edit.svg';
import { TableCell, Typography } from '@material-ui/core';
import MyTable from '../../../components/MyTable';
import qs from 'query-string';
import useAction from '../../../hooks/useAction';
import { ActionModel } from '@cuidador/database';
import {
  Container,
  AddButton,
  Header,
  EditIconButton,
  DeleteIconButton,
  IconContainer,
} from './styles';
import {
  formatClassification,
  formatType,
  formatActionOutcomeType,
  formatDeletionActionText,
  formatSendEmailColumn,
} from './utils';
import SearchTextField from '../../../components/SearchTextField';
import StyledSimpleDialog from '../../../components/StyledSimpleDialog';

const tableColumns = ['Tipo', 'Plano de cuidado', 'Ação', 'Comunicações', ''];

const Action: React.FC = () => {
  const [deleteDialogStatus, setDeleteDialogStatus] = useState(false);
  const [
    actionBeingDeleted,
    setActionBeingDeleted,
  ] = React.useState<ActionModel>();
  const { getPaginated, ids, byId, total, loading, remove } = useAction();
  const history = useHistory();

  const limit = parseInt(String(qs.parse(history.location.search).limit)) || 20;

  useEffect(() => {
    onChangeParams();
  }, []);

  const onChangeParams = () => {
    const params = qs.parse(history.location.search);
    getPaginated({ ...params, limit: Number(params.limit) || 20 });
  };

  const data = ids.map((id) => byId[id]);

  const deleteAction = (id: Id) => {
    remove(id)
      .then(() => {
        // Forces table refresh
        return onChangeParams();
      })
      .catch((err: AxiosError<APIError>) => {
        const displayMessage = resolveErrorMessage(err);
        toast.error(displayMessage);
      })
      .finally(() => {
        setDeleteDialogStatus(false);
      });
  };

  const openInsertActionPage = () => {
    history.push('/cuidados/acoes/adicionar');
  };

  const openUpdateActionPage = (id: Id) => {
    history.push(`/cuidados/acoes/${id}`);
  };

  const renderActionItem = (item: ActionModel) => {
    return (
      <>
        <TableCell>{formatType(item?.type)}</TableCell>
        {item?.type === 'shift_question' ? (
          <TableCell>
            {item?.careQuestion?.careLine?.name} -{' '}
            {formatClassification(item?.careQuestion?.classification)}
          </TableCell>
        ) : item?.type === 'interview_question' ? (
          <TableCell>
            {item?.careQuestion?.careCategory?.name} -{' '}
            {item?.careQuestion?.name}
          </TableCell>
        ) : (
          <TableCell>{item?.eventSubcategory?.name}</TableCell>
        )}
        <TableCell>
          {formatActionOutcomeType(item?.actionOutcomeType)}
        </TableCell>
        <TableCell>
          {formatSendEmailColumn(
            item?.contentId,
            item?.sendEmailGuardian,
            item?.sendEmailDoctor
          )}
        </TableCell>
        <TableCell align="right">
          <IconContainer>
            <EditIconButton>
              <EditIcon
                onClick={() => openUpdateActionPage(Number(item.id))}
                data-testid="edit-dialog-open"
              />
            </EditIconButton>
            <DeleteIconButton>
              <DeleteIcon
                onClick={() => {
                  setActionBeingDeleted(item);
                  setDeleteDialogStatus(true);
                }}
                data-testid="delete-dialog-open"
              />
            </DeleteIconButton>
          </IconContainer>
        </TableCell>
      </>
    );
  };

  return (
    <Container>
      <Header>
        <AddButton
          data-testid="create-action-dialog-open"
          onClick={openInsertActionPage}
        >
          <AddIcon fontSize="large" />
          <Typography variant="h5">Adicionar</Typography>
        </AddButton>
        <SearchTextField
          // custom queryString field for filtering either care question, care line or event subcategory name
          fieldName="careQuestionOrLineOrEventSubcategoryName"
          onChangeDebounced={onChangeParams}
        />
      </Header>
      <MyTable
        columnNames={tableColumns}
        data={data}
        totalPages={Math.ceil(total / limit)}
        keyExtractor={({ item }) => String(item!.id)}
        renderItem={renderActionItem}
        onChangePage={onChangeParams}
        onChangeRowsPerPage={onChangeParams}
        loading={loading}
      />
      <StyledSimpleDialog
        open={deleteDialogStatus}
        handleNo={() => setDeleteDialogStatus(false)}
        handleYes={() =>
          deleteAction(actionBeingDeleted?.id ? actionBeingDeleted.id : 0)
        }
        title={`Tem certeza que deseja excluir a ação da pergunta de cuidado ${formatDeletionActionText(
          actionBeingDeleted!
        )}?`}
      />
    </Container>
  );
};

export default Action;
