import * as Yup from 'yup';

export const validationSchema = Yup.object().shape(
  {
    title: Yup.string()
      .max(50, 'Máximo de 50 caracteres')
      .required('Por favor, informe um título'),
    description: Yup.string()
      .max(500, 'Máximo de 500 caracteres')
      .required('Por favor, informe uma descrição'),
    permissions: Yup.array(),
    isVisible: Yup.boolean(),
    isAutoPatientRelateEnabled: Yup.boolean(),
    guardianAppAccess: Yup.boolean().when(
      ['caregiverAppAccess', 'adminAppAccess'],
      {
        is: (caregiverAppAccess: boolean, adminAppAccess: boolean) =>
          !caregiverAppAccess && !adminAppAccess,
        then: Yup.boolean().oneOf([true]),
      }
    ),
    caregiverAppAccess: Yup.boolean().when(
      ['guardianAppAccess', 'adminAppAccess'],
      {
        is: (guardianAppAccess: boolean, adminAppAccess: boolean) =>
          !guardianAppAccess && !adminAppAccess,
        then: Yup.boolean().oneOf([true]),
      }
    ),
    adminAppAccess: Yup.boolean().when(
      ['guardianAppAccess', 'caregiverAppAccess'],
      {
        is: (guardianAppAccess: boolean, caregiverAppAccess: boolean) =>
          !guardianAppAccess && !caregiverAppAccess,
        then: Yup.boolean().oneOf([true]),
      }
    ),
  },
  [
    ['guardianAppAccess', 'caregiverAppAccess'],
    ['guardianAppAccess', 'adminAppAccess'],
    ['caregiverAppAccess', 'adminAppAccess'],
  ]
);

export interface FormValues {
  title?: string;
  description?: string;
  permissions: Array<{ id: number }>;
  isVisible?: boolean;
  isAutoPatientRelateEnabled?: boolean;
  guardianAppAccess?: boolean;
  caregiverAppAccess?: boolean;
  adminAppAccess?: boolean;
}
