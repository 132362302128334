import { AnswerConfig } from '@cuidador/database';
import React, { Fragment, useEffect, useState } from 'react';
import { v4 as generateUUID } from 'uuid';
import AnswerConfigItem, { AnswerConfigRenderProps } from './AnswerConfigItem';
import { Container, UnfoldContainer } from './styles';
import { mountAnswerConfig, mountAnswerRender } from './utils';

const mainAnswerConfig: AnswerConfigRenderProps = {
  level: 0,
  options: [],
  type: '',
  optionId: undefined,
};

interface Props {
  interview?: boolean;
  onChange: (answerConfigDb: AnswerConfig) => void;
  initialData?: AnswerConfig;
  questionText?: string;
}

const AnswerConfigList: React.FC<Props> = ({
  onChange,
  initialData,
  interview,
  questionText,
}) => {
  const [answerConfigRenderList, setAnswerConfigRenderList] = useState<
    AnswerConfigRenderProps[]
  >([mainAnswerConfig]);

  useEffect(() => {
    if (initialData) {
      const mappedAnswerConfigRenderList = mountAnswerRender(initialData);
      setAnswerConfigRenderList(mappedAnswerConfigRenderList);
      onChange(initialData);
    }
  }, []);

  const handleConfigItemChange = (
    changedAnswerConfig: AnswerConfigRenderProps
  ) => {
    let indexToChange: number;
    if (changedAnswerConfig.level === 0) {
      indexToChange = 0;
    } else {
      // if level > 0 the answerConfig must have an optionId
      const foundAnswer = answerConfigRenderList.find(
        (answer) =>
          answer.level === changedAnswerConfig.level &&
          answer.optionId === changedAnswerConfig.optionId
      );
      indexToChange = answerConfigRenderList.indexOf(
        foundAnswer as AnswerConfigRenderProps
      );
    }

    const newAnswerConfigRenderList = answerConfigRenderList.map(
      (answer, index) => {
        if (index !== indexToChange) return answer;
        return {
          ...answer,
          ...changedAnswerConfig,
        };
      }
    );

    // Mount DB Model, then mount back render model
    const dbAnswerConfig = mountAnswerConfig(newAnswerConfigRenderList);
    const mappedAnswerConfigRenderList = mountAnswerRender(dbAnswerConfig);

    setAnswerConfigRenderList(mappedAnswerConfigRenderList);

    // callback for upper component handle answerConfig on DB Model
    onChange(dbAnswerConfig);
  };

  const getAnswerConfigLists = () => {
    const answerConfigLists: AnswerConfigRenderProps[][] = [];
    answerConfigRenderList.forEach((answerConfig) => {
      if (!answerConfigLists[answerConfig.level])
        answerConfigLists[answerConfig.level] = [];
      answerConfigLists[answerConfig.level].push({ ...answerConfig });
    });
    return answerConfigLists;
  };

  return (
    <>
      <Container>
        {getAnswerConfigLists().map((answerConfigList, index) => (
          // [TODO] ajustar layout
          <Fragment key={index}>
            {index > 0 && (
              <UnfoldContainer>
                <h2>Desdobramentos nível {index}</h2>
                <hr />
              </UnfoldContainer>
            )}
            {answerConfigList.map((answerRender) => (
              <AnswerConfigItem
                questionText={questionText}
                key={generateUUID()}
                {...answerRender}
                onChange={handleConfigItemChange}
                interview={interview}
              />
            ))}
          </Fragment>
        ))}
      </Container>
    </>
  );
};

export default AnswerConfigList;
