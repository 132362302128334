import styled from 'styled-components';
import { Paper } from '@material-ui/core';

export const Container = styled.div`
  margin: ${({ theme }) => theme.spacing(2, 4, 2)};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledPaperTab = styled(Paper)`
  border-radius: 13px;
  padding: ${({ theme }) => theme.spacing(0, 2, 0, 0)};
  width: ${({ theme }) => theme.spacing(50)}px;
`;
