import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import FormikTextField from '../../../components/Forms/FormikTextField';
import LoadingBackdrop from '../../../components/LoadingBackdrop';
import Button from '../../../components/StyledButton';
import TabMenu from '../TabMenu';
import RoleApps from './RoleApps';
import useUser from '../../../hooks/useUser';
import useRoles from '../../../hooks/useRoles';
import { cpfMask, numberMask, phoneMask } from '../../../utils/inputs';
import { resolveErrorMessage } from '../../../utils/error';
import { FormValues, validationSchema } from './utils';
import {
  Container,
  FormContainer,
  Section,
  Label,
  RoleLabel,
  RoleDescription,
  RoleMenuItem,
  FieldsContainer,
  RoleSelect,
  AppContainer,
  AppLabel,
  StyledLogoBlue,
  StyledLogoWhite,
  StyledLogoPurple,
} from './styles';

type RouteParams = {
  id: string;
};

const apps = [
  { label: 'Tem acesso ao app da Família', icon: <StyledLogoWhite /> },
  { label: 'Tem acesso ao app do Cuidador', icon: <StyledLogoBlue /> },
  { label: 'Tem acesso ao app Admin', icon: <StyledLogoPurple /> },
];

const UpsertUser: React.FC = () => {
  const { id } = useParams<RouteParams>();
  const {
    loading: loadingUser,
    getById: getUserById,
    byId: usersById,
    patch: patchUser,
  } = useUser();
  const {
    loading: loadingRoles,
    getAll: getAllRoles,
    byId: rolesById,
    ids: rolesIds,
  } = useRoles();
  const history = useHistory();

  const user = usersById[id];
  const systemRoles = rolesIds.map((roleId) => rolesById[roleId]);

  useEffect(() => {
    getUserById(id);
    getAllRoles();
  }, [getUserById, getAllRoles, id]);

  const handleSubmit = (values: FormValues) => {
    const { name, cpf, phoneNumber, email, roleId } = values;

    return patchUser(id, {
      name,
      cpf: numberMask(String(cpf)),
      phoneNumber,
      email,
      roleId,
    })
      .then(() => {
        toast.success('Alterações salvas com sucesso');
        history.push('/usuarios/todos-usuarios');
      })
      .catch((err) => {
        const displayMessage = resolveErrorMessage(err);
        toast.error(displayMessage);
      });
  };

  if (loadingUser || loadingRoles) {
    return <LoadingBackdrop loading={loadingUser || loadingRoles} />;
  }

  const initialValues: FormValues = {
    name: user?.name || '',
    cpf: cpfMask(user?.cpf || ''),
    phoneNumber: phoneMask(user?.phoneNumber || ''),
    email: user?.email || '',
    roleId: user?.role?.['id'] || undefined,
  };

  return (
    <Container>
      <TabMenu />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue, isSubmitting }) => {
          return (
            <FormContainer noValidate={true}>
              <FieldsContainer>
                <FormikTextField
                  name="name"
                  label="Nome"
                  margin="normal"
                  inputProps={{ 'data-testid': 'name' }}
                />
                <FormikTextField
                  name="cpf"
                  label="CPF"
                  margin="normal"
                  inputProps={{ 'data-testid': 'cpf' }}
                  onChange={({ target }) =>
                    setFieldValue('cpf', cpfMask(target.value))
                  }
                />
                <FormikTextField
                  name="phoneNumber"
                  label="Telefone"
                  margin="normal"
                  inputProps={{ 'data-testid': 'phoneNumber' }}
                  onChange={({ target }) =>
                    setFieldValue('phoneNumber', phoneMask(target.value))
                  }
                />
                <FormikTextField
                  name="email"
                  label="E-mail"
                  margin="normal"
                  inputProps={{ 'data-testid': 'email' }}
                />
                <Section>
                  <Label>Tipo de perfil</Label>
                  <Section>
                    {apps.map(({ label, icon }, index) => (
                      <AppContainer key={index}>
                        <div>{icon}</div>
                        <AppLabel>{label}</AppLabel>
                      </AppContainer>
                    ))}
                  </Section>
                  <RoleSelect
                    name="roleId"
                    inputProps={{ 'data-testid': 'roleId' }}
                  >
                    {systemRoles.map((role) => (
                      <RoleMenuItem
                        key={role.id}
                        value={role.id}
                        onClick={() => {
                          setFieldValue('roleId', String(role.id));
                        }}
                      >
                        <RoleLabel>
                          {role.title}
                          <RoleApps
                            guardianAppAccess={role.guardianAppAccess}
                            caregiverAppAccess={role.caregiverAppAccess}
                            adminAppAccess={role.adminAppAccess}
                          />
                        </RoleLabel>
                        <RoleDescription>{role.description}</RoleDescription>
                      </RoleMenuItem>
                    ))}
                  </RoleSelect>
                </Section>
                <Section>
                  <Label>Descrição do tipo de perfil</Label>
                  <p data-testid="description">
                    {rolesById?.[String(values.roleId)]?.description}
                  </p>
                </Section>
              </FieldsContainer>
              <Button
                data-testid="save-button"
                type="submit"
                color="primary"
                disabled={isSubmitting}
              >
                Salvar alterações
              </Button>
            </FormContainer>
          );
        }}
      </Formik>
    </Container>
  );
};

export default UpsertUser;
