import * as Yup from 'yup';

export const STATUS_ENUM: Record<string, string> = {
  disabled: 'Inativo',
  active: 'Ativo',
  invitation_sent: 'Convite enviado',
};

export const LEVEL_ENUM: Record<string, string> = {
  junior: 'Júnior',
  full: 'Pleno',
  senior: 'Senior',
};

export const levelsOptions = [
  { value: 'junior', label: 'Júnior' },
  { value: 'full', label: 'Pleno' },
  { value: 'senior', label: 'Senior' },
];

export interface FormValues {
  level: string;
}

export const validationSchema = Yup.object().shape({
  level: Yup.string()
    .required('Por favor, escolha o nível')
    .oneOf(levelsOptions.map((option) => option.value)),
});
