import { FormControlLabel } from '@material-ui/core';
import styled from 'styled-components';

const StyledFormControlLabel = styled(FormControlLabel)`
  & .MuiRadio-root {
    color: ${({ theme }) => theme.palette.primary.main};
  }
  & .MuiCheckbox-root {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export default StyledFormControlLabel;
