import styled from 'styled-components';

export const Background = styled.div`
  padding: 0px ${({ theme }) => theme.spacing(2)}px;
  margin: ${({ theme }) => theme.spacing(2, 4, 2)};
  width: 100%;
`;

export const Container = styled.div`
  width: 100%;
`;
