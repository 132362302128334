import { useCallback, useReducer } from 'react';
import axios from '../config/axios';
import { EventModel } from '@cuidador/database';
import { createReducer, ReducerData, Item } from '../utils/store/index';

const endpoint = '/care/event';

const initialData: ReducerData<EventModel> = {
  byId: {} as Record<string, Item<EventModel>>,
  ids: [] as Array<number>,
  total: 0,
  loading: false,
  error: null,
};

const useEventResolved = () => {
  const [state, dispatch] = useReducer(
    createReducer<EventModel>(),
    initialData
  );

  const patch = useCallback(async (id: Id, data: Partial<EventModel>) => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.patch(`${endpoint}/${id}/resolved`, data);
      dispatch({ type: 'UPDATE', payload: response.data });
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
    }
  }, []);

  return {
    ...state,
    patch,
  };
};

export default useEventResolved;
