import React, { useEffect, useState } from 'react';
import {
  Container,
  AddButton,
  Header,
  EditIconButton,
  DeleteIconButton,
  TextWrap,
} from './styles';

import { useHistory } from 'react-router-dom';
import { CareQuestionModel } from '@cuidador/database';
import { resolveErrorMessage } from '../../../utils/error';
import { AxiosError } from 'axios';
import { APIError } from '@cuidador/lib';
import { toast } from 'react-toastify';
import qs from 'query-string';
import useCareQuestion from '../../../hooks/useCareQuestion';
import AddIcon from '@material-ui/icons/AddCircleOutlineRounded';
import DeleteIcon from '@material-ui/icons/Delete';
import { ReactComponent as EditIcon } from '../../../assets/Icon-edit.svg';
import { TableCell, Typography } from '@material-ui/core';
import MyTable from '../../../components/MyTable';
import SearchTextField from '../../../components/SearchTextField';
import StyledSimpleDialog from '../../../components/StyledSimpleDialog';

const tableColumns = [
  'Categoria de cuidado',
  'Nome da pergunta',
  'Pergunta da entrevista',
  'Ordem',
  '',
];

const InterviewQuestion: React.FC = () => {
  const [deleteDialogStatus, setDeleteDialogStatus] = useState(false);
  const [
    interviewQuestionBeingDeleted,
    setInterviewQuestionBeingDeleted,
  ] = React.useState<CareQuestionModel>();

  const { getPaginated, ids, byId, total, loading, remove } = useCareQuestion();
  const history = useHistory();

  const limit = parseInt(String(qs.parse(history.location.search).limit)) || 20;

  useEffect(() => {
    onChangeParams();
  }, []);

  const onChangeParams = () => {
    const params = qs.parse(history.location.search);
    getPaginated({
      ...params,
      type: 'interview_question',
      limit: Number(params.limit) || 20,
    });
  };

  const openUpdateInterviewQuestionPage = (id: Id) => {
    history.push(`/cuidados/perguntas-de-entrevista/${id}`);
  };

  const data = ids.map((id) => byId[id]);

  const deleteInterviewQuestion = (id: Id) => {
    remove(id)
      .then(() => {
        // Forces table refresh
        return onChangeParams();
      })
      .catch((err: AxiosError<APIError>) => {
        const displayMessage = resolveErrorMessage(err);
        toast.error(displayMessage);
      })
      .finally(() => {
        setDeleteDialogStatus(false);
      });
  };

  const renderContentItem = (item: CareQuestionModel) => {
    return (
      <>
        <TableCell>{item?.careCategory?.name}</TableCell>
        <TableCell>{item.name}</TableCell>
        <TableCell>
          <TextWrap>{item?.careQuestionText}</TextWrap>
        </TableCell>
        <TableCell>{item?.orderNumber}</TableCell>
        <TableCell align="right">
          <EditIconButton>
            <EditIcon
              data-testid="edit-dialog-open"
              onClick={() => openUpdateInterviewQuestionPage(Number(item.id))}
            />
          </EditIconButton>
          <DeleteIconButton>
            <DeleteIcon
              onClick={() => {
                setInterviewQuestionBeingDeleted(item);
                setDeleteDialogStatus(true);
              }}
              data-testid="delete-dialog-open"
            />
          </DeleteIconButton>
        </TableCell>
      </>
    );
  };

  return (
    <Container>
      <Header>
        <AddButton
          data-testid="create-content-dialog-open"
          onClick={() =>
            history.push('/cuidados/perguntas-de-entrevista/adicionar')
          }
        >
          <AddIcon fontSize="large" color="primary" />
          <Typography variant="h5">Adicionar</Typography>
        </AddButton>
        <SearchTextField
          fieldName="careQuestion.name"
          onChangeDebounced={onChangeParams}
        />
      </Header>
      <MyTable
        columnNames={tableColumns}
        data={data}
        totalPages={Math.ceil(total / limit)}
        hasPagination={true}
        keyExtractor={({ item }) => String(item!.id)}
        renderItem={renderContentItem}
        onChangeRowsPerPage={onChangeParams}
        onChangePage={onChangeParams}
        loading={loading}
      />
      <StyledSimpleDialog
        open={deleteDialogStatus}
        handleNo={() => setDeleteDialogStatus(false)}
        handleYes={() =>
          deleteInterviewQuestion(
            interviewQuestionBeingDeleted?.id
              ? interviewQuestionBeingDeleted.id
              : 0
          )
        }
        title={`Tem certeza que deseja excluir a pergunta de entrevista ${interviewQuestionBeingDeleted?.name}?`}
        subTitle="Todas as ações relacionados com ela serão excluídas também."
      />
    </Container>
  );
};

export default InterviewQuestion;
