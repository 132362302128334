import React, { useEffect, useState } from 'react';
import HeaderBase from '../../../../components/HeaderBase';
import { useHistory, useParams } from 'react-router-dom';
import { Container, Backdrop, BackdropCircularProgress } from './styles';
import { Typography } from '@material-ui/core';
import { resolveErrorMessage } from '../../../../utils/error';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import { APIError } from '@cuidador/lib';
import useCareQuestion from '../../../../hooks/useCareQuestion';
import { CareQuestionModel } from '@cuidador/database';
import InterviewQuestionUpsertForm from '../../../../components/InterviewQuestionUpsertForm';
import {
  interviewQuestionModelToFormData,
  FormValues,
} from '../../../../components/InterviewQuestionUpsertForm/utils';
import StyledSimpleDialog from '../../../../components/StyledSimpleDialog';

const InterviewQuestionUpdate: React.FC = () => {
  const [
    initialInterviewQuestionData,
    setInitialInterviewQuestionData,
  ] = useState<FormValues | null>(null);
  const [
    interviewQuestionToBeEdited,
    setInterviewQuestionToBeEdited,
  ] = useState<CareQuestionModel | null>(null);
  const [
    cancelEditInterviewQuestionDialogOpen,
    setCancelEditInterviewQuestionDialogOpen,
  ] = useState(false);
  const params = useParams<{ id: string }>();
  const { loading, getById, patch } = useCareQuestion();
  const history = useHistory();

  const id = Number(params.id);
  useEffect(
    function fetchInitialValues() {
      if (id) {
        getInterviewQuestionData();
      }
    },
    [id]
  );

  const getInterviewQuestionData = async () => {
    try {
      const interviewQuestion = (await getById(id)) as CareQuestionModel;
      setInitialInterviewQuestionData(
        interviewQuestionModelToFormData(interviewQuestion)
      );
    } catch (err) {
      const displayMessage = resolveErrorMessage(err);
      toast.error(displayMessage);
    }
  };

  const handleOpenEditConfirmation = (data: CareQuestionModel) => {
    setInterviewQuestionToBeEdited(data);
  };

  const handleCancel = () => {
    setCancelEditInterviewQuestionDialogOpen(true);
  };

  const handleSubmit = () => {
    return patch(id, interviewQuestionToBeEdited!)
      .then(() => {
        history.goBack();
      })
      .catch((err: AxiosError<APIError>) => {
        const displayMessage = resolveErrorMessage(err);
        toast.error(displayMessage);
      })
      .finally(() => setInterviewQuestionToBeEdited(null));
  };

  if (loading) {
    return (
      <Backdrop open={loading}>
        {loading && (
          <BackdropCircularProgress data-testid="table-backdrop-spinner" />
        )}
      </Backdrop>
    );
  }

  return (
    <>
      <HeaderBase title={'ENTREVISTA'} onBackButtonClick={handleCancel} />
      <Container>
        <Typography variant="h5">Pergunta de entrevista</Typography>
        {initialInterviewQuestionData && (
          <InterviewQuestionUpsertForm
            initialValues={initialInterviewQuestionData}
            onSubmit={handleOpenEditConfirmation}
            disableDefaultIsSubmittingHandle={true}
          />
        )}
        <StyledSimpleDialog
          open={Boolean(interviewQuestionToBeEdited)}
          handleNo={() => setInterviewQuestionToBeEdited(null)}
          handleYes={handleSubmit}
          title={'Tem certeza que deseja editar essa pergunta de entrevista?'}
          subTitle="Todas as alterações serão salvas."
        />
        <StyledSimpleDialog
          open={cancelEditInterviewQuestionDialogOpen}
          handleNo={() => setCancelEditInterviewQuestionDialogOpen(false)}
          handleYes={() => history.goBack()}
          title={
            'Tem certeza que deseja cancelar a edição dessa pergunta de entrevista?'
          }
          subTitle="Todas as alterações serão perdidas."
        />
      </Container>
    </>
  );
};

export default InterviewQuestionUpdate;
