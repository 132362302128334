import React from 'react';
import { ReportStaticCareLineModel } from '@cuidador/database';
import { ReportStaticCareCategory } from '@cuidador/shared';
import { Checkbox, Typography } from '@material-ui/core';

import StyledFormControlLabel from '../../../components/StyledFormControlLabel';

import {
  IndicatorTitleContainer,
  OptionContainer,
  IndicatorTitle,
} from '../styles';
import { IndicatorSectionProps } from '../utils';
import { CareLineName, CareCategoryColumn, CareCategory } from './styles';

const StaticCareLineIndicator: React.FC<
  IndicatorSectionProps<ReportStaticCareCategory[]>
> = ({ data = [], onChange, selectedIndicators }) => {
  const renderOptionLabel = (
    reportStaticCareLine: ReportStaticCareLineModel
  ) => <IndicatorTitle>{reportStaticCareLine!.careLine?.name}</IndicatorTitle>;

  return (
    <div data-testid="staticCareLineIndicatorSection">
      <IndicatorTitleContainer>
        <Typography variant="h6">
          Condições Complementares de Saúde e Cuidado
        </Typography>
      </IndicatorTitleContainer>
      <CareCategoryColumn>
        {data.map((careCategory) => (
          <CareCategory key={careCategory.id}>
            <CareLineName>{careCategory.name}</CareLineName>
            {careCategory?.reportStaticCareLines?.map(
              (reportStaticCareLine) => (
                <OptionContainer key={reportStaticCareLine.id}>
                  <StyledFormControlLabel
                    value={reportStaticCareLine.id}
                    name={String(reportStaticCareLine.id)}
                    data-testid={reportStaticCareLine.id}
                    control={
                      <Checkbox
                        color="primary"
                        checked={selectedIndicators.includes(
                          reportStaticCareLine.id!
                        )}
                        onChange={() => onChange(reportStaticCareLine.id!)}
                      />
                    }
                    label={renderOptionLabel(reportStaticCareLine!)}
                  />
                </OptionContainer>
              )
            )}
          </CareCategory>
        ))}
      </CareCategoryColumn>
    </div>
  );
};

export default React.memo(StaticCareLineIndicator);
