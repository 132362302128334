import React, { useCallback } from 'react';
import { Container, StyledPaperTab } from './styles';

import { Redirect, useHistory, useParams } from 'react-router';
import { tabOptions, RouteParams } from './utils';

import { Tab } from '@material-ui/core';
import StyledTabs from '../../components/StyledTabs';
import CaregiverDashboard from './CaregiverDashboard';
import PatientDashboard from './PatientDashboard';
import Downloads from './Downloads';

const Dashboard: React.FC = () => {
  const history = useHistory();
  const { tab } = useParams<RouteParams>();

  const handleChange = useCallback((_, value) => {
    history.replace(`/dashboard/${value}`);
  }, []);

  if (!tab || !tabOptions.some((item) => item.key === tab)) {
    return <Redirect to="/dashboard/pacientes" />;
  }

  return (
    <Container>
      <StyledPaperTab>
        <StyledTabs centered value={tab} onChange={handleChange}>
          {tabOptions.map((option) => (
            <Tab
              wrapped={true}
              key={option.key}
              label={option.label}
              value={option.key}
            />
          ))}
        </StyledTabs>
      </StyledPaperTab>

      {/* it will be changed by real content */}
      <div data-testid={tab} />
      {tab == 'pacientes' && <PatientDashboard />}
      {tab == 'cuidadores' && <CaregiverDashboard />}
      {tab == 'downloads' && <Downloads />}
    </Container>
  );
};

export default Dashboard;
