import React, { useEffect, useState } from 'react';
import {
  FieldsContainer,
  FooterContainer,
  ActionOutcomeTypeContainer,
  StaticCareLineContainer,
  StaticCareLineTextBox,
  StaticClassificationTextBox,
} from '../styles';
import {
  validationSchema,
  FormValues,
  formInitialValue,
  formDataToActionModel,
  formatClassification,
  actionModelToFormData,
  optionClassifications,
} from './utils';

import { Formik, Form, FormikHelpers } from 'formik';
import { resolveErrorMessage } from '../../../utils/error';
import { toast } from 'react-toastify';
import { ActionModel } from '@cuidador/database';
import { CareQuestionModel } from '@cuidador/database';
import { RadioGroup, Radio, Typography } from '@material-ui/core';

import useCareQuestion from '../../../hooks/useCareQuestion';
import useCareLine from '../../../hooks/useCareLine';
import StyledFormControlLabel from '../../../components/StyledFormControlLabel';
import StyledButton from '../../../components/StyledButton';
import StyledSelectField from '../../../components/Forms/FormikSelect';
import StyledMenuItem from '../../StyledMenuItem';
import AutocompleteTextField, {
  OnChangeValue as AutoCompleteOnChangeValue,
} from '../../AutocompleteTextField';

const ActionInterview: React.FC<{
  initialValues?: ActionModel;
  onSubmit: (action: ActionModel) => void | Promise<void>;
  disableDefaultIsSubmittingHandle?: boolean;
}> = ({ initialValues, onSubmit, disableDefaultIsSubmittingHandle }) => {
  const [optionPaths, setOptionPaths] = useState<
    CareQuestionModel['optionPaths'] | undefined
  >();

  const { getPaginated: getCareQuestionPaginated, getById } = useCareQuestion();
  const { getPaginated: getCareLinePaginated } = useCareLine();

  const selectedMainCareQuestionOptionPath = async (careQuestionId: Id) => {
    try {
      const careQuestion = (await getById(careQuestionId)) as CareQuestionModel;
      setOptionPaths(careQuestion.optionPaths);
    } catch (err) {
      const displayMessage = resolveErrorMessage(err);
      toast.error(displayMessage);
    }
  };

  const initialValuesForm = initialValues
    ? actionModelToFormData(initialValues)
    : formInitialValue;

  useEffect(() => {
    if (initialValues)
      selectedMainCareQuestionOptionPath(initialValues.careQuestionId!);
  }, []);

  const mainCareQuestionIdInitialValue = initialValuesForm.careQuestionId
    ? {
        text: initialValuesForm?.careQuestion?.careQuestionText || '',
        value: Number(initialValuesForm.careQuestionId),
      }
    : undefined;

  const newCareQuestionInitialValue = initialValuesForm.newCareQuestionId
    ? {
        text: `${
          initialValues?.newCareQuestion?.careLine?.name
        } - ${formatClassification(
          initialValues?.newCareQuestion?.classification
        )}`,
        value: Number(initialValuesForm.newCareQuestionId),
      }
    : undefined;

  const deactivatedCareLineInitialValue = initialValuesForm.deactivatedCareLineId
    ? {
        text: `${initialValues?.deactivatedCareLine?.name}`,
        value: Number(initialValuesForm.deactivatedCareLineId),
      }
    : undefined;

  const newCareLineAutoCompleteInitialValue = initialValuesForm.newCareLineId
    ? {
        text: `${initialValues?.newCareLine?.name}`,
        value: Number(initialValuesForm.newCareLineId),
      }
    : undefined;

  const handleMainInterviewQuestionAutoCompleteOnChange = async (
    textValue: string
  ): Promise<AutoCompleteOnChangeValue[]> => {
    const params = {
      type: 'interview_question' as CareQuestionModel['type'],
      careQuestionText: `%${textValue}%`,
    };
    const data = await getCareQuestionPaginated({ ...params, limit: 20 });

    return data?.map((careQuestion) => ({
      text: `${careQuestion?.careQuestionText}`,
      value: careQuestion?.id,
    })) as AutoCompleteOnChangeValue[];
  };

  const handleShiftQuestionAutoCompleteOnChange = async (
    textValue: string
  ): Promise<AutoCompleteOnChangeValue[]> => {
    const params = {
      type: 'shift_question' as CareQuestionModel['type'],
      'careLine.name': `%${textValue}%`,
    };
    const data = await getCareQuestionPaginated({ ...params, limit: 20 });
    return data?.map((careQuestion) => ({
      text: `${careQuestion?.careLine?.name} - ${formatClassification(
        careQuestion?.classification
      )}`,
      value: careQuestion?.id,
    })) as AutoCompleteOnChangeValue[];
  };

  const handleCareLineAutoCompleteOnChange = async (
    textValue: string
  ): Promise<AutoCompleteOnChangeValue[]> => {
    const params = { name: `%${textValue}%` };
    const data = await getCareLinePaginated({ ...params, limit: 20 });
    return data?.map((careLine) => ({
      text: careLine.name,
      value: careLine.id,
    })) as AutoCompleteOnChangeValue[];
  };

  const selectedOptionPath = async (careQuestionId: Id) => {
    try {
      const careQuestion = (await getById(careQuestionId)) as CareQuestionModel;
      setOptionPaths(careQuestion.optionPaths);
    } catch (err) {
      const displayMessage = resolveErrorMessage(err);
      toast.error(displayMessage);
    }
  };

  const handleNewCareQuestionListItemClick = (
    item: AutoCompleteOnChangeValue,
    setFieldValue: (field: string, value: string) => void
  ) => {
    // set Formik field value outside form, because it is our autocomplete component
    setFieldValue('newCareQuestionId', String(item.value));
    // set field to clear the other input value that might be selected before
    setFieldValue('deactivatedCareLineId', '');
  };

  const handleDeactivatedCareLineListItemClick = (
    item: AutoCompleteOnChangeValue,
    setFieldValue: (field: string, value: string) => void
  ) => {
    // set Formik field value outside form, because it is our autocomplete component
    setFieldValue('deactivatedCareLineId', String(item.value));
    // set field to clear the other input value that might be selected before
    setFieldValue('newCareQuestionId', '');
  };

  const handleNewCareLineListItemClick = (
    item: AutoCompleteOnChangeValue,
    setFieldValue: (field: string, value: string) => void
  ) => {
    // set Formik field value outside form, because it is our autocomplete component
    setFieldValue('newCareLineId', String(item.value));
  };

  const handleAutoCompleteListItemClick = (
    item: AutoCompleteOnChangeValue,
    setFieldValue: (field: string, value: string) => void
  ) => {
    // set Formik field value outside form, because it is our autocomplete component
    setFieldValue('careQuestionId', String(item.value));
    selectedOptionPath(item.value);
  };

  const handleSubmit = (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    if (values.actionOutcomeType != 'deactivate_care_line') {
      values.deactivatedCareLineId = undefined;
    } else {
      values.newCareQuestionId = undefined;
    }

    const data = formDataToActionModel({
      ...values,
    });

    if (disableDefaultIsSubmittingHandle) setSubmitting(false);
    return onSubmit(data as ActionModel);
  };

  return (
    <>
      <Formik
        initialValues={initialValuesForm as FormValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnChange={false}
      >
        {(formik) => (
          <Form noValidate={true}>
            <FieldsContainer>
              <AutocompleteTextField
                onListValueClick={(item: AutoCompleteOnChangeValue) => {
                  handleAutoCompleteListItemClick(item, formik.setFieldValue);
                }}
                onChangeDebounced={
                  handleMainInterviewQuestionAutoCompleteOnChange
                }
                name="careQuestionId"
                label="Descrição da pergunta"
                initialValue={mainCareQuestionIdInitialValue}
              />
              <StyledSelectField
                name="careQuestionOptionId"
                label="Resposta associada"
                value={formik.values.careQuestionOptionId || ''}
                SelectDisplayProps={{
                  'data-testid': 'careQuestionOptionId',
                }}
                color="secondary"
              >
                {optionPaths?.map((item) => (
                  <StyledMenuItem
                    key={item.optionId}
                    value={item.optionId}
                    color="secondary"
                  >
                    {item.pathValue}
                  </StyledMenuItem>
                ))}
              </StyledSelectField>
              <ActionOutcomeTypeContainer>
                <Typography variant="h5">Ação gerada</Typography>
                <RadioGroup
                  aria-label="actionOutcomeType"
                  data-test-id="actionOutcomeType-radio"
                  name="actionOutcomeType"
                  value={formik.values.actionOutcomeType}
                >
                  <StyledFormControlLabel
                    value="activate_care_question"
                    control={<Radio color="primary" />}
                    onChange={() => {
                      formik.setFieldValue(
                        'actionOutcomeType',
                        'activate_care_question'
                      );
                    }}
                    label="Ativação de classificação"
                  />
                  <StyledFormControlLabel
                    value="deactivate_care_line"
                    control={<Radio color="primary" />}
                    onChange={() => {
                      formik.setFieldValue(
                        'actionOutcomeType',
                        'deactivate_care_line'
                      );
                    }}
                    label="Desativação de linha de cuidado"
                  />
                  <StyledFormControlLabel
                    value="activate_static_care_line"
                    control={<Radio color="primary" />}
                    onChange={() =>
                      formik.setFieldValue(
                        'actionOutcomeType',
                        'activate_static_care_line'
                      )
                    }
                    label="Ativação de classificação estática"
                  />
                </RadioGroup>
                {formik.values.actionOutcomeType ===
                  'activate_care_question' && (
                  <AutocompleteTextField
                    onListValueClick={(item: AutoCompleteOnChangeValue) => {
                      handleNewCareQuestionListItemClick(
                        item,
                        formik.setFieldValue
                      );
                    }}
                    onChangeDebounced={handleShiftQuestionAutoCompleteOnChange}
                    name="newCareQuestionId"
                    label="Linha de cuidado - Classificação"
                    testId="newCareQuestionId"
                    initialValue={newCareQuestionInitialValue}
                  />
                )}
                {formik.values.actionOutcomeType === 'deactivate_care_line' && (
                  <AutocompleteTextField
                    onListValueClick={(item: AutoCompleteOnChangeValue) => {
                      handleDeactivatedCareLineListItemClick(
                        item,
                        formik.setFieldValue
                      );
                    }}
                    onChangeDebounced={handleCareLineAutoCompleteOnChange}
                    name="deactivatedCareLineId"
                    label="Linha de cuidado"
                    testId="deactivatedCareLineId"
                    initialValue={deactivatedCareLineInitialValue}
                  />
                )}
                {formik.values.actionOutcomeType ==
                  'activate_static_care_line' && (
                  <StaticCareLineContainer>
                    <StaticCareLineTextBox>
                      <AutocompleteTextField
                        onListValueClick={(item: AutoCompleteOnChangeValue) => {
                          handleNewCareLineListItemClick(
                            item,
                            formik.setFieldValue
                          );
                        }}
                        onChangeDebounced={handleCareLineAutoCompleteOnChange}
                        name="newCareLineId"
                        label="Linha de cuidado"
                        testId="newCareLineAutocomplete"
                        initialValue={newCareLineAutoCompleteInitialValue}
                      />
                    </StaticCareLineTextBox>
                    <StaticClassificationTextBox>
                      <StyledSelectField
                        name="classification"
                        label="Classificação"
                        value={formik.values.classification || ''}
                        SelectDisplayProps={{
                          'data-testid': 'classification',
                        }}
                        color="secondary"
                      >
                        {optionClassifications?.map((item) => (
                          <StyledMenuItem
                            key={item.id}
                            value={item.id}
                            color="secondary"
                          >
                            {item.type}
                          </StyledMenuItem>
                        ))}
                      </StyledSelectField>
                    </StaticClassificationTextBox>
                  </StaticCareLineContainer>
                )}
              </ActionOutcomeTypeContainer>
            </FieldsContainer>

            <FooterContainer>
              <StyledButton
                color="inherit"
                data-testid="submit"
                type="submit"
                disabled={formik.isSubmitting}
              >
                Salvar
              </StyledButton>
            </FooterContainer>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ActionInterview;
