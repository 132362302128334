import styled from 'styled-components';

export const StyledAutocompleteList = styled.ul`
  margin-top: 0;
  position: absolute;
  min-width: 350px;
  max-height: 300px;
  max-width: 500px;
  overflow: auto;
  z-index: 999;
  background: white;
  border-radius: 5px;
  box-shadow: 0px 0px 10px ${({ theme }) => theme.palette.common.black};
  padding: ${({ theme }) => theme.spacing(1)}px;
  list-style: none;
`;

export const StyledAutocompleteItem = styled.li`
  cursor: pointer;
  padding: 5px;
  margin: 2px 0;
  background: ${({ theme }) => theme.palette.grey[200]};
  border-radius: 5px;
`;
