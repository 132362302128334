import { CaregiverMood } from '@cuidador/database';
import { timezoneUnawareDate } from '../date';
import formatDate from 'date-fns/format';

export interface NormalizedCaregiverMood {
  nome?: string;
  data?: string;
  momento?: string;
  resposta?: string;
}

export function formatMood(moodLevel: number | undefined) {
  switch (moodLevel) {
    case 1:
      return 'Muito triste';
    case 2:
      return 'Triste';
    case 3:
      return 'Neutro';
    case 4:
      return 'Feliz';
    case 5:
      return 'Muito feliz';
    default:
      return '';
  }
}

export function formatShiftPeriod(shiftPeriod: string | undefined) {
  switch (shiftPeriod) {
    case 'shiftStart':
      return 'Início do plantão';
    case 'shiftEnd':
      return 'Fim do plantão';
    default:
      return '';
  }
}

export function formatCreatedAt(createdAt: string) {
  return formatDate(timezoneUnawareDate(createdAt), 'dd/MM/yy - HH:mm');
}

export const normalizeCaregiverMood = (
  caregiverMood: CaregiverMood,
  name: string | undefined
): NormalizedCaregiverMood => {
  return {
    nome: name,
    data: caregiverMood.createdAt
      ? formatCreatedAt(caregiverMood.createdAt)
      : '',
    momento: formatShiftPeriod(caregiverMood.shiftPeriod),
    resposta: formatMood(caregiverMood.moodLevel),
  };
};
