import { DialogActions } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import useRoles from '../../../../hooks/useRoles';
import theme from '../../../../styles/theme';
import StyledDialog from '../../../StyledDialog';
import StyledMenuItem from '../../../StyledMenuItem';
import {
  DialogSubtitle,
  Section,
  StyledButton,
  StyledDialogContent,
  StyledSelectContainer,
  StyledTypography,
} from '../styles';

export type ChooseProfileProps = {
  open: boolean;
  roleTitle?: string;
  handleGetRoleIdToBeAssigned: (roleToBeAssigned: number) => void;
  handleBack: () => void;
  handleConfirm: () => void;
};

const ChooseRoleDialog: React.FC<ChooseProfileProps> = ({
  open,
  roleTitle,
  handleGetRoleIdToBeAssigned,
  handleBack,
  handleConfirm,
}) => {
  const { ids, byId, getAll } = useRoles();

  useEffect(() => {
    getAll();
  }, [getAll]);

  const roles = ids.map((roleId) => byId[roleId]);
  const rolesWithoutCurrentRole = roles.filter(
    ({ title }) => title !== roleTitle
  );
  const roleIdSelectedByDefault = rolesWithoutCurrentRole[0]?.id;

  const [roleIdSelected, setRoleIdSelected] = useState(roleIdSelectedByDefault);

  useEffect(() => {
    setRoleIdSelected(roleIdSelectedByDefault);
  }, [roleIdSelectedByDefault]);

  const infoColor = theme.palette.info.main;
  const contrastColor = theme.palette.getContrastText('#000');

  return (
    <StyledDialog open={open}>
      <StyledDialogContent>
        <DialogSubtitle width={80}>
          <StyledTypography>
            Escolha um tipo de perfil existente para substituir em eventuais
            usuários que já possuem o Tipo de Perfil que será EXCLUÍDO
          </StyledTypography>
        </DialogSubtitle>
        <Section>
          <StyledSelectContainer
            name="roleTitle"
            SelectDisplayProps={{
              'data-testid': 'select-role-title',
            }}
            onChange={({ target }) => setRoleIdSelected(Number(target.value))}
            value={roleIdSelected}
          >
            {rolesWithoutCurrentRole.map(({ id, title }) => (
              <StyledMenuItem key={id} value={id}>
                {title}
              </StyledMenuItem>
            ))}
          </StyledSelectContainer>
        </Section>
        <DialogActions>
          <StyledButton
            textColor={infoColor}
            backgroundColor={contrastColor}
            borderColor={contrastColor}
            onClick={handleBack}
          >
            VOLTAR
          </StyledButton>
          <StyledButton
            data-testid="choose-profile-button"
            textColor={contrastColor}
            borderColor={contrastColor}
            onClick={() => {
              handleGetRoleIdToBeAssigned(roleIdSelected);
              handleConfirm();
            }}
          >
            CONFIRMAR
          </StyledButton>
        </DialogActions>
      </StyledDialogContent>
    </StyledDialog>
  );
};

export default ChooseRoleDialog;
