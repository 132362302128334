import React, { useEffect, useState } from 'react';
import { AnswerOption as DbAnswerOption } from '@cuidador/database';
import { Input, InputAdornment, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import AnswerIconChecked from '../../../assets/Icon-answerBlue.svg';
import AnswerIcon from '../../../assets/Icon-answerWhite.svg';
import TextIconChecked from '../../../assets/Icon-textBlue.svg';
import TextIcon from '../../../assets/Icon-textWhite.svg';
import { Container } from './styles';
import { AnswerConfigRenderProps } from '../AnswerConfigItem';

const SelectedAnswerIcon = <img src={AnswerIconChecked} />;
const UnselectedAnswerIcon = <img src={AnswerIcon} />;
const SelectedTextIcon = <img src={TextIconChecked} />;
const UnselectedTextIcon = <img src={TextIcon} />;

export type OptionProps = Pick<DbAnswerOption, 'needDescription' | 'value'> & {
  type: AnswerConfigRenderProps['type'];
  hasNextAnswer: boolean;
  /**
   * Any UUID for rendering and referencing help
   */
  id: string;
  interview?: boolean;
};

const AnswerOption: React.FC<
  OptionProps & {
    onChange: (option: OptionProps) => void;
    onDelete: (option: OptionProps) => void;
  }
> = ({
  type,
  value,
  needDescription,
  hasNextAnswer,
  id,
  onChange,
  onDelete,
  interview,
}) => {
  const [optionText, setOptionText] = useState('');

  useEffect(() => {
    setOptionText(value);
  }, []);

  const buildOption = (modify?: Partial<OptionProps>): OptionProps => ({
    hasNextAnswer,
    needDescription,
    value,
    type,
    id,
    ...modify,
  });

  const DescriptionIcon = () => {
    const needDescriptionChange = (value: boolean) => {
      if (hasNextAnswer) return;
      onChange(
        buildOption({
          needDescription: value,
        })
      );
    };
    return (
      <IconButton onClick={() => needDescriptionChange(!needDescription)}>
        {needDescription ? SelectedTextIcon : UnselectedTextIcon}
      </IconButton>
    );
  };

  const NextQuestionIcon = () => {
    const hasNextAnswerChange = (value: boolean) => {
      if (needDescription) return;
      onChange(
        buildOption({
          hasNextAnswer: value,
        })
      );
    };
    return (
      <IconButton onClick={() => hasNextAnswerChange(!hasNextAnswer)}>
        {hasNextAnswer ? SelectedAnswerIcon : UnselectedAnswerIcon}
      </IconButton>
    );
  };

  return (
    <Container>
      <Input
        color="primary"
        value={optionText}
        onChange={(e) => setOptionText(e.target.value)}
        onBlur={(e) => {
          onChange(
            buildOption({
              value: e.target.value,
            })
          );
        }}
        onKeyPress={(e) => {
          if (e.key === 'Enter') e.preventDefault();
        }}
        fullWidth
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => onDelete(buildOption())}
            >
              <CloseIcon />
            </IconButton>
          </InputAdornment>
        }
      />

      {type === 'single' && !interview && <DescriptionIcon />}
      {type === 'single' && !interview && <NextQuestionIcon />}
    </Container>
  );
};

export default AnswerOption;
