import styled from 'styled-components';

export const FormContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing(2)}px;
`;

export const ConfigDiv = styled.div`
  display: flex;
  flex-direction: row;
`;

export const CategoryDiv = styled.div`
  width: 40%;
  margin-right: ${({ theme }) => theme.spacing(6)}px;
`;

export const SubCategoryDiv = styled.div`
  width: 40%;
`;

export const TriggerEventStatusDiv = styled.div`
  width: 30%;
`;

export const TriggerTitle = styled.div`
  margin-top: ${({ theme }) => theme.spacing(4)}px;
`;

export const ActionTriggerContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ActionTriggerTypeContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: ${({ theme }) => theme.spacing(6)}px;
`;

export const TriggerThresholdTextBox = styled.div`
  width: 25%;
`;

export const TriggerAdherenceTypeTextBox = styled.div`
  width: 15%;
`;

export const TriggerTimeTextBox = styled.div`
  width: 15%;
  margin-left: ${({ theme }) => theme.spacing(4)}px;
`;

export const ActionOutcomeTypeContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.spacing(4)}px;
`;

export const EmailContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing(2)}px;
`;

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;
