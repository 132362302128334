import { useCallback, useReducer } from 'react';
import axios from '../config/axios';
import { GuardianModel } from '@cuidador/database';
import { createReducer, ReducerData, Item } from '../utils/store/index';

const endpoint = '/user/guardian';

const initialData: ReducerData<GuardianModel> = {
  byId: {} as Record<string, Item<GuardianModel>>,
  ids: [] as Array<number>,
  total: 0,
  loading: false,
  error: null,
};

const useGuardian = () => {
  const [state, dispatch] = useReducer(
    createReducer<GuardianModel>(),
    initialData
  );

  const patchStatus = useCallback(
    async (id: Id, data: Partial<GuardianModel>) => {
      try {
        dispatch({ type: 'LOADING' });
        const response = await axios.patch(`${endpoint}/${id}/status`, data);
        dispatch({ type: 'UPDATE', payload: response.data });
      } catch (err) {
        dispatch({ type: 'ERROR', payload: err });
        return Promise.reject(err);
      }
    },
    []
  );

  return {
    ...state,
    patchStatus,
  };
};

export default useGuardian;
