import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { MenuItemProps } from './';

export const StyledLink = styled(Link)<MenuItemProps>`
  svg {
    margin-right: ${({ theme }) => theme.spacing(2)}px;
  }
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(2)}px;
  text-decoration: none;
  letter-spacing: 2px;
  color: ${({ theme }) => theme.palette.common.white};
  background-color: ${({ $active }) => ($active ? '#ffffff32' : 'transparent')};
  &:hover {
    background-color: #ffffff16;
  }
`;
