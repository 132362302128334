import React from 'react';
import { DialogProps } from '@material-ui/core/Dialog';
import StyledDialog from '../../../../components/StyledDialog';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Typography,
} from '@material-ui/core';
import StyledButton from '../../../../components/StyledButton';
import StyledTextFieldWhite from '../../../../components/StyledTextFieldWhite';
import { CareLineIndicatorMessageObject } from '@cuidador/database';
import { PopupHelper } from '../styles';

import { Formik, Form } from 'formik';

import { FormValues } from '../utils';

interface CareLineIndicatorsDialogProps extends DialogProps {
  careLineIndicator?: CareLineIndicatorMessageObject;
  onConfirm: (values: FormValues) => void;
  onCancel: () => void;
}

const CareLineIndicatorsDialog: React.FC<CareLineIndicatorsDialogProps> = ({
  careLineIndicator,
  onCancel,
  onConfirm,
  ...rest
}) => {
  const formInitialValue = {
    message: careLineIndicator?.ruleMessage || '',
    careLineId: careLineIndicator?.careLineId,
    careLineIndicatorRuleId: careLineIndicator?.careLineIndicatorRuleId,
  };

  return (
    <StyledDialog {...rest}>
      <DialogContent>
        <DialogTitle>Digite a mensagem:</DialogTitle>
        {careLineIndicator?.ruleName === 'Oscilou' && (
          <Typography variant="subtitle1">
            Use <PopupHelper>{' <mensagem_classificacao_atual>'}</PopupHelper>{' '}
            para recuperar a mensagem da classificação atual.
          </Typography>
        )}
        <Formik
          initialValues={formInitialValue as FormValues}
          onSubmit={onConfirm}
          validateOnChange={true}
        >
          {(formik) => (
            <Form noValidate={true}>
              <DialogContentText>
                <StyledTextFieldWhite
                  name="message"
                  label="Mensagem"
                  inputProps={{ 'data-testid': 'message-textbox' }}
                  value={formik.values.message}
                  error={Boolean(formik.errors.message)}
                  helperText={formik.errors.message}
                  onChange={formik.handleChange}
                  margin="normal"
                  multiline
                  rows={6}
                />
              </DialogContentText>
              <DialogActions>
                <StyledButton
                  disabled={formik.isSubmitting}
                  onClick={onCancel}
                  color="inherit"
                >
                  Cancelar
                </StyledButton>
                <StyledButton
                  disabled={formik.isSubmitting}
                  type="submit"
                  color="inherit"
                  data-testid="save-careLine-button"
                >
                  Salvar
                </StyledButton>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </StyledDialog>
  );
};

export default CareLineIndicatorsDialog;
