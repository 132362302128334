/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  ConfigDiv,
  FooterContainer,
  TriggerEventStatusDiv,
  CategoryDiv,
  SubCategoryDiv,
  FormContainer,
  TriggerTitle,
  ActionTriggerTypeContainer,
  ActionTriggerContainer,
  TriggerThresholdTextBox,
  TriggerTimeTextBox,
  TriggerAdherenceTypeTextBox,
  ActionOutcomeTypeContainer,
  EmailContainer,
} from './styles';
import {
  StaticCareLineContainer,
  StaticCareLineTextBox,
  StaticClassificationTextBox,
} from '../styles';
import {
  Typography,
  RadioGroup,
  Radio,
  FormHelperText,
  InputAdornment,
  FormGroup,
  Checkbox,
} from '@material-ui/core';

import { Formik, Form, FormikHelpers } from 'formik';
import { resolveErrorMessage } from '../../../utils/error';
import { toast } from 'react-toastify';
import AutocompleteTextField, {
  OnChangeValue as AutoCompleteOnChangeValue,
} from '../../../components/AutocompleteTextField';
import StyledButton from '../../../components/StyledButton';
import StyledSelectField from '../../../components/Forms/FormikSelect';
import FormikTextField from '../../../components/Forms/FormikTextField';
import StyledMenuItem from '../../../components/StyledMenuItem';
import StyledFormControl from '../../../components/StyledFormControl';
import { ActionModel, EventModel } from '@cuidador/database';
import StyledFormControlLabel from '../../../components/StyledFormControlLabel';
import {
  validationSchema,
  FormValues,
  formInitialValue,
  eventCategory,
  triggerStatusTypes,
  triggerAdherenceTypes,
  formDataToActionModel,
  mustHaveNewCareQuestion,
  formatClassification,
  actionEventModelToFormData,
  optionClassifications,
} from './utils';
import useEventSubCategory from '../../../hooks/useEventSubCategory';
import useCareQuestion from '../../../hooks/useCareQuestion';
import useCareLine from '../../../hooks/useCareLine';
import { EventSubCategoryModel } from '@cuidador/database';
import { CareQuestionModel } from '@cuidador/database';
import { numberMask } from '../../../utils/inputs';
import useContent from '../../../hooks/useContent';

const ActionEvent: React.FC<{
  initialValues?: ActionModel;
  onSubmit: (action: ActionModel) => void | Promise<void>;
  disableDefaultIsSubmittingHandle?: boolean;
}> = ({ initialValues, onSubmit, disableDefaultIsSubmittingHandle }) => {
  const [subCategoryList, setSubCategoryList] = useState<
    EventSubCategoryModel[] | undefined
  >([]);
  const {
    getByParams: getEventSubCategoriesByParams,
    getOnlyForMedication,
  } = useEventSubCategory();
  const { getPaginated: getCareQuestionPaginated } = useCareQuestion();
  const { getPaginated: getCareLinePaginated } = useCareLine();
  const { getPaginated: getContentPaginated } = useContent();

  const selectedCategoryId = async (
    categoryId: string,
    setFieldValue?: (field: string, value: string) => void
  ) => {
    try {
      if (setFieldValue) {
        setFieldValue('eventCategoryId', categoryId);
        setFieldValue('eventSubcategoryId', '');
      }
      if (categoryId !== '2') {
        // for categories 1, 3 and 4, the select field is fed with all its respective subcategories
        const listSubCategory = await getEventSubCategoriesByParams(
          parseInt(categoryId)
        );

        setSubCategoryList(listSubCategory);
      }
    } catch (err) {
      const displayMessage = resolveErrorMessage(err);
      toast.error(displayMessage);
    }
  };

  const initialValuesForm = initialValues
    ? actionEventModelToFormData(initialValues)
    : formInitialValue;

  useEffect(() => {
    if (initialValues)
      selectedCategoryId(
        String(initialValues.eventSubcategory?.eventCategory?.id)
      );
  }, []);

  const newCareQuestionAutoCompleteInitialValue = initialValuesForm.newCareQuestionId
    ? {
        text: `${
          initialValues?.newCareQuestion?.careLine?.name
        } - ${formatClassification(
          initialValues?.newCareQuestion?.classification
        )}`,
        value: Number(initialValuesForm.newCareQuestionId),
      }
    : undefined;

  const deactivatedCareLineAutoCompleteInitialValue = initialValuesForm.deactivatedCareLineId
    ? {
        text: `${initialValues?.deactivatedCareLine?.name}`,
        value: Number(initialValuesForm.deactivatedCareLineId),
      }
    : undefined;

  const newCareLineAutoCompleteInitialValue = initialValuesForm.newCareLineId
    ? {
        text: `${initialValues?.newCareLine?.name}`,
        value: Number(initialValuesForm.newCareLineId),
      }
    : undefined;

  const contentAutoCompleteInitialValue = initialValuesForm.contentId
    ? {
        text: `${initialValues?.content?.title}`,
        value: Number(initialValuesForm.contentId),
      }
    : undefined;

  const medicationAutoCompleteInitialValue =
    initialValuesForm.eventCategoryId == '2'
      ? {
          text: `${initialValues?.eventSubcategory?.name}`,
          value: Number(initialValues?.eventSubcategory?.id),
        }
      : undefined;

  const handleCareQuestionAutoCompleteOnChange = async (
    textValue: string
  ): Promise<AutoCompleteOnChangeValue[]> => {
    const params = {
      type: 'shift_question' as CareQuestionModel['type'],
      'careLine.name': `%${textValue}%`,
    };
    const data = await getCareQuestionPaginated({ ...params, limit: 20 });
    return data?.map((careQuestion) => ({
      text: `${careQuestion?.careLine?.name} - ${formatClassification(
        careQuestion?.classification
      )}`,
      value: careQuestion?.id,
    })) as AutoCompleteOnChangeValue[];
  };

  const handleCareLineAutoCompleteOnChange = async (
    textValue: string
  ): Promise<AutoCompleteOnChangeValue[]> => {
    const params = { name: `%${textValue}%` };
    const data = await getCareLinePaginated({ ...params, limit: 20 });
    return data?.map((careLine) => ({
      text: careLine.name,
      value: careLine.id,
    })) as AutoCompleteOnChangeValue[];
  };

  const handleContentAutoCompleteOnChange = async (
    textValue: string
  ): Promise<AutoCompleteOnChangeValue[]> => {
    const params = { title: `%${textValue}%` };
    const data = await getContentPaginated({ ...params, limit: 20 });
    return data?.map((content) => ({
      text: content.title,
      value: content.id,
    })) as AutoCompleteOnChangeValue[];
  };

  const handleMedicationAutoCompleteOnChange = async (
    textValue: string
  ): Promise<AutoCompleteOnChangeValue[]> => {
    const params = {
      'eventSubCategory.name': `%${textValue}%`,
    };
    const data = await getOnlyForMedication({ ...params, limit: 20 });
    return data?.map((medication: EventModel) => ({
      text: medication.name,
      value: medication.id,
    })) as AutoCompleteOnChangeValue[];
  };

  const handleNewCareQuestionListItemClick = (
    item: AutoCompleteOnChangeValue,
    setFieldValue: (field: string, value: string) => void
  ) => {
    // set Formik field value outside form, because it is our autocomplete component
    setFieldValue('newCareQuestionId', String(item.value));
  };

  const handleDeactivatedCareLineListItemClick = (
    item: AutoCompleteOnChangeValue,
    setFieldValue: (field: string, value: string) => void
  ) => {
    // set Formik field value outside form, because it is our autocomplete component
    setFieldValue('deactivatedCareLineId', String(item.value));
  };

  const handleNewCareLineListItemClick = (
    item: AutoCompleteOnChangeValue,
    setFieldValue: (field: string, value: string) => void
  ) => {
    // set Formik field value outside form, because it is our autocomplete component
    setFieldValue('newCareLineId', String(item.value));
  };

  const handleContentListItemClick = (
    item: AutoCompleteOnChangeValue,
    setFieldValue: (field: string, value: string) => void
  ) => {
    // set Formik field value outside form, because it is our autocomplete component
    setFieldValue('contentId', String(item.value));
  };

  const handleMedicationListItemClick = (
    item: AutoCompleteOnChangeValue,
    setFieldValue: (field: string, value: string) => void
  ) => {
    // set Formik field value outside form, because it is our autocomplete component
    setFieldValue('eventSubcategoryId', String(item.value));
  };

  const handleSubmit = (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    const data = formDataToActionModel(values);
    setSubmitting(false);
    return onSubmit(data as ActionModel);
  };

  return (
    <>
      <Formik
        initialValues={initialValuesForm as FormValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnChange={false}
      >
        {(formik) => (
          <Form noValidate={true}>
            <FormContainer>
              <Typography variant="h5">Configurar ação para evento</Typography>
              <ConfigDiv>
                <CategoryDiv>
                  <StyledSelectField
                    name="eventCategoryId"
                    label="Categoria"
                    value={formik.values.eventCategoryId || ''}
                    SelectDisplayProps={{
                      'data-testid': 'eventCategoryId',
                    }}
                    color="secondary"
                    onChange={(event) =>
                      selectedCategoryId(
                        String(event.target.value),
                        formik.setFieldValue
                      )
                    }
                  >
                    {eventCategory?.map((item) => (
                      <StyledMenuItem
                        key={item.id}
                        value={item.id}
                        color="secondary"
                      >
                        {item.category}
                      </StyledMenuItem>
                    ))}
                  </StyledSelectField>
                </CategoryDiv>
                <SubCategoryDiv>
                  {formik.values.eventCategoryId == '2' ? ( // for the medication category, an autocomplete is rendered because it has too many items
                    <AutocompleteTextField
                      onListValueClick={(item: AutoCompleteOnChangeValue) => {
                        handleMedicationListItemClick(
                          item,
                          formik.setFieldValue
                        );
                      }}
                      onChangeDebounced={handleMedicationAutoCompleteOnChange}
                      name="eventSubCategoryId"
                      label="Tipo"
                      initialValue={medicationAutoCompleteInitialValue}
                      testId="medicationAutoComplete"
                    />
                  ) : (
                    // for the others, with fewer items, a select component is rendered
                    <StyledSelectField
                      name="eventSubcategoryId"
                      label="Tipo"
                      fullWidth
                      value={formik.values.eventSubcategoryId || ''}
                      SelectDisplayProps={{
                        'data-testid': 'eventSubcategoryId',
                      }}
                      color="secondary"
                    >
                      {subCategoryList?.map((item) => (
                        <StyledMenuItem
                          key={item.id}
                          value={item.id}
                          color="secondary"
                        >
                          {item.name}
                        </StyledMenuItem>
                      ))}
                    </StyledSelectField>
                  )}
                </SubCategoryDiv>
              </ConfigDiv>
              <TriggerEventStatusDiv>
                <StyledSelectField
                  name="triggerEventStatus"
                  label="Tipo de resposta"
                  value={formik.values.triggerEventStatus || ''}
                  SelectDisplayProps={{
                    'data-testid': 'triggerEventStatus',
                  }}
                  color="secondary"
                  fullWidth
                >
                  {triggerStatusTypes?.map((item) => (
                    <StyledMenuItem
                      key={item.id}
                      value={item.id}
                      color="secondary"
                    >
                      {item.status}
                    </StyledMenuItem>
                  ))}
                </StyledSelectField>
              </TriggerEventStatusDiv>
              <TriggerTitle>
                <Typography variant="h5">Gatilho</Typography>
              </TriggerTitle>
              <ActionTriggerContainer>
                <ActionTriggerTypeContainer>
                  <RadioGroup
                    aria-label="actionTriggerType"
                    data-test-id="actionTriggerType-radio"
                    name="actionTriggerType"
                    value={formik.values.actionTriggerType}
                  >
                    <StyledFormControlLabel
                      value="consecutive"
                      control={<Radio color="primary" />}
                      onChange={() =>
                        formik.setFieldValue('actionTriggerType', 'consecutive')
                      }
                      label="Respostas consecutivas"
                    />
                    <StyledFormControlLabel
                      value="answers_quantity_timelapse"
                      control={<Radio color="primary" />}
                      onChange={() =>
                        formik.setFieldValue(
                          'actionTriggerType',
                          'answers_quantity_timelapse'
                        )
                      }
                      label="Respostas em um intervalo de tempo"
                    />
                    <StyledFormControlLabel
                      value="answer_adherence"
                      control={<Radio color="primary" />}
                      onChange={() =>
                        formik.setFieldValue(
                          'actionTriggerType',
                          'answer_adherence'
                        )
                      }
                      label="Adesão de respostas"
                    />
                    {formik.errors.actionTriggerType && (
                      <FormHelperText error={true}>
                        {formik.errors.actionTriggerType}
                      </FormHelperText>
                    )}
                  </RadioGroup>
                </ActionTriggerTypeContainer>
                {formik.values.actionTriggerType == 'consecutive' && (
                  <TriggerThresholdTextBox>
                    <FormikTextField
                      name="triggerThreshold"
                      label="Número"
                      margin="normal"
                      value={numberMask(formik.values.triggerThreshold)}
                      inputProps={{
                        'data-testid': 'triggerThreshold',
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            respostas consecutivas
                          </InputAdornment>
                        ),
                      }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </TriggerThresholdTextBox>
                )}
                {formik.values.actionTriggerType ==
                  'answers_quantity_timelapse' && (
                  <>
                    <TriggerThresholdTextBox>
                      <FormikTextField
                        name="triggerThreshold"
                        label="Número"
                        margin="normal"
                        value={numberMask(formik.values.triggerThreshold)}
                        inputProps={{
                          'data-testid': 'triggerThreshold',
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              respostas
                            </InputAdornment>
                          ),
                        }}
                        InputLabelProps={{ shrink: true }}
                      />
                    </TriggerThresholdTextBox>
                    <TriggerTimeTextBox>
                      <FormikTextField
                        name="triggerTimeLapseInHours"
                        label="Tempo"
                        margin="normal"
                        value={numberMask(
                          formik.values.triggerTimeLapseInHours
                        )}
                        inputProps={{
                          'data-testid': 'triggerTimeLapseInHours',
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              Horas
                            </InputAdornment>
                          ),
                        }}
                        InputLabelProps={{ shrink: true }}
                      />
                    </TriggerTimeTextBox>
                  </>
                )}
                {formik.values.actionTriggerType == 'answer_adherence' && (
                  <>
                    <TriggerAdherenceTypeTextBox>
                      <StyledSelectField
                        name="triggerAdherenceType"
                        label="Tipo"
                        value={formik.values.triggerAdherenceType || ''}
                        SelectDisplayProps={{
                          'data-testid': 'triggerAdherenceType',
                        }}
                        color="secondary"
                        fullWidth
                      >
                        {triggerAdherenceTypes?.map((item) => (
                          <StyledMenuItem
                            key={item.id}
                            value={item.id}
                            color="secondary"
                          >
                            {item.type}
                          </StyledMenuItem>
                        ))}
                      </StyledSelectField>
                    </TriggerAdherenceTypeTextBox>
                    <TriggerTimeTextBox>
                      <FormikTextField
                        name="triggerAdherenceInPercentage"
                        label="Porcentagem"
                        margin="normal"
                        value={numberMask(
                          formik.values.triggerAdherenceInPercentage
                        )}
                        inputProps={{
                          'data-testid': 'triggerAdherenceInPercentage',
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                          ),
                        }}
                        InputLabelProps={{ shrink: true }}
                      />
                    </TriggerTimeTextBox>
                    <TriggerTimeTextBox>
                      <FormikTextField
                        name="triggerTimeLapseInHours"
                        label="Tempo"
                        margin="normal"
                        value={numberMask(
                          formik.values.triggerTimeLapseInHours
                        )}
                        inputProps={{
                          'data-testid': 'triggerTimeLapseInHours',
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              Horas
                            </InputAdornment>
                          ),
                        }}
                        InputLabelProps={{ shrink: true }}
                      />
                    </TriggerTimeTextBox>
                  </>
                )}
              </ActionTriggerContainer>
              <ActionOutcomeTypeContainer>
                <Typography variant="h5">Ação gerada</Typography>
                <RadioGroup
                  aria-label="actionOutcomeType"
                  data-test-id="actionOutcomeType-radio"
                  name="actionOutcomeType"
                  value={formik.values.actionOutcomeType}
                >
                  <StyledFormControlLabel
                    value="activate_care_question"
                    control={<Radio color="primary" />}
                    onChange={() =>
                      formik.setFieldValue(
                        'actionOutcomeType',
                        'activate_care_question'
                      )
                    }
                    label="Ativação de classificação"
                  />
                  <StyledFormControlLabel
                    value="deactivate_care_line"
                    control={<Radio color="primary" />}
                    onChange={() =>
                      formik.setFieldValue(
                        'actionOutcomeType',
                        'deactivate_care_line'
                      )
                    }
                    label="Desativação de linha de cuidado"
                  />
                  <StyledFormControlLabel
                    value="activate_additional_care_question"
                    control={<Radio color="primary" />}
                    onChange={() =>
                      formik.setFieldValue(
                        'actionOutcomeType',
                        'activate_additional_care_question'
                      )
                    }
                    label="Ativação de classificação complementar"
                  />
                  <StyledFormControlLabel
                    value="activate_static_care_line"
                    control={<Radio color="primary" />}
                    onChange={() =>
                      formik.setFieldValue(
                        'actionOutcomeType',
                        'activate_static_care_line'
                      )
                    }
                    label="Ativação de classificação estática"
                  />
                  {formik.errors.actionOutcomeType && (
                    <FormHelperText error={true}>
                      {formik.errors.actionOutcomeType}
                    </FormHelperText>
                  )}
                </RadioGroup>
              </ActionOutcomeTypeContainer>
              {mustHaveNewCareQuestion(formik.values.actionOutcomeType) && (
                <AutocompleteTextField
                  onListValueClick={(item: AutoCompleteOnChangeValue) => {
                    handleNewCareQuestionListItemClick(
                      item,
                      formik.setFieldValue
                    );
                  }}
                  onChangeDebounced={handleCareQuestionAutoCompleteOnChange}
                  name="newCareQuestionId"
                  label="Linha de cuidado - Classificação"
                  initialValue={newCareQuestionAutoCompleteInitialValue}
                  testId="newCareQuestionAutocomplete"
                />
              )}
              {formik.values.actionOutcomeType == 'deactivate_care_line' && (
                <AutocompleteTextField
                  onListValueClick={(item: AutoCompleteOnChangeValue) => {
                    handleDeactivatedCareLineListItemClick(
                      item,
                      formik.setFieldValue
                    );
                  }}
                  onChangeDebounced={handleCareLineAutoCompleteOnChange}
                  name="deactivatedCareLineId"
                  label="Linha de cuidado"
                  initialValue={deactivatedCareLineAutoCompleteInitialValue}
                  testId="deactivatedCareLineAutocomplete"
                />
              )}
              {formik.values.actionOutcomeType ==
                'activate_static_care_line' && (
                <StaticCareLineContainer>
                  <StaticCareLineTextBox>
                    <AutocompleteTextField
                      onListValueClick={(item: AutoCompleteOnChangeValue) => {
                        handleNewCareLineListItemClick(
                          item,
                          formik.setFieldValue
                        );
                      }}
                      onChangeDebounced={handleCareLineAutoCompleteOnChange}
                      name="newCareLineId"
                      label="Linha de cuidado"
                      testId="newCareLineAutocomplete"
                      initialValue={newCareLineAutoCompleteInitialValue}
                    />
                  </StaticCareLineTextBox>
                  <StaticClassificationTextBox>
                    <StyledSelectField
                      name="classification"
                      label="Classificação"
                      value={formik.values.classification || ''}
                      SelectDisplayProps={{
                        'data-testid': 'classification',
                      }}
                      color="secondary"
                    >
                      {optionClassifications?.map((item) => (
                        <StyledMenuItem
                          key={item.id}
                          value={item.id}
                          color="secondary"
                        >
                          {item.type}
                        </StyledMenuItem>
                      ))}
                    </StyledSelectField>
                  </StaticClassificationTextBox>
                </StaticCareLineContainer>
              )}
              <EmailContainer>
                <StyledFormControl fullWidth>
                  <FormGroup aria-label="position">
                    <StyledFormControlLabel
                      value="sendEmailGuardian"
                      name="sendEmailGuardian"
                      control={
                        <Checkbox
                          color="primary"
                          checked={formik.values.sendEmailGuardian}
                          onChange={(e) =>
                            formik.setFieldValue(
                              'sendEmailGuardian',
                              e.target.checked
                            )
                          }
                        />
                      }
                      label="Enviar e-mail para a família"
                    />
                    {formik.values.sendEmailGuardian && (
                      <FormikTextField
                        name="emailMessageAddition"
                        label="Texto personalizado do e-mail"
                        margin="normal"
                        value={formik.values.emailMessageAddition}
                        inputProps={{
                          'data-testid': 'emailMessageAddition',
                        }}
                      />
                    )}
                    <StyledFormControlLabel
                      value="hasRecommendedContent"
                      name="hasRecommendedContent"
                      control={
                        <Checkbox
                          color="primary"
                          checked={formik.values.hasRecommendedContent}
                          onChange={(e) => {
                            formik.setFieldValue(
                              'hasRecommendedContent',
                              e.target.checked
                            );
                          }}
                        />
                      }
                      label="Configurar conteúdo recomendo para cuidador"
                    />
                  </FormGroup>
                </StyledFormControl>
                {formik.values.hasRecommendedContent && (
                  <AutocompleteTextField
                    onListValueClick={(item: AutoCompleteOnChangeValue) => {
                      handleContentListItemClick(item, formik.setFieldValue);
                    }}
                    onChangeDebounced={handleContentAutoCompleteOnChange}
                    name="contentId"
                    label="Conteúdo recomendado"
                    initialValue={contentAutoCompleteInitialValue}
                    testId="contentAutocomplete"
                  />
                )}
              </EmailContainer>
              <FooterContainer>
                <StyledButton
                  color="inherit"
                  data-testid="submit"
                  type="submit"
                  disabled={formik.isSubmitting}
                >
                  Salvar
                </StyledButton>
              </FooterContainer>
            </FormContainer>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ActionEvent;
