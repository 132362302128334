import Select from '@material-ui/core/Select';
import styled from 'styled-components';

const StyledSelectField = styled(Select).attrs({ variant: 'outlined' })`
  & label {
    color: ${({ theme }) => theme.palette.primary.main};
  }
  & input {
    color: ${({ theme }) => theme.palette.primary.main};
  }
  & textarea {
    color: ${({ theme }) => theme.palette.primary.main};
  }
  & .MuiOutlinedInput-root:not(.Mui-error) {
    color: ${({ theme }) => theme.palette.primary.main};
    & fieldset {
      border-color: ${({ theme }) => theme.palette.primary.main};
      color: ${({ theme }) => theme.palette.primary.main};
    }
    &:hover fieldset {
      border-color: ${({ theme }) => theme.palette.primary.main};
      color: ${({ theme }) => theme.palette.primary.main};
    }
    &.Mui-focused fieldset {
      border-color: ${({ theme }) => theme.palette.primary.main};
      color: ${({ theme }) => theme.palette.primary.main};
    }
  }
  color: ${({ theme }) => theme.palette.primary.main};
`;

export default StyledSelectField;
