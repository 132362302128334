import styled from 'styled-components';
import { Container, Typography } from '@material-ui/core';
import { CloudDownload } from '@material-ui/icons';

export const StyledContainer = styled(Container)`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const StyledClickContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const StyledTypography = styled(Typography)`
  text-align: center;
`;

export const StyledIcon = styled(CloudDownload)`
  font-size: ${({ theme }) => theme.spacing(15)}px;
  color: ${({ theme }) => theme.palette.secondary.main};
  transition-delay: 0.2s;
`;
