import React from 'react';
import HeaderBase from '../../../../components/HeaderBase';
import { useHistory } from 'react-router-dom';
import { Container } from './styles';
import { Typography } from '@material-ui/core';
import useAction from '../../../../hooks/useAction';
import { resolveErrorMessage } from '../../../../utils/error';
import { AxiosError } from 'axios';
import { APIError } from '@cuidador/lib';
import { toast } from 'react-toastify';
import { ActionModel } from '@cuidador/database';
import ActionUpsertForm from '../../../../components/ActionUpsertForm';

const ActionItem: React.FC = () => {
  const { post } = useAction();
  const history = useHistory();

  const handleSubmit = (data: ActionModel) => {
    return post(data)
      .then(() => {
        history.goBack();
      })
      .catch((err: AxiosError<APIError>) => {
        const displayMessage = resolveErrorMessage(err);
        toast.error(displayMessage);
      });
  };

  return (
    <>
      <HeaderBase title={'CONFIGURAR AÇÕES'} />
      <Container>
        <Typography variant="h5">Tipo</Typography>
        <ActionUpsertForm onSubmit={handleSubmit} />
      </Container>
    </>
  );
};

export default ActionItem;
