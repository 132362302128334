import { PatientModel } from '@cuidador/database';
import * as Yup from 'yup';
import { cepMask, cpfMask, phoneMask } from '../../../utils/inputs';

export const validationSchema = Yup.object().shape({
  gender: Yup.mixed().required('Por favor, selecione um sexo'),
  nickname: Yup.string().nullable(),
  livesWith: Yup.string().nullable(),
  educationLevel: Yup.mixed().nullable(),
  martialStatus: Yup.mixed().nullable(),
  religion: Yup.mixed().nullable(),
  hasHealthPlan: Yup.mixed().nullable(),
  healthPlanName: Yup.string().nullable(),
  isMonitoredByCaregiver: Yup.mixed().nullable(),
  isMonitoredSince: Yup.string().nullable(),
  infoToCaregiver: Yup.string().nullable(),
  postalCode: Yup.string().nullable(),
  province: Yup.mixed().nullable(),
  streetName: Yup.string().nullable(),
  streetNumber: Yup.string().nullable(),
  streetComplement: Yup.string().nullable(),
  city: Yup.string().nullable(),
  doctorSpecialty: Yup.string().nullable(),
  doctorName: Yup.string().nullable(),
  doctorPhoneNumber: Yup.string().nullable(),
  doctorEmail: Yup.string().email().nullable(),
});

export interface FormValues {
  cpf?: string;
  gender?: PatientModel['gender'];
  nickname?: string;
  livesWith?: string;
  educationLevel?: PatientModel['educationLevel'];
  martialStatus?: PatientModel['martialStatus'];
  religion?: PatientModel['religion'];
  hasHealthPlan?: number;
  healthPlanName?: string;
  isMonitoredByCaregiver?: number;
  isMonitoredSince?: string;
  postalCode?: string;
  province?: string;
  streetName?: string;
  streetNumber?: string;
  streetComplement?: string;
  city?: string;
  infoToCaregiver?: string;
  guardianName?: string;
  guardianCpf?: string;
  guardianPhoneNumber?: string;
  guardianEmail?: string;
  guardianHowMetUs?: string;
  guardianRelationToPatient?: string;
  doctorSpecialty?: string;
  doctorName?: string;
  doctorPhoneNumber?: string;
  doctorEmail?: string;
}

export const PtBrProvinces = [
  { id: 'AC', name: 'Acre' },
  { id: 'AL', name: 'Alagoas' },
  { id: 'AP', name: 'Amapá' },
  { id: 'AM', name: 'Amazonas' },
  { id: 'BA', name: 'Bahia' },
  { id: 'CE', name: 'Ceará' },
  { id: 'DF', name: 'Distrito Federal' },
  { id: 'ES', name: 'Espírito Santo' },
  { id: 'GO', name: 'Goiás' },
  { id: 'MA', name: 'Maranhão' },
  { id: 'MT', name: 'Mato Grosso' },
  { id: 'MS', name: 'Mato Grosso do Sul' },
  { id: 'MG', name: 'Minas Gerais' },
  { id: 'PA', name: 'Pará' },
  { id: 'PB', name: 'Paraíba' },
  { id: 'PR', name: 'Paraná' },
  { id: 'PE', name: 'Pernambuco' },
  { id: 'PI', name: 'Piauí' },
  { id: 'RJ', name: 'Rio de Janeiro' },
  { id: 'RN', name: 'Rio Grande do Norte' },
  { id: 'RS', name: 'Rio Grande do Sul' },
  { id: 'RO', name: 'Rondônia' },
  { id: 'RR', name: 'Roraima' },
  { id: 'SC', name: 'Santa Catarina' },
  { id: 'SP', name: 'São Paulo' },
  { id: 'SE', name: 'Sergipe' },
  { id: 'TO', name: 'Tocantins' },
];

export const patientModelToFormData = (data: PatientModel) => {
  return {
    cpf: cpfMask(data.cpf || ''),
    nickname: data.nickname || '',
    livesWith: data.livesWith || '',
    educationLevel: data.educationLevel || '',
    martialStatus: data.martialStatus || '',
    religion: data.religion || '',
    hasHealthPlan:
      data.hasHealthPlan === undefined ? undefined : data.hasHealthPlan,
    healthPlanName: data.healthPlanName || '',
    isMonitoredByCaregiver:
      data.isMonitoredByCaregiver === undefined
        ? undefined
        : data.isMonitoredByCaregiver,
    isMonitoredSince: data.isMonitoredSince || '',
    gender: data.gender || undefined,
    postalCode: cepMask(data.postalCode || ''),
    province: data.province || '',
    streetName: data?.streetName || '',
    streetNumber: data?.streetNumber || '',
    streetComplement: data?.streetComplement || '',
    city: data?.city || '',
    infoToCaregiver: data.infoToCaregiver || '',
    guardianName: data.organization?.createdBy?.name || '',
    guardianCpf: data.organization?.createdBy?.cpf || '',
    guardianPhoneNumber: phoneMask(
      data.organization?.createdBy?.phoneNumber || ''
    ),
    guardianEmail: data.organization?.createdBy?.email || '',
    guardianHowMetUs: data.organization?.createdBy?.howMetUs || '',
    guardianRelationToPatient: '',
    doctorSpecialty: data.doctorSpecialty || '',
    doctorName: data.doctorName || '',
    doctorPhoneNumber: data.doctorPhone || '',
    doctorEmail: data.doctorEmail || '',
  };
};

export const formDataToPatientModel = (data: FormValues) => {
  return {
    // cpf: data.cpf, [Warn] CPF is striped off, since it cannot be changed by the Admin interface
    nickname: data.nickname || '',
    livesWith: data.livesWith || '',
    educationLevel: data.educationLevel || undefined,
    martialStatus: data.martialStatus || undefined,
    religion: data.religion || undefined,
    hasHealthPlan:
      data.hasHealthPlan === null ? undefined : Boolean(data.hasHealthPlan),
    healthPlanName: Boolean(data.hasHealthPlan) ? data.healthPlanName : '',
    isMonitoredByCaregiver:
      data.isMonitoredByCaregiver === null
        ? undefined
        : Boolean(data.isMonitoredByCaregiver),
    isMonitoredSince: Boolean(data.isMonitoredByCaregiver)
      ? data.isMonitoredSince
      : '',
    gender: data.gender,
    postalCode: data.postalCode || '',
    province: data.province || '',
    streetName: data.streetName || '',
    streetNumber: data.streetNumber || '',
    streetComplement: data.streetComplement || '',
    city: data.city || '',
    infoToCaregiver: data.infoToCaregiver || '',
    doctorSpecialty: data.doctorSpecialty || '',
    doctorName: data.doctorName || '',
    doctorPhone: data.doctorPhoneNumber || '',
    doctorEmail: data.doctorEmail || '',
  };
};
