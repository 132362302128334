import { ReportModel } from '@cuidador/database';
import { IconButton, TableCell } from '@material-ui/core';
import Refresh from '@material-ui/icons/Refresh';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import StyledButton from '../../../../components/StyledButton';
import useReport from '../../../../hooks/useReport';
import { resolveErrorMessage } from '../../../../utils/error';
import { DeleteButton } from '../styles';
import { formatReportPeriod, formatStatus } from '../utils';

const ReportListItem: React.FC<
  ReportModel & {
    patientId: Id;
    setReportToBeRemoved: (report: Partial<ReportModel>) => void;
  }
> = ({
  id,
  name,
  startDate,
  endDate,
  patientId,
  isDraft,
  setReportToBeRemoved,
}) => {
  const { getReportUrl, regenerateReport } = useReport();
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const handleViewReport = async () => {
    try {
      setLoading(true);
      const {
        data: { signedUrl },
      } = await getReportUrl(patientId, id!);
      window.open(signedUrl, '_blank');
    } catch (err) {
      toast.error(resolveErrorMessage(err));
    } finally {
      setLoading(false);
    }
  };

  const handleRegenerateReport = async () => {
    try {
      setLoading(true);
      await regenerateReport(id!);
      toast.success('Relatório regerado com sucesso.');
    } catch (err) {
      toast.error(resolveErrorMessage(err));
    } finally {
      setLoading(false);
    }
  };

  const handleEditReport = async () => {
    history.push(`${history.location.pathname}/${id}/configurar`);
  };

  const handleRemoveReport = () => {
    setReportToBeRemoved({ id, name, patientId });
  };

  return (
    <>
      <TableCell>{name}</TableCell>
      <TableCell>{formatReportPeriod(startDate!, endDate!)}</TableCell>
      <TableCell>{formatStatus(Boolean(isDraft))}</TableCell>
      <TableCell>
        {!isDraft && (
          <IconButton
            data-testid={`regenerate-report-${id}`}
            color="primary"
            size="small"
            disabled={loading}
            onClick={handleRegenerateReport}
          >
            <Refresh />
          </IconButton>
        )}
      </TableCell>
      <TableCell align="right">
        {!isDraft && (
          <StyledButton
            data-testid={`view-report-${id}`}
            color="primary"
            variant="text"
            size="small"
            disabled={loading}
            onClick={handleViewReport}
          >
            VISUALIZAR
          </StyledButton>
        )}
        {!!isDraft && (
          <StyledButton
            data-testid={`edit-report-${id}`}
            color="primary"
            variant="text"
            size="small"
            disabled={loading}
            onClick={handleEditReport}
          >
            EDITAR
          </StyledButton>
        )}
      </TableCell>
      <TableCell>
        <DeleteButton
          data-testid={`remove-report-${id}`}
          color="primary"
          onClick={handleRemoveReport}
        />
      </TableCell>
    </>
  );
};

export default ReportListItem;
