import { PatientModel } from '@cuidador/database';

export type PatientStatusType = 'active' | 'inactive' | 'pending';

export const getPatientStatus = (
  patientStatus: NonNullable<PatientModel['status']>,
  patientIsNew: boolean
): PatientStatusType => {
  if (patientStatus === 'disabled') return 'inactive';
  if (patientStatus === 'enabled' && patientIsNew) return 'pending';
  return 'active';
};

export const getPatientStatusLabelAfterReset = (
  patientIsNew: boolean
): string => {
  return resolveStatusLabel(getPatientStatus('enabled', patientIsNew));
};

export const resolveStatusLabel = (
  patientStatus: PatientStatusType
): string => {
  if (patientStatus === 'pending') return 'PENDENTE';
  if (patientStatus === 'active') return 'ATIVO';
  return 'INATIVO';
};

export const getStatusDescription = (status: PatientStatusType) => {
  switch (status) {
    case 'inactive':
      return 'Uso suspenso. Nesse status, não é possível registrar plantões.';
    case 'pending':
      return 'Ainda não foram iniciados plantões para monitoramento dos cuidados.';
    case 'active':
      return 'Uso iniciado.';
    default:
      return '-';
  }
};

export const resolveDisablingReasonLabel = (
  reason: PatientModel['disablingReason']
) => {
  switch (reason) {
    case 'death':
      return 'Óbito';
    case 'cancellation':
      return 'Atendimento cancelado';
    case 'temporary':
      return 'Suspensão temporária (ex. internação, viagem, etc)';
    default:
      return 'Outro';
  }
};
