import React, { useEffect, useState } from 'react';
import HeaderBase from '../../../../components/HeaderBase';
import { useHistory, useParams } from 'react-router-dom';
import { Container } from './styles';
import { Typography } from '@material-ui/core';
import { resolveErrorMessage } from '../../../../utils/error';
import { toast } from 'react-toastify';
import useCareQuestion from '../../../../hooks/useCareQuestion';
import { AxiosError } from 'axios';
import { APIError } from '@cuidador/lib';
import { CareQuestionModel } from '@cuidador/database';
import CareQuestionUpsertForm from '../../../../components/CareQuestionUpsertForm';
import {
  careQuestionModelToFormData,
  FormValues,
} from '../../../../components/CareQuestionUpsertForm/utils';
import LoadingBackdrop from '../../../../components/LoadingBackdrop';
import StyledSimpleDialog from '../../../../components/StyledSimpleDialog';

const CareQuestionItem: React.FC = () => {
  const [
    initialCareQuestionData,
    setInitialCareQuestionData,
  ] = useState<FormValues | null>(null);
  const [
    careQuestionToBeEdited,
    setCareQuestionToBeEdited,
  ] = useState<CareQuestionModel | null>(null);
  const [
    cancelEditCareQuestionDialogOpen,
    setCancelEditCareQuestionDialogOpen,
  ] = useState(false);
  const params = useParams<{ id: string }>();
  const { loading, getById, patch } = useCareQuestion();
  const history = useHistory();

  const id = Number(params.id);
  useEffect(
    function fetchInitialValues() {
      if (id) {
        getCareQuestionData();
      }
    },
    [id]
  );

  const getCareQuestionData = async () => {
    try {
      const careQuestion = (await getById(id)) as CareQuestionModel;
      setInitialCareQuestionData(careQuestionModelToFormData(careQuestion));
    } catch (err) {
      const displayMessage = resolveErrorMessage(err);
      toast.error(displayMessage);
    }
  };

  const handleOpenEditConfirmation = (data: CareQuestionModel) => {
    setCareQuestionToBeEdited(data);
  };

  const handleCancel = () => {
    setCancelEditCareQuestionDialogOpen(true);
  };

  const handleSubmit = () => {
    return patch(id, careQuestionToBeEdited!)
      .then(() => {
        history.goBack();
      })
      .catch((err: AxiosError<APIError>) => {
        const displayMessage = resolveErrorMessage(err);
        toast.error(displayMessage);
      })
      .finally(() => setCareQuestionToBeEdited(null));
  };

  if (loading) return <LoadingBackdrop loading={loading} />;

  return (
    <>
      <HeaderBase
        title={'PERGUNTA DE CUIDADO'}
        onBackButtonClick={handleCancel}
      />
      <Container>
        <Typography variant="h5">Pergunta de cuidado</Typography>
        {initialCareQuestionData && (
          <CareQuestionUpsertForm
            initialValues={initialCareQuestionData}
            onSubmit={handleOpenEditConfirmation}
            disableDefaultIsSubmittingHandle={true}
          />
        )}
        <StyledSimpleDialog
          open={Boolean(careQuestionToBeEdited)}
          handleNo={() => setCareQuestionToBeEdited(null)}
          handleYes={handleSubmit}
          title={'Tem certeza que deseja editar essa pergunta de cuidado?'}
          subTitle="Todas as alterações serão salvas."
        />
        <StyledSimpleDialog
          open={cancelEditCareQuestionDialogOpen}
          handleNo={() => setCancelEditCareQuestionDialogOpen(false)}
          handleYes={() => history.goBack()}
          title={
            'Tem certeza que deseja cancelar a edição dessa pergunta de cuidado?'
          }
          subTitle="Todas as alterações serão perdidas."
        />
      </Container>
    </>
  );
};

export default CareQuestionItem;
