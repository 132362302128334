import React, { useEffect, useState } from 'react';

import AddIcon from '@material-ui/icons/AddCircleOutlineRounded';
import DeleteIcon from '@material-ui/icons/Delete';
import { ReactComponent as EditIcon } from '../../../assets/Icon-edit.svg';
import { TableCell, Typography } from '@material-ui/core';
import MyTable from '../../../components/MyTable';
import useEventSubCategory from '../../../hooks/useEventSubCategory';
import { EventSubCategoryModel } from '@cuidador/database';
import { resolveErrorMessage } from '../../../utils/error';
import { AxiosError } from 'axios';
import { APIError } from '@cuidador/lib';
import { toast } from 'react-toastify';
import qs from 'query-string';
import { useHistory } from 'react-router-dom';

import {
  resolveCategoryNameById,
  resolveCategoryListLabelById,
  formDataToEventSubCategoryModel,
  FormValues,
} from './utils';
import SubCategoryDialog from './SubCategoryDialog';

import {
  Container,
  AddButton,
  Header,
  EditIconButton,
  DeleteIconButton,
} from './styles';
import SearchTextField from '../../../components/SearchTextField';
import StyledSimpleDialog from '../../../components/StyledSimpleDialog';

const tableColumns = ['Categoria', 'Tipo', 'Lista', ''];

const Events: React.FC = () => {
  const {
    getPaginated,
    post,
    patch,
    remove,
    ids,
    total,
    byId,
    loading,
  } = useEventSubCategory();
  const [
    subCategoryDialogOpen,
    setSubCategoryDialogOpen,
  ] = React.useState<boolean>(false);

  const [
    subCategoryBeingEdited,
    setSubCategoryBeingEdited,
  ] = React.useState<EventSubCategoryModel>();

  const [deleteDialogStatus, setDeleteDialogStatus] = useState(false);
  const [
    subCategoryBeingDeleted,
    setSubCategoryBeingDeleted,
  ] = React.useState<EventSubCategoryModel>();

  const history = useHistory();

  const limit = parseInt(String(qs.parse(history.location.search).limit)) || 20;

  useEffect(() => {
    onChangeParams();
  }, []);

  const onChangeParams = () => {
    const params = qs.parse(history.location.search);
    getPaginated({
      ...params,
      limit: Number(params.limit) || 20,
    });
  };

  const renderEventsSubCategoryItem = (item: EventSubCategoryModel) => {
    return (
      <>
        <TableCell>{resolveCategoryNameById(item?.categoryId)}</TableCell>
        <TableCell>{item?.name}</TableCell>
        <TableCell>
          {item?.categoryId === 1
            ? resolveCategoryListLabelById(item?.isRoutine, item?.isAppointment)
            : '-'}
        </TableCell>
        <TableCell align="right">
          <EditIconButton>
            <EditIcon
              onClick={() => setSubCategoryBeingEdited(item)}
              data-testid="edit-dialog-open"
            />
          </EditIconButton>
          <DeleteIconButton>
            <DeleteIcon
              onClick={() => {
                setSubCategoryBeingDeleted(item);
                setDeleteDialogStatus(true);
              }}
              data-testid="delete-dialog-open"
            />
          </DeleteIconButton>
        </TableCell>
      </>
    );
  };

  const cancelDialog = () => {
    if (subCategoryDialogOpen) {
      return setSubCategoryDialogOpen(false);
    } else {
      return setSubCategoryBeingEdited(undefined);
    }
  };

  const postSubCategory = (values: FormValues) => {
    subCategoryBeingEdited
      ? patch(
          subCategoryBeingEdited!.id!,
          formDataToEventSubCategoryModel(values)
        )
          .then(() => {
            setSubCategoryBeingEdited(undefined);
          })
          .catch((err: AxiosError<APIError>) => {
            const displayMessage = resolveErrorMessage(err);
            toast.error(displayMessage);
          })
      : post(formDataToEventSubCategoryModel(values))
          .then(() => {
            setSubCategoryDialogOpen(false);
          })
          .catch((err: AxiosError<APIError>) => {
            const displayMessage = resolveErrorMessage(err);
            toast.error(displayMessage);
          });
  };

  const deleteSubCategory = (id: Id) => {
    remove(id)
      .then(() => {
        // Forces table refresh
        setDeleteDialogStatus(false);
        return onChangeParams();
      })
      .catch((err: AxiosError<APIError>) => {
        const displayMessage = resolveErrorMessage(err);
        toast.error(displayMessage);
      });
  };

  const data = ids.map((id) => byId[id]);

  return (
    <Container>
      <Header>
        <AddButton
          data-testid="create-subcategory-dialog-open"
          onClick={() => setSubCategoryDialogOpen(true)}
        >
          <AddIcon fontSize="large" color="primary" />
          <Typography variant="h5">Adicionar</Typography>
        </AddButton>
        <SearchTextField
          fieldName="eventSubCategory.name"
          onChangeDebounced={onChangeParams}
        />
      </Header>
      <MyTable
        columnNames={tableColumns}
        data={data}
        totalPages={Math.ceil(total / limit)}
        hasPagination={true}
        keyExtractor={({ item }) => String(item!.id)}
        renderItem={renderEventsSubCategoryItem}
        onChangePage={onChangeParams}
        onChangeRowsPerPage={onChangeParams}
        loading={loading}
      />
      <SubCategoryDialog
        open={subCategoryDialogOpen || Boolean(subCategoryBeingEdited)}
        subCategory={subCategoryBeingEdited}
        onCancel={() => cancelDialog()}
        onConfirm={postSubCategory}
      />
      <StyledSimpleDialog
        open={deleteDialogStatus}
        handleNo={() => setDeleteDialogStatus(false)}
        handleYes={() =>
          deleteSubCategory(
            subCategoryBeingDeleted?.id ? subCategoryBeingDeleted.id : 0
          )
        }
        title={`Tem certeza que deseja excluir a subcategoria ${subCategoryBeingDeleted?.name}?`}
      />
    </Container>
  );
};

export default Events;
