import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { TableCell, Typography } from '@material-ui/core';
import MyTable from '../../../components/MyTable';
import { TextWrap } from '../../Content/styles';
import { ReactComponent as EditIcon } from '../../../assets/Icon-edit.svg';
import { AddButton, TableContainer, Container, EditIconButton } from './styles';
import useRoles from '../../../hooks/useRoles';
import AddIcon from '@material-ui/icons/AddCircleOutlineRounded';
import { RoleModel } from '@cuidador/database';

const columnNames = ['Nome', 'Descrição', 'Visibilidade', ''];

const renderItem = (item: RoleModel) => {
  const history = useHistory();

  const handleViewProfileInfo = (id: number) =>
    history.push(`/usuarios/perfis/${id}`);

  const resolveIsVisibleLabel = (isVisible?: boolean) => {
    return isVisible ? 'Público' : '';
  };

  return (
    <>
      <TableCell>
        <TextWrap>{item.title}</TextWrap>
      </TableCell>
      <TableCell>
        <TextWrap>{item.description}</TextWrap>
      </TableCell>
      <TableCell>{resolveIsVisibleLabel(item?.isVisible)}</TableCell>
      <TableCell align="right">
        <EditIconButton>
          <EditIcon
            data-testid="edit-dialog-open"
            onClick={() => handleViewProfileInfo(Number(item.id))}
          />
        </EditIconButton>
      </TableCell>
    </>
  );
};
const RolesList: React.FC = () => {
  const history = useHistory();

  const { ids, byId, loading, getAll } = useRoles();

  useEffect(() => {
    getAll();
  }, [getAll]);

  const roles = ids.map((roleId) => byId[roleId]);

  return (
    <Container>
      <TableContainer>
        <MyTable
          columnNames={columnNames}
          data={roles}
          renderItem={renderItem}
          keyExtractor={({ item }) => String(item!.id)}
          hasPagination={false}
          loading={loading}
        />
      </TableContainer>
      <AddButton
        data-testid="create-carecategory-dialog-open"
        onClick={() => history.push('/usuarios/perfis/novo')}
      >
        <AddIcon fontSize="large" color="primary" />
        <Typography variant="h6">Novo Tipo de Perfil</Typography>
      </AddButton>
    </Container>
  );
};

export default RolesList;
