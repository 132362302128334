import styled from 'styled-components';

export const QuestionTypeContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${({ theme }) => theme.spacing(4)}px;
`;

export const CareLineDiv = styled.div`
  width: 70%;
  margin-right: ${({ theme }) => theme.spacing(6)}px;
`;

export const ActionOutcomeTypeContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.spacing(4)}px;
`;

export const EmailContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing(2)}px;
`;

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;
