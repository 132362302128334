import differenceInYears from 'date-fns/differenceInYears';
import React from 'react';
import { useHistory } from 'react-router-dom';
import user from '../../assets/user.png';
import StyledSwitch from '../StyledSwitch';
import {
  StyledHeader,
  StyledHeaderBackButton,
  StyledHeaderIcon,
  StyledHeaderImg,
  StyledHeaderLabel,
  SwitchContainer,
} from './styles';

interface Header {
  img?: string;
  name?: string;
  dateOfBirth?: string;
  checked?: boolean;
  onCheckedChange?: () => void;
}

const Header: React.FC<Header> = ({
  img,
  name,
  dateOfBirth,
  checked,
  onCheckedChange,
}: Header) => {
  const history = useHistory();

  const goBackNavigation = () => {
    history.length <= 1 ? history.push('/') : history.goBack();
  };

  let years = '';
  if (dateOfBirth) {
    const age = differenceInYears(new Date(), new Date(dateOfBirth));
    years = `${age} anos`;
  }

  const image = img ? img : user;

  return (
    <StyledHeader>
      <StyledHeaderBackButton onClick={goBackNavigation}>
        <StyledHeaderIcon />
      </StyledHeaderBackButton>
      <StyledHeaderImg src={image} />
      <StyledHeaderLabel>{name}</StyledHeaderLabel>
      <StyledHeaderLabel>{years}</StyledHeaderLabel>
      {!!onCheckedChange && (
        <SwitchContainer>
          <StyledSwitch
            checked={checked}
            data-testid="change-status-dialog-open"
            onChange={onCheckedChange}
          />
        </SwitchContainer>
      )}
    </StyledHeader>
  );
};

export default Header;
