import { ActionModel } from '@cuidador/database';

export function formatClassification(classification: string | undefined) {
  switch (classification) {
    case 'functional':
      return 'Funcional';
    case 'dysfunctional':
      return 'Disfuncional';
    case 'severe_dysfunctional':
      return 'Disfuncional grave';
    default:
      return '';
  }
}

export function formatType(type: string | undefined) {
  switch (type) {
    case 'shift_question':
      return 'Pergunta de cuidado';
    case 'interview_question':
      return 'Entrevista';
    case 'event':
      return 'Evento';
    default:
      return '';
  }
}

/**
 * The names are changed only for the user's visualization,
 * the actions are not being performed in the classification
 */
export function formatActionOutcomeType(actionOutcomeType: string | undefined) {
  switch (actionOutcomeType) {
    case 'replace_care_question':
      return 'Substituição de classificação';
    case 'deactivate_care_line':
      return 'Desativação de linha de cuidado';
    case 'activate_additional_care_question':
      return 'Ativação de pergunta de cuidado complementar';
    case 'activate_care_question':
      return 'Ativação de pergunta de cuidado';
    case 'activate_static_care_line':
      return 'Ativação de classificação estática';
    default:
      return '';
  }
}

export const formatDeletionActionText = (item: ActionModel) => {
  return item?.type === 'shift_question'
    ? `${item?.careQuestion?.careLine?.name} - ${formatClassification(
        item?.careQuestion?.classification
      )}`
    : `${item?.careQuestion?.careCategory?.name} - ${item?.careQuestion?.name}`;
};

export function formatSendEmailColumn(
  contentId: number | null | undefined,
  emailGuardian: boolean | undefined,
  emailDoctor: boolean | undefined
) {
  let textFormatted = '';
  contentId ? (textFormatted = 'Cuidador\n') : null;
  emailGuardian ? (textFormatted = textFormatted + 'Família\n') : null;
  emailDoctor ? (textFormatted = textFormatted + 'Médico\n') : null;
  return textFormatted;
}
