import styled from 'styled-components';
import { InputBase } from '@material-ui/core';

export const StyledInput = styled(InputBase)`
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
  color: ${({ theme }) => theme.palette.primary.main};
  background-color: ${({ theme }) => theme.palette.action.hover};
  padding: ${({ theme }) => theme.spacing(1, 1)};
  border-radius: 24px;
  max-width: 100%;
  input {
    font-size: ${({ theme }) => theme.typography.overline.fontSize};
    width: 32ch;
    padding: ${({ theme }) => theme.spacing(0, 1)};
  }
  .MuiInputAdornment-positionEnd {
    margin: 0;
  }
`;
