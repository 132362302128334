import { CareQuestionModel } from '@cuidador/database';
import { APIError } from '@cuidador/lib';
import { TableCell, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddCircleOutlineRounded';
import DeleteIcon from '@material-ui/icons/Delete';
import { AxiosError } from 'axios';
import qs from 'query-string';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ReactComponent as EditIcon } from '../../../assets/Icon-edit.svg';
import MyTable from '../../../components/MyTable';
import SearchTextField from '../../../components/SearchTextField';
import StyledSimpleDialog from '../../../components/StyledSimpleDialog';
import useCareQuestion from '../../../hooks/useCareQuestion';
import { resolveErrorMessage } from '../../../utils/error';
import {
  AddButton,
  Container,
  DeleteIconButton,
  EditIconButton,
  Header,
  IconContainer,
  TextWrap,
} from './styles';
import { formatClassification, formatFrequency } from './utils';

const tableColumns = [
  'Linha de cuidado - Classificação',
  'Frequência',
  'Descrição da pergunta',
  'Ativada automaticamente',
  '',
];

const CareQuestion: React.FC = () => {
  const { getPaginated, remove, ids, byId, total, loading } = useCareQuestion();
  const history = useHistory();
  const [deleteDialogStatus, setDeleteDialogStatus] = useState(false);
  const [
    careQuestionBeingDeleted,
    setCareQuestionBeingDeleted,
  ] = React.useState<CareQuestionModel>();

  const limit = parseInt(String(qs.parse(history.location.search).limit)) || 20;

  useEffect(() => {
    onChangeParams();
  }, []);

  const onChangeParams = () => {
    const params = qs.parse(history.location.search);
    getPaginated({
      ...params,
      type: 'shift_question',
      limit: Number(params.limit) || 20,
    });
  };

  const data = ids.map((id) => byId[id]);

  const openInsertCareQuestionPage = () => {
    history.push('/cuidados/perguntas-de-cuidado/adicionar');
  };
  const openUpdateCareQuestionPage = (id: Id) => {
    history.push(`/cuidados/perguntas-de-cuidado/${id}`);
  };

  const deleteCareQuestion = (id: Id) => {
    remove(id)
      .then(() => {
        // Forces table refresh
        return onChangeParams();
      })
      .catch((err: AxiosError<APIError>) => {
        const displayMessage = resolveErrorMessage(err);
        toast.error(displayMessage);
      })
      .finally(() => {
        setDeleteDialogStatus(false);
      });
  };

  const renderCareQuestionItem = (item: CareQuestionModel) => {
    return (
      <>
        <TableCell>
          {item?.careLine?.name} - {formatClassification(item?.classification)}
        </TableCell>
        <TableCell>{formatFrequency(item?.frequencyRule)}</TableCell>
        <TableCell>
          <TextWrap>{item?.careQuestionText}</TextWrap>
        </TableCell>
        <TableCell>
          <TextWrap>{item.shouldRunOnOnboarding ? 'Sim' : 'Não'}</TextWrap>
        </TableCell>
        <TableCell align="right">
          <IconContainer>
            <EditIconButton>
              <EditIcon
                onClick={() => openUpdateCareQuestionPage(Number(item.id))}
                data-testid="edit-dialog-open"
              />
            </EditIconButton>
            <DeleteIconButton>
              <DeleteIcon
                onClick={() => {
                  setCareQuestionBeingDeleted(item);
                  setDeleteDialogStatus(true);
                }}
                data-testid="delete-dialog-open"
              />
            </DeleteIconButton>
          </IconContainer>
        </TableCell>
      </>
    );
  };

  return (
    <Container>
      <Header>
        <AddButton
          data-testid="create-subcategory-dialog-open"
          onClick={openInsertCareQuestionPage}
        >
          <AddIcon fontSize="large" color="primary" />
          <Typography variant="h5">Adicionar</Typography>
        </AddButton>
        <SearchTextField
          fieldName="careLine.name"
          onChangeDebounced={onChangeParams}
        />
      </Header>
      <MyTable
        columnNames={tableColumns}
        data={data}
        totalPages={Math.ceil(total / limit)}
        keyExtractor={({ item }) => String(item!.id)}
        renderItem={renderCareQuestionItem}
        onChangePage={onChangeParams}
        onChangeRowsPerPage={onChangeParams}
        loading={loading}
      />
      <StyledSimpleDialog
        open={deleteDialogStatus}
        handleNo={() => setDeleteDialogStatus(false)}
        handleYes={() =>
          deleteCareQuestion(
            careQuestionBeingDeleted?.id ? careQuestionBeingDeleted.id : 0
          )
        }
        title={`Tem certeza que deseja excluir a pergunta de cuidado ${
          careQuestionBeingDeleted?.careLine?.name
        } - ${formatClassification(careQuestionBeingDeleted?.classification)}?`}
        subTitle="Todas as ações relacionados com ela serão excluídas também."
      />
    </Container>
  );
};

export default CareQuestion;
