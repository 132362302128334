import React from 'react';
import {
  Container,
  TypographyContainer,
  InputBox,
  Backdrop,
  BackdropCircularProgress,
} from './styles';

import { useHistory } from 'react-router-dom';
import { resolveErrorMessage } from '../../../utils/error';
import { AxiosError } from 'axios';
import { APIError } from '@cuidador/lib';
import { toast } from 'react-toastify';
import useContent from '../../../hooks/useContent';
import Header from '../../../components/HeaderBase';
import ContentUpsertForm from '../../../components/ContentUpsertForm';
import { FormValues } from '../../../components/ContentUpsertForm';
import { Typography } from '@material-ui/core';

const formInitialValue = {
  title: '',
  text: '',
};

const ContentInsert: React.FC = () => {
  const { post, loading } = useContent();
  const history = useHistory();

  const handleSubmit = (values: FormValues) => {
    post(values)
      .then(() => {
        history.goBack();
      })
      .catch((err: AxiosError<APIError>) => {
        const displayMessage = resolveErrorMessage(err);
        toast.error(displayMessage);
      });
  };

  if (loading) {
    return (
      <Backdrop open={loading}>
        {loading && (
          <BackdropCircularProgress data-testid="table-backdrop-spinner" />
        )}
      </Backdrop>
    );
  }

  return (
    <Container>
      <Header title="Adição de Conteúdo" />
      <InputBox>
        <TypographyContainer>
          <Typography variant="h4">Conteúdos</Typography>
        </TypographyContainer>
        <ContentUpsertForm
          initialValues={formInitialValue}
          onSubmit={handleSubmit}
        />
      </InputBox>
    </Container>
  );
};

export default ContentInsert;
