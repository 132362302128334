import { useCallback, useReducer } from 'react';
import axios from '../config/axios';
import { CareCategoryModel } from '@cuidador/database';
import { createReducer, ReducerData, Item } from '../utils/store/index';

const endpoint = '/care/category';

const initialData: ReducerData<CareCategoryModel> = {
  byId: {} as Record<string, Item<CareCategoryModel>>,
  ids: [] as Array<number>,
  total: 0,
  loading: false,
  error: null,
};

const useCareCategory = () => {
  const [state, dispatch] = useReducer(
    createReducer<CareCategoryModel>(),
    initialData
  );

  const getAll = useCallback(async () => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.get(`${endpoint}?orderBy=name`);
      dispatch({ type: 'GET_ALL', payload: response.data });
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
    }
  }, []);

  const post = useCallback(async (data: CareCategoryModel) => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.post(`${endpoint}`, data);
      dispatch({ type: 'CREATE', payload: response.data });
      return Promise.resolve(response);
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
      return Promise.reject(err);
    }
  }, []);

  const patch = useCallback(
    async (id: Id, data: Partial<CareCategoryModel>) => {
      try {
        dispatch({ type: 'LOADING' });
        const response = await axios.patch(`${endpoint}/${id}`, data);
        dispatch({ type: 'UPDATE', payload: response.data });
      } catch (err) {
        dispatch({ type: 'ERROR', payload: err });
        return Promise.reject(err);
      }
    },
    []
  );

  const remove = useCallback(async (id: Id) => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.delete(`${endpoint}/${id}`);
      dispatch({ type: 'REMOVE', payload: response.data });
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
      return Promise.reject(err);
    }
  }, []);

  return {
    ...state,
    getAll,
    post,
    patch,
    remove,
  };
};

export default useCareCategory;
