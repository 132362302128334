import styled from 'styled-components';
import { Form } from 'formik';

// technical debit - refactor inputs variants
export const StyledForm = styled(Form)`
  & label {
    color: ${({ theme }) => theme.palette.common.white};
  }
  & input {
    color: ${({ theme }) => theme.palette.common.white};
  }
  & textarea {
    color: ${({ theme }) => theme.palette.common.white};
  }
  & .MuiOutlinedInput-root:not(.Mui-error) {
    color: ${({ theme }) => theme.palette.common.white};
    & fieldset {
      border-color: ${({ theme }) => theme.palette.common.white};
      color: ${({ theme }) => theme.palette.common.white};
    }
    &:hover fieldset {
      border-color: ${({ theme }) => theme.palette.common.white};
      color: ${({ theme }) => theme.palette.common.white};
    }
    &.Mui-focused fieldset {
      border-color: ${({ theme }) => theme.palette.common.white};
      color: ${({ theme }) => theme.palette.common.white};
    }
  }
  color: ${({ theme }) => theme.palette.common.white};
`;

export const ColorContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing(1)}px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
`;
