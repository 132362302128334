import React, { memo } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import useRoles from '../../../hooks/useRoles';
import usePermissions from '../../../hooks/usePermissions';
import Form from '../../../components/RolePermissions/Form';
import LoadingBackdrop from '../../../components/LoadingBackdrop';
import { FormValues } from '../../../components/RolePermissions/utils';
import { resolveErrorMessage } from '../../../utils/error';

const NewRole: React.FC = () => {
  const { post } = useRoles();
  const { loading: loadingPermissions, allPermissions } = usePermissions();
  const history = useHistory();

  const handleSubmit = (values: FormValues) => {
    return post(values)
      .then(() => {
        toast.success('Perfil criado com sucesso');
        history.push('/usuarios/perfis');
      })
      .catch((err) => {
        const displayMessage = resolveErrorMessage(err);
        toast.error(displayMessage);
      });
  };

  if (loadingPermissions) {
    return <LoadingBackdrop loading={loadingPermissions} />;
  }

  if (!allPermissions) {
    return null;
  }

  const formInitialValue = {
    title: '',
    description: '',
    permissions: [],
    isVisible: false,
    guardianAppAccess: false,
    caregiverAppAccess: false,
    adminAppAccess: false,
  };

  return (
    <Form
      handleSubmit={handleSubmit}
      formInitialValue={formInitialValue}
      allPermissions={allPermissions}
      hasDeleteAction={false}
      saveButtonLabel="Salvar"
    />
  );
};

export default memo(NewRole);
