import styled from 'styled-components';
import {
  Button,
  DialogContent,
  Typography,
  DialogActions,
} from '@material-ui/core';
import StyledSelect from '../../Forms/FormikSelect';
import TextField from '../../Forms/FormikTextField';

interface StyledButtonProps {
  textColor: string;
  backgroundColor?: string;
  borderColor: string;
}

interface StyledDialogContentProps {
  backgroundColor?: string;
}

interface StyledDialogSubtitleProps {
  width: number;
}

export const StyledDialogContent = styled(
  DialogContent
)<StyledDialogContentProps>`
  background-color: ${(props) => props.backgroundColor};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 455px;
  width: 100%;
`;

export const DialogTitle = styled.div`
  font-weight: 700;
  margin-block: ${({ theme }) => theme.spacing(1)}px;
  width: 70%;
`;

export const DialogSubtitle = styled.div<StyledDialogSubtitleProps>`
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  text-align: center;
  width: ${(props) => props.width}%;
`;

export const StyledTypography = styled(Typography).attrs(({ variant }) => ({
  variant: variant || 'subtitle2',
}))`
  margin-top: ${({ theme }) => theme.spacing(2)}px;
`;

export const StyledSelectContainer = styled(StyledSelect)`
  color: ${({ theme }) => theme.palette.getContrastText('#FFF')};
  background-color: ${({ theme }) => theme.palette.getContrastText('#000')};
`;

export const StyledTextField = styled(TextField)`
  background-color: ${({ theme }) => theme.palette.info.main};

  .MuiInputBase-root {
    border-radius: 0;
  }

  fieldset {
    border: 0;
  }

  input {
    color: ${({ theme }) => theme.palette.getContrastText('#FFF')};
    background-color: ${({ theme }) => theme.palette.getContrastText('#000')};
    border-radius: 15px;
    font-weight: 500;
    text-align: center;
  }
`;

export const StyledDialogActions = styled(DialogActions)`
  margin-top: ${({ theme }) => theme.spacing(4)}px;
`;

export const StyledButton = styled(Button).attrs(({ variant }) => ({
  variant: variant || 'outlined',
}))<StyledButtonProps>`
  color: ${(props) => props.textColor};
  background-color: ${(props) => props.backgroundColor};
  border: 2px solid ${(props) => props.borderColor};

  &:hover {
    color: ${(props) => props.textColor};
    background-color: ${(props) => props.backgroundColor};
    border: 2px solid ${(props) => props.borderColor};
  }
`;

export const Section = styled.div`
  max-width: 300px;
  width: 100%;
`;
