import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';

export const Container = styled.div`
  margin-top: ${({ theme }) => theme.spacing(3)}px;
`;

export const EditIconButton = styled(IconButton)`
  padding: 0;
  margin-left: ${({ theme }) => theme.spacing(1)}px;
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const ColorCell = styled.div`
  display: block;
  height: ${({ theme }) => theme.spacing(3)}px;
`;

export const NoMessageDiv = styled.div`
  color: ${({ theme }) => theme.palette.info.contrastText};
`;

export const PopupHelper = styled.div`
  font-weight: bold;
  display: inline-block;
`;
