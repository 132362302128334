import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

const defaultConfig = {
  timeout: 60000,
  headers: {
    Accept: 'application/json;charset=UTF-8',
    'Content-Type': 'application/json',
    // To fix CORS problem ?
    // 'Access-Control-Allow-Origin': '*',
  },
};

export const axiosInstance = axios.create({
  ...defaultConfig,
  baseURL: `${
    process.env.AMB === 'local'
      ? process.env.LOCAL_GATEWAY_URL
      : process.env.REACT_APP_GATEWAY_URL
  }/api/v1`,
});

export const addGlobalRequestInterceptor = (
  onFulfilled: (
    config: AxiosRequestConfig
  ) => AxiosRequestConfig | Promise<AxiosRequestConfig>,
  onRejected?: () => void
) => {
  const id = axiosInstance.interceptors.request.use(onFulfilled, onRejected);
  return id;
};

export const removeGlobalRequestInterceptor = (id: number) => {
  axiosInstance.interceptors.request.eject(id);
};

export const addGlobalResponseInterceptor = (
  onFulfilled: (config: AxiosResponse) => AxiosResponse,
  // axios onRejected default type is any
  onRejected?: (error: any) => any // eslint-disable-line @typescript-eslint/no-explicit-any
) => {
  const id = axiosInstance.interceptors.response.use(onFulfilled, onRejected);
  return id;
};

export const removeGlobalResponseInterceptor = (id: number) => {
  axiosInstance.interceptors.response.eject(id);
};

export default axiosInstance;
