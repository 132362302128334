import { Typography } from '@material-ui/core';
import styled from 'styled-components';

export const StyledPlan = styled.div`
  padding: ${({ theme }) => theme.spacing(1)}px;
  margin: ${({ theme }) => theme.spacing(1)}px;
  border-radius: ${({ theme }) => theme.spacing(1)}px;
`;

export const StyledTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.extra.text.sectionColor};
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
`;

export const StyledIcon = styled.span`
  color: ${({ theme }) => theme.palette.extra.text.sectionColor};
  margin-right: ${({ theme }) => theme.spacing(1)}px;
  vertical-align: middle;
  padding-top: ${({ theme }) => theme.spacing(1)}px;
`;

export const StyledPlanDescription = styled.div`
  margin-left: ${({ theme }) => theme.spacing(5)}px;
`;
