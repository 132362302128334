import { NormalizedReport } from '@cuidador/shared'
import { Typography } from '@material-ui/core'
import { Form, Formik, FormikProps } from 'formik'
import React, { useMemo, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import FormikTextField from '../../components/Forms/FormikTextField'
import HeaderBase from '../../components/HeaderBase'
import LoadingBackdrop from '../../components/LoadingBackdrop'
import StyledButton from '../../components/StyledButton'
import useReport from '../../hooks/useReport'

import CareLineIndicator from './CareLineIndicator'
import ComplicationIndicator from './ComplicationIndicator'
import MedicationIndicator from './MedicationIndicator'
import RoutineIndicator from './RoutineIndicator'
import StaticCareLineIndicator from './StaticCareLineIndicator'

import { resolveErrorMessage } from '../../utils/error'
import {
  Container,
  Divider,
  Footer,
  PageContainer,
  PageTitle,
  TitleContainer,
} from './styles'
import { formatReportPeriod, FormValues, getInitialValues } from './utils'
import axios from '../../config/axios'
import { useAsync } from 'react-async-hook'

type RouteParams = {
  reportId: string
  patientId: string
}

const ReportSetup: React.FC = () => {
  const history = useHistory()
  const formikRef = useRef<FormikProps<FormValues> | null>()
  const { reportId, patientId } = useParams<RouteParams>()
  const { saveReportSetup, loading } = useReport()
  const [report, setReport] = useState<NormalizedReport | undefined>()

  useAsync(async () => {
    const reportResult = await getReportValues()

    setReport(reportResult)
  }, [patientId, reportId])

  async function getReportValues () {
    return (await axios.get(`/admin/patient/${patientId}/report/${reportId}`)).data
  }

  const initialValues = useMemo(() => getInitialValues(report), [report])

  const handleIndicatorChange = (id: number) => {
    if (!formikRef.current) return

    const { reportIndicatorIdList } = formikRef.current.values
    if (reportIndicatorIdList?.includes(id)) {
      formikRef.current.setFieldValue(
        'reportIndicatorIdList',
        reportIndicatorIdList.filter((item) => item !== id)
      )
    } else {
      formikRef.current.setFieldValue(
        'reportIndicatorIdList',
        reportIndicatorIdList.concat(id)
      )
    }
  }

  const handleSubmit = async ({
    message,
    reportIndicatorIdList,
  }: FormValues) => {
    return saveReportSetup(reportId, {
      message,
      reportIndicatorIdList: reportIndicatorIdList,
    })
      .then(() => {
        toast.success('PDF gerado com sucesso.')
        history.goBack()
      })
      .catch((err) => toast.error(resolveErrorMessage(err)))
  }

  return (
    <>
      {loading && <LoadingBackdrop loading={loading} />}
      <HeaderBase title="CONFIGURAR RELATÓRIO" />
      {!report ? null : (
        <PageContainer>
          <Container>
            <TitleContainer>
              <PageTitle align="center">{report.name}</PageTitle>
              <Typography align="center">
                {formatReportPeriod(report.startDate!, report.endDate!)}
              </Typography>
            </TitleContainer>

            <Typography>
              {'Paciente: '}
              <Typography color="textPrimary" component="span">
                {report.patient?.name}
              </Typography>
            </Typography>
            <Typography>
              {'Cuidador(es): '}
              <Typography color="textPrimary" component="span">
                {report.relatedCaregivers?.map((user) => user?.name).join(', ')}
              </Typography>
            </Typography>

            <Divider />
            <Formik
              innerRef={(ref) => (formikRef.current = ref)}
              initialValues={initialValues}
              onSubmit={handleSubmit}
            >
              {({ values, isSubmitting }) => (
                <Form>
                  <FormikTextField
                    name="message"
                    inputProps={{
                      'data-testid': 'message',
                    }}
                    multiline
                    rows={2}
                    fullWidth
                    label="Texto do relatório"
                  />

                  <Divider />

                  {report.complicationIndicators && report.complicationIndicators?.length > 0 && <ComplicationIndicator
                    data={report.complicationIndicators}
                    selectedIndicators={values.reportIndicatorIdList}
                    onChange={handleIndicatorChange}
                  />}

                  <Divider />

                  {Boolean(report.medicationIndicator) && <MedicationIndicator
                    data={report.medicationIndicator}
                    selectedIndicators={values.reportIndicatorIdList}
                    onChange={handleIndicatorChange}
                  />}

                  <Divider />

                  {report.routineIndicators && report.routineIndicators.length > 0 && <RoutineIndicator
                    data={report.routineIndicators}
                    selectedIndicators={values.reportIndicatorIdList}
                    onChange={handleIndicatorChange}
                  />}

                  <Divider />

                  {report.careCategoriesIndicators && report.careCategoriesIndicators.length > 0 && <CareLineIndicator
                    data={report.careCategoriesIndicators}
                    selectedIndicators={values.reportIndicatorIdList}
                    onChange={handleIndicatorChange}
                  />}

                  <Divider />

                  {report.staticCareCategoriesIndicators &&
                    report?.staticCareCategoriesIndicators?.length > 0 && (
                      <>
                        <StaticCareLineIndicator
                          data={report.staticCareCategoriesIndicators}
                          selectedIndicators={values.reportIndicatorIdList}
                          onChange={handleIndicatorChange}
                        />

                        <Divider />
                      </>
                    )}

                  <Footer>
                    <StyledButton
                      disabled={isSubmitting}
                      type="submit"
                      color="primary"
                    >
                      Gerar PDF
                    </StyledButton>
                  </Footer>
                </Form>
              )}
            </Formik>
          </Container>
        </PageContainer>
      )}
    </>
  )
}

export default ReportSetup
