import React, { useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Background, Container } from './styles';
import { TableCell, Checkbox } from '@material-ui/core';
import qs from 'query-string';
import { useHistory } from 'react-router-dom';
import { EventModel } from '@cuidador/database';
import useComplication from '../../../hooks/useComplication';
import MyTable from '../../../components/MyTable';
import formatDate from 'date-fns/format';
import useEventResolved from '../../../hooks/useEventResolved';
import { resolveErrorMessage } from '../../../utils/error';
import { AxiosError } from 'axios';
import { APIError } from '@cuidador/lib';
import { toast } from 'react-toastify';

const tableColumns = [
  'Data',
  'Tipo',
  'Reportado por',
  'Descrição',
  'Intercorrência tratada',
];

const ComplicationList: React.FC = () => {
  const { patch, loading: loadingEventResolved } = useEventResolved();
  const { id } = useParams<{ id: string }>();

  const {
    getPaginated,
    ids,
    byId,
    total,
    loading: loadingComplication,
  } = useComplication();
  const history = useHistory();
  const limit = parseInt(String(qs.parse(history.location.search).limit)) || 20;

  useEffect(() => {
    onChangeParams();
  }, []);

  const onChangeParams = () => {
    const params = qs.parse(history.location.search);
    getPaginated(Number(id), {
      ...params,
      limit: Number(params.limit) || 20,
    });
  };

  const renderComplicationItem = useCallback((item: EventModel) => {
    return (
      <>
        <TableCell>
          {item.eventHappensAt &&
            formatDate(new Date(item.eventHappensAt), 'dd/MM/yyyy - HH:mm')}
        </TableCell>
        <TableCell>{item.subCategory?.name}</TableCell>
        <TableCell>{item.shift?.caregiver?.user?.name}</TableCell>
        <TableCell>{item.description}</TableCell>
        <TableCell align="center">
          <Checkbox
            // alt is used to perform the checkbox click test and patch request
            inputProps={{ alt: `complication-resolved-${item.id}` }}
            color="primary"
            checked={item.isResolved ? true : false}
            onChange={(e) =>
              patch(Number(item?.id), { isResolved: e.target.checked })
                .then(() => {
                  return onChangeParams();
                })
                .catch((err: AxiosError<APIError>) => {
                  const displayMessage = resolveErrorMessage(err);
                  toast.error(displayMessage);
                })
            }
          />
        </TableCell>
      </>
    );
  }, []);

  const data = ids.map((id) => byId[id]);

  return (
    data && (
      <Background>
        <Container>
          <MyTable
            columnNames={tableColumns}
            data={data}
            totalPages={Math.ceil(total / limit)}
            keyExtractor={({ item }) => String(item!.id)}
            renderItem={renderComplicationItem}
            loading={loadingComplication || loadingEventResolved}
            onChangePage={onChangeParams}
            onChangeRowsPerPage={onChangeParams}
          />
        </Container>
      </Background>
    )
  );
};

export default ComplicationList;
