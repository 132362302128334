import styled from 'styled-components';
import { ContentProps } from '.';

export const Container = styled.div`
  display: flex;
`;

export const Content = styled.div<ContentProps>`
  flex: 1;
  margin-left: ${({ fullWidth }) => (fullWidth ? '0px' : '256px')};
  min-height: 100vh;
  overflow-x: hidden;
`;
