import React from 'react';
import { DialogProps } from '@material-ui/core/Dialog';
import StyledDialog from '../../../../components/StyledDialog';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Typography,
  FormHelperText,
} from '@material-ui/core';
import StyledButton from '../../../../components/StyledButton';
import StyledTextFieldWhite from '../../../../components/StyledTextFieldWhite';
import { SwatchesPicker } from 'react-color';
import { StyledForm, ColorContainer } from './styles';
import { Formik } from 'formik';

import { FormValues, validationSchema } from '../utils';
import { numberMask } from '../../../../utils/inputs';
import { CareCategoryModel } from '@cuidador/database';

interface CareCategoryDialogProps extends DialogProps {
  careCategory?: CareCategoryModel;
  onConfirm: (values: FormValues) => void;
  onCancel: () => void;
}

const CareCategoryDialog: React.FC<CareCategoryDialogProps> = ({
  careCategory,
  onCancel,
  onConfirm,
  ...rest
}) => {
  const formInitialValue = {
    name: careCategory?.name || '',
    colorHexa: careCategory?.colorHexa
      ? `#${careCategory?.colorHexa}`
      : '#b71c1c', // default color value
    order: careCategory?.order ? String(careCategory?.order) : '',
  };

  const isEdit = careCategory ? true : false;

  return (
    <StyledDialog {...rest}>
      <DialogContent>
        <DialogTitle>
          {isEdit ? 'Editar' : 'Adicionar'} categoria de cuidado
        </DialogTitle>
        <Formik
          validationSchema={validationSchema}
          initialValues={formInitialValue}
          onSubmit={onConfirm}
          validateOnChange={false}
        >
          {(formik) => (
            <StyledForm noValidate={true}>
              <DialogContentText>
                <StyledTextFieldWhite
                  name="name"
                  label="Nome da categoria de cuidado"
                  inputProps={{ 'data-testid': 'name-textbox' }}
                  value={formik.values.name}
                  error={Boolean(formik.errors.name)}
                  onChange={formik.handleChange}
                  helperText={formik.errors.name}
                  margin="normal"
                />
                <StyledTextFieldWhite
                  name="order"
                  label="Ordem"
                  inputProps={{ 'data-testid': 'order-textbox' }}
                  value={numberMask(formik.values.order)}
                  error={Boolean(formik.errors.order)}
                  onChange={formik.handleChange}
                  helperText={formik.errors.order}
                  margin="normal"
                />
                <Typography variant="subtitle1">Selecione uma cor</Typography>
                <ColorContainer>
                  <SwatchesPicker
                    color={formik.values.colorHexa}
                    onChange={(color) => {
                      formik.setFieldValue('colorHexa', color.hex);
                    }}
                  />
                </ColorContainer>
                {formik.errors.colorHexa && (
                  <FormHelperText error={true}>
                    {formik.errors.colorHexa}
                  </FormHelperText>
                )}
              </DialogContentText>
              <DialogActions>
                <StyledButton
                  disabled={formik.isSubmitting}
                  onClick={onCancel}
                  color="inherit"
                >
                  Cancelar
                </StyledButton>
                <StyledButton
                  disabled={formik.isSubmitting}
                  type="submit"
                  data-testid="save-category-button"
                  variant="text"
                  color="inherit"
                >
                  Salvar
                </StyledButton>
              </DialogActions>
            </StyledForm>
          )}
        </Formik>
      </DialogContent>
    </StyledDialog>
  );
};

export default CareCategoryDialog;
