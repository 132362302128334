/* eslint-disable @typescript-eslint/no-unused-vars */
import { createMuiTheme, Theme } from '@material-ui/core/styles';

declare module 'styled-components' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface DefaultTheme extends Theme {
    palette: typeof styledTheme.palette;
  }
}

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#81BEE0',
      main: '#00076D',
    },
    secondary: {
      main: '#2B999F',
    },
    info: {
      main: '#0088D3',
      dark: '#636363',
      contrastText: '#C8C7C7',
    },
    error: {
      main: '#5c1221',
      light: '#a71633',
      dark: '#eb6441',
    },
    warning: {
      main: 'rgb(233, 255, 20)',
      dark: '#F6AF32',
      light: '#D20808',
    },
    success: {
      main: 'rgb(52, 187, 102)',
      light: '#24D23B',
    },
  },
  typography: {
    fontFamily: 'Work Sans',
    fontSize: 16,
  },
  spacing: 8,
  overrides: {
    MuiButton: {
      root: {
        borderRadius: '9px',
        textTransform: 'unset',
      },
    },
    MuiTab: {
      root: {
        textTransform: 'unset',
      },
    },
  },
});

export const styledTheme = {
  ...theme,
  palette: {
    ...theme.palette,
    extra: {
      indicators: {
        good: '#109839',
        bad: '#DE5C3B',
        moderate: '#F9C840',
      },
      text: {
        dark: '#242424',
        gray: '#454545',
        sectionColor: '#f3f3f3',
        white: '#fff',
      },
      patientStatusCard: {
        active: '#009922',
        inactive: '#636363',
        pending: '#E0B006',
      },
    },
  },
};

export default theme;
