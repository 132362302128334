import { TableRow } from '../../../hooks/useShift';

export interface ConditionalRow {
  when: keyof TableRow | 'default';
  is?: unknown;
  then: keyof TableRow;
}

export type TableColumnMap = [string, keyof TableRow | ConditionalRow[]];

export const tableColumnMap: TableColumnMap[] = [
  ['ID', 'shiftPatientId'],
  ['Nome plantão', 'shiftName'],
  ['Status', 'status'],
  [
    'Execução',
    [
      { when: 'rowType', is: 'execution', then: 'executionNumber' },
      { when: 'default', then: 'executionsAmount' },
    ],
  ],
  ['Início planejado', 'shiftPlannedToStartAt'],
  ['Fim planejado', 'shiftPlannedToEndAt'],
  ['Início registrado', 'executionRegisteredStartedAt'],
  ['Fim registrado', 'executionRegisteredEndedAt'],
  ['Início contestado', 'executionStartedAt'],
  ['Fim contestado', 'executionEndedAt'],
  ['ID Cuidador', 'caregiverId'],
  ['Cuidador', 'caregiverName'],
  ['Total eventos', 'totalEventsAmount'],
  ['Feitos', 'totalAccomplishedEventsAmount'],
  ['Não feitos', 'totalNotAccomplishedEventsAmount'],
  ['Total medicamentos', 'totalMedicationEventsAmount'],
  ['Administrados', 'totalMedicationAccomplishedEventsAmount'],
  ['Não administrados', 'totalMedicationNotAccomplishedEventsAmount'],
  ['PCs total', 'totalCareQuestionsAmount'],
  ['PCs respondidas', 'totalCareQuestionsAnswerAmount'],
  ['Intercorrências', 'totalComplicationEventsAmount'],
];

export const resolveValueKeyOfFieldFromColumnMap = (
  [, field]: TableColumnMap,
  row: TableRow
) => {
  if (typeof field === 'string') return field;

  const conditionedField = field.find(
    (condition) =>
      condition.when !== 'default' && row[condition.when] === condition.is
  );

  const defaultField = field.find(({ when }) => when === 'default');

  if (!!conditionedField) return conditionedField.then;

  if (!conditionedField && !!defaultField) return defaultField.then;

  return;
};
