import React from 'react';
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableSortLabel,
  Select,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import qs from 'query-string';
import Pagination from '@material-ui/lab/Pagination';
import StyledMenuItem from '../StyledMenuItem';
import ExportTable, { DownloadExcelTable } from '../ExportTable';

import {
  Container,
  HeaderCell,
  TableContainer,
  Backdrop,
  BackdropCircularProgress,
  PaginationContainer,
} from './styles';

interface MyTableProps<T> extends DownloadExcelTable {
  columnNames: string[];
  data: Array<T>;
  totalPages?: number;
  rowsPerPageOptions?: Array<number>;
  loading?: boolean;
  hasPagination?: boolean;
  order?: 'asc' | 'desc';
  orderBy?: string;
  onChangePage?: (page: number) => void;
  onChangeRowsPerPage?: (rowsPerPage: number) => void;
  onRequestSort?: (column: string) => void;
  renderItem?: (item: T, index?: number) => JSX.Element;
  renderRow?: (item: T, index?: number) => JSX.Element;
  keyExtractor?: (row: { index: number; item: T }) => string;
}

const LoadingBackdrop: React.FC<{
  loading: boolean;
}> = ({ loading }) => {
  return (
    <Backdrop open={loading}>
      {loading && (
        <BackdropCircularProgress data-testid="table-backdrop-spinner" />
      )}
    </Backdrop>
  );
};

export default function MyTable<T>({
  columnNames,
  data,
  totalPages,
  loading = false,
  hasPagination = true,
  rowsPerPageOptions = [20, 35, 50],
  order,
  orderBy,
  renderItem,
  renderRow,
  onChangePage,
  onChangeRowsPerPage,
  onRequestSort,
  keyExtractor,
  isExportableTable,
  fileName,
  sheetName,
  tablePayload,
}: MyTableProps<T>): JSX.Element {
  const history = useHistory();

  const handleRequestSort = (column: string) => {
    if (onRequestSort) {
      onRequestSort(column);
    }
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown> | null,
    page: number
  ) => {
    const newSearch = { ...qs.parse(history.location.search), page: page - 1 };
    const newUrl = qs.stringifyUrl({
      url: history.location.pathname,
      query: newSearch,
    });
    history.push(newUrl);

    if (onChangePage) {
      onChangePage(page);
    }
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const newRowsPerPage = parseInt(event.target.value as string);
    const newSearch = {
      ...qs.parse(history.location.search),
      limit: newRowsPerPage,
      page: 0,
    };
    const newUrl = qs.stringifyUrl({
      url: history.location.pathname,
      query: newSearch,
    });
    history.push(newUrl);

    if (onChangeRowsPerPage) {
      onChangeRowsPerPage(newRowsPerPage);
    }
  };

  const currentPage =
    parseInt(String(qs.parse(history.location.search).page || 0)) + 1;

  const rowsPerPage =
    parseInt(String(qs.parse(history.location.search).limit)) ||
    rowsPerPageOptions[0];

  return (
    <Container>
      <ExportTable
        isExportableTable={isExportableTable}
        fileName={fileName}
        sheetName={sheetName}
        tablePayload={tablePayload}
      >
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {columnNames.map((columnName) => (
                  <HeaderCell key={columnName}>
                    <TableSortLabel
                      active={orderBy === columnName}
                      direction={orderBy === columnName ? order : 'asc'}
                      onClick={() => handleRequestSort(columnName)}
                    >
                      {columnName}
                    </TableSortLabel>
                  </HeaderCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {data.map((item, index) => {
                if (!!renderItem)
                  return (
                    <TableRow
                      hover
                      key={
                        (!!keyExtractor && keyExtractor({ index, item })) ||
                        `table-row-${index}`
                      }
                    >
                      {renderItem(item, index)}
                    </TableRow>
                  );

                if (!!renderRow) return <>{renderRow(item, index)}</>;

                return <></>;
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </ExportTable>

      {hasPagination && (
        <PaginationContainer>
          <Select
            name="rowsPerPage-select"
            value={String(rowsPerPage)}
            onChange={handleRowsPerPageChange}
          >
            {rowsPerPageOptions?.map((item) => (
              <StyledMenuItem key={item} value={item} color="secondary">
                {item}
              </StyledMenuItem>
            ))}
          </Select>
          <Pagination
            data-testid="table-pagination-container"
            color="primary"
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
          />
        </PaginationContainer>
      )}
      <LoadingBackdrop loading={loading} />
    </Container>
  );
}
