import { PatientModel } from '@cuidador/database';
import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  expirationDate: yup
    .date()
    .required('Por favor, insira uma data no futuro')
    .min(new Date(), 'Por favor, insira uma data no futuro'),
});

export const formInitialValues = {
  expirationDate: '',
};

export interface ChangeExpirationDateFormValues {
  expirationDate: string;
}
export interface ChangeClassificationFormValues {
  classification: PatientModel['classification'];
}

export const formatClassification = (
  classification: PatientModel['classification']
): string => {
  if (!classification) return '';
  const classificationMap = {
    functional: 'Funcional',
    dysfunctional: 'Disfuncional',

    severe_dysfunctional: 'Disfuncional grave',
  };
  return classificationMap[classification];
};
