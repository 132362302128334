import React from 'react';
import StyledSelectField from './styles';
import StyledFormControl from '../StyledFormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { FormHelperText } from '@material-ui/core';

export interface SelectProps {
  name?: string;
  label: string;
  value?: string;
  id: string;
  error?: boolean;
  disabled?: boolean;
}

const StyledSelect: React.FC<SelectProps> = ({
  id,
  label,
  children,
  error,
  disabled,
  ...props
}) => {
  return (
    <StyledFormControl fullWidth error={Boolean(error)} disabled={disabled}>
      <InputLabel id={id}>{label}</InputLabel>
      <StyledSelectField
        label={label}
        labelId={id}
        disabled={disabled}
        {...props}
      >
        {children}
      </StyledSelectField>
      {error && <FormHelperText>{error}</FormHelperText>}
    </StyledFormControl>
  );
};

export default StyledSelect;
