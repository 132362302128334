import { useEffect, useState } from 'react';
import { PermissionModel } from '@cuidador/database';
import { toast } from 'react-toastify';
import axios from '../config/axios';
import { resolveErrorMessage } from '../utils/error';

const endpoint = '/auth/role';

export interface AllPermissions {
  [resource: string]: PermissionModel[];
}

const usePermissions = () => {
  const [loading, setLoading] = useState(true);
  const [allPermissions, setAllPermissions] = useState<AllPermissions>({});

  const fetchAllPermissions = async () => {
    try {
      const response = await axios.get(`${endpoint}/permission`);
      const allPermissions = response.data;
      setAllPermissions(allPermissions);
    } catch (err) {
      const displayMessage = resolveErrorMessage(err);
      toast.error(displayMessage);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllPermissions();
  }, []);

  return {
    loading,
    allPermissions,
  };
};

export default usePermissions;
