import { useCallback, useReducer } from 'react';
import axios from '../config/axios';
import { CareLineIndicatorMessageObject } from '@cuidador/database';
import {
  createReducer,
  ReducerData,
  Item,
  PaginatedRequestParams,
} from '../utils/store/index';
import { normalizeCareLineIndicatorMessage } from '../utils/indicators';

const endpoint = '/report/indicator';

const initialData: ReducerData<CareLineIndicatorMessageObject> = {
  byId: {} as Record<string, Item<CareLineIndicatorMessageObject>>,
  ids: [] as Array<number>,
  total: 0,
  loading: false,
  error: null,
};

const useContent = () => {
  const [state, dispatch] = useReducer(
    createReducer<CareLineIndicatorMessageObject>(),
    initialData
  );

  const getPaginated = useCallback(async (params: PaginatedRequestParams) => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.get(`${endpoint}`, { params });
      dispatch({
        type: 'PAGINATION',
        payload: {
          results: response.data.results.map(normalizeCareLineIndicatorMessage),
          total: response.data.total,
        },
      });
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
    }
  }, []);

  const patchMessage = useCallback(
    async (data: Partial<CareLineIndicatorMessageObject>) => {
      try {
        dispatch({ type: 'LOADING' });
        const response = await axios.patch(`${endpoint}/message`, data);
        dispatch({ type: 'UPDATE', payload: response.data });
      } catch (err) {
        dispatch({ type: 'ERROR', payload: err });
        return Promise.reject(err);
      }
    },
    []
  );

  return {
    ...state,
    getPaginated,
    patchMessage,
  };
};

export default useContent;
