import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.spacing(4)}px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
`;

export const EditIconButton = styled(IconButton)`
  padding: 0;
  color: ${({ theme }) => theme.palette.primary.main};
  margin-left: ${({ theme }) => theme.spacing(1)}px;
`;
