import * as Yup from 'yup';
import { CareQuestionModel, AnswerConfig } from '@cuidador/database';

export const validationSchema = Yup.object().shape({
  name: Yup.mixed().required('Por favor, selecione o nome da pergunta'),
  careCategoryId: Yup.number().required(
    'Por favor, insira uma categoria de cuidado'
  ),
  orderNumber: Yup.number().required('Por favor, insira uma ordem'),
  careQuestionText: Yup.string().required('Por favor, insira uma pergunta'),
});

export interface FormValues {
  name?: string;
  careCategoryId: Id | undefined;
  orderNumber: number | undefined;
  careQuestionText: string;
  answerConfig: AnswerConfig | null;
}

export const formInitialValue = {
  name: '',
  careCategoryId: undefined,
  orderNumber: undefined,
  careQuestionText: '',
  answerConfig: null,
};

export const formDataToInterviewQuestionModel = (values: FormValues) =>
  ({
    name: values.name,
    careCategoryId: values.careCategoryId,
    orderNumber: values.orderNumber,
    careQuestionText: values.careQuestionText,
    answerConfig: values.answerConfig,
    type: 'interview_question',
  } as CareQuestionModel);

export const interviewQuestionModelToFormData = (
  careQuestion: CareQuestionModel
): FormValues => ({
  name: careQuestion.name,
  careCategoryId: parseInt(`${careQuestion.careCategoryId}`),
  orderNumber: parseInt(`${careQuestion.orderNumber}`),
  careQuestionText: String(careQuestion.careQuestionText),
  answerConfig: careQuestion.answerConfig as AnswerConfig,
});
