import styled from 'styled-components';
import { Typography, TableRow } from '@material-ui/core';

export const Background = styled.div`
  padding: 0px ${({ theme }) => theme.spacing(2)}px;
  margin: ${({ theme }) => theme.spacing(2, 4, 2)};
  width: 100%;
`;

export const Container = styled.div`
  width: 100%;
`;

export const EmptyTableTypography = styled(Typography)`
  text-align: center;
`;

export const TableRowWithBackground = styled(TableRow)<{
  variant: 'opaque' | 'clear';
}>`
  background-color: ${({ variant, theme }) =>
    variant === 'opaque'
      ? theme.palette.grey[200]
      : theme.palette.background.paper};
`;
