import styled from 'styled-components';
import { Paper } from '@material-ui/core';

export const Container = styled.div`
  padding: ${({ theme }) => theme.spacing(0, 4)};
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

export const StyledPaperTab = styled(Paper)`
  margin-top: ${({ theme }) => theme.spacing(2)}px;
  border-radius: 12px;
`;
